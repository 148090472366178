import axios from "axios";
import { travel_url } from "../../../Host";
import { commonheader } from "../../../utils/header";
const IPWhiteInstance = axios.create({
  baseURL: travel_url,
});
IPWhiteInstance.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      config.headers = commonheader();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const getIPWhiteListApi = () => IPWhiteInstance.get(`/getIPs`);
export const addIPWhiteListApi = (data) =>
  IPWhiteInstance.post(`/saveIP`, data);
export const deleteIPWhiteListApi = (id) =>
  IPWhiteInstance.delete(`/deleteIP?id=${id}`);
