import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "react-bootstrap";

const TransactionModal = ({
  modalShow,
  handleClose,
  gateway,
  switcher,
  gaID,
}) => {
  return (
    <div>
      <Modal show={modalShow} onHide={handleClose} className="Modal">
        <ModalHeader close>Payment gateway Table</ModalHeader>
        <ModalBody>
          {gateway
            .filter((item) => item.gid == gaID)
            .map((items) => (
              <Table>
                <thead className="bg-light">
                  <tr>
                    <th>GatewayId</th>
                    <th>GatewayName</th>
                    <th>modifiedDate</th>
                    <th>modifiedBy</th>
                    <th>chargePercentage</th>
                    <th>JsLogic</th>
                    <th>Salt</th>
                    <th>privateKey</th>
                    <th>publicKey</th>
                    <th>secret</th>
                    <th>webhook</th>
                    <th>errorCallBack</th>
                    <th>successCallback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{items.gid}</td>
                    <td>{items.gatewayName}</td>
                    <td>{items.modifiedDate}</td>
                    <td>{items.modifiedBy}</td>
                    <td>{items.chargePercentage}</td>
                    <td>{items.jsLogic}</td>
                    <td>{items.salt}</td>
                    <td>{items.privateKey}</td>
                    <td>{items.publicKey}</td>
                    <td>{items.secret}</td>
                    <td>{items.webhook}</td>
                    <td>{items.errorCallBack}</td>
                    <td>{items.successCallback}</td>
                  </tr>
                </tbody>
              </Table>
            ))}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
};

export default TransactionModal;
