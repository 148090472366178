import moment from "moment/moment";

export const UpdateTravoFareRuleApi = (
  data,
  supplierList,
  metaList,
  isduplicate
) => [
  {
    id: 1,
    name: "ruleName",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter Rule Name",
    label: "Rule Name",
    required: true,
    disabled: isduplicate ? false : true,
    defaultValue: data.ruleName,
  },
  {
    id: 3,
    name: "origin",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter Origin",
    label: "Origin",
    required: true,
    disabled: false,
    defaultValue: data.origin,
  },
  {
    id: 4,
    name: "destination",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter Destination",
    label: "Destination",
    required: true,
    disabled: false,
    defaultValue: data.destination,
  },
  {
    id: 5,
    name: "cabinType",
    type: "select",
    placeholder: "Enter Cabin Type",
    label: "Cabin Type",
    required: true,
    disabled: false,
    optionValue: [
      { id: 0, name: "ALL", value: 0 },
      { id: 1, name: "Economy", value: 1 },
      { id: 2, name: "Premium Economy", value: 2 },
      { id: 3, name: "Bussines", value: 3 },
      { id: 4, name: "First", value: 4 },
    ],
    defaultValue: data.cabinType,
  },
  {
    id: 24,
    name: "trip",
    type: "select",
    placeholder: "Enter trip Type",
    label: "Trip Type",
    required: true,
    disabled: false,
    optionValue: [
      { id: 0, name: "ALL", value: 0 },
      { id: 1, name: "One Way", value: 1 },
      { id: 2, name: "Round Trip", value: 2 },
    ],
    defaultValue: data.trip,
  },
  {
    id: 6,
    name: "stops",
    type: "select",
    placeholder: "Enter Stops",
    label: "Select Stops",
    required: true,
    disabled: false,
    optionValue: [
      { id: 0, name: "ALL", value: 0 },
      { id: 1, name: "Direct Flight", value: 1 },
      { id: 2, name: "1 Stop Flight", value: 2 },
      { id: 3, name: "2 Stop Flight", value: 3 },
      { id: 4, name: "3 Stop Flight", value: 4 },
      { id: 5, name: "4 Stop Flight", value: 5 },
    ],
    defaultValue: data.stops,
  },
  {
    id: 7,
    name: "fromCountry",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter From Country Code",
    label: "From Country Code",
    required: true,
    disabled: false,
    defaultValue: data.fromCountry,
  },
  {
    id: 8,
    name: "toCountry",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter To Country Code",
    label: "To Country Code",
    required: true,
    disabled: false,
    defaultValue: data.toCountry,
  },
  {
    id: 9,
    name: "siteId",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter Site Id",
    label: "Site Id",
    required: true,
    disabled: false,
    defaultValue: data.siteId,
  },
  {
    id: 10,
    name: "airline",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter Airline",
    label: "Airline",
    required: true,
    disabled: false,
    defaultValue: data.airline,
  },
  {
    id: 26,
    name: "opAkAirline",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter Opaque Airline",
    label: "Opaque Airline",
    required: true,
    disabled: !["SKYSCANNER", "MOMONDOIND"].includes(metaList),
    defaultValue: data.opAkAirline,
  },
  {
    id: 25,
    name: "classOfService",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "X,Y,Z",
    label: "Onward Class Of Service",
    required: true,
    disabled: false,
    defaultValue: data.classOfService,
  },
  {
    id: 33,
    name: "classOfServiceReturn",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "X,Y,Z",
    label: "Return Class Of Service",
    required: true,
    disabled: false,
    defaultValue: data.classOfServiceReturn,
  },
  {
    id: 31,
    name: "cappingDays",
    pattern: "[a-zA-Z]+",
    type: "number",
    placeholder: "Enter Cap days",
    label: "Cap Days",
    required: true,
    disabled: false,
    defaultValue: "",
    defaultValue: data.cappingDays,
  },
  {
    id: 32,
    name: "cappingAmount",
    pattern: "[a-zA-Z]+",
    type: "number",
    placeholder: "Enter Cap Amount",
    label: "Cap Amount",
    required: true,
    disabled: false,
    defaultValue: "",
    defaultValue: data.cappingAmount,
  },
  {
    id: 11,
    name: "source",
    type: "select",
    placeholder: "Enter Source",
    label: "Source",
    required: true,
    disabled: typeof metaList == "string" ? true : false,
    optionValue:
      typeof metaList == "string"
        ? [{ id: 1, name: metaList, value: metaList }]
        : metaList.map((item, i) => {
            return { id: i + 1, name: item, value: item };
          }),
    defaultValue: data.source,
  },
  {
    id: 22,
    name: "fromDate",
    pattern: "[a-zA-Z]+",
    type: "date",
    placeholder: "Enter Date",
    label: "From Date",
    required: true,
    disabled: false,
    defaultValue: moment(data.fromDate).format("YYYY-MM-DD"),
  },
  {
    id: 23,
    name: "toDate",
    pattern: "[a-zA-Z]+",
    type: "date",
    placeholder: "Enter Date",
    label: "To Date",
    required: true,
    disabled: false,
    defaultValue: moment(data.toDate).format("YYYY-MM-DD"),
  },
  {
    id: 34,
    name: "fromAmount",
    pattern: "[0-9]+",
    type: "number",
    placeholder: "Enter from amount",
    label: "From Amount",
    required: true,
    disabled: false,
    defaultValue: data.fromAmount,
  },
  {
    id: 35,
    name: "toAmount",
    pattern: "[0-9]+",
    type: "number",
    placeholder: "Enter to amount",
    label: "To Amount",
    required: true,
    disabled: false,
    defaultValue: data.toAmount,
  },
  {
    id: 2,
    name: "api",
    type: "select",
    placeholder: "Enter Supplier",
    label: "Supplier",
    required: true,
    disabled: false,
    optionValue: [
      { id: 0, name: "All Supplier", value: "all" },
      ...supplierList,
    ],
    defaultValue: data.api,
  },
  // {
  //   id: 12,
  //   name: "fareType",
  //   type: "select",
  //   placeholder: "Enter FareType",
  //   label: "Fare Type",
  //   required: true,
  //   disabled: false,
  //   optionValue: [
  //     { id: 0, name: "Select", value: "" },
  //     { id: 1, name: "Publish", value: "pub" },
  //     { id: 2, name: "Private", value: "pvt" },
  //   ],
  //   defaultValue: data.fareType,
  // },
  {
    id: 12,
    name: "fareType",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "EX- pub:250:F,pvt:-100:F",
    label: "Enter FareType Key Value",
    required: true,
    disabled: false,
    defaultValue: data.fareType,
  },
  // {
  //   id: 13,
  //   name: "markupType",
  //   type: "select",
  //   placeholder: "Enter Markup Type",
  //   label: "Markup Type",
  //   required: true,
  //   disabled: false,
  //   optionValue: [
  //     { id: 0, name: "Select", value: "" },
  //     { id: 1, name: "Discount", value: "DISCOUNT" },
  //     { id: 2, name: "Markup", value: "MARKUP" },
  //   ],
  //   defaultValue: data.markupType,
  // },
  // {
  //   id: 14,
  //   name: "amountType",
  //   type: "select",
  //   placeholder: "Enter Amount Type",
  //   label: "Amount Type",
  //   required: true,
  //   disabled: false,
  //   optionValue: [
  //     { id: 0, name: "Select", value: "" },
  //     { id: 1, name: "Flat", value: "FLAT" },
  //     { id: 2, name: "Percent", value: "PERCENT" },
  //   ],
  //   defaultValue: data.amountType,
  // },

  {
    id: 14,
    name: "adult",
    type: "select",
    placeholder: "Enter Adult No",
    label: "Adult",
    required: true,
    disabled: false,
    optionValue: adultList,
    defaultValue: data.adult,
  },
  {
    id: 16,
    name: "child",
    type: "select",
    placeholder: "Enter Child No",
    label: "Child",
    required: true,
    disabled: false,
    optionValue: adultList,
    defaultValue: data.child,
  },
  {
    id: 17,
    name: "infant",
    type: "select",
    placeholder: "Enter Infant No",
    label: "Infant",
    required: true,
    disabled: false,
    optionValue: adultList,
    defaultValue: data.infant,
  },
  // {
  //   id: 18,
  //   name: "amount",
  //   pattern: "",
  //   type: "number",
  //   placeholder: "Enter Amount",
  //   label: "Amount",
  //   required: true,
  //   disabled: false,
  //   defaultValue: data.amount,
  // },

  {
    id: 19,
    name: "enabled",
    type: "select",
    placeholder: "Enter Status",
    label: "Status",
    required: true,
    disabled: isduplicate ? false : true,
    optionValue: [
      { id: 0, name: "Select", value: "" },
      { id: 1, name: "True", value: true },
      { id: 2, name: "False", value: false },
    ],
    defaultValue: data.enabled,
  },
  {
    id: 20,
    name: "convenienceFee",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter Convenience Fee",
    label: "Convenience Fee",
    required: true,
    disabled: false,
    defaultValue: data.convenienceFee,
  },
  // {
  //   id: 21,
  //   name: "additionalDisAmountType",
  //   type: "select",
  //   placeholder: "Enter Additional Amount Type",
  //   label: "Additional Amount Type",
  //   required: true,
  //   disabled: false,
  //   optionValue: [
  //     { id: 0, name: "Select", value: "" },
  //     { id: 1, name: "Flat", value: "FLAT" },
  //     { id: 2, name: "Percent", value: "PERCENT" },
  //   ],
  //   defaultValue: data.additionalDisAmountType,
  // },
  {
    id: 21,
    name: "additionalDisAmount",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "EX- pub:250:F,pvt:-100:F",
    label: "Additional Discount Key Value",
    required: true,
    disabled: false,
    defaultValue: data.additionalDisAmount,
  },
];

const adultList = [
  {
    id: -1,
    name: "Exclude",
    value: -1,
  },
  {
    id: 0,
    name: "All",
    value: 0,
  },
  {
    id: 1,
    name: 1,
    value: 1,
  },
  {
    id: 2,
    name: "1P",
    value: "1P",
  },
  {
    id: 3,
    name: 2,
    value: 2,
  },
  {
    id: 4,
    name: "2P",
    value: "2P",
  },
  {
    id: 5,
    name: 3,
    value: 3,
  },
  {
    id: 6,
    name: "3P",
    value: "3P",
  },
  {
    id: 7,
    name: 4,
    value: 4,
  },
  {
    id: 8,
    name: "4P",
    value: "4P",
  },
  {
    id: 9,
    name: 5,
    value: 5,
  },
  {
    id: 10,
    name: "5P",
    value: "5P",
  },
  {
    id: 11,
    name: 6,
    value: 6,
  },
  {
    id: 12,
    name: "6P",
    value: "6P",
  },
  {
    id: 13,
    name: 7,
    value: 7,
  },
  {
    id: 14,
    name: "7P",
    value: "7P",
  },
  {
    id: 15,
    name: 8,
    value: 8,
  },
  {
    id: 16,
    name: "8P",
    value: "8P",
  },
  {
    id: 17,
    name: 9,
    value: 9,
  },
];
