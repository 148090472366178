import React, { useEffect, useState } from "react";
import { travel_url } from "../../Host";
import Source from "../../CustomApi/Source.json";
import moment from "moment";
import Collapsible from "react-collapsible";

const PaymnetFilter = ({ setFilterObj }) => {
  const [filterObjs, setFilterObjs] = useState({
    from: 0,
    to: 0,
    orderId: "",
    transactionID: "",
    transactionstatus: "",
    gatewayName: "",
    userId: "",
  });

  const handlerChange = (e, input) => {
    e.preventDefault();
    if (input === "from") {
      const date = new Date(e.target.value);
      const ab = date.getTime();
      setFilterObjs({ ...filterObjs, [input]: ab });
    } else if (input === "to") {
      const date = new Date(e.target.value);
      const ab = date.getTime();
      setFilterObjs({ ...filterObjs, [input]: ab });
    } else {
      setFilterObjs({ ...filterObjs, [input]: e.target.value });
    }
  };
  const bookingFormData = (e) => {
    e.preventDefault();
    setFilterObj({ ...filterObjs });
  };

  //   useEffect(() => {
  //     userDetail();
  //   }, []);

  const date = new Date(filterObjs.from);
  const filter = (
    <span>
      <i className="fa-solid fa-filter"></i> Filter
    </span>
  );

  return (
    <div className="container-fluid px-3">
      <div></div>
      <Collapsible
        contentInnerClassName="collapsible-custom mt-3"
        triggerClassName="btn-submit btnver-2 lightgry d-inline-flex justify-content-center flex-column"
        triggerOpenedClassName="btn-submit btnver-2 lightgry d-inline-flex justify-content-center flex-column"
        trigger={filter}
      >
        <div className="filter-css">
          <form className="align-items-end row" novalidate>
            <div className="mb-4 col-xl-3 col-md-6 col-12">
              <div className="input-classicsite">
                <label for="validationCustom01" className="form-label">
                  From Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="validationCustom01"
                  onChange={(e) => handlerChange(e, "from")}
                  required
                />
                <div className="valid-feedback">Looks good!</div>
              </div>
            </div>

            <div className="mb-4 col-xl-3 col-md-6 col-12">
              <div className="input-classicsite">
                <label for="validationCustom02" className="form-label">
                  To Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="validationCustom02"
                  onChange={(e) => handlerChange(e, "to")}
                  required
                />
                <div className="valid-feedback">Looks good!</div>
              </div>
            </div>

            <div className="mb-4 col-xl-3 col-md-6 col-12">
              <div className="input-classicsite">
                <label for="validationCustom03" className="form-label">
                  order ID
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="validationCustom03"
                  required
                  value={filterObjs.orderId}
                  onChange={(e) => handlerChange(e, "orderId")}
                />
                <div className="invalid-feedback">
                  Please provide a valid city.
                </div>
              </div>
            </div>

            <div className="mb-4 col-xl-3 col-md-6 col-12">
              <div className="input-classicsite">
                <label for="validationCustom03" className="form-label">
                  Transaction ID
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="validationCustom03"
                  required
                  value={filterObjs.transactionID}
                  onChange={(e) => handlerChange(e, "transactionID")}
                />
                <div className="invalid-feedback">
                  Please provide a valid city.
                </div>
              </div>
            </div>

            <div className="mb-4 col-xl-3 col-md-6 col-12">
              <div className="input-classicsite">
                <label for="validationCustom05" className="form-label">
                  Transaction status
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom05"
                  onChange={(e) => handlerChange(e, "transactionstatus")}
                  value={filterObjs.transactionstatus}
                  required
                />
                <div className="invalid-feedback">
                  Please provide a valid zip.
                </div>
              </div>
            </div>

            <div className="mb-4 col-xl-3 col-md-6 col-12">
              <div className="input-classicsite">
                <label for="validationCustom05" className="form-label">
                  GatewayName
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom05"
                  onChange={(e) => handlerChange(e, "gatewayName")}
                  value={filterObjs.gatewayName}
                  required
                />
                <div className="invalid-feedback">
                  Please provide a valid zip.
                </div>
              </div>
            </div>

            <div className="mb-4 col-xl-3 col-md-6 col-12">
              <div className="input-classicsite">
                <label for="validationCustom05" className="form-label">
                  User ID
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="validationCustom05"
                  onChange={(e) => handlerChange(e, "userId")}
                  value={filterObjs.userId}
                  required
                />
                <div className="invalid-feedback">
                  Please provide a valid zip.
                </div>
              </div>
            </div>

            <div className="mb-4 col-xl-3 col-md-6 col-12">
              {/* <input
                type="button"
                className="btn btn-submit btnver-2"
                value="Submit"
                onClick={(e) => bookingFormData(e)}
              /> */}
              <button
                className="btn btn-submit btnver-2"
                onClick={(e) => bookingFormData(e)}
              >
                SUBMIT
              </button>
              <div className="invalid-feedback">
                Please provide a valid zip.
              </div>
            </div>
            {/* <div className="col-12">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="invalidCheck"
                required
              />
              <label className="form-check-label" for="invalidCheck">
                Agree to terms and conditions
              </label>
              <div className="invalid-feedback">
                You must agree before submitting.
              </div>
            </div>
          </div> */}
            {/* <div className="mb-4 col-xl-3 col-md-6 col-12">
            <button
              className="btn btn-dark"
              type="submit"
              onClick={(e) => bookingFormData(e)}
            >
              Submit form
            </button>
          </div> */}
          </form>
        </div>
      </Collapsible>
    </div>
  );
};

export default PaymnetFilter;
/* {
"from":"",
"to":"",
"pnr":"",
"bookingId":"",
"orderId":"",
"source":"",
"agentName":"",
"bookingGds":"",
"doneGds":"",
"tripType"
} */
