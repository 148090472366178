import React from "react";
import { useState } from "react";
import Modals from "../../CustomComponent/Modals";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddPrefredAirline from "./components/AddPrefredAirline";
import swal from "sweetalert";
import axios from "axios";
import { travel_url } from "../../Host";
import { commonheader } from "../../utils/header";
import { useLocation } from "react-router-dom";

const PreferedAirline = ({
  data,
  setTicketingStatus,
  setUpdatedMetaState,
  getSuppliersData,
}) => {
  const { pathname } = useLocation();
  const [addRangeshowModal, setAddRangeshowModal] = useState(false);
  const [state, setState] = useState("false");
  const [isedit, setIsEdit] = useState("");
  const addRange = () => {
    setAddRangeshowModal(true);
  };
  function handleClose() {
    setAddRangeshowModal(false);
    setIsEdit("");
  }
  const handleDelete = async (id) => {
    try {
      const { data } = await axios.delete(
        `${travel_url}/deleteBookingPreference?id=${id}`,
        {
          headers: commonheader(),
        }
      );
      setUpdatedMetaState(data);
      swal("Successfully Deleted");
    } catch (error) {
      console.log(error);
      swal("Some Error", "Please try again after some time", "error");
    }
  };
  return (
    <div>
      <div className="col-12 mb-4 ">
        <div className="metaBox position-relative d-block border-0 smtb">
          <h6 className="title-lightthm p-3 m-0">Airline Booking Preference</h6>
          <div className="w-100">
            <div className="table-responsive lightthm-wrap">
              <table className="table table-lightthm text-left">
                <thead className="bg-light">
                  <tr>
                    <th width={20}>S.N.</th>
                    {/* <th>Source</th> */}
                    <th>Airline</th>
                    <th>Supplier</th>
                    <th>Hold</th>
                    <th>Status</th>
                    <th>Edit</th>
                    {pathname === "/api-tracking" && <th>Deleted</th>}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      {/* <td>{"Google"}</td> */}
                      <td>{item.airline.toString()}</td>
                      <td>{item.supplier}</td>
                      <td
                        className={`${
                          item.hold ? "text-success" : "text-danger"
                        }`}
                      >
                        <i
                          className={`fa-solid fa-circle`}
                          style={{ fontSize: "10px", marginRight: "4px" }}
                        ></i>
                        <span className="">
                          {item.hold ? "Active" : "Inactive"}
                        </span>
                      </td>
                      <td
                        className={`${
                          item.enable ? "text-success" : "text-danger"
                        }`}
                      >
                        <i
                          className={`fa-solid fa-circle`}
                          style={{ fontSize: "10px", marginRight: "4px" }}
                        ></i>
                        <span className="">
                          {item.enable ? "Active" : "Inactive"}
                        </span>
                      </td>

                      <td>
                        <div className="btni-sp">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                          >
                            <button
                              className="btn btn-sm btn-soft-success"
                              onClick={() => {
                                setIsEdit(item);
                                setAddRangeshowModal(true);
                              }}
                            >
                              <i className="fa-solid fa-pencil"></i>
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                      {pathname === "/api-tracking" && (
                        <td>
                          <div className="btni-sp">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Delete</Tooltip>}
                            >
                              <button
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => handleDelete(item._id)}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {pathname === "/api-tracking" && (
            <div className="text-right px-3 py-4">
              <div className="btni-sp">
                <button
                  className="btn-submit btnver-2 success"
                  onClick={() => addRange()}
                >
                  Add <i className="fa-solid fa-plus icon-add"></i>
                </button>
              </div>
            </div>
          )}

          <Modals
            show={addRangeshowModal}
            HandleClose={handleClose}
            ModalBody={
              <AddPrefredAirline
                closeModal={setAddRangeshowModal}
                setTicketingStatus={setTicketingStatus}
                setUpdatedMetaState={setUpdatedMetaState}
                isedit={isedit}
                setIsEdit={setIsEdit}
                getSuppliersData={getSuppliersData}
              />
            }
            title={isedit ? "Update" : "Add"}
          />
        </div>
      </div>
    </div>
  );
};

export default PreferedAirline;
