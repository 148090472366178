import React from "react";
import moment from "moment";
const FlightDetailsTable = ({ modify }) => {
  return (
    <>
      <span className="badge bdgsite mb-2">Departure</span>
      <div className="table-responsive lightthm-wrap scroll-auto w-100">
        <table className="table table-lightthm text-left">
          <thead className="bg-light">
            <tr>
              <th scope="col">Flying From</th>
              <th scope="col">Flying To</th>
              <th scope="col">Departure date</th>
              <th scope="col">Arrival date</th>
              <th scope="col">Cabin Class</th>
              <th scope="col">Airline no</th>
              <th scope="col">Airline code</th>
            </tr>
          </thead>
          <tbody>
            {modify.supplierFlight.onWordFlight.outBound.map((item, index) => (
              <>
                <tr>
                  <td> {item.fromAirport}</td>
                  <td>{item.toAirport}</td>
                  <td>
                    {moment(item.depDate).format("ddd, MMM-DD-yyyy, hh:mm A")}
                  </td>
                  <td>
                    {" "}
                    {moment(item.reachDate).format("ddd, MMM-DD-yyyy, hh:mm A")}
                  </td>
                  <td>
                    {item.cabinClass === 1
                      ? "Economy"
                      : item.cabinClass === 2
                      ? "Premium Economy"
                      : item.cabinClass === 3
                      ? "Business Class"
                      : item.cabinClass === 4
                      ? "First Class"
                      : null}
                  </td>
                  <td>{item.flightNo}</td>
                  <td>{item.airline}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      {modify.supplierFlight.tripType == 2 ? (
        <>
          <span className="badge bdgsite mb-2 mt-5">Return</span>

          <div className="table-responsive lightthm-wrap scroll-auto w-100">
            <table className="table table-lightthm text-left">
              <thead className="bg-light">
                <tr>
                  <th scope="col">Flying From</th>
                  <th scope="col">Flying To</th>
                  <th scope="col">Departure date</th>
                  <th scope="col">Arrival date</th>
                  <th scope="col">Cabin Class</th>
                  <th scope="col">Airline no</th>
                  <th scope="col">Airline code</th>
                </tr>
              </thead>

              <tbody>
                {modify.supplierFlight.tripType == 2 && (
                  <>
                    {modify.supplierFlight.tripType == 2 &&
                    modify.supplierFlight.domestic == false ? (
                      <>
                        {modify.supplierFlight.onWordFlight.inBound.map(
                          (item, index) => (
                            <tr>
                              <td> {item.fromAirport}</td>
                              <td>{item.toAirport}</td>
                              <td>
                                {moment(item.depDate).format(
                                  "ddd, MMM-DD-yyyy, hh:mm A"
                                )}
                              </td>
                              <td>
                                {" "}
                                {moment(item.reachDate).format(
                                  "ddd, MMM-DD-yyyy, hh:mm A"
                                )}
                              </td>
                              <td>
                                {item.cabinClass === 1
                                  ? "Economy"
                                  : item.cabinClass === 2
                                  ? "Premium Economy"
                                  : item.cabinClass === 3
                                  ? "Business Class"
                                  : item.cabinClass === 4
                                  ? "First Class"
                                  : null}
                              </td>
                              <td>{item.flightNo}</td>
                              <td>{item.airline}</td>
                            </tr>
                          )
                        )}
                      </>
                    ) : (
                      <>
                        {modify.supplierFlight.returnFlight === null ? (
                          ""
                        ) : (
                          <>
                            {" "}
                            {modify.supplierFlight.returnFlight.inBound.map(
                              (item, index) => (
                                <tr>
                                  <td> {item.fromAirport}</td>
                                  <td>{item.toAirport}</td>
                                  <td>
                                    {moment(item.depDate).format(
                                      "ddd, MMM-DD-yyyy, hh:mm A"
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {moment(item.reachDate).format(
                                      "ddd, MMM-DD-yyyy, hh:mm A"
                                    )}
                                  </td>
                                  <td>
                                    {item.cabinClass === 1
                                      ? "Economy"
                                      : item.cabinClass === 2
                                      ? "Premium Economy"
                                      : item.cabinClass === 3
                                      ? "Business Class"
                                      : item.cabinClass === 4
                                      ? "First Class"
                                      : null}
                                  </td>
                                  <td>{item.flightNo}</td>
                                  <td>{item.airline}</td>
                                </tr>
                              )
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : null}
    </>
  );
};

export default FlightDetailsTable;
