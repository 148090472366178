import React, { useEffect, useState } from "react";
import Screen from "../../CustomComponent/Screen";
import Button from "../../CustomComponent/Button";
import { FaFilter, FaSearch } from "react-icons/fa";
import Modals from "../../CustomComponent/Modals";
import { header } from "../../utils/header";
import { useFetch } from "../../hooks/useFetch";
import { getSourceUrl } from "../../hooks/url";
import Loading from "../../CustomComponent/Loading";
import { TbDatabaseSearch } from "react-icons/tb";
import SearchHotelCouponData from "../../Molecule/CouponManagement/FlightCouponMgmt/SearchHotelCouponData";
import HotelMgtTable from "../../Molecule/CouponManagement/FlightCouponMgmt/Tables/HotelMgtTable";
import { getHotelCouponApiData } from "../../Services/Api/couponMgtApi/HotelsCouponMgmtApi";
import AddHotelCouponModal from "../../Molecule/CouponManagement/FlightCouponMgmt/Modal/AddHotelCouponModal";
import HotelUpdateCouponModal from "../../Molecule/CouponManagement/FlightCouponMgmt/Modal/HotelUpdateCouponModal";
const HotelCuponMgt = () => {
  const [isSearch, setISsearch] = useState(false);
  const [mgtData, SetMgtData] = useState("");
  const [updatedData, setUpdatedData] = useState({});
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState({
    creationFrom: 0,
    creationTo: 0,
    status: null,
    couponCode: null,
    couponType: null,
    sortBy: "creationDate",
  });
  const headerList = header();
  var myHeaders = headerList;
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  const getMetaSource = useFetch(getSourceUrl, requestOptions);
  const [showModal, setShowModal] = useState({
    showAddModal: false,
    showEditModal: false,
  });
  const fetchData = async (e) => {
    try {
      const { data } = await getHotelCouponApiData(filterData);
      SetMgtData(data);
    } catch (e) {}
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const isEditData = (data) => {
    setShowModal({ showEditModal: true });
    setUpdatedData(data);
  };
  return (
    <div>
      <Screen>
        <div className="pb-3  d-flex justify-content-between">
          <Button
            title={
              <>
                <FaFilter /> Filter
              </>
            }
            color="btn-submit btnver-2 success"
            onClick={() => setISsearch(!isSearch)}
          />
          <Button
            title={
              <>
                <i className="fa-solid fa-plus icon-add"></i> Add
              </>
            }
            color="btn-submit btnver-2 success ms-auto"
            onClick={() => {
              setShowModal({ showAddModal: true });
            }}
          />
        </div>
        <SearchHotelCouponData
          isSearch={isSearch}
          filterData={filterData}
          setFilterData={setFilterData}
          onFilter={() => fetchData()}
        />
        {loading && <Loading title="Loading..." />}
        {(mgtData && mgtData.content.length) > 0 ? (
          <HotelMgtTable
            mgtData={mgtData}
            editClick={(data) => isEditData(data)}
          />
        ) : (
          <div className=" text-center text-muted">
            <TbDatabaseSearch size={40} />
            <p className="text text-bold mt-2">No Data Available!</p>
          </div>
        )}
      </Screen>
      {/* ------------------------Modal------------===================== */}
      <Modals
        show={showModal.showAddModal}
        HandleClose={() => {
          setShowModal({ showAddModal: false });
        }}
        ModalBody={
          <AddHotelCouponModal
            soruceData={getMetaSource.response && getMetaSource.response.data}
            HandleClose={() => {
              setShowModal({ showEditModal: false });
            }}
            updatedFetchData={() => fetchData()}
          />
        }
        title={"Add"}
      />
      <Modals
        show={showModal.showEditModal}
        HandleClose={() => {
          setShowModal({ showEditModal: false });
        }}
        ModalBody={
          <HotelUpdateCouponModal
            data={updatedData}
            soruceData={getMetaSource.response && getMetaSource.response.data}
            HandleClose={() => {
              setShowModal({ showEditModal: false });
            }}
            updatedFetchData={() => fetchData()}
          />
        }
        title={"Update"}
      />
    </div>
  );
};

export default HotelCuponMgt;
