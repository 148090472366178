import axios from "axios";
import { travel_url } from "../../../Host";
import { commonheader } from "../../../utils/header";

const holdTicketApiInstance = axios.create({
    baseURL: travel_url,
  });
  holdTicketApiInstance.interceptors.request.use(
    (config) => {
      const userToken = localStorage.getItem("token");
      if (userToken) {
        config.headers = commonheader();
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  export const getHoldticketApi=()=>holdTicketApiInstance.get(`/hold/getHandleTicket`);
  export const addHoldticketApi=(data)=>holdTicketApiInstance.post(`/hold/saveHandleTicket`,data);
  export const deleteHoldticketApi=(id)=>holdTicketApiInstance.delete(`/hold/deleteHoldHandler?token=${id}`);
  export const updateHoldticketApi=(data)=>holdTicketApiInstance.put(`/hold/updateHoldHandler`,data);