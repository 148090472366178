import React from "react";
import Form from "react-bootstrap/Form";

const Select = ({ label, optionValue, firstName, classstyle, ...props }) => {
  return (
    <>
      <Form.Group className="input-classicsite">
        {label && <Form.Label>{label}</Form.Label>}
        <select className={`form-select ${classstyle}`} {...props}>
          {firstName ? <option value="">{firstName}</option> : ""}
          {optionValue.map((item) => {
            return (
              <option value={item.value} key={item.id}>
                {item.name}
              </option>
            );
          })}
        </select>
      </Form.Group>
    </>
  );
};

export default Select;
export const SelectWithObjectVal = ({
  label,
  optionValue,
  firstName,
  classstyle,
  ...props
}) => {
  return (
    <>
      <Form.Group className="input-classicsite">
        {label && <Form.Label>{label}</Form.Label>}
        <select className={`form-select ${classstyle}`} {...props}>
          {firstName ? <option value="">{firstName}</option> : ""}
          {optionValue.map((item) => {
            return (
              <option value={item} key={item.id}>
                {item.name}
              </option>
            );
          })}
        </select>
      </Form.Group>
    </>
  );
};
