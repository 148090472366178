import React, { useState } from "react";
import Table from "../../CustomComponent/Table";

const TravoFareRuleTable = ({
  getData,
  onEdit,
  onEnable,
  onDelete,
  onDuplicate,
  onEditTable,
  alleditdata,
  filterdata,
  onRestoreClick,
}) => {
  return (
    <div className="table-responsive lightthm-wrap scroll-auto">
      <table className="table table-lightthm text-left">
        <thead className="bg-light">
          <tr>
            <th scope="col">Select</th>
            <th scope="col">S.No.</th>
            <th scope="col">Rule Id</th>
            <th scope="col">Rule Name</th>
            <th scope="col">Origin</th>
            <th scope="col">Destination</th>
            <th scope="col">Cabin Type</th>
            <th scope="col">Trip Type</th>
            <th scope="col">Time</th>
            <th scope="col">Stops</th>
            <th scope="col">From Country</th>
            <th scope="col">To Country</th>
            <th scope="col">Site Id</th>
            <th scope="col">Airline</th>
            <th scope="col">Opaque Airline</th>
            <th scope="col">Onward Class Of Service</th>
            <th scope="col">Return Class Of Service</th>
            <th scope="col">Cap Days</th>
            <th scope="col">Cap Amount</th>
            <th scope="col">Source</th>
            <th scope="col">From Date</th>
            <th scope="col">To Date</th>
            <th scope="col">From Amount</th>
            <th scope="col">To Amount</th>
            <th scope="col">Supplier</th>
            <th scope="col">Fare Type Key Value</th>
            {/* <th scope="col">Markup Type</th> */}
            {/* <th scope="col">Amount Type</th> */}
            <th scope="col">Additional Discount Key Value</th>
            {/* <th scope="col">Additional Amount</th> */}
            <th scope="col">Adult</th>
            <th scope="col">Child</th>
            <th scope="col">Infant</th>
            <th scope="col">Enable</th>
            <th scope="col">Convenience Fee</th>
            {!filterdata.deleted && <th scope="col">Update</th>}
            {((!filterdata.deleted &&
              localStorage.getItem("role") == "DEVELOPER") ||
              localStorage.getItem("role") == "MANAGER") && (
              <th scope="col">Delete</th>
            )}
            {!filterdata.deleted && <th scope="col">Duplicate</th>}
            {filterdata.deleted && <th scope="col">Restore</th>}
          </tr>
        </thead>
        <tbody>
          {getData.content.length &&
            getData.content.map((item, i) => {
              const EditIcons = (
                <div className="btni-sp">
                  <button
                    className={`btn btn-sm btn-soft-success`}
                    onClick={() => onEdit(item)}
                  >
                    <div>
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </div>
                  </button>
                </div>
              );

              const DeleteIcons = (
                <div className="btni-sp">
                  <button
                    className={`btn btn-sm btn-soft-success`}
                    onClick={() => onDelete(item)}
                  >
                    <div>
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </div>
                  </button>
                </div>
              );
              const restorIcon = (
                <div className="btni-sp">
                  <button
                    className={`btn btn-sm btn-soft-success`}
                    onClick={() => onRestoreClick(item._id)}
                  >
                    <div>
                      <i className="fa-solid fa-arrow-rotate-left"></i>
                    </div>
                  </button>
                </div>
              );
              const DuplicateIcons = (
                <div className="btni-sp">
                  <button
                    className={`btn btn-sm `}
                    onClick={() => onDuplicate(item)}
                  >
                    <div>
                      <i className="fa-regular fa-copy" aria-hidden="true"></i>
                    </div>
                  </button>
                </div>
              );
              {
                /* <i className="fa-regular fa-copy"></i> */
              }
              const switchIcon = (
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    value={item.enable}
                    checked={item.enable}
                    onClick={() => onEnable(item)}
                  />
                </div>
              );
              const checkbox = (
                <div className="">
                  ,
                  <input
                    type="checkbox"
                    className="cursor-pointer"
                    id=""
                    checked={
                      alleditdata.find((ele) => ele._id === item._id)
                        ? true
                        : false
                    }
                    onChange={(e) => onEditTable(e, item)}
                  />
                </div>
              );
              return (
                <React.Fragment key={item._id}>
                  <Table
                    onClick={() => true}
                    classStyle=""
                    data={[
                      checkbox,
                      i + 1,
                      "##" + item.ruleId,
                      item.ruleName,
                      item.origin,
                      item.destination,
                      item.cabinType,
                      item.trip,
                      new Date(item.time).toISOString(),
                      item.stops,
                      item.fromCountry,
                      item.toCountry,
                      item.siteId,
                      item.airline,
                      item.opAkAirline ? item.opAkAirline : "N/A",
                      item.classOfService,
                      item.classOfServiceReturn,
                      item.cappingDays,
                      item.cappingAmount,
                      item.source,
                      item.fromDate,
                      item.toDate,
                      item.fromAmount,
                      item.toAmount,
                      item.api,
                      item.fareType,
                      // item.markupType,
                      // item.amountType,
                      item.additionalDisAmount,
                      // item.additionalDisAmountType,
                      item.adult,
                      item.child,
                      item.infant,
                      switchIcon,
                      item.convenienceFee,
                      !filterdata.deleted ? EditIcons : "",
                      (!filterdata.deleted &&
                        localStorage.getItem("role") == "DEVELOPER") ||
                      localStorage.getItem("role") == "MANAGER"
                        ? DeleteIcons
                        : "",
                      !filterdata.deleted ? DuplicateIcons : "",
                      filterdata.deleted ? restorIcon : "",
                    ]}
                  />
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TravoFareRuleTable;
