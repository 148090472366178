import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { travel_url } from "../../Host";
import Multiselect from "multiselect-react-dropdown";
import loadergif from "../../Image/load.gif";
import Loading from "../../CustomComponent/Loading";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { getSourceUrl } from "../../hooks/url";
import { useFetch } from "../../hooks/useFetch";
import { header } from "../../utils/header";
import axios from "axios";

const Userinfo = () => {
  const headerList = header();
  var myHeaders = headerList;
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  const getMetaSource = useFetch(getSourceUrl, requestOptions);
  // ---------------------------------Modal for ADD USER---------------------------------
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ---------------------------------Modal for ADD USER END---------------------------------

  // ---------------------------------Modal2 for Modify User---------------------------------
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ---------------------------------Modal2 for Modify USER END---------------------------------

  // --------------------------------------SHOW ALL User--------------------------------------
  const [AllUser, setAllUser] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState();
  function getUser() {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/users`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // if (result.httpStatus === "OK") {
        //   setAllUser(result);
        // } else {
        //   setError("error");
        // }
        setAllUser(result);
        setLoad(true);
      })
      .catch((error) => {
        setError("error");
      });
  }

  // --------------------------------------SHOW ALL User END--------------------------------------

  //--------------------------------------------- ADD USER INFO-------------------------------------------

  // USER INFO
  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [currencies, setCurrencies] = useState("");
  const [selectCurrency, setselectCurrency] = useState("");
  const [metas, setMetas] = useState("");
  const [selectmeta, setSelectMeta] = useState("");
  const [userRoleData,setUserRoleData]=useState([])

  function SaveUserInfo() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    var raw = JSON.stringify({
      phone: phone,
      name: name,
      email: email,
      password: password,
      roles: [
        {
          role: role,
        },
      ],
      currencies: currencies,
      meta: metas,
      deleted: false,
      active: true,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/createUser`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        getUser();
      })
      .catch((error) => {});

    handleClose();
  }
  //--------------------------------------------- ADD USER INFO END ------------------------------------------

  // ----------------------------------MODIFICATION OF USER----------------------------------
  const [modify, setModify] = useState([]);
  const [modifystatus, setModifystaus] = useState(false);

  function modifyUser(e) {
    setModify(e);
    setModifystaus(true);
    setModifyID(e._id);
    handleShow2();
    var arr = e.currencies;
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = {};
      obj.key = arr[i];
      newArr.push(obj);
    }
    setselectCurrency(newArr);
    setCurrencies(arr);
    if (e.meta) {
      let arr2 = e.meta;
      let newArr2 = [];
      for (let i = 0; i < arr2.length; i++) {
        newArr2.push({ name: arr2[i] });
      }
      setSelectMeta(newArr2);
      setMetas(arr2);
    }
  }
  const [Modifyrole, setModifyRole] = useState("");
  const [Modifyname, setModifyName] = useState("");
  const [Modifyemail, setModifyEmail] = useState("");
  const [Modifyphone, setModifyPhone] = useState("");
  const [Modifypassword, setModifyPassword] = useState("");
  const [ModifyID, setModifyID] = useState("");

  function modifyDone() {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      phone: Modifyphone,
      name: Modifyname,
      email: Modifyemail,
      password: Modifypassword,
      roles: [
        {
          role: Modifyrole,
        },
      ],
      currencies: currencies,
      meta: metas,
      deleted: false,
      active: true,
      _id: ModifyID,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/updateUser`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        getUser();
        handleClose2();
      })
      .catch((error) => {});
  }

  // ----------------------------------MODIFICATION OF USER END----------------------------------

  //  ------------------------Delete User-------------------------
  function deleteUser(e) {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/deleteUser?id=${e._id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        getUser();
      })
      .catch((error) => {});
  }
  // ------------------------------------
  const getUserRole=async()=>{
    try{
const {data}=await axios.get(`${travel_url}/getActiveRole`);
setUserRoleData(data)
console.log("Role",data)
    }catch(error){
      console.log(error)
    }
  }
  console.log()
  //  ------------------------Delete User END-------------------------

  // ---------------------------use effect ---------------------------
  useEffect(() => {
    getUser();
    getUserRole()
  }, []);
  // ---------------------------use effect  END---------------------------

  if (error === "error") {
    return "error";
  }
  function selectedCurrency(e) {
    setCurrencies(e.map((item, i) => item.currency));
  }
  function removeCurrency(e) {
    setCurrencies(e.map((item, i) => item.key));
  }
  function selectedMetaDatas(e) {
    setMetas(e.map((item, i) => item.name));
  }
  function removeSelectMeta(e) {
    setMetas(e.map((item, i) => item.name));
  }
  function formcheckStatus(items) {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: items._id,
      status: !items.active,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/updateUserStatus`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.msg);
        window.location.reload();
      })
      .catch((error) => {});
  }

  return (
    <>
      <div>
        {/* Add User */}
        <div className="container-fluid AddUserContainer pt-1 px-3">
          <div className="row">
            <div className="col-12 text-right">
              <button
                className="btn-submit btnver-2 success"
                onClick={handleShow}
              >
                {" "}
                <i className="fa-solid fa-plus icon-add"></i> Add User
              </button>
            </div>
          </div>
        </div>
        {/* Add User */}

        {/* UserList */}
        <div className="container-fluid px-3">
          {load ? (
            <div className="metaBox position-relative d-block border-0 smtb  mt-4">
              <div className="table-responsive lightthm-wrap scroll-auto">
                <table className="table table-lightthm text-left">
                  <thead className="bg-light">
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Role</th>
                      <th>Currency</th>
                      <th>Meat</th>
                      <th>Created Date</th>
                      <th>Status</th>
                      <th>Modify</th>
                      <th>Delete</th>
                    </tr>
                  </thead>

                  <tbody>
                    {AllUser.map((items, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{items.name}</td>
                        <td>{items.email}</td>
                        <td>{items.phone}</td>
                        <td>{items.roles[0].role}</td>
                        <td>
                          {items.currencies
                            ? items.currencies.map((item, i) => item + " ")
                            : ""}
                        </td>
                        <td>{items.meta ? items.meta.toString() : ""}</td>
                        <td>{items.creationDate.slice(0, 10)}</td>
                        <td>
                          <Form>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              checked={items.active}
                              onClick={() => formcheckStatus(items)}
                            />
                          </Form>
                        </td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Modify User</Tooltip>}
                          >
                            <div className="btni-sp">
                              <button
                                className="btn btn-sm btn-soft-success"
                                onClick={(e) => modifyUser(items)}
                              >
                                {" "}
                                <i className="fa-sharp fa-solid fa-pen-to-square fa-2x"></i>
                              </button>
                            </div>
                          </OverlayTrigger>
                        </td>
                        <td>
                          <div className="btni-sp">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Delete</Tooltip>}
                            >
                              <button
                                className="btn btn-sm btn-soft-danger"
                                onClick={(e) => deleteUser(items)}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <Loading title="loading..." />
          )}
        </div>
        {/* UserList */}

        {/* ADD USER */}
        <Modal
          size="xl"
          className="modalbooknow-classic ovflw-visible"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title> Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="row">
              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Enter Name</label>
                  <input
                    type={`text`}
                    className="form-control"
                    placeholder="Enter Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Enter Phone</label>
                  <input
                    type={`text`}
                    className="form-control"
                    placeholder="Enter Phone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Enter Email-ID</label>
                  <input
                    type={`text`}
                    className="form-control"
                    placeholder="Enter Email-ID"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Enter Password</label>
                  <input
                    type={`text`}
                    className="form-control"
                    placeholder="Enter Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Confirm Password</label>
                  <input
                    type={`text`}
                    className="form-control"
                    placeholder="Confirm Password"
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Select Role</label>
                  <select
                    id="framework"
                    className="form-control"
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="">Select Role</option>
                   { userRoleData.map((ele,i)=>{
                    return <option value={ele.role} key={i}>{ele.role}</option>
                   })}
                  </select>
                </Form.Group>
              </Col>

              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Select Currency</label>
                  <label className="form-label">Select Currency</label>
                  <Multiselect
                    displayValue="key"
                    placeholder="Select Currency"
                    className="multiselect-cs"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck() {}}
                    onSearch={function noRefCheck() {}}
                    onSelect={(e) => selectedCurrency(e)}
                    options={[
                      {
                        currency: "INR",
                        key: "INR",
                      },
                      {
                        currency: "USD",
                        key: "USD",
                      },
                      {
                        currency: "GBP",
                        key: "GBP",
                      },
                    ]}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Select Meta List</label>
                  <label className="form-label">Select Meta List</label>
                  <Multiselect
                    displayValue="name"
                    placeholder="Select Meta List"
                    className="multiselect-cs"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck() {}}
                    onSearch={function noRefCheck() {}}
                    onSelect={(e) => selectedMetaDatas(e)}
                    options={
                      getMetaSource.response ? getMetaSource.response.data : []
                    }
                  />
                </Form.Group>
              </Col>
            </div>
          </Modal.Body>
          <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
            <Button
              className="btn-submit btnver-2 lightgry me-3"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              className="btn-submit btnver-2"
              onClick={() => SaveUserInfo()}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* ADD USER */}

        {/* MODIFY  USER */}
        <Modal
          size="xl"
          className="modalbooknow-classic"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show2}
          onHide={handleClose2}
        >
          <Modal.Header closeButton>
            <Modal.Title> Modify User</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="row">
              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Set Modify Name</label>
                  <input
                    type={`text`}
                    className="form-control"
                    placeholder={modify.name}
                    onChange={(e) => setModifyName(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Set Modify Phone</label>
                  <input
                    type={`text`}
                    className="form-control"
                    placeholder={modify.phone}
                    onChange={(e) => setModifyPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Set Modify Email</label>
                  <input
                    type={`text`}
                    className="form-control"
                    placeholder={modify.email}
                    onChange={(e) => setModifyEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Set Modify Password</label>
                  <input
                    type={`text`}
                    className="form-control"
                    placeholder={modify.password}
                    onChange={(e) => setModifyPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Select Role</label>
                  <select
                    id="framework"
                    className="form-control"
                    onChange={(e) => setModifyRole(e.target.value)}
                    defaultValue={show2 && modify.roles[0].role}
                  >
                    <option value="" selected={false}>
                      Select Role
                    </option>
                    { userRoleData.map((ele,i)=>{
                    return <option value={ele.role} key={i}>{ele.role}</option>
                   })}
                  </select>
                </Form.Group>
              </Col>

              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Select Currency</label>
                  <Multiselect
                    displayValue="key"
                    placeholder="Select Currency"
                    className="multiselect-cs"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={(e) => removeCurrency(e)}
                    onSearch={function noRefCheck() {}}
                    onSelect={(e) => selectedCurrency(e)}
                    options={[
                      {
                        currency: "INR",
                        key: "INR",
                      },
                      {
                        currency: "USD",
                        key: "USD",
                      },
                      {
                        currency: "GBP",
                        key: "GBP",
                      },
                    ]}
                    selectedValues={selectCurrency}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} xl={4} className="mb-4">
                <Form.Group className="input-classicsite">
                  <label className="form-label">Select Meta List</label>
                  <label className="form-label">Select Meta List</label>
                  <Multiselect
                    displayValue="name"
                    placeholder="Select Meta List"
                    className="multiselect-cs"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={(e) => removeSelectMeta(e)}
                    onSearch={function noRefCheck() {}}
                    onSelect={(e) => selectedMetaDatas(e)}
                    options={
                      getMetaSource.response ? getMetaSource.response.data : []
                    }
                    selectedValues={selectmeta}
                  />
                </Form.Group>
              </Col>
            </div>
          </Modal.Body>
          <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
            <Button
              className="btn-submit btnver-2"
              onClick={() => modifyDone()}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* MODIFY USER */}
      </div>
    </>
  );
};

export default Userinfo;
