import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Inputs from "../../../CustomComponent/Inputs";
import Button from "../../../CustomComponent/Button";
import { addIPWhiteListApi } from "../../../Services/Api/IPWhitelistApi";
import Swal from "sweetalert2";

const AddIPWhitelistModal = ({ fetchData, setModalopen }) => {
  const [ip, setIP] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await addIPWhiteListApi({
        ip: ip,
      });
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
      fetchData();
      setModalopen(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6} xl={6}>
            <Inputs
              type="text"
              name="ip"
              placeholder={"Enter the IP"}
              label={"IP"}
              onChange={(e) => setIP(e.target.value)}
              required={true}
              classstyle="form-control"
            />
          </Col>
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2  btn-md "
              // onClick={dublicateHandleSubmit}
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddIPWhitelistModal;
