import axios from "axios";
import { DownloadFile } from "../../utils/DownloadFile";
import { DownloadTravFareRule_Url } from "../Urls";
import { DownloadExcel } from "../../utils/DownloadExcel";
export const DownloadTravFareRule = (data) => {
  const options = {
    method: "GET",
    responseType: "blob",
    url: `${DownloadTravFareRule_Url}/?source=${data}`,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": localStorage.getItem("token"),
    },
  };

  axios
    .request(options)
    .then(function (response) {
      DownloadExcel(response.data);
    })
    .catch(function (error) {
      console.error(error);
    });
};
