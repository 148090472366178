export const DateFormat = () => {
  const da = new Date();
  const date = da.getUTCDate();
  const month = da.getMonth();
  const year = da.getUTCFullYear();

  if (
    month == 1 ||
    month == 2 ||
    month == 3 ||
    month == 4 ||
    month == 5 ||
    month == 6 ||
    month == 7 ||
    month == 8 ||
    month == 9
  ) {
    if (
      date == 1 ||
      date == 2 ||
      date == 3 ||
      date == 4 ||
      date == 5 ||
      date == 6 ||
      date == 7 ||
      date == 8 ||
      date == 9
    ) {
      return `${year}-0${month}-0${date}`;
    } else {
      return `${year}-0${month}-${date}`;
    }
  } else {
    if (
      date == 1 ||
      date == 2 ||
      date == 3 ||
      date == 4 ||
      date == 5 ||
      date == 6 ||
      date == 7 ||
      date == 8 ||
      date == 9
    ) {
      return `${year}-0${month}-0${date}`;
    } else {
      return `${year}-0${month}-${date}`;
    }
  }
};
