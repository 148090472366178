import React from "react";

const Table = ({ data, classStyle, onClick }) => {
  return (
    <tr
      className={classStyle && classStyle}
      onClick={onClick ? onClick : () => {}}
    >
      {data &&
        data.map((items, i) => {
          return <> {items === "" ? "" : <td>{items}</td>}</>;
        })}
    </tr>
  );
};

export default Table;
