import React, { useState } from "react";
import BookingLists from "../Molecule/BookingList/BookingList";
import BookingListFilter from "../Molecule/BookingList/BookingListFilter";
import BookingSidebar from "../Molecule/BookingList/BookingSidebar";
import SupplierLists from "../Molecule/SupplierResponse/SupplierList";
import SupplierListFilter from "../Molecule/SupplierResponse/SupplierListFilter";
import SupplierSideBar from "../Molecule/SupplierResponse/SupplierSideBar";

const SupplierResponse = () => {
  const [filterObj, setFilterObj] = useState({
    from: 0,
    to: 0,
    bookingId: 0,
    orderId: 0,
    gds: 0
  });

  return (
    <div>
      {/* <SupplierSideBar  Pagetitle={`Supplier Response`}/> */}
      <SupplierListFilter setFilterObj={setFilterObj} />
      <SupplierLists filterObj={filterObj}  />
    </div>
  );
};
export default SupplierResponse;
