import React from "react";
import Form from "react-bootstrap/Form";

const NumberSelect = ({
  label,
  optionValue,
  firstName,
  classstyle,
  ...props
}) => {
  return (
    <div className="d-flex align-items-center">
      {label && (
        <span className="font-bold text-sm text-secondary text-left pe-3">
          {label}
        </span>
      )}
      <Form.Group className="input-classicsite">
        <select className={`form-select noof-result ${classstyle}`} {...props}>
          {firstName ? <option value="">{firstName}</option> : ""}
          {optionValue.map((item) => {
            return (
              <option value={item.value} key={item.id}>
                {item.name}
              </option>
            );
          })}
        </select>
      </Form.Group>
    </div>
  );
};

export default NumberSelect;
