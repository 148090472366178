import { GetSource_Url } from "../Urls";

export const GetAllSources = ({ state, state2, error }) => {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", localStorage.getItem("token"));

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(GetSource_Url, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result) {
        state(result);
        state2(true);
      }
    })
    .catch((err) => {
      error(
        "There is Some Technical Error, Please contact with Technical Team"
      );
      // setIsLoading(false);
    });
};
