import React, { useState } from "react";

const BookingDetailsTable = ({ modify, payDetails, transactionStatus }) => {
  return (
    <div className="table-responsive lightthm-wrap scroll-auto w-100">
    <table className="table table-lightthm text-left">
      <thead className="bg-light">
        <tr className="bg-light-skblue">
          <th scope="col">BookingID</th>
          <th scope="col">Email</th> 
          <th scope="col">Phone</th>
          <th scope="col">Booking Status </th>
          <th scope="col">Payment Status</th>
          <th scope="col">Country</th>
          <th scope="col">Trip Type</th>
          <th scope="col">Trip</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{modify.orderId}</td>
          <td>
            {modify.passengerDetails === null ? (
              "---"
            ) : (
              <>{modify.passengerDetails[0].email}</>
            )}
          </td>
          {/* <td>
            {modify.passengerDetails === null ? (
              "---"
            ) : (
              <>
                {modify.passengerDetails[0].ticketDetails !== null
                  ? modify.passengerDetails[0].ticketDetails[0].pnr
                  : ""}
              </>
            )}
          </td> */}
          <td>
            {" "}
            {modify.passengerDetails === null ? (
              "---"
            ) : (
              <>
                {modify.passengerDetails[0].countryCode}{" "}
                {modify.passengerDetails[0].phone}
              </>
            )}
          </td>

          <td
            className={
              modify.bookingStatus !== "SUCCESS"
                ? "PayTextStatPending"
                : "PayTextStatSuccess"
            }
          >
            {modify.bookingStatus}
          </td>

          {transactionStatus == "SUCCESS" ? (
            <td className="BookTextStatSuccess">SUCCESS</td>
          ) : (
            <td
              className={
                modify.paymentStatus === "success" ||
                modify.paymentStatus === "SUCCESS"
                  ? "BookTextStatSuccess"
                  : "BookTextStatPending"
              }
            >
              {modify.paymentStatus}
            </td>
          )}

          <td>
            {" "}
            {modify.passengerDetails === null ? (
              "---"
            ) : (
              <>{modify.passengerDetails[0].country}</>
            )}
          </td>
          <td>
            {modify.supplierFlight.tripType == 2 ? "Round way" : "One Way"}
          </td>
          <td>{modify.domestic == true ? "Domestic" : "International"}</td>
        </tr>
      </tbody>
    </table>
    </div>
  );
};

export default BookingDetailsTable;
