import axios from "axios";
import { travel_url } from "../../../Host";
import { commonheader, header } from "../../../utils/header";
const couponMgtInstance = axios.create({
  baseURL: travel_url,
});
couponMgtInstance.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      config.headers = commonheader();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const addCouponApi = (data) =>
  couponMgtInstance.post(`/coupon/add`, data);
export const getCouponApiData = (data) =>
  couponMgtInstance.post(`/coupon/filter`, data);
export const updateCouponApi = (data) =>
  couponMgtInstance.post(`/coupon/update`, data);
