import React from "react";

const GstModalBody = ({ pessengerPassportData }) => {
  return (
    <div>
      <div className="table-responsive lightthm-wrap scroll-auto">
        <table className="table table-lightthm text-left  table-striped">
          <thead className="bg-light">
            <tr>
              <th>Registration Number</th>
              <th>Company Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{pessengerPassportData[0].gstNumber}</td>
              <td>{pessengerPassportData[0].gstCompany}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GstModalBody;
