import axios from "axios";
import { travel_url } from "../../../Host";
import { commonheader } from "../../../utils/header";

const insuranceInstance = axios.create({
  baseURL: `${travel_url}`,
});
insuranceInstance.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      config.headers = commonheader();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const getInsuranceBenefitApi = () =>
  insuranceInstance.get(`/asegoCrm/allBenefit`);
export const deleteInsuranceBenefitApi = (id) =>
  insuranceInstance.delete(`/asegoCrm/deleteBenefit/${id}`);
export const addInsuranceBenefitApi = (data) =>
  insuranceInstance.post(`/asegoCrm/addBenefit`, data);
export const editInsuranceBenefitApi = (id, data) =>
  insuranceInstance.put(`/asegoCrm/editBenefit/${id}`, data);
export const getInsurancePlanApi = (country, category, iCode) =>
  insuranceInstance.get(
    `/Insurance/getPlansName?country=${country}&category=${category}&iCode=${iCode}`
  );
