import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { travel_url } from "../../Host";
import Lottie from "react-lottie";
import loader from "../../Image/93134-not-found.json";
import Table from "../../CustomComponent/Table";
import Loading from "../../CustomComponent/Loading";
import { UpdateAutoHandler } from "../../Apis/Urls";
import { UpdateMeta } from "../../Apis/Meta/UpdateMeta";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { commonheader } from "../../utils/header";
import Swal from "sweetalert2";
const DashboardData = ({ allApi }) => {
  const { pathname } = useLocation();
  const [metaData, setMetaData] = useState();
  const [isloading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [updatedMetaState, setUpdatedMetaState] = useState([]);
  const [suppliers, setSuppliers] = useState({});
  const [SuppliersStatus, setSuppliersStatus] = useState(false);
  // Switch loading
  const [ticketingStatus, setTicketingStatus] = useState(false);
  const [fieldData, setFieldData] = useState({
    source: "Select Meta",
    supplier: "",
    hold: false,
    enable: true,
  });
  const { source, supplier, hold, enable } = fieldData;

  const handleSwitch = (e, item) => {
    const { name, value } = e.target;
    const body = { ...item, [name]: value };
    UpdateMeta({
      loading: setTicketingStatus,
      state: setUpdatedMetaState,
      url: UpdateAutoHandler,
      method: "PUT",
      body: body,
    });
  };

  const HandleShow = () => {
    GetSupplierAPI();
    setShow(true);
  };
  const HandleClose = () => setShow(false);

  const handleChangeValue = (e) => {
    let { value, name } = e.target;
    setFieldData({ ...fieldData, [name]: value });
  };
  /* --------------------------------------------------------ApiCalls--------------------------------------------------------------------------- */
  /*------------------------------------------------------------------ GET META */
  const GetAllMeta = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getHandleTicket`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setMetaData(result);
          setIsLoading(false);
        }
      })
      .catch((error) => {});
  };
  // /*------------------------------------------------------------------ GET META */----------------------------------------------------

  // /*------------------------------------------------------------------ SAVE META */--------------
  const saveMeta = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(fieldData);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/saveHandleTicket`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.httpStatus === "OK") {
          GetAllMeta();
          HandleClose();
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => {});
  };

  const GetSupplierAPI = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getSource`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setSuppliers(result);
          setSuppliersStatus(true);
        }
      })
      .catch((error) => {});
  };
  // ----------------------------------------------Delete META API CALL------------------------------------------

  const handleDeleteApi = async (id) => {
    try {
      const { data } = await axios.delete(
        `${travel_url}/deleteAutoHandler?token=${id}`,
        {
          headers: commonheader(),
        }
      );
      data.httpStatus === "OK" && GetAllMeta();
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {}
  };
  /*---------------------------------------------------------------- list of suppliers */

  useEffect(() => GetAllMeta(), [updatedMetaState]);
  /* --------------------------------------------------------ApiCalls--------------------------------------------------------------------------- */

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (isloading) {
    return (
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
        <div className="container-fluid px-3">
          <h6>Wait for getting data...</h6>
        </div>
      </div>
    );
  }

  return (
    <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
      <div className="container-fluid px-3">
        <div className="metaBox position-relative d-block border-0 smtb">
          {ticketingStatus && <Loading title="loading..." />}
          {metaData.data === null ? (
            <>
              <Lottie options={defaultOptions} height={400} width={400} />
              <h6> Click on add button to add meta</h6>
            </>
          ) : (
            <>
              <div className="w-100">
                <div className="table-responsive lightthm-wrap  border-top-0">
                  <table className="table table-lightthm text-left">
                    <thead className="bg-light">
                      <tr>
                        <th width={20}>S no.</th>
                        <th>Source</th>
                        <th width={220}>Ticketing Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {metaData.data.map((item, i) => {
                        return (
                          <>
                            <Table
                              i={i}
                              data={[
                                i + 1,
                                item.source,
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    name="enable"
                                    value={!item.enable}
                                    onChange={(e) => handleSwitch(e, item)}
                                    defaultChecked={item.enable}
                                  />
                                </div>,
                                // <div className="btni-sp">
                                //   <OverlayTrigger
                                //     placement="top"
                                //     overlay={<Tooltip>Delete</Tooltip>}
                                //   >
                                //     <button
                                //       className="btn btn-sm btn-soft-danger"
                                //       onClick={() => handleDeleteApi(item._id)}
                                //     >
                                //       <i className="fa-solid fa-trash"></i>
                                //     </button>
                                //   </OverlayTrigger>
                                // </div>,
                              ]}
                            />
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          <div className="text-right px-3 py-4">
            {localStorage.getItem("role") === "DEVELOPER" &&
            pathname === "/api-tracking" ? (
              <button
                className="btn-submit btnver-2 success"
                onClick={() => HandleShow()}
              >
                Add <i className="fa-solid fa-plus icon-add"></i>
              </button>
            ) : (
              ""
            )}
          </div>
          <Modal
            size="xl"
            className="modalbooknow-classic metadialog"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={HandleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title className="modal-Title">Add Meta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="input-classicsite">
                      <label for="inputEmail3" className="form-label">
                        Source
                      </label>
                      <select
                        className="form-select"
                        name="source"
                        placeholder="Select Title"
                        value={source}
                        onChange={handleChangeValue}
                      >
                        <option>Select Meta</option>
                        {SuppliersStatus ? (
                          <>
                            {suppliers.data.map((items, i) => (
                              <option value={items.name}>{items.name}</option>
                            ))}
                          </>
                        ) : (
                          <>
                            <option>Select Meta</option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="input-classicsite">
                      <label for="inputEmail3" className="form-label">
                        Supplier
                      </label>
                      <select
                        className="form-select"
                        name="supplier"
                        placeholder="Select Title"
                        value={supplier}
                        onChange={handleChangeValue}
                      >
                        <option>Select Supplier</option>
                        {allApi ? (
                          <>
                            {allApi.data.map((items, i) => (
                              <option value={items.name}>{items.name}</option>
                            ))}
                          </>
                        ) : (
                          <>
                            <option>Select Supplier</option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <fieldset className="row mb-3 mt-4">
                  <legend className="col-form-label col-12 pt-0 fw-bold">
                    Status
                  </legend>
                  <div className="col-12">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="enable"
                        id="gridRadios1"
                        value={true}
                        onChange={handleChangeValue}
                        defaultChecked={true}
                      />
                      <label className="form-check-label" for="gridRadios1">
                        Active
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="enable"
                        id="gridRadios2"
                        value={false}
                        onChange={handleChangeValue}
                      />
                      <label className="form-check-label" for="gridRadios2">
                        Inactive
                      </label>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="row mb-3 mt-4">
                  <legend className="col-form-label col-12 pt-0 fw-bold">
                    Hold
                  </legend>
                  <div className="col-12">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="hold"
                        id="gridRadios3"
                        value={true}
                        onChange={handleChangeValue}
                      />
                      <label className="form-check-label" for="gridRadios3">
                        Active
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="hold"
                        id="gridRadio4"
                        value={false}
                        onChange={handleChangeValue}
                        defaultChecked={true}
                      />
                      <label className="form-check-label" for="gridRadios4">
                        Inactive
                      </label>
                    </div>
                  </div>
                </fieldset>
              </form>
            </Modal.Body>
            <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
              <button
                type="submit"
                className="btn btn btn-submit btnver-2"
                onClick={() =>
                  source === "Select Meta"
                    ? alert("please select a meta")
                    : saveMeta()
                }
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default DashboardData;
