import React, { useState, useEffect } from "react";
import axios from "axios";
import { travel_url } from "../../Host";

const useGetSupplierPrefferedAirline = (refresh) => {
  const [getData, setGetData] = useState({});
  const [getDataLoading, setGetDataLoading] = useState(false);
  const fetchData = async () => {
    setGetDataLoading(true);
    const options = {
      method: "GET",
      url: `${travel_url}/allPreferredAirline`,
      headers: {
        "x-api-key": localStorage.getItem("token"),
      },
    };

    try {
      const apiData = await axios.request(options);

      const getSupplierData = await apiData.data;

      if (getSupplierData.httpStatus === "OK") {
        setGetData(getSupplierData);
      } else {
        setGetData(getSupplierData);
      }
      // .then(function (response) {
      //   console.log(response.data);
      // })
      // .catch(function (error) {
      //   console.error(error);
      // });
    } catch (e) {
      setGetData({
        msg: "Server Error From AllPreferredAirline",
        httpStatus: "not ok",
      });
    } finally {
      setGetDataLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [refresh]);

  return [getData, getDataLoading];
};

export default useGetSupplierPrefferedAirline;
