import React, { useState } from "react";
import user from "../../Image/userheader.png";
import { useNavigate } from "react-router-dom";
import { MdOutlineExpandMore } from "react-icons/md";
import { MdOutlineMenu, MdOutlineArrowForward } from "react-icons/md";

const TopBar = (props) => {
  let navigate = useNavigate();

  function logout() {
    localStorage.clear();
    navigate("/Login");
  }

  let name = localStorage.getItem("name");
  let Phone = localStorage.getItem("phone");

  const [isActiveMsm, setIsActive] = useState(false);
  const MenuhandleClick = (event) => {
    setIsActive(!isActiveMsm);
  };

  return (
    <header className={"userheader-tp " + (isActiveMsm ? "active" : "")}>
      <div className="user-header">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="dashboard_bar fw-bold fs-5">
            <span className="me-3 menuboxbars" onClick={MenuhandleClick}>
              {isActiveMsm ? (
                <MdOutlineArrowForward size={28} color={"#878a99"} />
              ) : (
                <MdOutlineMenu size={28} color={"#878a99"} />
              )}
            </span>

            <span className="text-capitalize">
              {props.pagetitle ? props.pagetitle : null}
            </span>
          </div>

          {/* <Dropdown>
    <Dropdown.Toggle id="dropdown-basic">
      <img src={user} className="user-button" id="dropdown-basic" />
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item href="#/action-1">{name}</Dropdown.Item>
      <Dropdown.Item href="#/action-2">{Phone}</Dropdown.Item>

    </Dropdown.Menu>
  </Dropdown> */}

          <div className="dropdown">
            <div
              className="text-dark fw-bold"
              data-bs-toggle="dropdown"
              id="dropdown-basic"
            >
              <img src={user} className="user-button" />
              <span className="mx-1 text-sm">{name}</span>
              <MdOutlineExpandMore />
            </div>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  <i className="fa fa-user-o mr-2" aria-hidden="true"></i>{" "}
                  {name}
                </a>
              </li>
              <li>
                <a className="dropdown-item" href={`tel:${Phone}`}>
                  <i className="fa fa-phone mr-2" aria-hidden="true"></i>{" "}
                  {Phone}
                </a>
              </li>
              <li>
                <button className="dropdown-item" onClick={() => logout()}>
                  <i className="fa fa-sign-out mr-2" aria-hidden="true"></i>{" "}
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="user-empty"></div>
    </header>
  );
};
export default TopBar;
