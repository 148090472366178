import React, { Fragment, useEffect, useState } from "react";
import { AddCouponMgtData } from "../../../SmallApi/CouponMgtData/AddCouponMgtData";
import Button from "../../../CustomComponent/Button";
import Select from "../../../CustomComponent/Select";
import DateInput from "../../../CustomComponent/DateInput";
import Inputs from "../../../CustomComponent/Inputs";
import { Col, Row } from "react-bootstrap";
import { Checkbox, FormControlLabel } from "@mui/material";
import { addCouponApi } from "../../../Services/Api/couponMgtApi";
import Swal from "sweetalert2";

const AddCouponModal = ({ soruceData, updatedFetchData, HandleClose }) => {
  const [advanceField, setAdvanceField] = useState(false);
  const [fieldData, setFieldData] = useState({
    siteId: 6,
    source: "ALL",
    product: "FLIGHT",
    couponType: "",
    couponCode: "",
    discountApplicable: "BOOKING",
    description: "",
    minAmount: "",
    maxDiscount: "",
    usedCount: 0,
    discountType: "",
    discountOn: "",
    discountAmt: 0,
    currency: "",
    newUser: false,
    useAbility: "mulitipletime",
    useLimit: 1,
    status: true,
    validFrom: 0,
    validTo: 0,
    originCountry: "ALL",
    destinationCountry: "ALL",
    originAirport: "ALL",
    destinationAirport: "ALL",
    airlines: "ALL",
    stops: 0,
    cabin: 0,
    platform: "ALL",
    paymentMode: "ALL",
    capDays: 0,
    travelFrom: 0,
    travelTo: 0,
    dayOfWeek: 0,
    dateOfMonth: 0,
    userIds: null,
  });
  function checkFieldInclids(fieldName) {
    return fieldName == "description"
      ? 7
      : fieldName == "originCountry" ||
        fieldName == "destinationCountry" ||
        fieldName == "couponCode"
      ? 3
      : 2;
  }

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (
      name === "validFrom" ||
      name === "validTo" ||
      name === "travelFrom" ||
      name === "travelTo"
    ) {
      value = new Date(value).valueOf();
    }
    if (
      [
        "couponCode",
        "originCountry",
        "destinationCountry",
        "currency",
        "airlines",
        "originAirport",
        "destinationAirport",
      ].includes(name)
    ) {
      value = value.toUpperCase();
    }
    if (name === "userIds") {
      value = value.split(",");
    }
    if (fieldData.discountType === "F" && name === "discountAmt") {
      setFieldData({ ...fieldData, maxDiscount: value });
    }
    setFieldData({ ...fieldData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      fieldData.validTo >= fieldData.validFrom &&
      fieldData.travelTo >= fieldData.travelFrom
    ) {
      try {
        const { data } = await addCouponApi(fieldData);
        Swal.fire({
          icon: data.httpStatus === "OK" ? "success" : "error",
          text: data.msg,
          showConfirmButton: false,
          timer: 2000,
        });
        updatedFetchData();
        HandleClose();
      } catch (e) {}
    } else {
      alert("Invalid Date");
    }
  };
  useEffect(() => {
    if (fieldData.newUser) {
      setFieldData({ ...fieldData, useLimit: 1, useAbility: "onetime" });
    } else {
      setFieldData({ ...fieldData, useAbility: "mulitipletime" });
    }
  }, [fieldData.newUser]);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className=" d-flex justify-content-between align-items-center">
          <FormControlLabel
            checked={fieldData.newUser}
            control={
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldData({ ...fieldData, newUser: true });
                  } else {
                    setFieldData({ ...fieldData, newUser: false });
                  }
                }}
              />
            }
            label="First Time User"
          />
          <FormControlLabel
            checked={advanceField}
            control={
              <Checkbox onChange={(e) => setAdvanceField(e.target.checked)} />
            }
            label="Additional fields"
          />
        </div>
        <Row>
          {AddCouponMgtData(soruceData, fieldData.newUser, advanceField)
            .slice(0, advanceField ? 34 : 20)
            .map((item, i) => {
              const type = item.type;
              return (
                <Fragment key={item.id}>
                  {["text", "number", "textarea"].includes(type) ? (
                    <>
                      <Col
                        xs={12}
                        md={6}
                        xl={checkFieldInclids(item.name)}
                        className="mb-3"
                        key={i}
                      >
                        <Inputs
                          type={item.type}
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => handleChange(e)}
                          required={item.required}
                          value={fieldData[item.name]}
                          disabled={
                            item.name === "maxDiscount" &&
                            fieldData.discountType === "F"
                              ? true
                              : item.disabled
                          }
                          classstyle={`form-control ${type === "textarea" &&
                            "pt-2"}`}
                        />
                      </Col>
                    </>
                  ) : type === "date" ? (
                    <>
                      <Col xs={12} md={6} xl={2} className="mb-3" key={i}>
                        <DateInput
                          type={item.type}
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => handleChange(e)}
                          required={item.required}
                          defaultValue={item.defaultValue}
                          disabled={item.disabled}
                          classstyle="form-control"
                        />
                      </Col>
                    </>
                  ) : type === "heading" && advanceField ? (
                    <Col xl={12}>
                      <div className=" text-center my-3 py-2  font-bold bg-success text-white">
                        {item.label}
                      </div>
                    </Col>
                  ) : (
                    <>
                      <Col xs={12} md={6} xl={2} className="mb-3" key={i}>
                        <Select
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => handleChange(e)}
                          firstName={""}
                          optionValue={item.optionValue}
                          required={item.required}
                          value={fieldData[item.name]}
                          disabled={
                            item.name === "discountApplicable"
                              ? true
                              : item.disabled
                          }
                          classstyle="form-control"
                        />
                      </Col>
                    </>
                  )}
                </Fragment>
              );
            })}

          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2  btn-md "
              // onClick={dublicateHandleSubmit}
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddCouponModal;
