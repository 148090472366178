import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Inputs from "../../../../CustomComponent/Inputs";
import Button from "../../../../CustomComponent/Button";
import Select from "../../../../CustomComponent/Select";
import Swal from "sweetalert2";
import { getInsurancePlanApi } from "../../../../Services/Api/InsuranceHandleApi";
import { insuranceProviderList } from "../../InsurnaceHandlerMarkup/StaticInsuranceOpt";
import {
  addInsuranceApi,
  getInsuranceBenefitApi,
  getInsurancePremiumApi,
  updateInsuranceApi,
} from "../../../../Services/Api/insurance";

const AddInsuranceModal = ({ allSource, fetchData, setShowModal, isEdit }) => {
  const [planList, setPlanList] = useState([]);
  const [premiumList, setPremiumList] = useState([]);
  const [planLoading, setPlanLoading] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState({});
  const newEditData = {
    ...isEdit,
    insurancePlan: JSON.stringify(isEdit.insurancePlan),
    premium: JSON.stringify(isEdit.premium),
  };
  const [load, setLoad] = useState(false);
  const [fieldData, setFieldData] = useState(
    isEdit
      ? newEditData
      : {
          source: "",
          currency: "",
          siteId: "",
          insuranceProvider: "",
          insuranceName: "",
          travelCategory: "",
          planCountry: "",
          insurancePlan: "",
          premium: null,
        }
  );
  const {
    source,
    currency,
    siteId,
    insuranceProvider,
    insuranceName,
    travelCategory,
    planCountry,
    insurancePlan,
    premium,
  } = fieldData;
  const handleInsuranceProvider = () => {
    const res = insuranceProviderList.find(
      (ele) => ele.name === insuranceProvider
    );
    setSelectedProvider(res);
    if (
      isEdit &&
      Object.keys(isEdit).length > 0 &&
      Object.keys(res).length > 0
    ) {
      getInsurancePlan(res);
    }
  };
  const getInsurancePlan = async (res = "") => {
    setPlanLoading(true);
    const getPlanCtryId = (res ? res : selectedProvider).travelCountryList.find(
      (it) => it.name === planCountry
    ).valueCode;
    const getTravelCateId = (
      res ? res : selectedProvider
    ).travelCategoryList.find((it) => it.name === travelCategory).valueCode;
    const getInsPlanNameId = (
      res ? res : selectedProvider
    ).insuranceNameList.find((it) => it.name === insuranceName).valueCode;
    try {
      const { data } = await getInsurancePlanApi(
        getPlanCtryId,
        getTravelCateId,
        getInsPlanNameId
      );
      setPlanList(data.data ? data.data : []);
    } catch (error) {}
    setPlanLoading(false);
  };
  const getPremiumandBenefitList = async () => {
    const planCode = JSON.parse(insurancePlan).planCode;
    try {
      const { data } = await getInsurancePremiumApi(planCode);
      setPremiumList(data.data ? data.data : []);
    } catch (error) {}
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "currency") {
      value = value.toUpperCase();
    }
    setFieldData({ ...fieldData, [name]: value.trimStart() });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    const payload = {
      ...fieldData,
      premium: JSON.parse(premium),
      insurancePlan: JSON.parse(insurancePlan),
    };
    try {
      const { data } = isEdit
        ? await updateInsuranceApi(payload)
        : await addInsuranceApi(payload);
      if (data.httpStatus === "OK") {
        fetchData();
        setShowModal(false);
      }
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
    setLoad(false);
  };
  useEffect(() => {
    insuranceProvider && handleInsuranceProvider();
    insurancePlan && getPremiumandBenefitList();
  }, [insuranceProvider, insurancePlan]);
  return (
    <div>
      <div className="">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="source"
                label="Source"
                onChange={handleChange}
                optionValue={allSource.data}
                firstName={"Select"}
                required={true}
                classstyle="form-control"
                value={source}
                disabled={isEdit ? true : false}
              />
            </Col>

            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="number"
                name="siteId"
                placeholder="Enter the Site ID"
                label="Site ID"
                onChange={handleChange}
                required={true}
                classstyle="form-control"
                value={siteId}
                disabled={isEdit ? true : false}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="text"
                name="currency"
                placeholder="Enter the currency code"
                label="Currency"
                onChange={handleChange}
                required={true}
                classstyle="form-control"
                value={currency}
                disabled={isEdit ? true : false}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="insuranceProvider"
                label="Insurance Provider"
                onChange={handleChange}
                optionValue={insuranceProviderList}
                firstName={"Select"}
                required={true}
                classstyle="form-control mt-4"
                value={insuranceProvider}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="insuranceName"
                optionValue={selectedProvider.insuranceNameList || []}
                firstName={"Select"}
                value={insuranceName}
                label="Insurance Name"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                disabled={!insuranceProvider}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="travelCategory"
                optionValue={selectedProvider.travelCategoryList || []}
                firstName={"Select"}
                label="Travel Category"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={travelCategory}
                disabled={!insuranceProvider}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="planCountry"
                optionValue={selectedProvider.travelCountryList || []}
                firstName={"Select"}
                label="Travel Country"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={planCountry}
                disabled={!insuranceProvider}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <div className="input-classicsite ">
                <label className="form-label">Insurance Plan</label>
                <select
                  onChange={handleChange}
                  value={insurancePlan}
                  className="form-control mt-4"
                  name="insurancePlan"
                  onClick={() =>
                    insuranceName &&
                    travelCategory &&
                    planCountry &&
                    getInsurancePlan()
                  }
                  required
                >
                  <option>{planLoading ? "Loading..." : "Select"}</option>
                  {planList.map((ele) => {
                    return (
                      <option value={JSON.stringify(ele)}>{ele.name}</option>
                    );
                  })}
                </select>
              </div>
            </Col>
            <Col xs={12} md={6} xl={4}>
              <div className="input-classicsite ">
                <label className="form-label">Premimum</label>
                <select
                  onChange={handleChange}
                  value={premium}
                  className="form-control mt-4"
                  name="premium"
                  required
                  disabled={!insurancePlan}
                >
                  <option value={null}>{"Select"}</option>
                  {premiumList.map((ele) => {
                    return (
                      <option
                        value={JSON.stringify(ele)}
                      >{`Age: ${ele.ageLimit}, Day: ${ele.dayLimit}, Premium: ${ele.premium}`}</option>
                    );
                  })}
                </select>
              </div>
            </Col>
            <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
              <Button
                type="submit"
                title={load ? "Please Wait..." : "Submit"}
                disabled={load}
                color="btn btn-submit btnver-2  btn-md "
              />
            </div>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default AddInsuranceModal;
