import React from "react";
import Form from "react-bootstrap/Form";

const Inputs = ({
  label,
  type,
  placeholder,
  classstyle,
  pattern,
  ...props
}) => {
  return (
    <Form.Group className="input-classicsite">
      {label && <Form.Label>{label}</Form.Label>}
      {type === "textarea" ? (
        <textarea
          type={type}
          className={classstyle}
          placeholder={placeholder}
          {...props}
        ></textarea>
      ) : (
        <input
          type={type}
          className={classstyle}
          placeholder={placeholder}
          {...props}
        />
      )}
    </Form.Group>
  );
};

export default Inputs;
