import React, { useEffect, useState } from "react";
import BookingLists from "../Molecule/BookingList/BookingList";
import BookingListFilter from "../Molecule/BookingList/BookingListFilter";
import BookingSidebar from "../Molecule/BookingList/BookingSidebar";
import { travel_url } from "../Host";

const BookingList = () => {
  const [getAllsource, setAllSource] = useState([]);
  const [filterObj, setFilterObj] = useState({
    from: 0,
    to: 0,
    pnr: "",
    bookingId: 0,
    orderId: "",
    source: "",
    agentName: "",
    bookingGds: 0,
    doneGds: 0,
    tripType: 0,
    paymentStatus: "",
  });
  /*------------------------------------------------------------------ GET Sources_------------------- */
  const GetAllSources = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getSource`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        result.data && setAllSource(result.data);
      })
      .catch((error) => {
        console.log(error);
        // setError(
        //   "There is Some Technical Error, Please contact with Technical Team"
        // );
        // setIsLoading(false);
      });
  };
  useEffect(() => {
    GetAllSources();
  }, []);
  return (
    <div>
      {/* <BookingSidebar  Pagetitle={`Booking List`}/> */}
      <BookingListFilter
        setFilterObj={setFilterObj}
        getAllsource={getAllsource}
      />
      <BookingLists filterObj={filterObj} />
    </div>
  );
};
export default BookingList;
