import axios from "axios";
import React, { useEffect, useState } from "react";
import { travel_url } from "../../../Host";
import { commonheader, header } from "../../../utils/header";
import { TbDatabaseSearch } from "react-icons/tb";
import Modals from "../../../CustomComponent/Modals";
import AddProtalDataModal from "./AddProtalDataModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert";
const PortalConfig = () => {
  const [configData, setConfigData] = useState([]);
  const [load, setLoad] = useState(true);
  const [modalopen, setModalopen] = useState(false);
  const fetchData = async () => {
    setLoad(true);
    try {
      const { data } = await axios.get(`${travel_url}/getAllPortalConfig`, {
        headers: commonheader(),
      });
      if (data.data) {
        setConfigData(data.data);
      }
    } catch (error) {}
    setLoad(false);
  };
  const handleSubmit = async (payload) => {
    try {
      const { data } = await axios.post(`${travel_url}/portalConfig`, payload, {
        headers: commonheader(),
      });
      swal("", data.msg, data.httpStatus === "OK" ? "success" : "warning");
      console.log("first", data);
      if (data.httpStatus === "OK") {
        setModalopen(false);
        fetchData();
      }
    } catch (error) {}
  };
  const handleDelete = async (id) => {
    try {
      const { data } = await axios.delete(
        `${travel_url}/deletePortalConfig/${id}`,
        {
          headers: commonheader(),
        }
      );
      swal("", data.msg, data.httpStatus === "OK" ? "success" : "warning");
      if (data.httpStatus === "OK") {
        fetchData();
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  if (load) {
    return (
      <div className="col-12 mb-4 text-center font-semibod">
        Please wait for getting data....
      </div>
    );
  }
  return (
    <div>
      <div className="col-12 mb-4">
        <div className="metaBox position-relative d-block border-0 smtb">
          <h6 className="title-lightthm p-3 m-0">Portal Config</h6>
          {configData.length > 0 && (
            <div className="w-100">
              <div className="table-responsive lightthm-wrap">
                <table className="table table-lightthm text-left">
                  <thead className="bg-light">
                    <tr>
                      <th width={20}>S.N.</th>
                      <th>Website</th>
                      <th>Primiary</th>
                      <th>Creation Date</th>
                      <th>Created By</th>
                      <th>Status</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {configData.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{item.site}</td>
                          <td>{item.primary}</td>
                          <td>{new Date(item.time).toDateString()}</td>
                          <td>{item.user.name}</td>
                          <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input cursor-pointer"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                onChange={(e) =>
                                  handleSubmit({
                                    ...item,
                                    enabled: e.target.checked,
                                  })
                                }
                                defaultChecked={item.enabled}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="btni-sp">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft-danger"
                                  onClick={() => handleDelete(item._id)}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {configData.length <= 0 && (
            <div className=" text-center text-muted mt-4">
              <TbDatabaseSearch size={40} />
              <p className="text text-bold mt-2">No Data Available!</p>
            </div>
          )}
          <div className="text-right px-3 py-4">
            <div className="btni-sp">
              <button
                className="btn-submit btnver-2 success"
                onClick={() => setModalopen(true)}
              >
                Add <i className="fa-solid fa-plus icon-add"></i>
              </button>
            </div>
          </div>

          <Modals
            show={modalopen}
            HandleClose={() => setModalopen(false)}
            ModalBody={<AddProtalDataModal handleSubmit={handleSubmit} />}
            title={"Add"}
            mclass="bdsList"
          />
        </div>
      </div>
    </div>
  );
};

export default PortalConfig;
