import React, { useState } from "react";
import axios from "axios";
import { travel_url } from "../../Host";

const useGetCacheManagement = () => {
  const [apiData, setApiData] = useState({
    httpStatus: "not ok",
    msg: "initial",
  });
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    const options = {
      method: "GET",
      url: `${travel_url}/cache/config/`,
      headers: {
        "x-api-key": localStorage.getItem("token"),
      },
    };

    try {
      const apiCall = await axios.request(options);
      const data = await apiCall;
      setApiData(data.data);
    } catch (e) {
      setApiData({
        httpStatus: "not ok",
        msg: "This is expection",
      });
      console.log(e);
    }
    setLoading(false);
  };

  return [apiData, loading, fetchData];
};

export default useGetCacheManagement;
