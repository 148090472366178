import axios from "axios";
import { getAllConfig_url } from "./Urls";

export const getAllConfig = ({ setGetAllConfigData }) => {
  var data = JSON.stringify({});

  var config = {
    method: "get",
    url: getAllConfig_url,
    headers: {
      "x-api-key": localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
     
      setGetAllConfigData(response.data.data);
    })
    .catch(function (error) {
    
    });
};
