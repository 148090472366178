import { getApiAirlines } from "./Urls";

export const getAllPreferAirline = ({ setPreferAirline }) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": localStorage.getItem("token"),
    },
  };
  fetch(getApiAirlines, options)
    .then((response) => response.json())
    .then((response) => {
      setPreferAirline(response);
    })
    .catch((err) => console.error(err));
};
