import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import swal from "sweetalert";
import Button from "../../../CustomComponent/Button";
import Inputs from "../../../CustomComponent/Inputs";
import Loading from "../../../CustomComponent/Loading";
import Select from "../../../CustomComponent/Select";
import useAddCacheManagement from "../../../hooks/CachemenagementHook/useAddCacheManagement";
import { AddCacheManagementApi } from "../../../SmallApi/CacheManagementSmallApi.js/AddCacheManagementApi";

const AddCacheManagementModal = (props) => {
  const { setRefreshloading, refreshLoading, setShowAddModal, rangeList } =
    props;
  const [getData, loading, AddData] = useAddCacheManagement();
  const [addCacheManagement, setAddCacheManagement] = useState({
    origin: "",
    destination: "",
    range: "",
    session: "",
    enabled: "",
  });
  const onSubmit = async (e) => {
    e.preventDefault();

    if (
      addCacheManagement.origin.toUpperCase() === "ALL" &&
      addCacheManagement.destination.toUpperCase() !== "ALL"
    ) {
      swal(
        "Add Error",
        "In case of ALL , Origin and Destination both will be same"
      );
    } else if (
      addCacheManagement.origin.toUpperCase() !== "ALL" &&
      addCacheManagement.destination.toUpperCase() === "ALL"
    ) {
      swal(
        "Add Error",
        "In case of ALL , Origin and Destination both will be same"
      );
    } else {
      AddData(
        addCacheManagement,
        setRefreshloading,
        refreshLoading,
        setShowAddModal
      );
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setAddCacheManagement({ ...addCacheManagement, [name]: value });
  };

  // rangeList.unshift({
  //   id: "0",
  //   name: "Select Range",
  //   value: "0-0",
  // });

  return (
    <form onSubmit={onSubmit}>
      <div className="text-center text-danger">
        {loading ? "Loading..." : getData.msg}
      </div>
      <Row>
        {AddCacheManagementApi(rangeList).map((item, i) => {
          const type = item.type;
          return (
            <Fragment key={item.id}>
              {["text", "date"].includes(type) ? (
                <>
                  <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                    <Inputs
                      type={item.type}
                      name={item.name}
                      placeholder={item.placeholder}
                      label={item.label}
                      onChange={(e) => onChange(e)}
                      required={item.required}
                      defaultValue={item.defaultValue}
                      disabled={item.disabled}
                      classstyle="form-control"
                    />
                  </Col>
                </>
              ) : (
                <>
                  <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                    <Select
                      name={item.name}
                      placeholder={item.placeholder}
                      label={item.label}
                      onChange={(e) => onChange(e)}
                      optionValue={item.optionValue}
                      required={item.required}
                      defaultValue={item.defaultValue}
                      disabled={item.disabled}
                      classstyle="form-control"
                    />
                  </Col>
                </>
              )}
            </Fragment>
          );
        })}
        <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
          <Button
            type="submit"
            title="Submit"
            color="btn btn-submit btnver-2  btn-md "
            // onClick={dublicateHandleSubmit}
          />
        </div>
      </Row>
    </form>
  );
};

export default AddCacheManagementModal;
