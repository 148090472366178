import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AddcachePriceFeed } from "../../Apis/CachePriceFeedApi/AddcachePriceFeed";
import { DownloadCachePriceFeed } from "../../Apis/CachePriceFeedApi/DownloadCachePriceFeed";
import { GetCachePriceFeed } from "../../Apis/CachePriceFeedApi/GetCachePriceFeed";
import Button from "../../CustomComponent/Button";
import Inputs from "../../CustomComponent/Inputs";
import Loading from "../../CustomComponent/Loading";
import PriceFeedTable from "../../Molecule/CachePriceFeedComp/PriceFeedTable";
import PriceFeedTableFilter from "../../Molecule/CachePriceFeedComp/PriceFeedTableFilter";
import DashboardBar from "../../Molecule/Dashboard/DashboardSideBar";
import ResultNotFound from "../../Molecule/MarkUpDeatils/ErrorScreen/ResultNotFound";
import { AddCachePriceFeedInput } from "../../SmallApi/CachePriceFeedSmallApi/FilterCachePriceFeedInput";
import { DownloadModalInput } from "../../SmallApi/CachePriceFeedSmallApi/DownloadModalInput";
import axios from "axios";
import { travel_url } from "../../Host";
import moment from "moment";
import { commonheader } from "../../utils/header";
import Modals from "../../CustomComponent/Modals";
import AllfilesDownloadModal from "../../Molecule/CachePriceFeedComp/ModalBody/AllfilesDownloadModal";

const PriceFeed = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [isdownloader, setIsDownloader] = useState(false);
  const [getcachePricefeedData, setGetcachePricefeedData] = useState({});
  const [cachePricefeedLoading, setCachePricefeedLoading] = useState(false);
  const [addCacheFeedLoading, setAddCacheFeedLoading] = useState(false);
  const [checkValue, setCheckValue] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [downInpData, setDonwInpData] = useState({
    _id: "1",
    min: null,
    dataLimit: null,
    fromDate: "",
    toDate: "",
  });
  const [
    updateCachePriceFeedLoading,
    setUpdateCachePriceFeedLoading,
  ] = useState(false);
  const [
    deleteCachePriceFeedLoading,
    setDeleteCachePriceFeedLoading,
  ] = useState(false);
  const [dublicateCacheFeedLoading, setDublicateCacheFeedLoading] = useState(
    false
  );
  const [sendcachePricefeedData, setSendcachePricefeedData] = useState({
    tripType: "",
    origin: "",
    destination: "",
    outboundDate: "",
    returnDate: "",
    outboundItinerary: [
      {
        origin: "",
        destination: "",
        marketingAirline: "",
        marketingFlightNumber: "",
        operationAirline: "",
        operatingFlightNumber: "",
        depDate: "",
        depTime: "",
        arriTime: "",
      },
    ],
    returnItinerary: [
      {
        origin: "",
        destination: "",
        marketingAirline: "",
        marketingFlightNumber: "",
        operationAirline: "",
        operatingFlightNumber: "",
        depDate: "",
        depTime: "",
        arriTime: "",
      },
    ],
    totalPrice: "",
    currency: "",
  });
  const [outItinerary, setOutItinerary] = useState([
    {
      origin: "",
      destination: "",
      marketingAirline: "",
      marketingFlightNumber: "",
      operationAirline: "",
      operatingFlightNumber: "",
      depDate: "",
      depTime: "",
      arriTime: "",
    },
  ]);
  const [inItinerary, setInItinerary] = useState([
    {
      origin: "",
      destination: "",
      marketingAirline: "",
      marketingFlightNumber: "",
      operationAirline: "",
      operatingFlightNumber: "",
      depDate: "",
      depTime: "",
      arriTime: "",
    },
  ]);

  const closeModal = () => {
    setShowModal(false);
  };
  const changeFunction = (e) => {
    const { name, value } = e.target;
    setSendcachePricefeedData({ ...sendcachePricefeedData, [name]: value });
  };

  const AddCachePriceFeedHandleSubmit = () => {
    const {
      outboundItinerary,
      returnItinerary,
      ...rest
    } = sendcachePricefeedData;

    var status = false;
    const JointData = {
      ...rest,
      outboundItinerary: outItinerary,
      returnItinerary: inItinerary,
    };
    if (
      sendcachePricefeedData.tripType &&
      sendcachePricefeedData.origin &&
      sendcachePricefeedData.destination &&
      sendcachePricefeedData.outboundDate &&
      sendcachePricefeedData.totalPrice &&
      sendcachePricefeedData.currency
    ) {
      for (let index = 0; index < outItinerary.length; index++) {
        const element = outItinerary[index];
        if (
          element.origin &&
          element.destination &&
          element.marketingAirline &&
          element.marketingFlightNumber &&
          element.operationAirline &&
          element.operatingFlightNumber &&
          element.depDate &&
          element.depTime &&
          element.arriTime
        ) {
          if (sendcachePricefeedData.tripType == "RT") {
            for (let i = 0; i < inItinerary.length; i++) {
              const element = inItinerary[i];
              if (
                element.origin &&
                element.destination &&
                element.marketingAirline &&
                element.marketingFlightNumber &&
                element.operationAirline &&
                element.operatingFlightNumber &&
                element.depDate &&
                element.depTime &&
                element.arriTime
              ) {
                status = true;
              } else {
                alert("Please Fill all the fileds");
              }
            }
          }
          status = true;
        } else {
          alert("Please Fill all the fileds");
        }
      }
    } else {
      alert("Please Fill all the fileds");
    }

    if (status) {
      AddcachePriceFeed({
        data: JointData,
        loading: setAddCacheFeedLoading,
        navigate,
        modalClose: setShowModal,
      });
      setSendcachePricefeedData({
        tripType: "",
        origin: "",
        destination: "",
        outboundDate: "",
        returnDate: "",
        outboundItinerary: [
          {
            origin: "",
            destination: "",
            marketingAirline: "",
            marketingFlightNumber: "",
            operationAirline: "",
            operatingFlightNumber: "",
            depDate: "",
            depTime: "",
            arriTime: "",
          },
        ],
        returnItinerary: [
          {
            origin: "",
            destination: "",
            marketingAirline: "",
            marketingFlightNumber: "",
            operationAirline: "",
            operatingFlightNumber: "",
            depDate: "",
            depTime: "",
            arriTime: "",
          },
        ],
        totalPrice: "",
        currency: "",
      });
    }
  };

  useEffect(() => {
    GetCachePriceFeed({
      data: sendcachePricefeedData,
      getData: setGetcachePricefeedData,
      loading: setCachePricefeedLoading,
      navigate,
    });
    setCheckValue(false);
  }, [
    checkValue,
    addCacheFeedLoading,
    updateCachePriceFeedLoading,
    deleteCachePriceFeedLoading,
  ]);
  function DownloadCachePriceFeed1() {
    const link = document.createElement("a");
    link.href =
      "https://travomint-s3.s3.ap-south-1.amazonaws.com/CacheFeed/08-04-2024-11-02.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  // ------------------DownLoad Modal ----------------
  function onChange(e) {
    const { name, value } = e.target;
    if (name === "fromDate" || name === "toDate") {
      setDonwInpData({
        ...downInpData,
        [name]: moment().format(value, "YYYY-MM-DD"),
      });
    }
    setDonwInpData({ ...downInpData, [name]: value });
  }
  const handleDownloadSubmit = async (e) => {
    e.preventDefault();
    setIsDownloader(true);
    try {
      const { data } = await axios.post(
        `${travel_url}/googleCache/config`,
        downInpData,
        { headers: commonheader() }
      );
      if (data) {
        DownloadCachePriceFeed(sendcachePricefeedData, setIsDownloader);
      }
    } catch (error) {
      console.log(error);
      setIsDownloader(false);
    }
  };
  useEffect(() => {
    !isdownloader && setShow(false);
  }, [isdownloader]);
  return (
    <div>
      {(cachePricefeedLoading ||
        addCacheFeedLoading ||
        updateCachePriceFeedLoading ||
        deleteCachePriceFeedLoading ||
        dublicateCacheFeedLoading) && <Loading title="loading..." />}
      {/* <DashboardBar  Pagetitle={`Cache Price Feed`}/> */}
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1 mt-cachepricefeed">
        <div className="container-fluid px-3 bg-white pt-5 pb-3">
          <div className=" text-right pb-3  d-flex">
            <Button
              title={
                <span>
                  <i className="fa fa-arrow-down" aria-hidden="true"></i>{" "}
                  Download
                </span>
              }
              color="btn-submit btnver-2 light shadow-0"
              onClick={
                () => setShow(true)
                // ()=> DownloadCachePriceFeed(sendcachePricefeedData)
              }
            />

            <Button
              title={
                <span>
                  <i className="fa-solid fa-filter"></i> Filter
                </span>
              }
              color="btn-submit btnver-2 lightgry d-inline-flex justify-content-center flex-column  ms-3 "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
              // onClick={() => DownloadCachePriceFeed()}
            />
            <Button
              title={
                <span>
                  <i className="fa-regular fa-file-lines mr-1"></i> Uploaded
                  Files
                </span>
              }
              color="btn-submit  success btnver-2 d-inline-flex justify-content-center flex-column  ms-3 "
              type="button"
              // data-bs-toggle="collapse"
              // data-bs-target="#collapseExample"
              // aria-expanded="false"
              // aria-controls="collapseExample"
              onClick={() => setOpenFile(true)}
            />
            <Button
              title={
                <>
                  <i className="fa-solid fa-plus icon-add"></i> Add
                </>
              }
              color="btn-submit btnver-2 success ms-auto"
              onClick={() => setShowModal(true)}
            />
          </div>

          {Object.keys(getcachePricefeedData).length !== 0 ? (
            <>
              {getcachePricefeedData.httpStatus == "OK" &&
              getcachePricefeedData.msg == "Data returned successfully" &&
              getcachePricefeedData.data !== null ? (
                <>
                  <div className="collapse" id="collapseExample">
                    <div className="filter-css w-100 float-left mt-0 mb-4">
                      <PriceFeedTableFilter
                        changeFunction={changeFunction}
                        onClick={() => setCheckValue(true)}
                      />
                    </div>
                  </div>

                  <PriceFeedTable
                    data={getcachePricefeedData}
                    showModal={showModal}
                    closeModal={closeModal}
                    mainBody={sendcachePricefeedData}
                    setMainBody={setSendcachePricefeedData}
                    body={outItinerary}
                    setBody={setOutItinerary}
                    inbody={inItinerary}
                    setInBody={setInItinerary}
                    AddHandleSubmit={AddCachePriceFeedHandleSubmit}
                    Updateloading={setUpdateCachePriceFeedLoading}
                    Deleteloading={setDeleteCachePriceFeedLoading}
                    setDublicateCacheFeedLoading={setDublicateCacheFeedLoading}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <ResultNotFound />
          )}
        </div>
      </div>
      {/* <div className={{}}>1,2,3</div> */}
      <Modal centered show={show} onHide={() => setShow(false)}>
        {" "}
        <form onSubmit={handleDownloadSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="modal-Title">
              Download + Auto Upload
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="">
              {DownloadModalInput.map((item) => {
                return (
                  <Col xs={12} md={6} xl={4}>
                    <Inputs
                      type={item.type}
                      name={item.name}
                      placeholder={item.placeholder}
                      label={item.label}
                      onChange={(e) => onChange(e)}
                      required={item.required}
                      // defaultValue={api}
                      disabled={false}
                      classstyle="form-control mb-2"
                    />
                  </Col>
                );
              })}
            </Row>
          </Modal.Body>
          <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
            <button
              type="submit"
              disabled={isdownloader}
              className="btn btn btn-submit btnver-2"
            >
              {isdownloader ? "Please Wait..." : "Submit"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ width: "65rem", margin: "auto", right: "0" }}
        show={openFile}
        onHide={() => setOpenFile(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-Title">Download Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AllfilesDownloadModal />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PriceFeed;
