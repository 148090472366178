import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Inputs from "../../../../CustomComponent/Inputs";
import Button from "../../../../CustomComponent/Button";
import Swal from "sweetalert2";

import { editInsuranceBenefitApi } from "../../../../Services/Api/InsuranceBenefitsApi";
import Modals from "../../../../CustomComponent/Modals";
import AddIconModal from "./AddIconModal";

const EditInsuranceBenefit = ({
  fetchData,
  setShowModal,
  isEdit,
  iconList,
}) => {
  const [showIcon, setShowIcon] = useState(false);
  const { id, type, ...restEditData } = isEdit;
  const [load, setLoad] = useState(false);
  const [fieldData, setFieldData] = useState({
    ...restEditData,
    benefitName: type,
  });
  const { planCode, coverage, icon, benefitName } = fieldData;

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "currency") {
      value = value.toUpperCase();
    }
    setFieldData({ ...fieldData, [name]: value.trimStart() });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      planCode: planCode,
      coverage: coverage,
      icon: icon,
      type: benefitName,
    };
    setLoad(true);
    try {
      const { data } = await editInsuranceBenefitApi(id, payload);
      if (data.httpStatus === "OK") {
        fetchData();
        setShowModal(false);
      }
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
    setLoad(false);
  };

  return (
    <div>
      <div className="">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="text"
                name="benefitName"
                placeholder="Enter the insurance benefit name"
                label="Benefit Name"
                onChange={handleChange}
                required={true}
                classstyle="form-control"
                value={benefitName}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="planCode"
                name="coverage"
                placeholder="Enter the plan code"
                label="Plan Code"
                required={true}
                classstyle="form-control"
                value={planCode}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="number"
                name="coverage"
                placeholder="Enter the coverage"
                label="Coverage"
                onChange={handleChange}
                required={true}
                classstyle="form-control"
                value={coverage}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <div className="input-classicsite">
                <label className="form-label">Icon</label>
                <div
                  className="form-control mt-4 d-flex align-items-center justify-content-between cursor-pointer"
                  onClick={() => setShowIcon(true)}
                >
                  <div>
                    {icon ? (
                      <img
                        src={`data:image/svg+xml;base64,${btoa(icon)}`}
                        width={30}
                        height={30}
                        alt="icon"
                      />
                    ) : (
                      "Add Icon"
                    )}
                  </div>
                  <i className="fa-solid fa-plus"></i>
                </div>
              </div>
            </Col>
            <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
              <Button
                type="submit"
                title={load ? "Please Wait..." : "Update"}
                disabled={load}
                color="btn btn-submit btnver-2  btn-md "
              />
            </div>
          </Row>
        </form>
      </div>
      <Modals
        show={showIcon}
        HandleClose={() => setShowIcon(false)}
        ModalBody={
          <AddIconModal
            iconList={iconList}
            onClickIcon={(svg) => {
              setFieldData({ ...fieldData, icon: svg });
              setShowIcon(false);
            }}
            icon={icon}
          />
        }
        title={"Updated Icon"}
      />
    </div>
  );
};

export default EditInsuranceBenefit;
