import axios from "axios";
import { DownloadFile } from "../../utils/DownloadFile";
import { DownloadCachePriceFeed_Url } from "../Urls";
export const DownloadCachePriceFeed = (data,Loading) => {
  const options = {
    method: "POST",
    url: DownloadCachePriceFeed_Url,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": localStorage.getItem("token"),
    },
    data: data,
  };

  axios
    .request(options)
    .then(function (response) {
      DownloadFile(response.data.data.file, "travomint_full_feed", ".csv");
      Loading(false)
    })
    .catch(function (error) {
      console.error(error);
    });
    
};
