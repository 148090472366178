import axios from "axios";
import { mca_url, travel_url } from "../../../Host";
import { commonheader, header } from "../../../utils/header";
const gatewayInstance = axios.create({
  baseURL: travel_url,
});
gatewayInstance.interceptors.request.use((config) => {
  const userToken = localStorage.getItem("token");
  if (userToken) {
    config.headers = commonheader();
  }
  return config;
});
// const extraHeader = {
//   "X-Extra-Key": "your_extra_value",
// };
export const getAllGatewayListApi = () =>
  gatewayInstance.get("/findAllGatewayMapping");
export const addUdateGatewayApi = (data) =>
  gatewayInstance.post("/saveGatewayMapping", data);
export const deleteGatewayApi = (id) =>
  gatewayInstance.delete(`/deleteGatewayMapping?id=${id}`);
export const getAllPaymentGateListApi = () =>
  gatewayInstance.get("/findAllGateway");
