import axios from "axios";
import { MarkUp_List } from "../Urls";
export const MarkUpList = ({ state, loading, navigate }) => {
  var data = JSON.stringify({});
  var config = {
    method: "get",
    url: MarkUp_List,
    headers: {
      "x-api-key": localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    data: data,
  };

  loading(true);

  axios
    .request(config)
    .then(function (response) {
      if (
        response.data.httpStatus === "OK" &&
        response.data.data !== null &&
        response.data.msg.includes("successfully")
      ) {
        state(response.data);
        loading(false);
      } else {
        alert(response.data.msg);
        state(response.data);
        loading(false);
      }
    })
    .catch(function (error) {
      // console.error(error);
      // if (error.response.status == 401) {
      //   navigate("/login");
      //   localStorage.clear();
      // }
    });
};
