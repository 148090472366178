import React from "react";

const Icons = ({ iconName, onClick }) => {
  return (
    <div onClick={onClick}>
      {" "}
      <i className={iconName}></i>{" "}
    </div>
  );
};

export default Icons;
