import React, { Fragment, useEffect, useState } from "react";
import Button from "../../../../CustomComponent/Button";
import Select from "../../../../CustomComponent/Select";
import DateInput from "../../../../CustomComponent/DateInput";
import Inputs from "../../../../CustomComponent/Inputs";
import { Col, Row } from "react-bootstrap";
import { Checkbox, FormControlLabel } from "@mui/material";
import Swal from "sweetalert2";
import moment from "moment";
import { UpdateHotelCouponMgtData } from "../../../../SmallApi/CouponMgtData/HotelCouponMgmt/UpdateHotelCouponMgtData";
import { updateHotelCouponApi } from "../../../../Services/Api/couponMgtApi/HotelsCouponMgmtApi";

const HotelUpdateCouponModal = ({
  data,
  soruceData,
  updatedFetchData,
  HandleClose,
}) => {
  const [fieldData, setFieldData] = useState(data);
  const [advanceField, setAdvanceField] = useState(false);
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (
      name === "validFrom" ||
      name === "validTo" ||
      name === "travelFrom" ||
      name === "travelTo"
    ) {
      value = new Date(value).valueOf();
    }
    if (
      [
        "couponCode",
        "country",
        "destinationCountry",
        "currency",
        "airlines",
        "originAirport",
        "destinationAirport",
      ].includes(name)
    ) {
      value = value.toUpperCase();
    }
    if (name === "userIds") {
      value = value.split(",");
    }
    if (fieldData.discountType === "F" && name === "discountAmt") {
      setFieldData({ ...fieldData, maxDiscount: value });
    }
    setFieldData({ ...fieldData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      fieldData.validTo >= fieldData.validFrom &&
      fieldData.checkInTo >= fieldData.checkInFrom
    ) {
      try {
        const { data } = await updateHotelCouponApi(fieldData);
        Swal.fire({
          icon: data.httpStatus === "OK" ? "success" : "error",
          text: data.msg,
          showConfirmButton: false,
          timer: 2000,
        });
        updatedFetchData();
        HandleClose();
      } catch (e) {}
    } else {
      alert("Invalid Date");
    }
  };
  function checkFieldInclids(fieldName) {
    return fieldName == "description"
      ? 7
      : fieldName === "couponCode"
      ? 3
      : fieldName === "country" || fieldName === "minAmount"
      ? 4
      : 2;
  }
  function checkAdanceFieldEnabled() {
    var status =
      ![null, "ALL"].includes(fieldData.city) ||
      fieldData.roomType != "ALL" ||
      fieldData.room != 0 ||
      fieldData.night != 0 ||
      fieldData.pax != 0 ||
      fieldData.platform != "ALL" ||
      fieldData.capDays != 0 ||
      fieldData.dayOfWeek != 0 ||
      fieldData.dateOfMonth != 0 ||
      fieldData.checkInFrom != 0 ||
      fieldData.checkInTo != 0 ||
      fieldData.userIds.length != 0;
    return status;
  }
  useEffect(() => {
    setAdvanceField(advanceField ? true : checkAdanceFieldEnabled());
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className=" d-flex justify-content-between align-items-center">
          <FormControlLabel
            checked={fieldData.newUser ? fieldData.newUser : false}
            control={
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldData({
                      ...fieldData,
                      newUser: true,
                      useLimit: 1,
                      useAbility: "onetime",
                    });
                  } else {
                    setFieldData({ ...fieldData, newUser: false });
                  }
                }}
              />
            }
            label="First Time User"
          />
          <FormControlLabel
            checked={advanceField}
            control={
              <Checkbox onChange={(e) => setAdvanceField(e.target.checked)} />
            }
            label="Additional fields"
          />
        </div>
        <Row>
          {UpdateHotelCouponMgtData(soruceData, fieldData, advanceField)
            .slice(0, advanceField ? 34 : 19)
            .map((item, i) => {
              const type = item.type;
              return (
                <Fragment key={item.id}>
                  {["text", "number", "textarea"].includes(type) ? (
                    <>
                      <Col
                        xs={12}
                        md={6}
                        xl={checkFieldInclids(item.name)}
                        className="mb-3"
                        key={i}
                      >
                        <Inputs
                          type={item.type}
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => handleChange(e)}
                          required={item.required}
                          value={fieldData[item.name]}
                          disabled={
                            item.name === "maxDiscount" &&
                            fieldData.discountType === "F"
                              ? true
                              : item.disabled
                          }
                          classstyle={`form-control ${type === "textarea" &&
                            "pt-2"}`}
                        />
                      </Col>
                    </>
                  ) : type === "date" ? (
                    <>
                      <Col xs={12} md={6} xl={2} className="mb-3" key={i}>
                        <DateInput
                          type={item.type}
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => handleChange(e)}
                          required={item.required}
                          value={
                            item.defaultValue !== 0 &&
                            moment(item.defaultValue).format("YYYY-MM-DD")
                          }
                          disabled={item.disabled}
                          classstyle="form-control"
                        />
                      </Col>
                    </>
                  ) : type === "heading" && advanceField ? (
                    <Col xl={12}>
                      <div className=" text-center my-3 py-2  font-bold bg-success text-white">
                        {item.label}
                      </div>
                    </Col>
                  ) : (
                    <>
                      <Col xs={12} md={6} xl={2} className="mb-3" key={i}>
                        <Select
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => handleChange(e)}
                          firstName={""}
                          optionValue={item.optionValue}
                          required={item.required}
                          value={item.defaultValue}
                          disabled={item.disabled}
                          classstyle="form-control"
                        />
                      </Col>
                    </>
                  )}
                </Fragment>
              );
            })}
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2  btn-md "
              // onClick={dublicateHandleSubmit}
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default HotelUpdateCouponModal;
