import React, { useState } from "react";
import { useEffect } from "react";
import { GetBookingListData } from "../../../Apis/BookingList/GetBookingListData";
import Table from "../../../CustomComponent/Table";

const CheckDataModal = (props) => {
  const { data } = props;
  const [getBookingData, setGetBookingData] = useState({});
  const [getBookingLoading, setGetBookingLoading] = useState(true);

  useEffect(() => {
    GetBookingListData({
      state: setGetBookingData,
      loading: setGetBookingLoading,
      pnr: data,
    });
  }, []);

  return (
    <div>
      <div>
        <div className="table-responsive lightthm-wrap scroll-auto">
          {Object.keys(getBookingData).length !== 0 ? (
            <>
              {getBookingData.status == 1 ? (
                <div className="px-3 text-center py-5">
                  <div className="my-5">Data not available!!!</div>
                </div>
              ) : (
                <>
                  <table className="table table-lightthm text-left  table-striped">
                    <thead className="bg-light">
                      <tr>
                        <th width={100}>Pnr</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Currency</th>
                        <th>InsideOrderNo</th>
                        <th>Totalprice</th>
                        <th>AirlineRecodeNo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <Table
                        data={[
                          getBookingData.responseTKT.ResultObject.pnrcode,
                          getBookingData.responseTKT.ResultObject.Contact_Name.split(
                            "/"
                          )[1] +
                            " " +
                            getBookingData.responseTKT.ResultObject.Contact_Name.split(
                              "/"
                            )[0],
                          getBookingData.responseTKT.ResultObject.Contact_Email,
                          getBookingData.responseTKT.ResultObject
                            .Contact_Mobile,
                          getBookingData.responseTKT.ResultObject
                            .CustomerPayment_Currency,
                          getBookingData.responseTKT.ResultObject.InsideOrderNo,
                          getBookingData.responseTKT.ResultObject
                            .CustomerPayment_Totalprice,
                          getBookingData.responseTKT.ResultObject
                            .AirlineRecodeNo,
                        ]}
                      />
                    </tbody>
                  </table>

                  <h5 className="mb-2 mt-4 px-2 font-weight-bold">
                    TicketList
                  </h5>
                  <table className="table table-lightthm text-left  table-striped">
                    <thead className="bg-light">
                      <tr>
                        <th width={100}>Sr No.</th>
                        <th>Ticket No</th>
                        <th>Birthday</th>
                        <th>Passenger Name</th>
                        <th>ID Number</th>
                        <th>Nationality</th>
                        <th>ID Expired</th>
                        <th>Gender</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getBookingData.responseTKT.ResultObject.TicketList.map(
                        (item, i) => {
                          const date = new Date(item.Birthday);

                          return (
                            <Table
                              data={[
                                i + 1,
                                item.TicketNo,
                                item.Birthday,
                                item.Passengername,
                                item.IDnumber,
                                item.Nationality,
                                item.ID_expired,
                                item.Gender,
                              ]}
                            />
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  <h5 className="mb-2 mt-4 px-2 font-weight-bold">
                    FlightList
                  </h5>

                  <table className="table table-lightthm text-left  table-striped">
                    <thead className="bg-light">
                      <tr>
                        <th width={100}>Sr No.</th>
                        <th>Carrier Code</th>
                        <th>Option Number</th>
                        <th>Dep Airport</th>
                        <th>Arr Airport</th>
                        <th>TakeOffTime</th>
                        <th>IDExpired</th>
                        <th>Flight No</th>
                        <th>Class Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getBookingData.responseTKT.ResultObject.FlightList.map(
                        (item, i) => {
                          return (
                            <Table
                              data={[
                                item.Seq,
                                item.CarrierCode,
                                item.OptionNumber,
                                item.DepAirport,
                                item.ArrAirport,
                                item.TakeOffTime,
                                item.ID_expired,
                                item.FlightNo,
                                item.ClassCode,
                              ]}
                            />
                          );
                        }
                      )}
                    </tbody>
                  </table>

                  {!getBookingData.SupplierAmount ||
                  getBookingData.SupplierAmount == null ||
                  getBookingData.SupplierAmount == undefined ? null : (
                    <>
                      <div>SupplierAmount</div>
                      <table className="table table-lightthm text-left  table-striped">
                        <thead className="bg-light">
                          <tr>
                            <th>Order No</th>
                            <th>PnrCode</th>
                            <th>Payment Time</th>
                            <th>Pay Total Amount</th>
                            <th>Pay Currency</th>
                          </tr>
                        </thead>
                        <tbody>
                          <Table
                            data={[
                              getBookingData.SupplierAmount.orderNo,
                              getBookingData.SupplierAmount.pnrCode,
                              getBookingData.SupplierAmount.paymentTime,
                              getBookingData.SupplierAmount.paytotalamount,
                              getBookingData.SupplierAmount.paycurrency,
                            ]}
                          />
                        </tbody>
                      </table>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="px-3 text-center py-5">
              <div className="my-5">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckDataModal;
