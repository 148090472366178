import axios from "axios";
import { UpdateMarkUp_Url } from "../Urls";
import Swal from "sweetalert2";
export const UpdateMarkUp = async ({ payload, loadingState }) => {
  const options = {
    method: "PUT",
    url: UpdateMarkUp_Url,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": localStorage.getItem("token"),
    },
    data: payload,
  };
  loadingState(true);
  try {
    const { data } = await axios.request(options);
    Swal.fire({
      icon: data.httpStatus === "OK" ? "success" : "error",
      text: data.msg,
      showConfirmButton: false,
      timer: 1500,
    });
    return data;
  } catch (error) {
    loadingState(true);
    return "error";
  }
};
