export const header = (key, value) => {
  var myHeaders = new Headers();
  myHeaders.append("Access-Control-Request-Headers", "*");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("x-api-key", localStorage.getItem("token"));
  myHeaders.append(key, value);
  return myHeaders;
};
export const commonheader = () => {
  return {
    "Content-Type": "application/json",
    "x-api-key": localStorage.getItem("token"),
  };
};
