import React from "react";
import DashboardBar from "../Molecule/Dashboard/DashboardSideBar";
import DashboardImf from "../../src/Image/welcomedash.png"; 

const MainDashboard = () => {
  let name = localStorage.getItem('name') 
  return (
    <> 
      {/* <DashboardBar Pagetitle={`Dasboard`} /> */}
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
        <div className="container-fluid px-3 text-center">
          <div className="bg-white welcomebck overflow-hidden">
            <div className="row align-items-end m-500">
              <div className="col-md-5 col-12 px-3 align-self-center">
              <h2 className="fw-bold">Welcome Back, <span className="bgthem-clr">{name}</span></h2>  
              </div>
              <div className="col-md-7 col-12 px-3 text-center ">
                <img src={DashboardImf} width={600} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainDashboard;
