import axios from "axios";
import { travel_url } from "../Host";
export const GetTravoFareRule = async (page, defaultResult, payload) => {
  try {
    const options = {
      method: "POST",
      url: `${travel_url}/travomint/fareRule`,
      params: { page: page, size: defaultResult },
      headers: {
        "x-api-key": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: payload,
    };
    const getData = await axios.request(options);
    const data = await getData.data;
    return data;
  } catch (err) {
    return "error";
  }
};
