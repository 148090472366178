import React from "react";

const Infant_Fare = ({ modify }) => {
  const totalPessenger =
    modify.supplierFlight.adults +
    modify.supplierFlight.child ;
  return (
    <div className="table-responsive lightthm-wrap scroll-auto w-100">
      <table className="table table-lightthm text-left tbthm-2">
        <thead> 
          <tr>
            <th scope="row">Adult tax</th>
            <td className="text-right">{modify.supplierFlight.onWordFlight.fare.adultTax}</td>
          </tr>
          <tr>
            <th scope="row">Child tax</th>
            <td className="text-right"> {modify.supplierFlight.onWordFlight.fare.childTax}</td>
          </tr>
          <tr>
            <th scope="row">Infant tax</th>
            <td className="text-right"> {modify.supplierFlight.onWordFlight.fare.infantTax}</td>
          </tr>
          <tr>
            <th scope="row">Convenience Fees</th>
            <td className="text-right">
              {" "}
              {modify.supplierFlight.onWordFlight.fare.convenienceFees *
                totalPessenger}
            </td>
          </tr>
          <tr>
            <th scope="row">Markup Id</th>
            <td className="text-right"> {modify.supplierFlight.onWordFlight.fare.markupID}</td>
          </tr>
          <tr>
            <th scope="row">
              <div className="extra_space">Extra</div>
            </th>
            <td className="text-right"> </td>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default Infant_Fare;
