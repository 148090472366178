import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { commonheader } from "../../../utils/header";
import { TbDatabaseSearch } from "react-icons/tb";
import { travel_url } from "../../../Host";

const AllfilesDownloadModal = () => {
  const [loading, setLoading] = useState(true);
  const [filedata, setFileData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(`${travel_url}/feed/getCacheFile`, {
          headers: commonheader(),
        });
        setFileData(data);
      } catch (error) {}
      setLoading(false);
    })();
  }, []);
  if (loading) {
    return <div className=" text-center">Loading...</div>;
  }
  return (
    <div>
      {filedata.length === 0 && (
        <div className=" text-center text-muted mt-4">
          <TbDatabaseSearch size={40} />
          <p className="text text-bold mt-2">No Data Available!</p>
        </div>
      )}
      {filedata.length > 0 && (
        <Row>
          {filedata.map((item, i) => {
            return (
              <Col xl={3} lg={3}>
                <a
                  href={item}
                  target="_blank"
                  download
                  className="text-decoration-none"
                >
                  <div className="d-flex align-items-center bg-success text-white rounded px-3 py-2 mb-3 cursor-pointer">
                    <i class="fa-solid fa-file-arrow-down mr-2 fa-2x"></i>{" "}
                    <span className="text-sm">{item.split("/")[4]}</span>
                  </div>
                </a>
              </Col>
            );
          })}
        </Row>
      )}
      {}
    </div>
  );
};

export default AllfilesDownloadModal;
