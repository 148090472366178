import CryptoJS from "crypto-js";
export const decryptDataWithMode = (data, isKeyAvail, jsonType = true) => {
  var encryptedBase64Key = isKeyAvail;
  var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
  const decrypted = CryptoJS.AES.decrypt(data, parsedBase64Key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
  return jsonType ? JSON.parse(decrypted) : decrypted;
};
