import React from "react";
import Table from "../../../CustomComponent/Table";

const PassportModalBody = (props) => {
  const { pessengerPassportData } = props;
  return (
    <div>
      <div className="table-responsive lightthm-wrap scroll-auto w- mb-3">
            <table className="table table-lightthm text-left">
          <thead className="bg-light">
            <tr>
              <th width={100}>Sr No.</th>
              <th>Passenger Name</th>
              <th>Passport Number</th>
              <th>Passport Issued</th>
              <th>Passport Expiry</th>
              <th>Passport Issued Country</th>
            </tr>
          </thead>
          <tbody>
            {pessengerPassportData.map((item, i) => {
              return (
                <Table
                  data={[
                    i + 1,
                    item.firstName +
                      " " +
                      item.middleName +
                      " " +
                      item.lastName,
                    item.passportNumber,
                    item.passportIssued,
                    item.passportExpiry,
                    item.passportIssuedCountry,
                  ]}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PassportModalBody;
