import React from "react";

const DropTable = ({ tableHead, tableBody }) => {
  return (
    <>
      <td colSpan={12} className="bgsub-light border-0">
        <div className="table-responsive lightthm-wrap scroll-auto animtop">
        <table className="table table-lightthm text-left">
          <thead>
            <tr>
              {tableHead.map((item) => {
                return <th scope="col">{item}</th>;
              })}
            </tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </table>
        </div>  
      </td>
    </>
  );
};

export default DropTable;
