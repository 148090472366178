import React, { useEffect, useState } from "react";
import { travel_url } from "../../Host";
import Source from "../../CustomApi/Source.json";
import moment from "moment";
import Collapsible from "react-collapsible";

const BookingListFilter = ({ setFilterObj, getAllsource }) => {
  const [agentName, setAgentName] = useState([]);
  const [filterObjs, setFilterObjs] = useState({
    from: "",
    to: 0,
    pnr: "",
    bookingId: 0,
    orderId: "",
    source: "",
    agentName: "",
    bookingGds: 0,
    doneGds: 0,
    tripType: 0,
    paymentStatus: "",
  });

  const handlerChange = (e, input) => {
    e.preventDefault();
    if (input === "from") {
      const date = new Date(e.target.value);
      const ab = date.getTime();
      setFilterObjs({ ...filterObjs, [input]: ab });
    } else if (input === "to") {
      const date = new Date(e.target.value);
      const ab = date.getTime();
      setFilterObjs({ ...filterObjs, [input]: ab });
    } else if (input === "source") {
      setFilterObjs({ ...filterObjs, [input]: e.target.value.toLowerCase() });
    } else {
      setFilterObjs({ ...filterObjs, [input]: e.target.value });
    }
  };
  const bookingFormData = (e) => {
    e.preventDefault();
    setFilterObj({ ...filterObjs });
  };

  const userDetail = async () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const userNames_data = await fetch(
      `${travel_url}/userNames`,
      requestOptions
    );
    const userNames_list = await userNames_data.json();
    if (userNames_list) {
      const data = userNames_list;
      setAgentName(data);
    } else {
    }
    // .then((response) => response.json())
    // .then((result) => {
    //   const data = result;

    //   setAgentName(data);
    // })
  };
  useEffect(() => {
    userDetail();
  }, []);

  const date = new Date(filterObjs.from);
  const filter = (
    <span>
      <i className="fa-solid fa-filter"></i> Filter
    </span>
  );

  return (
    <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
      <div className="container-fluid px-3">
        <div></div>
        <Collapsible
          contentInnerClassName="collapsible-custom mt-3"
          triggerClassName="btn-submit btnver-2 lightgry d-inline-flex justify-content-center flex-column"
          triggerOpenedClassName="btn-submit btnver-2 lightgry d-inline-flex justify-content-center flex-column"
          trigger={filter}
        >
          <div className="filter-css">
            <form className="align-items-end row" novalidate>
              <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom01" className="form-label">
                    From Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="validationCustom01"
                    onChange={(e) => handlerChange(e, "from")}
                    required
                  />
                  <div className="valid-feedback">Looks good!</div>
                </div>
              </div>

              <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom02" className="form-label">
                    To Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="validationCustom02"
                    onChange={(e) => handlerChange(e, "to")}
                    required
                  />
                  <div className="valid-feedback">Looks good!</div>
                </div>
              </div>

              <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustomUsername" className="form-label">
                    PNR
                  </label>
                  <div className="input-group has-validation">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustomUsername"
                      aria-describedby="inputGroupPrepend"
                      value={filterObjs.pnr}
                      onChange={(e) => handlerChange(e, "pnr")}
                      required
                    />
                    <div className="invalid-feedback">
                      Please choose a username.
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom04" className="form-label">
                    Trip Type
                  </label>
                  <select
                    className="form-select"
                    id="validationCustom04"
                    required
                    onChange={(e) => handlerChange(e, "tripType")}
                  >
                    <option value={0}>Select Your Trip</option>
                    <option value={1}>One Way</option>
                    <option value={2}>Round Way</option>
                  </select>
                  <div className="invalid-feedback">
                    Please select a valid state.
                  </div>
                </div>
              </div>

              <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom03" className="form-label">
                    Booking ID
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="validationCustom03"
                    required
                    value={filterObjs.bookingId}
                    onChange={(e) => handlerChange(e, "bookingId")}
                  />
                  <div className="invalid-feedback">
                    Please provide a valid city.
                  </div>
                </div>
              </div>

              <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom03" className="form-label">
                    order ID
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="validationCustom03"
                    required
                    value={filterObjs.orderId}
                    onChange={(e) => handlerChange(e, "orderId")}
                  />
                  <div className="invalid-feedback">
                    Please provide a valid city.
                  </div>
                </div>
              </div>

              <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom04" className="form-label">
                    Source
                  </label>
                  <select
                    className="form-select"
                    id="validationCustom04"
                    required
                    onChange={(e) => handlerChange(e, "source")}
                  >
                    <option value="">Select Your Source</option>
                    {getAllsource.map((item) => {
                      return (
                        <>
                          <option value={item.value}>{item.name}</option>
                        </>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">
                    Please select a valid state.
                  </div>
                </div>
              </div>

              <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom04" className="form-label">
                    Agent Name
                  </label>
                  <select
                    className="form-select"
                    id="validationCustom04"
                    required
                    onChange={(e) => handlerChange(e, "agentName")}
                  >
                    <option value="">Select Your Agent</option>
                    {agentName.map((item) => {
                      return (
                        <>
                          <option value={item.id}>{item.name}</option>
                        </>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">
                    Please select a valid state.
                  </div>
                </div>
              </div>

              <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom05" className="form-label">
                    Booking GDS
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="validationCustom05"
                    onChange={(e) => handlerChange(e, "bookingGds")}
                    value={filterObjs.bookingGds}
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide a valid zip.
                  </div>
                </div>
              </div>

              <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom05" className="form-label">
                    Done GDS
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="validationCustom05"
                    value={filterObjs.doneGds}
                    onChange={(e) => handlerChange(e, "doneGds")}
                    required
                  />
                  <div className="invalid-feedback">
                    Please provide a valid zip.
                  </div>
                </div>
              </div>
              <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
                <div className="input-classicsite">
                  <label for="validationCustom04" className="form-label">
                    Payment Status
                  </label>
                  <select
                    className="form-select"
                    id="validationCustom04"
                    required
                    onChange={(e) => handlerChange(e, "paymentStatus")}
                  >
                    <option value="">Select</option>
                    <option value="PENDING">Pending</option>
                    <option value="SUCCESS">Success</option>
                  </select>
                </div>
              </div>

              <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
                {/* <input
                type="button"
                className="btn btn-submit btnver-2"
                value="Submit"
                onClick={(e) => bookingFormData(e)}
              /> */}
                <button
                  className="btn btn-submit btnver-2"
                  onClick={(e) => bookingFormData(e)}
                >
                  SUBMIT
                </button>
                <div className="invalid-feedback">
                  Please provide a valid zip.
                </div>
              </div>
              {/* <div className="col-12">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="invalidCheck"
                required
              />
              <label className="form-check-label" for="invalidCheck">
                Agree to terms and conditions
              </label>
              <div className="invalid-feedback">
                You must agree before submitting.
              </div>
            </div>
          </div> */}

              {/* <div className="mb-4 col-xxl-3 col-xl-4 col-md-6 col-12">
            <button
              className="btn btn-dark"
              type="submit"
              onClick={(e) => bookingFormData(e)}
            >
              Submit form
            </button>
          </div> */}
            </form>
          </div>
        </Collapsible>
      </div>
    </div>
  );
};

export default BookingListFilter;
/* {
"from":"",
"to":"",
"pnr":"",
"bookingId":"",
"orderId":"",
"source":"",
"agentName":"",
"bookingGds":"",
"doneGds":"",
"tripType"
} */
