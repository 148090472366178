import React from "react";
import axios from "axios";
import { travel_url } from "../../Host";
import { useState } from "react";
import swal from "sweetalert";

const useUpdateSupplierPreferredAirline = () => {
  const [upDatedata, setUpDatedata] = useState({});
  const [upDatedataLoading, setUpDatedataLoading] = useState(false);
  const UpdateSupplierPreferredAirlineData = async (body) => {
    setUpDatedataLoading(true);
    const options = {
      method: "PUT",
      url: `${travel_url}/updatePreferredAirline`,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("token"),
      },
      data: body,
    };

    try {
      const apiData = await axios.request(options);
      const UpdateData = await apiData.data;

      if (UpdateData.httpStatus === "OK") {
        setUpDatedata(UpdateData);
        swal("Update Successfully");
      } else {
        setUpDatedata(UpdateData);
        swal("Some Error", "Please try again after some time");
      }
    } catch (e) {
      setUpDatedata({
        msg: "Server Error",
        httpStatus: "not ok",
      });
    } finally {
      setUpDatedataLoading(false);
    }
    //   .then(function (response) {
    //     console.log(response.data);
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //   });
  };
  return [upDatedata, upDatedataLoading, UpdateSupplierPreferredAirlineData];
};

export default useUpdateSupplierPreferredAirline;
