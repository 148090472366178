export const Google_Flight_Booking_Filter = () => [
  {
    id: 1,
    name: "from",
    type: "datetime-local",
    placeholder: "Enter date",
    label: "From Date",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
  {
    id: 2,
    name: "to",
    type: "datetime-local",
    placeholder: "Enter date",
    label: "To Date",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
  {
    id: 3,
    name: "orderId",
    type: "number",
    placeholder: "Enter Order Id",
    label: "order ID",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
  {
    id: 4,
    name: "gds",
    type: "number",
    placeholder: "Enter gds",
    label: "Supplier",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
  {
    id: 5,
    name: "onwardAirline",
    type: "text",
    placeholder: "Enter Onward Airline",
    label: "Onward Airline",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
  {
    id: 6,
    name: "returnAirline",
    type: "text",
    placeholder: "Enter Return Airline",
    label: "Return Airline",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
];

export const Google_Flight_Booking_Filter_option = () => [
  {
    name: "tripType",
    label: "TripType",
    optionValue: [
      {
        id: 1,
        name: "Select Your Trip",
        value: 0,
      },
      {
        id: 2,
        name: "One Way",
        value: 1,
      },
      {
        id: 3,
        name: "Round Way",
        value: 2,
      },
    ],
  },
  {
    name: "bookingStatus",
    label: "Booking Status",
    optionValue: [
      {
        id: 1,
        name: "Select Your Booking Status",
        value: "",
      },
      {
        id: 2,
        name: "Success",
        value: "success",
      },
      {
        id: 3,
        name: "Failure",
        value: "failure",
      },
      {
        id: 3,
        name: "Pending",
        value: "pending",
      },
    ],
  },
  {
    name: "paymentStatus",
    label: "Payment Status",
    optionValue: [
      {
        id: 1,
        name: "Select Payment Status",
        value: "",
      },
      {
        id: 2,
        name: "Success",
        value: "success",
      },
      {
        id: 3,
        name: "Failure",
        value: "failure",
      },
      {
        id: 3,
        name: "Pending",
        value: "pending",
      },
    ],
  },
  // {
  //   name: "source",
  //   label: "Source",
  //   optionValue: [
  //     {
  //       id: 1,
  //       name: "Select Payment Status",
  //       value: "",
  //     },
  //     {
  //       id: 2,
  //       name: "Jetradar India",
  //       value: "jtrdr_in",
  //     },
  //     {
  //       id: 3,
  //       name: "Momondo India",
  //       value: "momondoInd",
  //     },
  //     {
  //       id: 4,
  //       name: "Skyscanner",
  //       value: "skyscanner",
  //     },
  //     {
  //       id: 5,
  //       name: "Online",
  //       value: "online",
  //     },
  //   ],
  // },
];
