import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../CustomComponent/Button";
import Inputs from "../../../CustomComponent/Inputs";
import Select from "../../../CustomComponent/Select";
import useAddSupplierPreferredAirline from "../../../hooks/SupplierPrefferedAirline/useAddSupplierPreferredAirline";
import { AddSupplierPreferredAirlineInputBox } from "../../../SmallApi/SupplierPreferredAirlineApi.js/AddSupplierPreferredAirline";
import ResultNotFound from "../../MarkUpDeatils/ErrorScreen/ResultNotFound";
import { useFetch } from "../../../hooks/useFetch";
import { header } from "../../../utils/header";

const AddSupplierPreferredAirline = (props) => {
  const {
    setRefreshloading,
    refreshLoading,
    setShowAddModal,
    supplierList,
    metaList,
  } = props;
  const [getAddData, loading, setGetData] = useAddSupplierPreferredAirline();

  const [addInputData, setAddInputData] = useState({
    airline: "",
    supplier: "",
    source: "",
    enabled: false,
  });
  const onSubmit = async (e) => {
    e.preventDefault();
    const body = {
      airline: addInputData.airline.split(","),
      supplier: addInputData.supplier,
      enabled: addInputData.enabled,
      source: addInputData.source,
    };

    await setGetData(body);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setAddInputData({ ...addInputData, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(getAddData).length > 0) {
      if (getAddData.httpStatus === "OK") {
        setRefreshloading(!refreshLoading);
        setShowAddModal(false);
      }
    }
  }, [getAddData]);

  if (getAddData.msg === "Server Error From Save Preferred Airline") {
    return <ResultNotFound title="Server Error From Save Preferred Airline" />;
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="text-center text-danger">
          {loading ? "Loading..." : getAddData.msg}
        </div>
        <Row>
          {AddSupplierPreferredAirlineInputBox(supplierList, metaList).map(
            (item, i) => {
              const type = item.type;
              return (
                <Fragment key={item.id}>
                  {["text", "date"].includes(type) ? (
                    <>
                      <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                        <Inputs
                          type={item.type}
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => onChange(e)}
                          required={item.required}
                          defaultValue={item.defaultValue}
                          disabled={item.disabled}
                          classstyle="form-control"
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                        <Select
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => onChange(e)}
                          optionValue={item.optionValue}
                          required={item.required}
                          defaultValue={item.defaultValue}
                          disabled={item.disabled}
                          classstyle="form-control"
                        />
                      </Col>
                    </>
                  )}
                </Fragment>
              );
            }
          )}
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2  btn-md "
              // onClick={dublicateHandleSubmit}
            />
          </div>
        </Row>
      </form>
    </>
  );
};

export default AddSupplierPreferredAirline;
