import React, { useEffect, useState } from "react";
import Loading from "../../../CustomComponent/Loading";
import loader from "../../../Image/93134-not-found.json";
import Lottie from "react-lottie";
import Table from "../../../CustomComponent/Table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import {
  deleteMetaMapSupplierAPi,
  getMetaMapSupplierAPi,
} from "../../../Services/Api/metaMappingApi";
import Modals from "../../../CustomComponent/Modals";
import AddMetaMapSuppModal from "./Modal/AddMetaMapSuppModal";
const MetaSuppMap = ({ allSource, allSupplier }) => {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [metaMapData, setMetaMapData] = useState([]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const fetchData = async () => {
    try {
      const { data } = await getMetaMapSupplierAPi();
      data.data ? setMetaMapData(data.data) : setMetaMapData([]);
    } catch (error) {}
    setLoading(false);
  };
  // ----------------------------------------------Delete API CALL------------------------------------------

  const handleDeleteApi = async (id) => {
    try {
      const { data } = await deleteMetaMapSupplierAPi(id);
      data.httpStatus === "OK" && fetchData();
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="w-100">
      <div className="container-fluid px-3">
        <div className="metaBox position-relative d-block border-0 smtb">
          {loading && <Loading title="loading..." />}
          {metaMapData.length <= 0 ? (
            <>
              <Lottie options={defaultOptions} height={400} width={400} />
              <h6> Click on add button to add Meta Mapping Supplier</h6>
            </>
          ) : (
            <>
              <div className="w-100">
                <div className="table-responsive lightthm-wrap  border-top-0">
                  <table className="table table-lightthm text-left">
                    <thead className="bg-light">
                      <tr>
                        <th width={20}>S.no.</th>
                        <th>Source</th>
                        <th>Supplier</th>
                        <th>Mapping</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {metaMapData.map((item, i) => (
                        <Table
                          i={i}
                          data={[
                            i + 1,
                            item.source,
                            item.supplier,
                            item.mapping,
                            <div className="btni-sp">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft-danger"
                                  onClick={() => handleDeleteApi(item._id)}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </OverlayTrigger>
                            </div>,
                          ]}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          <div className="text-right px-3 py-4">
            <button
              className="btn-submit btnver-2 success"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Add <i className="fa-solid fa-plus icon-add"></i>
            </button>
          </div>
        </div>
      </div>
      <Modals
        show={showModal}
        HandleClose={() => setShowModal(false)}
        ModalBody={
          <AddMetaMapSuppModal
            allSource={allSource}
            fetchData={fetchData}
            setShowModal={setShowModal}
            allSupplier={allSupplier}
          />
        }
        title={"Add Meta Mapping Supplier"}
      />
    </div>
  );
};

export default MetaSuppMap;
