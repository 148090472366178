import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Dropdown,
  Form,
  FormSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import { CKEditor } from "ckeditor4-react";
import moment from "moment";
const EditPaymentGatwayModal = ({
  modalShowEdit,
  handleEditViewClose,
  switcher,
  gateway,
  seletedGateway,
  editGatewayData,
  setEditGatewayData,
  EditGatewayDetails,
  UpdateGatewayData,
}) => {
  const [gatewayChargeValue, setGatewayChargeValue] = useState();

  // useEffect(() => setEditGatewayData(Gateway), [])
  return (
    <div>
      <Modal
        show={modalShowEdit}
        onHide={handleEditViewClose}
        className="ModalEdit"
      >
        <ModalHeader closeButton>Gateway Configuration</ModalHeader>
        <ModalBody>
          {editGatewayData.map((gateway, i) => (
            <Form>
              <div className="form-split">
                <div>
                  <Form.Group className="mb-3" style={{ width: "41rem" }}>
                    <h5>
                      <Form.Label>Gateway ID</Form.Label>{" "}
                      <Badge bg="primary">{gateway.gid}</Badge>
                    </h5>
                    <h5>
                      <Form.Label>Modified On</Form.Label>{" "}
                      <Badge bg="primary">
                        {moment(gateway.modifiedDate).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </Badge>
                    </h5>
                    <h5>
                      <Form.Label>Modified By</Form.Label>{" "}
                      <Badge bg="primary">{gateway.modifiedBy}</Badge>
                    </h5>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Enter Gateway name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="text"
                      value={gateway.gatewayName}
                      name="gatewayName"
                      id="gatewayName"
                      onChange={(e) => EditGatewayDetails(e, i)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Gateway Charge Type</Form.Label>

                    <Form.Select
                      value={gateway.chargeType}
                      name="chargeType"
                      id="chargeType"
                      aria-label="Default select example"
                      onChange={(e) => EditGatewayDetails(e, i)}
                    >
                      <option value={"PERCENT"}>Percentage</option>
                      <option value={"flat"}>Flat</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Gateway Charge {gatewayChargeValue}</Form.Label>
                    <Form.Control
                      type="text"
                      name="chargePercentage"
                      id="chargePercentage"
                      placeholder="text"
                      value={gateway.chargePercentage}
                      onChange={(e) => EditGatewayDetails(e, i)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>JS Logic</Form.Label>
                    <Form.Control
                      type="text"
                      name="jsLogic"
                      id="jsLogic"
                      placeholder="text"
                      value={gateway.jsLogic}
                      onChange={(e) => EditGatewayDetails(e, i)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>SALT</Form.Label>
                    <Form.Control
                      type="text"
                      name="salt"
                      id="salt"
                      placeholder="text"
                      value={gateway.salt}
                      className=" form-input"
                      onChange={(e) => EditGatewayDetails(e, i)}
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="mb-3">
                    <Form.Label>Public Key</Form.Label>
                    <Form.Control
                      type="text"
                      name="publicKey"
                      id="publicKey"
                      placeholder="text"
                      value={gateway.publicKey}
                      onChange={(e) => EditGatewayDetails(e, i)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" style={{ width: "41rem" }}>
                    <Form.Label>Secret</Form.Label>
                    <Form.Control
                      type="text"
                      name="secret"
                      id="secret"
                      placeholder="text"
                      value={gateway.secret}
                      onChange={(e) => EditGatewayDetails(e, i)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Webhook</Form.Label>
                    <Form.Control
                      type="text"
                      name="webhook"
                      id="webhook"
                      placeholder="text"
                      value={gateway.webhook}
                      onChange={(e) => EditGatewayDetails(e, i)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Error CallBack</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="text"
                      value={gateway.errorCallBack}
                      onChange={(e) => EditGatewayDetails(e, i)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Success Callback</Form.Label>
                    <Form.Control
                      type="text"
                      name="successCallback"
                      id="successCallback"
                      placeholder="text"
                      value={gateway.successCallback}
                      onChange={(e) => EditGatewayDetails(e, i)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>privateKey</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="text"
                      name="privateKey"
                      id="privateKey"
                      value={gateway.privateKey}
                      onChange={(e) => EditGatewayDetails(e, i)}
                    />
                  </Form.Group>
                </div>
              </div>
              <Form.Group className="mb-3">
                <CKEditor initData={gateway.htmlUIJSP} />
              </Form.Group>
              <Button
                className="btn btn-primary"
                variant="primary"
                onClick={() => UpdateGatewayData(gateway)}
              >
                Submit
              </Button>
            </Form>
          ))}
        </ModalBody>
        <ModalFooter>My Modal Footer</ModalFooter>
      </Modal>
    </div>
  );
};

export default EditPaymentGatwayModal;
