import axios from "axios";
import { getAllConfig } from "./getAllConfig";
import { saveConfig } from "./Urls";

export const saveApihitStats = ({
  supplierApihit,
  sourceApihit,
  hitLimitValue,
  setGetAllConfigData
}) => {


  var data = JSON.stringify({
    supplier: supplierApihit,
    hitLimit: Number(hitLimitValue),
    source: sourceApihit,
    preferredAirline: [],
    excludeAirlines: [],
  });

  var config = {
    method: "post",
    url: saveConfig,
    headers: {
      "x-api-key": localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    data: data,
  };



  axios(config)
    .then(function (response) {
     
      getAllConfig({ setGetAllConfigData })
    })
    .catch(function (error) {
 
    });
};
