import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import Inputs from "../../../../CustomComponent/Inputs";
import Select from "../../../../CustomComponent/Select";
import Button from "../../../../CustomComponent/Button";
import { addUdateGatewayApi } from "../../../../Services/Api/paymentGateway";

const AddContentVisibleData = ({
  allSource,
  fetchData,
  setShowModal,
  isEditdata,
  allGatewayList,
}) => {
  const [load, setLoad] = useState(false);
  const [fieldData, setFieldData] = useState(
    isEditdata
      ? isEditdata
      : {
          gatewayName: "",
          siteId: null,
          sourceMedia: "",
          gatewayStatus: true,
          currency: "",
          chargeType: "FLAT",
          chargeValue: 0,
          fieldString: "",
          orderSequence: 1,
          product: "ALL",
        }
  );
  const {
    gatewayName,
    siteId,
    sourceMedia,
    gatewayStatus,
    currency,
    chargeType,
    chargeValue,
    fieldString,
    orderSequence,
    product,
  } = fieldData;
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "currency") {
      value = value.toUpperCase();
    }
    setFieldData({ ...fieldData, [name]: value.trimStart() });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    if (+orderSequence > 0) {
      try {
        const { data } = await addUdateGatewayApi(fieldData);
        if (data.httpStatus === "OK") {
          fetchData();
          setShowModal(false);
        }
        Swal.fire({
          icon: data.httpStatus === "OK" ? "success" : "error",
          text: data.msg,
          showConfirmButton: false,
          timer: 2000,
        });
      } catch (error) {}
    } else {
      alert("Order Sequence should be greater than 0");
    }
    setLoad(false);
  };
  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="gatewayName"
              placeholder=""
              label="All Payment Gateway"
              onChange={handleChange}
              optionValue={allGatewayList}
              required={true}
              classstyle="form-control"
              firstName={"Select"}
              value={gatewayName}
              disabled={isEditdata ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="sourceMedia"
              placeholder=""
              label="Source"
              onChange={handleChange}
              optionValue={allSource.data}
              firstName={"Select"}
              required={true}
              classstyle="form-control"
              value={sourceMedia}
              disabled={isEditdata ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="fieldString"
              placeholder="Enter the field description"
              label="Field label"
              onChange={handleChange}
              required={true}
              classstyle="form-control"
              value={fieldString}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="number"
              name="siteId"
              placeholder="Enter the Site ID"
              label="Site ID"
              onChange={handleChange}
              required={true}
              classstyle="form-control mt-4"
              value={siteId}
              disabled={isEditdata ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="product"
              placeholder=""
              label="Product"
              onChange={handleChange}
              optionValue={allProducts}
              required={true}
              classstyle="form-control mt-4"
              value={product}
              disabled={isEditdata ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="chargeType"
              placeholder=""
              label="Charge Type"
              onChange={handleChange}
              optionValue={[
                { id: 1, name: "Flat", value: "FLAT" },
                { id: 2, name: "Percentage", value: "PERCENTAGE" },
              ]}
              required={true}
              classstyle="form-control mt-4"
              value={chargeType}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="number"
              name="chargeValue"
              placeholder="Enter the gateway charge amount"
              label="Charge Value"
              onChange={handleChange}
              required={true}
              classstyle="form-control mt-4"
              value={chargeValue}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="currency"
              placeholder="Enter the currency code"
              label="Currency"
              onChange={handleChange}
              required={true}
              classstyle="form-control mt-4"
              value={currency}
              disabled={isEditdata ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="number"
              name="orderSequence"
              placeholder="Enter the Order Sequence"
              label="Order Sequence"
              onChange={handleChange}
              required={true}
              classstyle="form-control mt-4"
              value={orderSequence}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="gatewayStatus"
              placeholder=""
              label="Status"
              onChange={handleChange}
              optionValue={[
                { id: 1, name: "Active", value: true },
                { id: 2, name: "Inactive", value: false },
              ]}
              required={true}
              classstyle="form-control mt-4"
              value={gatewayStatus}
            />
          </Col>
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title={load ? "Please Wait..." : "Submit"}
              disabled={load}
              color="btn btn-submit btnver-2  btn-md "
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddContentVisibleData;
export const allProducts = [
  { id: 1, name: "All", value: "ALL" },
  { id: 2, name: "Flights", value: "FLIGHT" },
  { id: 3, name: "Hotels", value: "HOTEL" },
  { id: 4, name: "Transfers", value: "TRANSFER" },
  { id: 5, name: "Car Rental", value: "CAR_RENTAL" },
];
