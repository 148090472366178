import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "../../../CustomComponent/Select";
import Button from "../../../CustomComponent/Button";
import Inputs from "../../../CustomComponent/Inputs";
import { commonheader } from "../../../utils/header";
import axios from "axios";
import { travel_url } from "../../../Host";
import swal from "sweetalert";

const AddProtalDataModal = ({ setModalopen, fetchData, handleSubmit }) => {
  const [formdata, setFormData] = useState({
    site: null,
    primary: "email",
    enabled: true,
  });
  const { site, primary, enabled } = formdata;
  const onChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formdata, [name]: value });
  };
  console.log(formdata);

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(formdata);
        }}
      >
        <Row>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="number"
              name="site"
              placeholder={"Enter the Site Id"}
              label={"Site Id"}
              onChange={(e) => onChange(e)}
              required={true}
              classstyle="form-control"
              value={site}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              type="text"
              name="primary"
              label={"Primary"}
              onChange={(e) => onChange(e)}
              required={true}
              optionValue={[
                { name: "Email", value: "email", id: 1 },
                { name: "Phone", value: "phone", id: 2 },
              ]}
              disabled={false}
              value={primary}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              type="text"
              name="enabled"
              value={enabled}
              label={"Status"}
              onChange={(e) => onChange(e)}
              required={true}
              optionValue={[
                { name: "Active", value: true, id: 1 },
                { name: "Inactive", value: false, id: 2 },
              ]}
              disabled={false}
              classstyle="form-control"
            />
          </Col>
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2  btn-md "
              // onClick={dublicateHandleSubmit}
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddProtalDataModal;
