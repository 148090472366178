import axios from "axios";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Inputs from "../../../../CustomComponent/Inputs";
import Button from "../../../../CustomComponent/Button";
import Select from "../../../../CustomComponent/Select";
import swal from "sweetalert";
import { travel_url } from "../../../../Host";
import { commonheader } from "../../../../utils/header";

const AddUpdateBlockAir = ({
  getSourceData,
  isedit,
  setModalOpen,
  fetchData,
  getSuppliersData,
  airportdata,
}) => {
  const [inputdata, setInputData] = useState({
    siteId: null,
    org: "",
    dst: "",
    source: "",
    api: "",
  });
  const { siteId, org, dst, source, api } = inputdata;
  const onChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputdata, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.request({
        method: isedit ? "put" : "post",
        url: `${travel_url}/block/airport/${isedit ? "update" : "add"}`,
        data: inputdata,
        headers: commonheader(),
      });
      swal("", data.msg, data.data ? "success" : "error");
      fetchData();
      setInputData({
        siteId: null,
        org: "",
        dst: "",
        source: "",
        api: "",
      });
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      swal("Uncaught Error", "Please try again after some time", "error");
    }
  };
  useEffect(() => {
    if (isedit) {
      const newRes = airportdata.find((ele) => ele._id === isedit);
      setInputData(newRes);
    } else {
      setInputData({
        siteId: null,
        org: "",
        dst: "",
        source: "",
        api: "",
      });
    }
  }, [isedit]);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="org"
              placeholder={"Enter the Origin name"}
              label={"Origin"}
              onChange={(e) => onChange(e)}
              required={true}
              defaultValue={org}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="dst"
              placeholder={"Enter the Destination "}
              label={"Destination"}
              onChange={(e) => onChange(e)}
              required={true}
              defaultValue={dst}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="number"
              name="siteId"
              placeholder={"Enter the Site ID"}
              label={"Site ID"}
              onChange={(e) => onChange(e)}
              required={true}
              defaultValue={siteId}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="mt-2">
            <Select
              label="Source"
              firstName="Source"
              name="source"
              optionValue={getSourceData}
              onChange={(e) => onChange(e)}
              classstyle="form-control"
              required={true}
              value={source}
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="mt-2">
            <Select
              label="Supplier"
              firstName="Supplier"
              name="api"
              optionValue={getSuppliersData}
              onChange={(e) => onChange(e)}
              classstyle="form-control"
              required={true}
              value={api}
            />
          </Col>
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2  btn-md "
              // onClick={dublicateHandleSubmit}
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddUpdateBlockAir;
