import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AddcachePriceFeed } from "../../../Apis/CachePriceFeedApi/AddcachePriceFeed";
import Button from "../../../CustomComponent/Button";
import Inputs from "../../../CustomComponent/Inputs";
import Select from "../../../CustomComponent/Select";
import {
  DublicateCachePriceFeedInput,
  DublicateCachePriceFeedInputOneWay,
  DublicateCachePriceFeedSelect,
} from "../../../SmallApi/CachePriceFeedSmallApi/DublicateCachePriceFeedInputs";
import { DateFormat } from "../../../utils/DateFormat";
import InboundItinerary from "./InboundItinerary";
import OutboundItinerary from "./OutboundItinerary";

const DublicateCachePriceModalBody = (props) => {
  const { data, index } = props.data;
  const { setDublicate_showModal, setDublicateCacheFeedLoading } = props;
  const navigate = useNavigate();
  const [state, setState] = useState(data);
  const [dateFormat, setDateFormat] = useState("");
  const [stateoutboundItinerary, setStateoutboundItinerary] = useState(
    data.outboundItinerary
  );
  const [statereturnItinerary, setStatereturnItinerary] = useState(
    data.returnItinerary
  );

  const abc =
    data.tripType == "RT"
      ? DublicateCachePriceFeedInput
      : data.tripType == ""
      ? DublicateCachePriceFeedInput
      : DublicateCachePriceFeedInputOneWay;

  const OutChangeFunction = (e, i) => {
    const { name, value } = e.target;
    const list = [...stateoutboundItinerary];
    list[i][name] = value;
    setStateoutboundItinerary(list);
  };
  const InChangeFunction = (e, i) => {
    const { name, value } = e.target;
    const list = [...statereturnItinerary];
    list[i][name] = value;
    setStatereturnItinerary(list);
  };
  const changeFunction = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const dublicateHandleSubmit = () => {
    AddcachePriceFeed({
      data: state,
      loading: setDublicateCacheFeedLoading,
      navigate,
      modalClose: setDublicate_showModal,
    });
  };

  useEffect(() => {
    const date = DateFormat();
    setDateFormat(date);
  }, []);

  return (
    <div>
      <Row>
        {DublicateCachePriceFeedSelect(data).map((item, i) => {
          return (
            <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
              <Select
                name={item.name}
                placeholder={item.placeholder}
                label={item.label}
                onChange={(e) => changeFunction(e)}
                optionValue={item.optionValue}
                required={item.required}
                defaultValue={item.defaultValue}
                disabled={item.disabled}
                classstyle="form-control"
              />
            </Col>
          );
        })}

        {abc(data).map((item, i) => {
          return (
            <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
              <Inputs
                name={item.name}
                placeholder={item.placeholder}
                type={item.type}
                label={item.label}
                min={dateFormat}
                onChange={(e) => changeFunction(e)}
                required={item.required}
                defaultValue={item.defaultValue}
                disabled={item.disabled}
                classstyle="form-control"
              />
            </Col>
          );
        })}

        {data.tripType !== "" && (
          <>
            {(data.tripType == "OW" || data.tripType == "RT") && (
              <>
                <h4 className="fw-bold text-capitalize mt-5 mb-2 border-top pt-3">
                  OutBound Itinerary
                </h4>
                {data.outboundItinerary.map((item, index) => {
                  return (
                    <>
                      {index !== 0 && (
                        <hr className="my-3 border-lighttin"></hr>
                      )}
                      <OutboundItinerary
                        key={index}
                        changeFunction={OutChangeFunction}
                        i={index}
                        value={item}
                        minDate={dateFormat}
                      />
                    </>
                  );
                })}
              </>
            )}
          </>
        )}
        {data.tripType !== "" && (
          <>
            {data.tripType == "RT" && (
              <>
                <h4 className="fw-bold text-capitalize mt-5 mb-2 border-top pt-3">
                  Return Itinerary
                </h4>
                {data.returnItinerary.map((item, index) => {
                  return (
                    <>
                      {index !== 0 && (
                        <hr className="my-3 border-lighttin"></hr>
                      )}
                      <InboundItinerary
                        key={index}
                        changeFunction={InChangeFunction}
                        i={index}
                        value={item}
                        minDate={dateFormat}
                      />
                    </>
                  );
                })}
              </>
            )}
          </>
        )}

        <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
          <Button
            type="submit"
            title="Submit"
            color="btn btn-submit btnver-2  btn-md "
            onClick={dublicateHandleSubmit}
          />
        </div>
      </Row>
    </div>
  );
};

export default DublicateCachePriceModalBody;
