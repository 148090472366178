import React from "react";
import Table from "../../CustomComponent/Table";

const CacheManagementTable = ({ getData, onEdit, onDelete }) => {
  return (
    <>
      <table className="table table-lightthm text-left">
        <thead className="bg-light">
          <tr>
            <th scope="col" width={30}>
              Sr No.
            </th>
            <th scope="col">Origin</th>
            <th scope="col">Destination</th>
            <th scope="col">Range</th>
            <th scope="col">Session</th>
            <th scope="col">Modified By</th>
            <th scope="col">Status</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {getData.data.length &&
            getData.data.map(
              (
                { _id, origin, destination, range, session, enabled, user },
                i
              ) => {
                const switchIcon = (
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      value={enabled}
                      checked={enabled}
                    />
                  </div>
                );
                const EditIcons = (
                  <div class="btni-sp">
                    <button
                      className={`btn btn-sm btn-soft-success ${
                        origin === "ALL" && destination === "ALL"
                          ? "disabled"
                          : ""
                      }`}
                      onClick={() =>
                        origin === "ALL" && destination === "ALL"
                          ? true
                          : onEdit({
                              _id,
                              origin,
                              destination,
                              range,
                              session,
                              enabled,
                              user,
                            })
                      }
                    >
                      <div>
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </div>
                    </button>
                  </div>
                );
                const DeleteIcons = (
                  <div class="btni-sp">
                    <button
                      className={`btn btn-sm btn-soft-success ${
                        origin === "ALL" && destination === "ALL"
                          ? "disabled"
                          : ""
                      }`}
                      onClick={() =>
                        origin === "ALL" && destination === "ALL"
                          ? true
                          : onDelete({
                              _id,
                              origin,
                              destination,
                              range,
                              session,
                              enabled,
                              user,
                            })
                      }
                    >
                      <div>
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </div>
                    </button>
                  </div>
                );
                return (
                  <React.Fragment key={_id}>
                    <Table
                      onClick={() => true}
                      classStyle=""
                      data={[
                        i + 1,
                        origin,
                        destination,
                        range,
                        session,
                        user.name,
                        switchIcon,
                        EditIcons,
                        DeleteIcons,
                      ]}
                    />
                  </React.Fragment>
                );
              }
            )}
        </tbody>
      </table>
    </>
  );
};

export default CacheManagementTable;
