import axios from "axios";
import { AddCachePriceFeed_URL } from "../Urls";

export const AddcachePriceFeed = ({ data, loading, navigate, modalClose }) => {
  loading(true);
  const { _id, ...rest } = data;
  const abc = rest;

  const options = {
    method: "POST",
    url: AddCachePriceFeed_URL,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": localStorage.getItem("token"),
    },
    data: abc,
  };

  axios
    .request(options)
    .then(function (response) {
      alert(response.data.msg);
      if (response.data.httpStatus == "OK") {
        modalClose(false);
        loading(false);
      }
    })
    .catch(function (error) {
      console.error(error);
      if (error.response.status == 401) {
        navigate("/login");
        localStorage.clear();
      }
    });
};
