import React from "react";
import Button from "../../../CustomComponent/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Inputs from "../../../CustomComponent/Inputs";
import {
  AddCachePriceFeedInput,
  AddCachePriceFeedInputOneWay,
  AddCachePriceFeedSelect,
} from "../../../SmallApi/CachePriceFeedSmallApi/AddCachePriceFeedInput";
import Select from "../../../CustomComponent/Select";
import OutboundItinerary from "./OutboundItinerary";
import InboundItinerary from "./InboundItinerary";
const AddCachePriceFeedModalBody = (props) => {
  const {
    body,
    setBody,
    inbody,
    setInBody,
    mainBody,
    setMainBody,
    AddHandleSubmit,
  } = props;
  const OutHandleAddClick = () => {
    setBody([
      ...body,
      {
        origin: "",
        destination: "",
        marketingAirline: "",
        marketingFlightNumber: "",
        operationAirline: "",
        operatingFlightNumber: "",
        depDate: "",
        depTime: "",
        arriTime: "",
      },
    ]);
  };
  const InHandleAddClick = () => {
    setInBody([
      ...inbody,
      {
        origin: "",
        destination: "",
        marketingAirline: "",
        marketingFlightNumber: "",
        operationAirline: "",
        operatingFlightNumber: "",
        depDate: "",
        depTime: "",
        arriTime: "",
      },
    ]);
  };

  const changeFunction = (e) => {
    const { name, value } = e.target;
    setMainBody({ ...mainBody, [name]: value });
  };

  const OutChangeFunction = (e, i) => {
    const { name, value } = e.target;
    const list = [...body];
    list[i][name] = value;
    setBody(list);
  };
  const InChangeFunction = (e, i) => {
    const { name, value } = e.target;
    const list = [...inbody];
    list[i][name] = value;
    setInBody(list);
  };

  const abc =
    mainBody.tripType == "RT"
      ? AddCachePriceFeedInput
      : mainBody.tripType == ""
      ? AddCachePriceFeedInput
      : AddCachePriceFeedInputOneWay;

  return (
    <Row>
      {AddCachePriceFeedSelect.map((item, i) => {
        return (
          <Col xs={12} md={6} xl={4} className="mb-4" key={i}>
            <Select
              name={item.name}
              placeholder={item.placeholder}
              label={item.label}
              onChange={changeFunction}
              optionValue={item.optionValue}
              required={item.required}
              defaultValue={item.defaultValue}
              disabled={item.disabled}
              classstyle="form-control"
            />
          </Col>
        );
      })}
      {abc.map((item, i) => {
        return (
          <Col xs={12} md={6} xl={4} className="mb-4" key={i}>
            <Inputs
              name={item.name}
              placeholder={item.placeholder}
              type={item.type}
              label={item.label}
              onChange={changeFunction}
              required={item.required}
              defaultValue={item.defaultValue}
              disabled={item.disabled}
              classstyle="form-control"
            />
          </Col>
        );
      })}

      {mainBody.tripType !== "" && (
        <>
          {(mainBody.tripType == "OW" || mainBody.tripType == "RT") && (
            <>
              <h5 className="fw-bold text-capitalize mt-3 mb-4 border-top pt-4 itinhead">
                outboundItinerary
              </h5>
              {body.map((item, index) => {
                return (
                  <>
                    {index !== 0 && <hr className="my-3 border-lighttin"></hr>}
                    <OutboundItinerary
                      key={index}
                      changeFunction={OutChangeFunction}
                      i={index}
                      value={item}
                    />
                  </>
                );
              })}
              <div className="col-12 text-right mb-4">
                <Button
                  type="submit"
                  title={
                    <>
                      <i className="fa-solid fa-plus icon-add"></i> Add
                    </>
                  }
                  color="btn btn-submit btnver-2 success h-44"
                  onClick={OutHandleAddClick}
                />
              </div>
            </>
          )}
        </>
      )}
      {mainBody.tripType !== "" && (
        <>
          {mainBody.tripType == "RT" && (
            <>
              <h5 className="fw-bold text-capitalize mt-3 mb-4 border-top pt-4 itinhead">
                Return Itinerary
              </h5>
              {inbody.map((item, index) => {
                return (
                  <>
                    {index !== 0 && <hr className="my-3 border-lighttin"></hr>}
                    <InboundItinerary
                      key={index}
                      changeFunction={InChangeFunction}
                      i={index}
                      value={item}
                    />
                  </>
                );
              })}
              <div className="col-12 text-right mb-4">
                <Button
                  type="submit"
                  title={
                    <>
                      <i className="fa-solid fa-plus icon-add"></i> Add
                    </>
                  }
                  color="btn btn-submit btnver-2 success h-44"
                  onClick={InHandleAddClick}
                />
              </div>
            </>
          )}
        </>
      )}

      <div className="col-12 text-right border-top mt-1 py-4">
        <Button
          type="submit"
          title="Submit"
          color="btn btn-submit btnver-2  btn-md "
          onClick={AddHandleSubmit}
        />
      </div>
    </Row>
  );
};

export default AddCachePriceFeedModalBody;
