import React from "react";
import Table from "../../CustomComponent/Table";

const SupplierPrefferedAirlineTable = (props) => {
  const { getData, onEdit, onDelete } = props;

  return (
    <>
      <table className="table table-lightthm text-left">
        <thead className="bg-light">
          <tr>
            <th scope="col" width={30}>
              Sr No.
            </th>
            <th scope="col">Source</th>
            <th scope="col">Supplier</th>
            <th scope="col">Airline</th>
            <th scope="col">Enable</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {getData.data.length &&
            getData.data.map(
              ({ _id, airline, supplier, enabled, source }, i) => {
                const airlineList = airline.map((item) => (
                  <span className="badge bg-primary">{item}</span>
                ));

                const list = (
                  <div className="maxw-airlbx d-flex flex-wrap">
                    {airlineList}
                  </div>
                );
                const switchIcon = (
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      value={enabled}
                      checked={enabled}
                    />
                  </div>
                );
                const EditIcons = (
                  <div class="btni-sp">
                    <button
                      className={`btn btn-sm btn-soft-success`}
                      onClick={() =>
                        onEdit({
                          _id,
                          airline,
                          supplier,
                          enabled,
                        })
                      }
                    >
                      <div>
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </div>
                    </button>
                  </div>
                );
                const DeleteIcons = (
                  <div class="btni-sp">
                    <button
                      className={`btn btn-sm btn-soft-success`}
                      onClick={() =>
                        onDelete({
                          _id,
                        })
                      }
                    >
                      <div>
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </div>
                    </button>
                  </div>
                );

                return (
                  <React.Fragment key={_id}>
                    <Table
                      onClick={() => true}
                      classStyle=""
                      data={[
                        i + 1,
                        source,
                        supplier?supplier:"N/A",
                        list,
                        switchIcon,
                        EditIcons,
                        DeleteIcons,
                      ]}
                    />
                  </React.Fragment>
                );
              }
            )}
        </tbody>
      </table>
    </>
  );
};

export default SupplierPrefferedAirlineTable;
