import axios from "axios";
import { travel_url } from "../../../Host";
import { commonheader } from "../../../utils/header";

const IconHandlerInstance = axios.create({
  baseURL: travel_url,
});
IconHandlerInstance.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      config.headers = commonheader();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const addIconApi = (data) =>
  IconHandlerInstance.post(`/icons/save`, data);
export const deleteIconApi = (id) =>
  IconHandlerInstance.delete(`/icons/delete/${id}`);
export const getAllSvgIconApi = async () => {
  try {
    const { data } = await IconHandlerInstance.get(`/icons/findAll`);
    return data;
  } catch (error) {
    return "error";
  }
};
