import React from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import Inputs from "../../../CustomComponent/Inputs";
import Select from "../../../CustomComponent/Select";
import Button from "../../../CustomComponent/Button";

const SearchHotelCouponData = ({
  isSearch,
  setFilterData,
  filterData,
  onFilter,
}) => {
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };
  return (
    <Collapse in={isSearch}>
      <div>
        <Row>
          <Col xs={12} md={6} xl={4} className="mb-3">
            <Inputs
              name="couponCode"
              label="Coupon Code"
              type="text"
              placeholder="Enter the voucher code"
              classstyle="form-control"
              onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="mb-3">
            {" "}
            <Select
              name="couponType"
              label="Coupon Type"
              classstyle="form-control"
              onChange={(e) => handleChange(e)}
              optionValue={[
                { id: 0, name: "Select", value: "" },
                { id: 1, name: "Promotional", value: "PROMOTIONAL" },
                { id: 2, name: "Other", value: "OTHER" },
              ]}
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="mb-3">
            <Inputs
              name="creationFrom"
              label="Genration from date"
              type="date"
              classstyle="form-control"
              onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="mb-3">
            <Inputs
              name="creationTo"
              label="Genration to date"
              type="date"
              onChange={(e) => handleChange(e)}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="mt-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2  btn-md "
              onClick={onFilter}
            />
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default SearchHotelCouponData;
