import React, { useState, useEffect } from "react";
import axios from "axios";
import { travel_url } from "../../Host";

const useGetSupplier = () => {
  const [getSupplier, setGetSupplier] = useState({});
  const [getSupplierLoading, setGetSupplierLoading] = useState(true);

  const fetchData = async () => {
    const options = {
      method: "GET",
      url: `${travel_url}/getAPI`,
      headers: {
        "x-api-key": localStorage.getItem("token"),
      },
    };

    try {
      const apiData = await axios.request(options);
      const getSupplierData = await apiData.data;
      setGetSupplier(getSupplierData);
    } catch (e) {
      setGetSupplier({
        msg: "Server Error From Supplier",
        httpStatus: "not ok",
      });
    } finally {
      setGetSupplierLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    getSupplier,
    getSupplierLoading,
  };
};

export default useGetSupplier;
