import React, { Fragment } from "react";
import Button from "../../../CustomComponent/Button";
import axios from "axios";
import { travel_url } from "../../../Host";
import { commonheader } from "../../../utils/header";
import swal from "sweetalert";

const EditFareRuleTable = ({
  alleditdata,
  setShowEditModal,
  setAllEditdata,
}) => {
  function handleChange(e, ind) {
    let newRes = [...alleditdata];
    const { name, value } = e.target;
    newRes[ind][name] = value;
    setAllEditdata(newRes);
  }
  console.log("first", alleditdata);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `${travel_url}/travomint/edit/fareRules`,
        alleditdata,
        { headers: commonheader() }
      );

      if (data.httpStatusCodes === 0) {
        swal("", data.msg, "success");
        setAllEditdata([]);
      }
    } catch (err) {
      console.log(err);
    }
    setShowEditModal(false);
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="table-responsive lightthm-wrap scroll-auto">
          <table className="table table-lightthm text-left">
            <thead className="bg-light">
              <tr>
                <th scope="col">Rule Id</th>
                <th scope="col">Rule Name</th>
                <th scope="col">Origin</th>
                <th scope="col">Destination</th>
                <th scope="col">Fare Type Key Value</th>
                <th scope="col">Additional Discount Key Value</th>
                <th scope="col">Convenience Fee</th>
              </tr>
            </thead>
            <tbody>
              {alleditdata.map((item, ind) => {
                return (
                  <tr className="edit_table" key={ind}>
                    <td>
                      <input
                        type="text"
                        required={true}
                        onChange={(e) => handleChange(e, ind)}
                        name="ruleId"
                        defaultValue={item.ruleId}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        required={true}
                        onChange={(e) => handleChange(e, ind)}
                        name="ruleName"
                        defaultValue={item.ruleName}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        required={true}
                        onChange={(e) => handleChange(e, ind)}
                        name="origin"
                        defaultValue={item.origin}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        required={true}
                        onChange={(e) => handleChange(e, ind)}
                        name="destination"
                        defaultValue={item.destination}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        required={true}
                        onChange={(e) => handleChange(e, ind)}
                        name="fareType"
                        defaultValue={item.fareType}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        required={true}
                        onChange={(e) => handleChange(e, ind)}
                        name="additionalDisAmount"
                        defaultValue={item.additionalDisAmount}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        required={true}
                        onChange={(e) => handleChange(e, ind)}
                        name="convenienceFee"
                        defaultValue={item.convenienceFee}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
          <Button
            type="submit"
            title="Submit"
            color="btn btn-submit btnver-2  btn-md"
          />
        </div>
      </form>
    </div>
  );
};

export default EditFareRuleTable;
