import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { travel_url } from "../../Host";
import Lottie from "react-lottie";
import loader from "../../Image/93134-not-found.json";
import { getApihitStats } from "../../Apis/getApihitStats";
import HitApiModal from "../../Molecule/Apitracking/HitApiModal";
import { saveApihitStats } from "../../Apis/saveApihitStats";
import { getAllConfig } from "../../Apis/getAllConfig";
import Table from "../../CustomComponent/Table";
import Select from "../../CustomComponent/Select";
import SupplierSource from "../../SmallApi/SupplierSource.json";
import SupplierApi from "../../SmallApi/SupplierApi.json";
import Loading from "../../CustomComponent/Loading";
import { UpdateMeta } from "../../Apis/Meta/UpdateMeta";
import { UpdateManageAPI } from "../../Apis/Urls";
import { getAllPreferAirline } from "../../Apis/getAllPreferAirline";
import FareIdentifier from "./FareIdentifier";
import PreferedAirline from "../../Molecule/Apitracking/PreferedAirline";
const ApitrackingDev = ({ activetab }) => {
  // ______________________________________STates ______________________________________

  const [metaData, setMetaData] = useState([]);
  const [metaForUpdate, setMetaForUpdate] = useState("");
  const [isloading, setIsLoading] = useState(true);
  const [isOn, setOn] = useState("");
  const [show, setShow] = useState(false);
  const [sourcesModal, setSourcesModal] = useState(false);
  const [APIvalue, setAPIvalue] = useState("");
  const [APIModal, setAPIModal] = useState(false);
  const [source, setSource] = useState("Select Meta");
  const [Value, setValue] = useState("");
  const [sourceNameValue, setSourceNameValue] = useState("");
  const [state, setState] = useState("");
  const [saveticketHandlerData, setSaveticketHandlerData] = useState([]);
  const [updatedMetaState, setUpdatedMetaState] = useState([]);
  const [ApiData, setApiData] = useState("");
  const [ApiName, setApiName] = useState("");
  const [allApi, setAllApi] = useState("");
  const [allSource, setAllSource] = useState([]);
  const [sources, setSources] = useState({});
  const [sourceStatus, setSourcestatus] = useState(false);
  const [suppliers, setSuppliers] = useState({});
  const [SuppliersStatus, setSuppliersStatus] = useState(false);
  const [hitStatus, setHitStatus] = useState([]);
  const [preferairline, setPreferAirline] = useState([]);
  const [apiHitShow, setApiHitShow] = useState(false);
  const [sourceApihit, setSourceApihit] = useState("");
  const [supplierApihit, setSupplierApihit] = useState("");
  const [hitLimitValue, setHitLimitValue] = useState();
  const [getAllConfigData, setGetAllConfigData] = useState([]);
  const [error, setError] = useState("None");
  const [allsourceModalButton, setAllsourceModalButton] = useState(true);
  const [supplierAPIButton, setsupplierAPIButton] = useState(true);

  // Google Flight Supplier Handle
  // Filter
  const [getSourceValue, setGetSourceValue] = useState("");
  const [getSupplierValue, setGetSupplierValue] = useState("");
  // Switch loading
  const [ticketingStatus, setTicketingStatus] = useState(false);
  // ______________________________________STates ______________________________________

  // _______________________________function of all the component_______________________________

  // _______________________________MODAL ALL_______________________________

  const HandleShow = () => {
    setShow(true);
  };
  const HandleClose = () => {
    setApiData("");
    setApiName("");
    setShow(false);
  };

  const OpenSources = () => {
    setSourcesModal(true);
  };

  const closeSources = () => {
    setSourcesModal(false);
  };

  const OpenAPIModal = () => {
    setAPIModal(true);
  };

  const closeAPIModal = () => {
    setAPIModal(false);
  };
  // _______________________________MODAL ALL_______________________________

  // _______________________________target value_______________________________

  const handleSwitch = (e, item) => {
    if (item.status === true) {
      const body = {
        source: item.source,
        api: item.api,
        status: false,
      };
      setState(false);
      UpdateMeta({
        loading: setTicketingStatus,
        state: setUpdatedMetaState,
        url: UpdateManageAPI,
        method: "PUT",
        body: body,
      });
    } else if (item.status === false) {
      const body = {
        source: item.source,
        api: item.api,
        status: true,
      };
      setState(true);
      UpdateMeta({
        loading: setTicketingStatus,
        state: setUpdatedMetaState,
        url: UpdateManageAPI,
        method: "PUT",
        body: body,
      });
    }

    // UpdateMeta(item);
  };

  const Source = (e) => {
    setSource(e.target.value);
  };

  const handleChangeValue = (e) => {
    setValue(e.target.value);
  };

  const SourceName = (e) => {
    setSourceNameValue(e);
    if (e != "") {
      setAllsourceModalButton(false);
    } else {
      setAllsourceModalButton(true);
    }
  };

  const getapitextValue = (e) => {
    setAPIvalue(e);
    if (e != "") {
      setsupplierAPIButton(false);
    } else {
      setsupplierAPIButton(true);
    }
  };

  // _______________________________target value_______________________________

  /* --------------------------------------------------------ApiCalls--------------------------------------------------------------------------- */
  /*------------------------------------------------------------------ GET META */
  const GetAllMeta = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getAllManageAPI`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setMetaData(result.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setError(
          "There is Some Technical Error, Please contact with Technical Team"
        );
        setIsLoading(false);
      });
  };
  /* -----------------------------------Get Source------------------------------ */
  const GetAllSource = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getSource`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllSource(result);
      })
      .catch((error) => {});
  };
  /* -----------------------------------Get Source------------------------------ */
  /* -----------------------------------Get API------------------------------ */
  const GetAllAPI = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getAPI`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setAllApi(result);
        }
      })
      .catch((error) => {});
  };
  /* -----------------------------------Get API------------------------------ */

  /*------------------------------------------------------------------ GET Sources */
  const GetAllSources = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getSource`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setSources(result);
          setSourcestatus(true);
        }
      })
      .catch((error) => {
        setError(
          "There is Some Technical Error, Please contact with Technical Team"
        );
        setIsLoading(false);
      });
  };
  /*------------------------------------------------------------------ GET Sources */

  /*------------------------------------------------------------------ GET GetSupplierAPI */
  const GetSupplierAPI = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getAPI`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setSuppliers(result);
          setSuppliersStatus(true);
        }
      })
      .catch((error) => {});
  };
  /*------------------------------------------------------------------ GET GetSupplierAPI */

  const saveSourceName = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: sourceNameValue,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/saveSource`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.httpStatus === "OK") {
          setSourcesModal(false);
          GetAllSources();
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const saveAPIValue = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: APIvalue,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/saveAPI`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.httpStatus === "OK") {
          setAPIModal(false);
          GetSupplierAPI();
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => {
        console.log("error->", error);
      });
  };
  /*------------------------------------------------------------------ GET META */

  /*------------------------------------------------------------------ SAVE META */
  const saveMeta = () => {
    if (
      ApiData == null ||
      ApiData == "" ||
      ApiName == null ||
      ApiName == "" ||
      Value == null ||
      Value == ""
    ) {
      alert("All fields are required");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      source: ApiName,
      api: ApiData,
      status: Value,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/saveManageAPI`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.httpStatus === "OK") {
          GetAllMeta();
          HandleClose();
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => {});
  };

  /*------------------------------------------------------------------ SAVE META */

  /*---------------------------------------------------------------- Update META */
  // const UpdateMeta = async (item) => {
  //   setTicketingStatus(true);
  //   var myHeaders = new Headers();
  //   myHeaders.append("x-api-key", localStorage.getItem("token"));
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     source: item.source,
  //     api: item.api,
  //     status: state,
  //   });

  //   var requestOptions = {
  //     method: "PUT",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   const dataapi = await fetch(`${travel_url}/updateManageAPI`, requestOptions);

  //   const updatedata = await dataapi.json();
  //   if (updatedata) {
  //     setUpdatedMetaState(updatedata);
  //     if (updatedata) {
  //       alert(updatedata.msg);
  //     }
  //   } else {
  //     alert(updatedata.msg);
  //   }
  //   setTicketingStatus(false);
  // };

  const DeleteMeta = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,

      redirect: "follow",
    };

    const dataapi = await fetch(
      `${travel_url}/deleteManageAPI?id=${id}`,
      requestOptions
    );

    const deletedata = await dataapi.json();

    alert(deletedata.msg);
    GetAllMeta();
  };

  /*---------------------------------------------------------------- Update META */

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleApihit = () => {
    GetAllSources();
    setApiHitShow(true);
    // setApiHitShow(false);
  };

  const handlecloseApihit = () => {
    setApiHitShow(false);
  };

  const saveHitApiData = () => {
    // getAllConfig({ setGetAllConfigData });

    saveApihitStats({
      supplierApihit,
      sourceApihit,
      hitLimitValue,
      setGetAllConfigData,
    });
    setApiHitShow(false);
    getAllConfig({ setGetAllConfigData });
    // window.location.reload(false);
  };

  // ___________________________________DELETE SOURCE___________________________________

  const DeleteSource = (e) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/deleteSource?id=${e}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        GetAllSources();
      })
      .catch((error) => {});
  };
  // ___________________________________DELETE SOURCE___________________________________

  // ___________________________________DELETE SUPPLIER API___________________________________

  const DeleteSupplier = (e) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/deleteApi?id=${e}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        GetSupplierAPI();
      })
      .catch((error) => {});
  };
  // ___________________________________DELETE SUPPLIER API___________________________________

  // ___________________________________DELETE config ___________________________________

  const DeleteConfig = (e) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/deleteSupplierConfig?id=${e}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        getAllConfig({ setGetAllConfigData });
      })
      .catch((error) => {});
  };
  // ___________________________________DELETE SUPPLIER API___________________________________

  // _______________________________function of all the component_______________________________

  useEffect(() => {
    GetAllMeta();
    GetAllAPI();
    GetAllSource();
    GetAllSources();
    GetSupplierAPI();
    getApihitStats({ setHitStatus });
    getAllPreferAirline({ setPreferAirline });
    getAllConfig({ setGetAllConfigData });
    // GetAllSources();
  }, [updatedMetaState]);
  /* --------------------------------------------------------ApiCalls--------------------------------------------------------------------------- */
  const getSource = (e) => {
    setGetSourceValue(e.target.value);
  };
  const GetSupplier = (e) => {
    setGetSupplierValue(e.target.value);
  };

  var GFSHandlerData = metaData;

  if (getSourceValue && !getSupplierValue) {
    if (metaData) {
      GFSHandlerData = metaData.filter((item) => item.source == getSourceValue);
    }
  } else if (!getSourceValue && getSupplierValue) {
    if (metaData) {
      GFSHandlerData = metaData.filter((item) => item.api == getSupplierValue);
    }
  } else if (getSourceValue && getSupplierValue) {
    if (metaData) {
      GFSHandlerData = metaData.filter(
        (item) => item.source == getSourceValue && item.api == getSupplierValue
      );
    }
  }

  if (isloading) {
    return (
      <div className="w-100 metaDashboard pr-0-adms pb-0 pb-0 pt-0">
        <div className="container-fluid px-3">
          <Loading title="loading..." />
        </div>
      </div>
    );
  } else {
    const getSourceData = Object.keys(sources).length !== 0 ? sources.data : [];
    const getSuppliersData =
      Object.keys(suppliers).length !== 0 ? suppliers.data : [];

    return (
      <>
        {error === "None" ? (
          <>
            <div className="w-100 pr-0-adms pt-0">
              <div className="container-fluid px-3">
                {ticketingStatus && <Loading title="loading..." />}
                <div className="row">
                  {/* ____________________________________metaData____________________________________ */}

                  {activetab === 2 &&
                    (metaData != null && metaData.length != 0 ? (
                      <>
                        <div className=" col-xxl-12 col-12 mb-4 d-table  ">
                          <div className="metaBox position-relative d-block border-0 smtb">
                            <h6 className="title-lightthm p-3 m-0">
                              Supplier Handler
                            </h6>
                            <div className="w-100 metaData">
                              {metaData === null ? (
                                <>
                                  <Lottie
                                    options={defaultOptions}
                                    height={280}
                                    width={300}
                                  />
                                  <h6 className="title-lightthm p-3 m-0">
                                    {" "}
                                    Click on add button to add API
                                  </h6>
                                </>
                              ) : (
                                <>
                                  <div className="w-100">
                                    <div className="table-responsive lightthm-wrap border-top-0">
                                      <table className="table table-lightthm text-left">
                                        <thead className="bg-light">
                                          <tr>
                                            <th width={20}>S no.</th>
                                            <th>
                                              <Select
                                                label=""
                                                firstName="Select Source"
                                                optionValue={getSourceData}
                                                onChange={getSource}
                                                classstyle="form-control"
                                              />
                                            </th>
                                            <th>
                                              <Select
                                                label=""
                                                firstName="Select Supplier"
                                                optionValue={getSuppliersData}
                                                onChange={GetSupplier}
                                                classstyle="form-control"
                                              />
                                            </th>
                                            <th>Site ID</th>
                                            <th>Status</th>
                                            <th>Count</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {GFSHandlerData.map((item, i) => {
                                            return (
                                              <>
                                                <Table
                                                  i={i}
                                                  data={[
                                                    i + 1,
                                                    item.source,
                                                    item.api,
                                                    "N/A",
                                                    <div className="form-check form-switch">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexSwitchCheckDefault"
                                                        value={state}
                                                        onChange={(e) =>
                                                          handleSwitch(e, item)
                                                        }
                                                        checked={item.status}
                                                      />
                                                    </div>,
                                                    item.count,
                                                  ]}
                                                />
                                              </>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            {/* <div className="addBtn">
                          <button
                            className="btn-submit btnver-2"
                            onClick={() => HandleShow()}
                          >
                            Add API +
                          </button>
                        </div> */}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className=" col-xxl-12 col-12 mb-4 d-table  ">
                          <div className="metaBox position-relative d-block border-0 smtb">
                            <h6 className="title-lightthm p-3 m-0">
                              Google Flight Supplier Handle
                            </h6>
                            <Lottie
                              options={defaultOptions}
                              height={280}
                              width={300}
                            />
                            <h6 className="title-lightthm p-3 m-0">
                              {" "}
                              Click on add button to add API
                            </h6>
                            <div className="container-fluid px-3">
                              {/* <div className="row addBtn">
                            <button
                              className="btn-submit btnver-2"
                              onClick={() => HandleShow()}
                            >
                              Add API +
                            </button>
                          </div> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                  {/* ____________________________________metaData____________________________________ */}

                  {/* ____________________________________config____________________________________ */}

                  {activetab === 3 &&
                    (getAllConfigData != null &&
                    getAllConfigData.length != 0 ? (
                      <>
                        <div className=" col-xxl-12 col-12 mb-4 d-table  ">
                          <div className="metaBox position-relative d-block border-0 smtb">
                            <h6 className="title-lightthm p-3 m-0">
                              Supplier Config Hit Limit
                            </h6>
                            <div className="w-100 metaData">
                              <div className="w-100">
                                <div className="table-responsive lightthm-wrap border-top-0">
                                  <table className="table table-lightthm text-left">
                                    <thead className="bg-light">
                                      <tr>
                                        <th width={20}>S no.</th>
                                        <th>supplier</th>
                                        <th>hitLimit</th>
                                        <th>source</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {getAllConfigData.map((item, i) => {
                                        return (
                                          <>
                                            <Table
                                              i={i}
                                              data={[
                                                i + 1,
                                                item.supplier,
                                                item.hitLimit,
                                                item.source,
                                              ]}
                                            />
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            {/* <div className="addBtn">
                          <button
                            className="btn-submit btnver-2"
                            onClick={() => handleApihit()}
                          >
                            Add Config
                          </button>
                        </div> */}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className=" col-xl-5 col-12 mb-4  ">
                          <div className="metaBox position-relative d-block border-0 smtb">
                            <h6 className="title-lightthm p-3 m-0">
                              Supplier Config Hit Limit
                            </h6>
                            <Lottie
                              options={defaultOptions}
                              height={280}
                              width={300}
                            />
                            <h6 className="title-lightthm p-3 m-0">
                              {" "}
                              Click on add button to add API
                            </h6>
                            {/* <div className="container-fluid px-3">
                          <div className=" addBtn">
                            <button
                              className="btn-submit btnver-2"
                              onClick={() => handleApihit()}
                            >
                              Add Config +
                            </button>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </>
                    ))}

                  {/* ____________________________________config____________________________________ */}

                  {/* ____________________________________hitStatus____________________________________ */}

                  {activetab === 4 &&
                    (hitStatus != null && hitStatus.length != 0 ? (
                      <>
                        <div className="col-12 mb-4  ">
                          <div className="metaBox position-relative d-block border-0 smtb">
                            <h6 className="title-lightthm p-3 m-0">
                              Supplier Search history
                            </h6>
                            <div className="w-100 metaData">
                              <div className="w-100">
                                <div className="table-responsive lightthm-wrap border-top-0">
                                  <table className="table table-lightthm text-left">
                                    <thead className="bg-light">
                                      <tr>
                                        <th width={20}>S no.</th>
                                        <th>successHit</th>
                                        <th>errorHit</th>
                                        <th>localHit</th>
                                        <th>supplier</th>
                                        <th>sourceMedia</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {hitStatus.map((item, i) => {
                                        return (
                                          <>
                                            <Table
                                              data={[
                                                i + 1,
                                                item.successHit,
                                                item.errorHit,
                                                item.localHit,
                                                item.supplier,
                                                item.sourceMedia,
                                              ]}
                                            />
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className=" col-xxl-12 col-12 mb-4 d-table  ">
                          <h6 className="title-lightthm p-3 m-0">
                            Supplier Search history
                          </h6>
                        </div>
                      </>
                    ))}
                  {/* ____________________________________Airline Booking Preference____________________________________ */}
                  {activetab === 7 && (
                    <PreferedAirline
                      data={preferairline}
                      setTicketingStatus={setTicketingStatus}
                      setUpdatedMetaState={setUpdatedMetaState}
                      getSuppliersData={getSuppliersData}
                    />
                  )}
                  {activetab === 6 && <FareIdentifier />}
                </div>
              </div>

              {/* ------------------------------------google flight supplier handle ------------------------------------ */}
              <Modal
                size="xl"
                className="modalbooknow-classic"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={HandleClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="modal-Title">Add Meta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <div className="row mb-3">
                      <div className="col-sm-10 d-flex justify-content-around">
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">
                            Source
                          </span>
                          <select
                            className="form-select"
                            value={ApiName}
                            aria-label="Default select example"
                            onChange={(e) => setApiName(e.target.value)}
                          >
                            <option selected>select source</option>

                            {sources == null ||
                            sources === undefined ||
                            sources.data === undefined ||
                            sources.data == null ? (
                              <h3>No Record Found</h3>
                            ) : (
                              <>
                                <h4>
                                  {sources.data.map((items, i) => (
                                    <>
                                      <p>{i}</p>
                                    </>
                                  ))}
                                </h4>
                                {sources.data.map((item) => (
                                  <option value={item.name}>{item.name}</option>
                                ))}
                              </>
                            )}
                          </select>
                        </div>
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">
                            Api
                          </span>
                          <select
                            className="form-select"
                            value={ApiData}
                            aria-label="Default select example"
                            onChange={(e) => setApiData(e.target.value)}
                          >
                            <option selected>select API</option>

                            {suppliers == null ||
                            suppliers === undefined ||
                            suppliers.data === undefined ||
                            suppliers.data == null ? (
                              <h3>No Record Found</h3>
                            ) : (
                              <>
                                {suppliers.data.map((item) => (
                                  <option value={item.name}>{item.name}</option>
                                ))}
                              </>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    <fieldset className="row mb-3">
                      <legend className="col-form-label col-sm-2 pt-0">
                        Status
                      </legend>
                      <div className="col-sm-10">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            id="gridRadios1"
                            value="true"
                            onClick={(e) => handleChangeValue(e)}
                          />
                          <label className="form-check-label" for="gridRadios1">
                            Active
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            id="gridRadios2"
                            value="false"
                            onClick={(e) => handleChangeValue(e)}
                          />
                          <label className="form-check-label" for="gridRadios2">
                            Inactive
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </Modal.Body>
                <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
                  <div className="btn-metaSubmit">
                    <button
                      type="submit"
                      className="btn btn-dark"
                      onClick={() => saveMeta()}
                    >
                      submit
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
              {/* ------------------------------------google flight supplier handle ------------------------------------ */}

              {/* ------------------------------------Supplier config hit limit ------------------------------------ */}
              <HitApiModal
                handlecloseApihit={handlecloseApihit}
                apiHitShow={apiHitShow}
                allSource={allSource}
                ApiName={ApiName}
                allApi={allApi}
                sources={sources}
                suppliers={suppliers}
                setSourceApihit={setSourceApihit}
                setSupplierApihit={setSupplierApihit}
                supplierApihit={supplierApihit}
                sourceApihit={sourceApihit}
                setHitLimitValue={setHitLimitValue}
                hitLimitValue={hitLimitValue}
                onClick={() => saveHitApiData()}
              />
              {/* ------------------------------------Supplier config hit limit ------------------------------------ */}

              {/* ------------------------------------ALL sources ------------------------------------ */}
              <Modal
                size="xl"
                className="modalbooknow-classic"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={sourcesModal}
                onHide={closeSources}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="modal-Title">Add Sources</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <label>Enter the Source Name </label>
                  &nbsp; &nbsp;
                  <input
                    type={`text`}
                    required
                    onChange={(e) => SourceName(e.target.value)}
                  />
                </Modal.Body>
                <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
                  <div className="btn-metaSubmit">
                    <button
                      className="btn btn-success"
                      disabled={allsourceModalButton}
                      onClick={() => saveSourceName()}
                    >
                      Save
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
              {/* ------------------------------------ALL sources ------------------------------------ */}

              {/* ------------------------------------SAVE API ------------------------------------ */}
              <Modal
                size="xl"
                className="modalbooknow-classic"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={APIModal}
                onHide={closeAPIModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="modal-Title">Add API</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <label>Enter the API Name </label>
                  &nbsp; &nbsp;
                  <input
                    type={`text`}
                    required
                    onChange={(e) => getapitextValue(e.target.value)}
                  />
                </Modal.Body>
                <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
                  <div className="btn-metaSubmit">
                    <button
                      className="btn btn-success"
                      disabled={supplierAPIButton}
                      onClick={() => saveAPIValue()}
                    >
                      Save
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
              {/* ------------------------------------SAVE API ------------------------------------ */}
            </div>
          </>
        ) : (
          <>
            <div className="container ">
              <h4 className="error-class">{error}</h4>
            </div>
          </>
        )}
      </>
    );
  }
};

export default ApitrackingDev;
