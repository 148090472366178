import React, { useEffect, useState } from "react";
import { Badge, Button, Container, Form, Modal, Table } from "react-bootstrap";
import moment from "moment";
import TransactionModal from "../Molecule/switcher/TransactionModal";
import EditPaymentGatwayModal from "../Molecule/switcher/EditPaymentGatwayModal";
import SideBar from "../Atom/SideBar/SideBar";
import axios from "axios";
import UserBar from "../Molecule/User/UserBar";
const PaymentSwitcher = () => {
  const [switcher, setSwitcher] = useState([]);
  const [gateway, setGateway] = useState([]);
  const [seletedGateway, setSeletedGatewayID] = useState({});
  const [editSeletedGateway, setEditSeletedGateway] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [switcherLoad, setSwitcherLoad] = useState(false);
  const [gatewayLoad, setGatewayLoad] = useState(false);
  const [state, setState] = useState("");
  const [gaID, setGaId] = useState(0);
  const [editGatewayData, setEditGatewayData] = useState([]);

  const handleView = (e, data) => {
    setModalShow(true);
    setGaId(e.target.value);
  };
  const handleClose = () => setModalShow(false);
  const handleSwitch = (e, item) => {
    switcher
      .filter((item) => item.gaId == e.target.id)
      .map(
        (stat) => (
          setState(stat.gatewayStatus),
          stat.gatewayStatus === "true" ? setState("false") : setState("true")
        )
      );
    UpdateGatewayStatus(item);
  };

  const handleEditView = (e, data) => {
    setModalShowEdit(true);
    setSeletedGatewayID(e.target.value);
    const Gateway = gateway.filter((item) => item.gid == e.target.value);
    setEditGatewayData(Gateway);
  };
  const EditGatewayDetails = (e, index) => {
    const { name, value } = e.target;
    const list = [...editGatewayData];
    list[index][name] = value;
    setEditGatewayData(list);
  };
  const handleEditViewClose = () => setModalShowEdit(false);

  function getGatewayData() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    // fetch("http://stest1.hunterwave.com/gateway/showAllGateways?authcode=Trav3103s987876", requestOptions)
    fetch(
      "http://stest2.hunterwave.com/gateway/showAllGateways?authcode=Trav3103s987876",
      // "http://stest1.hunterwave.com/gateway/showAllGateways?authcode=Trav3103s987876",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setGatewayLoad(true);
        setGateway(result.response);
      })
      .catch((error) => { });
  }

  function UpdateGatewayData(mergeObj) {
    const options = {
      method: "POST",
      url: "http://stest2.hunterwave.com/gateway/updateGateway",
      params: { authcode: "Trav3103s987876" },
      headers: {
        "Content-Type": "application/json",
      },
      data: mergeObj,
    };

    axios
      .request(options)
      .then(function (response) { })
      .catch(function (error) {
        console.error(error);
      });
  }
  function UpdateGatewayStatus(item) {
    const stateChangeObj = { ...item, gatewayStatus: state };
    const options = {
      method: "POST",
      url: "http://stest2.hunterwave.com/gateway/updateRunningGateways",
      params: { authcode: "Trav3103s987876" },
      headers: {
        "Content-Type": "application/json",
      },
      data: stateChangeObj,
    };

    axios
      .request(options)
      .then(function (response) {
        if (response.data) {
          getSwitcherData();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  function getSwitcherData() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    // fetch("http://stest1.hunterwave.com/gateway/showAllRunGateways?authcode=Trav3103s987876", requestOptions)
    fetch(
      "http://stest2.hunterwave.com/gateway/showAllRunGateways?authcode=Trav3103s987876",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setSwitcherLoad(true);
        setSwitcher(result.response);
      })
      .catch((error) => { });
  }

  // ----------------------AddGATEWAY---------------------------

  useEffect(() => {
    getSwitcherData();
    getGatewayData();
  }, [switcherLoad, gatewayLoad]);

  return (
    <>

      {/* <SideBar /> */}
      {/* <UserBar Pagetitle={`Payment switcher`} /> */}

      <>
        {/*-------------------------- SHOW ALL Gateway----------------------- */}
        {switcherLoad ? (
          <div>
            <div className="container ShowAllGateway">
              <h2>All Running Gatways Config</h2>

              <table striped bordered hover>
                <tr>
                  <th>Gateway#</th>
                  <th>Country</th>
                  <th>Currency</th>
                  <th>GatewayName</th>
                  <th>IsActive</th>
                  <th>GDS</th>
                  <th>SourceMedia</th>
                  <th>Is3DES </th>
                  <th>ModifiedOn </th>
                  <th>ModifiedBy </th>
                  <th>WebsiteId </th>
                  <th colSpan={2}> View/Edit </th>
                </tr>

                {switcher.map((items, i) => (
                  <tr>
                    <td>{items.gaId}</td>
                    <td>{items.country}</td>
                    <td>{items.currency}</td>
                    <td>
                      {gateway
                        .filter((item) => item.gid == items.gatewayId)
                        .map((item, i) => item.gatewayName)}
                    </td>
                    <td>
                      <Form.Check
                        type="switch"
                        id={items.gaId}
                        onChange={(e) => handleSwitch(e, items)}
                        checked={items.gatewayStatus === "false" ? false : true}
                        value={state}
                      />

                      {/* <Form.Check
                        type="switch"
                        id={items.gaId}
                        checked={false}
                        onChange={statusChangeHandle}
                      /> */}
                    </td>
                    <td>{items.gds}</td>
                    <td>{items.sourceMedia}</td>
                    <td>
                      {items.sec3des == "true" ? (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked={true}
                        />
                      ) : (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked={false}
                        />
                      )}{" "}
                    </td>
                    <td>{moment(items.modifiedOn).format("YYYY-MM-DD")}</td>
                    <td>{items.modifiedBy}</td>
                    <td>
                      {items.siteId == 6 ? (
                        <a href="http://travomint.com">travomint.com</a>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <button
                        id={"btn-" + i}
                        onClick={(e) => handleView(e)}
                        className="btn btn-primary dashboard-item"
                        value={items.gatewayId}
                      >
                        View
                      </button>
                    </td>
                    <td>
                      <button
                        id={"btn-" + i}
                        onClick={(e) => handleEditView(e)}
                        className="btn btn-primary dashboard-item3"
                        value={items.gatewayId}
                      >
                        {" "}
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            <TransactionModal
              modalShow={modalShow}
              handleClose={handleClose}
              gateway={gateway}
              switcher={switcher}
              gaID={gaID}
            />
            <EditPaymentGatwayModal
              modalShowEdit={modalShowEdit}
              handleEditViewClose={handleEditViewClose}
              gateway={gateway}
              switcher={switcher}
              seletedGateway={seletedGateway}
              editGatewayData={editGatewayData}
              setEditGatewayData={setEditGatewayData}
              EditGatewayDetails={EditGatewayDetails}
              UpdateGatewayData={UpdateGatewayData}
            />
          </div>
        ) : (
          <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
            <div className="container-fluid px-3">
              Loading......
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default PaymentSwitcher;
