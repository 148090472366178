import axios from "axios";
import { AddMarkUp_Url } from "../Urls";
import Swal from "sweetalert2";
const AddMarkUp = async ({ payload, loadingState }) => {
  const options = {
    method: "POST",
    url: AddMarkUp_Url,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": localStorage.getItem("token"),
    },
    data: payload,
  };
  loadingState(true);
  try {
    const { data } = await axios.request(options);
    Swal.fire({
      icon: data.httpStatus === "OK" ? "success" : "error",
      text: data.msg,
      showConfirmButton: false,
      timer: 2000,
    });
    loadingState(false);
    return data;
  } catch (error) {
    loadingState(false);
    return "error";
  }
};
export default AddMarkUp;
