import React from "react";
import Table from "../../CustomComponent/Table";
import { useState } from "react";
import DropTable from "../../CustomComponent/DropTable";
import OutBoundFlightData from "./Inside/OutBoundFlightData";
import InBoundFlightData from "./Inside/InBoundFlightData";
import Modals from "../../CustomComponent/Modals";
import AddCachePriceFeedModalBody from "./ModalBody/AddCachePriceFeedModalBody";
import Inputs from "../../CustomComponent/Inputs";
import Icons from "../../CustomComponent/Icons";
import Button from "../../CustomComponent/Button";
import { UpdateCachePriceFeed } from "../../Apis/CachePriceFeedApi/UpdateCachePriceFeed";
import { useNavigate } from "react-router-dom";
import { DeleteCachePriceFeed } from "../../Apis/CachePriceFeedApi/DeleteCachePriceFeed";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DublicateCachePriceModalBody from "./ModalBody/DublicateCachePriceModalBody";

const PriceFeedTable = (props) => {
  const navigate = useNavigate();
  const {
    data,
    showModal,
    closeModal,
    body,
    setBody,
    inbody,
    setInBody,
    mainBody,
    setMainBody,
    AddHandleSubmit,
    Updateloading,
    Deleteloading,
    setDublicateCacheFeedLoading,
  } = props;
  const [showDetail, setShowDetail] = useState(false);
  const [updateDetailEdit, setUpdateDetailEdit] = useState(false);
  const [updateDetailEditWithIndex, setUpdateDetailEditWithIndex] = useState();
  const [valueindex, setValueindex] = useState(0);
  const setShowDetailFun = (index) => {
    setValueindex(index);
    setShowDetail(true);
  };

  const [selectedItem, setSelectedItem] = useState({});
  const [outselectedItem, setOutSelectedItem] = useState([]);
  const [inselectedItem, setInSelectedItem] = useState([]);
  const [hideAndShow, setHideAndShow] = useState(false);

  // Dublicate
  const [dublicate_showModal, setDublicate_showModal] = useState(false);

  const [dublicate_Data, setDublicate_Data] = useState({
    data: {},
    index: "",
  });

  const UpdateOpenModal = (item, index) => {
    setHideAndShow(!hideAndShow);
    if (updateDetailEdit == true) {
      setUpdateDetailEdit(false);
      setUpdateDetailEditWithIndex();
    } else {
      setSelectedItem(item);
      setOutSelectedItem(item.outboundItinerary);
      setInSelectedItem(item.returnItinerary);
      setUpdateDetailEdit(true);
      setUpdateDetailEditWithIndex(index);
    }
  };

  const changeHandler = (e, i) => {
    const { name, value } = e.target;
    setSelectedItem({ ...selectedItem, [name]: value });
  };

  const OutchangeHandler = (e, i) => {
    const { name, value } = e.target;
    const list = [...outselectedItem];
    list[i][name] = value;
    setOutSelectedItem(list);
  };
  const InchangeHandler = (e, i) => {
    const { name, value } = e.target;
    const list = [...inselectedItem];
    list[i][name] = value;
    setInSelectedItem(list);
  };

  const UpdateCachePriceFeedFun = () => {
    const { outboundItinerary, returnItinerary, ...rest } = selectedItem;

    const JointData = {
      ...rest,
      outboundItinerary: outselectedItem,
      returnItinerary:
        inselectedItem.length == 0
          ? inselectedItem.returnItinerary
          : inselectedItem,
    };

    UpdateCachePriceFeed({ data: JointData, loading: Updateloading, navigate });
    setUpdateDetailEditWithIndex();
    setUpdateDetailEdit(false);
  };
  const DeleteCachePriceFeedFun = (item) => {
    DeleteCachePriceFeed({
      data: item._id,
      loading: Deleteloading,
      navigate,
    });
  };

  const DublicateOpenModal = (item, index) => {
    setDublicate_Data({
      data: item,
      index: index,
    });
    setDublicate_showModal(true);
  };
  const DublicateCloseModal = () => {
    setDublicate_showModal(false);
  };

  const ArrayContent = data.data.content;
  return (
    <div className="metaBox position-relative d-block border-0 smtb mb-3 hidenull-td flightDetail-Google">
      <div className="table-responsive lightthm-wrap scroll-auto">
        <table className="table table-lightthm text-left">
          <thead className="bg-light">
            <tr>
              <th scope="col" width={30}>
                Sr No.
              </th>
              <th scope="col">TripType</th>
              <th scope="col">Origin</th>
              <th scope="col">Destination</th>
              <th scope="col">Out Date</th>
              <th scope="col">In Date</th>
              <th scope="col">Price</th>
              <th scope="col">Currency</th>
              <th scope="col">Token</th>
              <th scope="col">Edit</th>
              <th scope="col">Duplicate</th>
              <th scope="col">{hideAndShow ? "Check" : ""}</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {ArrayContent.map((item, index) => {
              const show = updateDetailEdit;
              const disabled =
                index == updateDetailEditWithIndex && show ? false : true;
              const editbtn = (
                <>
                  <div className="btni-sp">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Edit</Tooltip>}
                    >
                      <button
                        className="btn btn-sm btn-soft-success"
                        onClick={() => UpdateOpenModal(item, index)}
                      >
                        <Icons iconName="fa fa-pencil" />
                      </button>
                    </OverlayTrigger>
                  </div>
                </>
              );
              const duplicatebtn = (
                <>
                  <Icons
                    iconName="fa fa-files-o"
                    onClick={() => DublicateOpenModal(item, index)}
                  />
                </>
              );
              const deletebtn = (
                <div className="btni-sp">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Delete</Tooltip>}
                  >
                    <button
                      className="btn btn-sm btn-soft-danger"
                      onClick={() => DeleteCachePriceFeedFun(item)}
                    >
                      <Icons iconName="fa-sharp fa-solid fa-trash" />
                    </button>
                  </OverlayTrigger>
                </div>
              );
              const OutDate = (
                <Inputs
                  placeholder="Out Date"
                  type="date"
                  label=""
                  name="outboundDate"
                  defaultValue={item.outboundDate}
                  disabled={disabled}
                  classstyle={
                    index !== updateDetailEditWithIndex
                      ? "form-control-plaintext spaceeq"
                      : "form-control"
                  }
                  onChange={(e) => changeHandler(e, index)}
                />
              );
              const ReturnDate = (
                <>
                  {item.tripType !== "RT" ? (
                    "--"
                  ) : (
                    <>
                      <Inputs
                        placeholder="Return Date"
                        type="date"
                        label=""
                        name="returnDate"
                        defaultValue={item.returnDate}
                        disabled={
                          index == updateDetailEditWithIndex &&
                          item.tripType !== "RT"
                            ? true
                            : show
                            ? false
                            : true
                        }
                        classstyle={
                          index !== updateDetailEditWithIndex
                            ? "form-control-plaintext spaceeq"
                            : "form-control"
                        }
                        onChange={(e) => changeHandler(e, index)}
                      />
                    </>
                  )}
                </>
              );
              const totalPrice = (
                <Inputs
                  placeholder="Total Price"
                  type="text"
                  label=""
                  name="totalPrice"
                  defaultValue={item.totalPrice}
                  disabled={
                    index == updateDetailEditWithIndex && show ? false : true
                  }
                  classstyle={
                    index !== updateDetailEditWithIndex
                      ? "form-control-plaintext spaceeq"
                      : "form-control"
                  }
                  onChange={(e) => changeHandler(e, index)}
                />
              );

              const update = (
                <div className="btni-sp">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Update Cache Price</Tooltip>}
                  >
                    <button
                      className="btn btn-sm btn-soft-green btn-soft"
                      onClick={() => UpdateCachePriceFeedFun()}
                    >
                      <Icons iconName="fa-solid fa-check" />
                    </button>
                  </OverlayTrigger>
                </div>
              );

              return (
                <>
                  <Table
                    onClick={() => setShowDetailFun(index)}
                    classStyle="cursor-pointer"
                    data={[
                      index + 1,
                      item.tripType,
                      item.origin,
                      item.destination,
                      OutDate,
                      ReturnDate,
                      totalPrice,
                      item.currency,
                      item._id,
                      editbtn,
                      // index == updateDetailEditWithIndex && show ? update : "",
                      duplicatebtn,
                      index == updateDetailEditWithIndex && show
                        ? update
                        : null,
                      deletebtn,
                    ]}
                  />

                  {showDetail && valueindex == index && (
                    <>
                      <tr>
                        <DropTable
                          tableHead={[
                            " ",
                            "Origin",
                            "Destination",
                            "Travel Date",
                            "Marketing Airline",
                            "Marketing FlightNumber",
                            "Operation Airline",
                            "Operating FlightNumber",
                            "DepTime",
                            "ArriTime",
                          ]}
                          tableBody={
                            <>
                              {item.outboundItinerary.map((items, ind) => {
                                return (
                                  <>
                                    <OutBoundFlightData
                                      Currentindex={index}
                                      SelectedIndex={updateDetailEditWithIndex}
                                      Inputshow={show}
                                      mainData={item}
                                      data={items}
                                      index={ind}
                                      onChange={(e) => OutchangeHandler(e, ind)}
                                    />
                                  </>
                                );
                              })}
                              {item.tripType == "RT" && (
                                <>
                                  {item.returnItinerary.map((items, ind) => {
                                    return (
                                      <>
                                        {(item.returnItinerary !== null ||
                                          item.returnItinerary !== "") && (
                                          <InBoundFlightData
                                            Currentindex={index}
                                            SelectedIndex={
                                              updateDetailEditWithIndex
                                            }
                                            Inputshow={show}
                                            mainData={item}
                                            data={items}
                                            index={ind}
                                            onChange={(e) =>
                                              InchangeHandler(e, ind)
                                            }
                                          />
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          }
                        />
                        {/* <Button
                          title="Update"
                          color="btn-submit btnver-2 mx-2"
                          onClick={() => UpdateCachePriceFeedFun()}
                        />
                        <Button
                          title="Delete"
                          color="btn-submit btnver-2 mx-2"
                          onClick={() => DeleteCachePriceFeedFun(item)}
                        /> */}
                      </tr>

                      <tr>
                        {hideAndShow && (
                          <td
                            colspan="12"
                            className="dbgsub-light pt-0 border-0"
                          >
                            <div className="table-responsive lightthm-wrap scroll-auto shadow-0 border-0">
                              <table className="table table-lightthm text-left">
                                <tbody>
                                  <tr className="text-right">
                                    <div className="d-inline-block pb-3 pt-3">
                                      <button
                                        className="btn btn-submit btnver-2 success ms-3"
                                        onClick={() =>
                                          UpdateCachePriceFeedFun()
                                        }
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        )}
                      </tr>
                    </>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modals
        show={showModal}
        HandleClose={closeModal}
        ModalBody={
          <AddCachePriceFeedModalBody
            body={body}
            setBody={setBody}
            inbody={inbody}
            setInBody={setInBody}
            mainBody={mainBody}
            setMainBody={setMainBody}
            AddHandleSubmit={AddHandleSubmit}
          />
          // <UpdateModalBody
          //   changeFunction={UpdateMarkUpDataByInput}
          //   handleSubmit={UpdateMarkUpDataSubmit}
          //   selectedData={selectedMarkupdata}
          // />
        }
        title="Add"
      />
      <Modals
        show={dublicate_showModal}
        HandleClose={DublicateCloseModal}
        ModalBody={
          <DublicateCachePriceModalBody
            data={dublicate_Data}
            setDublicate_showModal={setDublicate_showModal}
            setDublicateCacheFeedLoading={setDublicateCacheFeedLoading}
          />
        }
        title="Dublicate"
      />
    </div>
  );
};

export default PriceFeedTable;
