export const DownloadModalInput=[
    {
      id: 1,
      name: "fromDate",
      type: "date",
      placeholder: "Enter From Date",
      label: "From Date",
      required: "true",
      disabled: false,
      defaultValue: "",
    },
    {
      id: 2,
      name: "toDate",
      type: "date",
      placeholder: "Enter to Date",
      label: "To Date",
      required: "true",
      disabled: false,
      defaultValue: "",
    },
    {
      id: 3,
      name: "min",
      type: "number",
      placeholder: "Enter Minute",
      label: "Cache in (minute)",
      required: "true",
      disabled: false,
      defaultValue: "",
    },
    {
      id: 4,
      name: "dataLimit",
      type: "number",
      placeholder: "Enter data limit",
      label: "Total Data Limit",
      required: "true",
      disabled: false,
      defaultValue: "",
    },
  ]