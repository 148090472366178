import React, { useState } from "react";
import Inputs from "../../CustomComponent/Inputs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "../../CustomComponent/Select";
import Button from "../../CustomComponent/Button";
import MarkupType from "../../SmallApi/MarkupType.json";
import AmountType from "../../SmallApi/AmountType.json";
import { AddMarkUpApi } from "../../SmallApi/AddMarkUpApi";
import { UpdateMarkUp } from "../../Apis/Markup/UpdateMarkUp";
const UpdateModalBody = ({ allSource, setShowModal, fetchData, editData }) => {
  const [loading, setLoading] = useState(false);
  const [fieldData, setFieldData] = useState(editData);
  const changeFunction = (e) => {
    let { name, value } = e.target;
    if (["airline", "origin", "destination"].includes(name)) {
      value = value.toUpperCase();
    }
    setFieldData({ ...fieldData, [name]: value });
  };
  const handleSubmit = async (e) => {
    const { markupHistory, deleted, expired, ...restData } = fieldData;
    e.preventDefault();
    const response = await UpdateMarkUp({
      payload: restData,
      loadingState: setLoading,
    });
    if (response.httpStatus === "OK") {
      fetchData();
      setShowModal(false);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        {AddMarkUpApi(allSource, MarkupType, AmountType, fieldData, true).map(
          (item, i) => {
            return (
              <Col xs={12} md={6} xl={4} className="mb-4" key={i}>
                {item.type === "select" ? (
                  <Select
                    name={item.name}
                    label={item.label}
                    onChange={changeFunction}
                    optionValue={item.optionValue}
                    required={item.required}
                    classstyle="form-control"
                    value={item.defaultValue}
                    firstName={item.name === "source" && "Please Select"}
                    disabled={item.disabled}
                  />
                ) : (
                  <Inputs
                    name={item.name}
                    placeholder={item.placeholder}
                    type={item.type}
                    label={item.label}
                    onChange={changeFunction}
                    required={item.required}
                    value={item.defaultValue}
                    disabled={item.disabled}
                    classstyle="form-control"
                  />
                )}
              </Col>
            );
          }
        )}
        <div className="col-12 text-right border-top mt-1 py-4">
          <Button
            type="submit"
            title={loading ? "Please Wait..." : "Update"}
            color="btn btn btn-submit btnver-2"
            disabled={loading}
          />
        </div>
      </Row>
    </form>
  );
};

export default UpdateModalBody;
