import { cache_host, travel_url, traveloes_url } from "../Host";

export const saveConfig = `${travel_url}/saveConfig`;
export const getApiHitStatus = `${travel_url}/getApiHitStatus`;
export const getApiAirlines = `${travel_url}/allBookingPreference`;
export const addUpdateAirline_Url = `${travel_url}/addBookingPreference`;
export const getAllConfig_url = `${travel_url}/getAllConfig`;
export const UpdateMarkUp_Url = `${travel_url}/updateMarkup`;
export const MarkUp_List = `${travel_url}/markupList`;
export const AddMarkUp_Url = `${travel_url}/addMarkup`;
export const Update_Markup_Status = `${travel_url}/updateMarkupStatus`;
export const GetSource_Url = `${travel_url}/getSource`;
export const UpdateManageAPI = `${travel_url}/updateManageAPI`;
export const UpdateAutoHandler = `${travel_url}/updateAutoHandler`;
export const GetCachePriceFeed_Url = `${travel_url}/feed/filter`;
export const DownloadCachePriceFeed_Url = `${travel_url}/feed/download`;
export const DownloadTravFareRule_Url = `${travel_url}/travomint/exportCacheMarkupRule`;
export const ImportTravFareRule_Url = `${travel_url}/travomint/importCacheMarkupRule`;
export const DownloadGoogleReport_URL = `${travel_url}/downloadReport`;
export const AddCachePriceFeed_URL = `${travel_url}/feed/add`;
export const UpdateCachePriceFeed_URL = `${travel_url}/feed/update`;
export const DeleteCachePriceFeed_URL = `${travel_url}/feed/delete`;
export const AirTicketDetails_URL = `${traveloes_url}/v1/api/AirTicketDetails`;
