import axios from "axios";
import { GetCachePriceFeed_Url } from "../Urls";
export const GetCachePriceFeed = ({ data, getData, loading, navigate }) => {
  loading(true);
  const options = {
    method: "POST",
    url: GetCachePriceFeed_Url,
    params: { page: "1", size: "100" },
    headers: {
      "Content-Type": "application/json",
      "x-api-key": localStorage.getItem("token"),
    },
    data: data,
  };

  axios
    .request(options)
    .then(function (response) {
      getData(response.data);
      loading(false);
    })
    .catch(function (error) {
      console.error(error);
      // if (error.response.status == 401) {
      localStorage.clear();
      navigate("/login");
      // }
    });
};
