import React from "react";
import Table from "../../../../CustomComponent/Table";

const ViewAllExistingBenfit = ({ listData }) => {
  return (
    <div>
      <div className="w-100">
        <div className="table-responsive border-top-0">
          <table className="table table-lightthm text-left">
            <thead className="bg-light">
              <tr>
                <th width={20}>S.no.</th>
                <th>Plan Name</th>
                <th>Coverage</th>
                <th>Icon</th>
                <th>Plan Code</th>
              </tr>
            </thead>
            <tbody>
              {listData.map((item, i) => (
                <Table
                  i={i}
                  data={[
                    i + 1,
                    item.type,
                    item.coverage,
                    <div>
                      <img
                        src={`data:image/svg+xml;base64,${btoa(item.icon)}`}
                        width={20}
                        height={20}
                        alt="icon"
                      />
                    </div>,
                    item.planCode,
                  ]}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewAllExistingBenfit;
