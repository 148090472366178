export const CabinType = ({ item }) => {
  const data =
    item === 1
      ? "Economy"
      : item === 2
      ? "PREMIUM ECONOMY"
      : item === 3
      ? "BUSINESS CLASS"
      : item === 4
      ? "FIRST CLASS"
      : null;

  return data;
};
