import { Button, Container, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";

import DatePicker from "react-datepicker";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-daterangepicker/daterangepicker.css";

import moment from "moment";
import SideBar from "../Atom/SideBar/SideBar";
import TopBar from "../Atom/TopBar/TopBar";

// Merchant Key:9A4ERJ
// Merchant Salt:65YVgbuDtBsC3mPOQ89nU5UTx9z0y1X4

const Payu = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [transactions, setTransactions] = useState({});
  const [isLoading, setLoading] = useState(false);

  const getPayments = async () => {

    const options = {
      method: "POST",
      headers: {
        cookie:
          "AWSALB=9EwSNGSTkt2ZFp9K65Vcu%2F0VFeM7Jtn5n4C1%2BgEl93elBLR8qQNFTfdaPNCEWhe6w0LWBFnnCMKj5TKDlzXRd4b3pxVJVflwOw3kHUIJIFKhbQIHd9QDgC%2FAoGUo; AWSALBCORS=9EwSNGSTkt2ZFp9K65Vcu%2F0VFeM7Jtn5n4C1%2BgEl93elBLR8qQNFTfdaPNCEWhe6w0LWBFnnCMKj5TKDlzXRd4b3pxVJVflwOw3kHUIJIFKhbQIHd9QDgC%2FAoGUo; JSESSIONID=57D8AD1DE9F4F9EA3363F57C8C901261",
        "Content-Type": "application/json",
      },
      body: `{"var1":"${moment(startDate).format(
        "YYYY-MM-DD"
      )}","var2":"${moment(endDate).format("YYYY-MM-DD")}"}`,
    };

    fetch("https://www.travomint.com/showtransactionsv2", options)
      .then((response) => response.json())
      .then((response) => {

        setLoading(true);
        setTransactions(response);
        //  setLoading(false)
      })
      .catch((err) => console.error(err));
  };

  const handleSelect = (ranges) => {

    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  };
  let handleEvent = (event, picker) => {

  };
  let handleCallback = (start, end, label) => {

  };
  const selectionRange = {
    startDate: new Date().toLocaleDateString(),
    endDate: new Date().toLocaleDateString(),
    key: "selection",
  };
  const simulateNetworkRequest = () => {
    return new Promise((resolve) => setTimeout(resolve, 2000));
  };

  useEffect(() => {
    getPayments();
  }, []);
  const LoadingButton = () => { };

  
  const handleClick = () => setLoading(true);
  return (
    <>
    {/* <TopBar pagetitle={'Pay U'}/> */}

      {/* <SideBar /> */}
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
        <div className="container-fluid px-3">

          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 col-xxl-6">
            <div className="pt-5 pb-2 my-4">
            <h3 className="mb-4"> Pay U Transactions</h3>
            <div className="payu-dateinput login-form">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="text-secondary text-sm mb-2"><i className="fa fa-calendar mr-1-cal" aria-hidden="true"></i> From</div>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 col-12">
                  <div className="text-secondary text-sm mb-2"><i className="fa fa-calendar mr-1-cal" aria-hidden="true"></i> To</div>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="mt-4">
                <Button
                  className="btn-submit btn btn-primary"
                  variant="primary"
                  disabled={isLoading}
                  onClick={getPayments}
                >
                  {isLoading ? "Loadin.................." : "Click to load Payments"}
                </Button>
              </div>
            </div>
          </div>

          <div className="wrapper-space">
            <div className=""></div>
            <div className="transaction-data">
              {transactions.msg === "Transaction Fetched Successfully" ? (
                <Table>
                  <tr key={"header"}>
                    <th>TransactionID</th>
                    <th>Page-Mid</th>
                    <th>IBIBO</th>
                    <th>Bank-Ref</th>
                    <th>First Name</th>
                    <th>field9</th>
                    <th>Gateway</th>
                    <th>Phone </th>
                    <th>Bank Name</th>
                    <th>TransactionFee</th>
                    <th>Status</th>
                  </tr>
                  {transactions.Transaction_details.map((item) => (
                    <>
                      <>
                        {item.status === "captured" ? (
                          <tr key={item.id}>
                            <td>{item.txnid}</td>
                            <td>{item.pg_mid}</td>
                            <td>{item.ibibo_code}</td>
                            <td>{item.bank_ref_no}</td>
                            <td>{item.firstname}</td>
                            <td>{item.field9}</td>
                            <td>{item.payment_gateway}</td>
                            <td>{item.phone}</td>
                            <td>{item.bank_name}</td>
                            <td>{item.transaction_fee}</td>
                            <td>{item.status}</td>
                          </tr>
                        ) : (
                          <tr key={item.id}>
                            <td>{item.txnid}</td>
                            <td>{item.pg_mid}</td>
                            <td>{item.ibibo_code}</td>
                            <td>{item.bank_ref_no}</td>
                            <td>{item.firstname}</td>
                            <td>{item.field9}</td>
                            <td>{item.payment_gateway}</td>
                            <td>{item.phone}</td>
                            <td>{item.bank_name}</td>
                            <td>{item.transaction_fee}</td>
                            <td
                              className={
                                item.status != "captured"
                                  ? "textTableError"
                                  : "textTableError"
                              }
                            >

                              {item.status}
                            </td>
                          </tr>
                        )}
                      </>
                    </>
                  ))}
                </Table>
              ) : (
                `No Records Found ${transactions.msg}`
              )}
            </div>
          </div>

            </div>
          </div>

        
       
        </div>
      </div>

    </>
  );
};

export default Payu;
