import axios from "axios";
import { travel_url } from "../../Host";
import { useState } from "react";
import { useEffect } from "react";

const useGetRangeList = () => {
  const [getData, setGetData] = useState({});
  const [getDataLoading, setGetDataLoading] = useState(true);
  const getRangeData = async () => {
    const options = {
      method: "GET",
      url: `${travel_url}/range`,
      headers: {
        "x-api-key": localStorage.getItem("token"),
      },
    };

    try {
      const apiData = await axios.request(options);
      const GetApiRangedata = await apiData.data;
      setGetData(GetApiRangedata);
    } catch (e) {
      setGetData({
        httpStatus: "not ok",
        msg: "Server Error From Range",
      });
    } finally {
      setGetDataLoading(false);
    }
  };

  useEffect(() => {
    getRangeData();
  }, []);

  return { getData, getDataLoading };
};

export default useGetRangeList;
