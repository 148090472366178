import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Button from "../../CustomComponent/Button";
import Loading from "../../CustomComponent/Loading";
import Modals from "../../CustomComponent/Modals";
import Screen from "../../CustomComponent/Screen";
import useDeleteCacheManagement from "../../hooks/CachemenagementHook/useDeleteCacheManagement";
import useGetCacheManagement from "../../hooks/CachemenagementHook/useGetCacheManagement";
import useGetRangeList from "../../hooks/CachemenagementHook/useGetRangeList";
import CacheManagementTable from "../../Molecule/CacheMamagement/CacheManagementTable";
import AddCacheManagementModal from "../../Molecule/CacheMamagement/Modal/AddCacheManagementModal";
import EditCacheManagementModal from "../../Molecule/CacheMamagement/Modal/EditCacheManagementModal";
import ResultNotFound from "../../Molecule/MarkUpDeatils/ErrorScreen/ResultNotFound";

const Cachemanagement = () => {
  const [getData, loading, setGetData] = useGetCacheManagement();
  const [deleteData, deleteloading, setDeleteData] = useDeleteCacheManagement();
  const getRangeList = useGetRangeList();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModalData, setShowEditModaldata] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  useEffect(() => {
    setGetData();
  }, [refreshLoading, deleteloading]);

  const closeAddModal = () => {
    setShowAddModal(false);
    setShowEditModal(false);
  };

  const onEdit = (item) => {
    setShowEditModaldata(item);
    setShowEditModal(true);
  };

  const onDelete = ({ _id }) => {
    setDeleteData(_id);
  };

  if (loading || deleteloading) {
    return <Loading title="Loading..." />;
  }

  if (getData.msg === "This is expection") {
    return (
      <Screen>
        <div>Server Error</div>
      </Screen>
    );
  }
  if (deleteData.httpStatus === "not ok") {
    return (
      <Screen>
        <div>Server Error</div>
      </Screen>
    );
  }
  return (
    <Screen>
      <div className=" text-right pb-3  d-flex">
        <Button
          title={
            <>
              <i className="fa-solid fa-plus icon-add"></i> Add
            </>
          }
          color="btn-submit btnver-2 success ms-auto"
          onClick={() => setShowAddModal(true)}
        />
      </div>
      {getData.httpStatus === "OK" &&
      getData.msg === "Config list returned successfully" ? (
        <>
          <CacheManagementTable
            getData={getData}
            onEdit={onEdit}
            onDelete={onDelete}
          />
          {/* Modal */}
        </>
      ) : (
        <>
          <ResultNotFound />
        </>
      )}
      <Modals
        show={showAddModal}
        HandleClose={closeAddModal}
        ModalBody={
          <AddCacheManagementModal
            setRefreshloading={setRefreshLoading}
            refreshLoading={refreshLoading}
            setShowAddModal={setShowAddModal}
            rangeList={
              getRangeList.getData.data === null
                ? [
                    {
                      id: 1,
                      name: "Select Your Trip",
                      value: "",
                    },
                  ]
                : getRangeList.getData.data
            }
          />
        }
        title="Add"
      />

      <Modals
        show={showEditModal}
        HandleClose={closeAddModal}
        ModalBody={
          <EditCacheManagementModal
            data={showEditModalData}
            setRefreshloading={setRefreshLoading}
            refreshLoading={refreshLoading}
            setShowAddModal={setShowEditModal}
            rangeList={
              getRangeList.getData.data === null
                ? [
                    {
                      id: 1,
                      name: "Select Your Trip",
                      value: "",
                    },
                  ]
                : getRangeList.getData.data
            }
          />
        }
        title="Edit"
      />
    </Screen>
  );
};

export default Cachemanagement;
