import React, { useEffect, useState } from "react";
import { travel_url } from "../../Host";
import Source from "../../CustomApi/Source.json";
import moment from "moment";
import Collapsible from "react-collapsible";
import {
  Google_Flight_Booking_Filter,
  Google_Flight_Booking_Filter_option,
} from "../../SmallApi/Google_Flight_Booking_Filter";
import Inputs from "../../CustomComponent/Inputs";
import Select from "../../CustomComponent/Select";
import Button from "../../CustomComponent/Button";
import { DownloadReport } from "../../Apis/GoogleFlightBooking/DownloadReport";

const FilterGoogle = ({ setFilterObj, getGoogleData }) => {
  const [filterObjs, setFilterObjs] = useState({
    from: 0,
    to: 0,
    source: null,
    orderId: 0,
    gds: 0,
    tripType: 0,
    paymentStatus: null,
    bookingStatus: null,
    onwardAirline: null,
    returnAirline: null,
  });

  const handlerChange = (e) => {
    e.preventDefault();

    const { value, name } = e.target;
    if (name === "from") {
      const date = new Date(value);
      const ab = date.getTime();
      setFilterObjs({ ...filterObjs, [name]: ab });
    } else if (name === "to") {
      const date = new Date(value);
      const ab = date.getTime();
      setFilterObjs({ ...filterObjs, [name]: ab });
    } else {
      setFilterObjs({ ...filterObjs, [name]: value });
    }
  };
  const bookingFormData = (e) => {
    e.preventDefault();
    setFilterObj({ ...filterObjs });
  };

  const date = new Date(filterObjs.from);

  return (
    <div className="container-fluid px-3 bg-white pt-5 pb-3">
      <div className=" btn-dark-add">
        <div className="row align-items-end">
          <div className="col-md-6">
            <h6 className="title-lightthm m-0 text-base font-500">
              {Object.keys(getGoogleData).length !== 0 && (
                <>
                  {/* <Button
              //   title={`Total Result Count, ${getGoogleData.totalElements}`}
              //   color="btn-submit btnver-2 me-2"
              //   // onClick={() => setShowAddModal(true)}
              // /> */}
                  {`Total Result Count, ${getGoogleData.totalElements}`}
                </>
              )}
            </h6>
          </div>
          <div className="col-md-6">
            <Button
              title={
                <span>
                  <i className="fa-solid fa-filter me-1"></i> Filter
                </span>
              }
              color="btn-submit btnver-2 lightgry d-inline-flex justify-content-center flex-column"
              // className="btn btn-primary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
              // onClick={() => setShowAddModal(true)}
            />
            {(localStorage.getItem("role") == "DEVELOPER" ||
              localStorage.getItem("role") == "MANAGER" ||
              localStorage.getItem("role") == "DATA_ANALYTICS") && (
              <Button
                title={
                  <span>
                    <i className="fa fa-arrow-down me-1" aria-hidden="true"></i>{" "}
                    Download Report
                  </span>
                }
                color="btn-submit btnver-2 ms-3 light shadow-0"
                onClick={() => DownloadReport({ data: filterObjs })}
              />
            )}
          </div>
        </div>
      </div>

      <div className="collapse " id="collapseExample">
        <div className="filter-css w-100 float-left my-4">
          <form className="row align-items-end needs-validation" novalidate>
            {Google_Flight_Booking_Filter().map((item) => {
              return (
                <div className="col-12 col-md-6 col-xl-4 col-xxl-3 mb-4">
                  <Inputs
                    name={item.name}
                    placeholder={item.placeholder}
                    type={item.type}
                    label={item.label}
                    onChange={(e) => handlerChange(e)}
                    required={item.required}
                    defaultValue={item.defaultValue}
                    disabled={item.disabled}
                    classstyle="form-control"
                  />
                </div>
              );
            })}

            {Google_Flight_Booking_Filter_option().map((item) => {
              return (
                <div className="col-12 col-md-6 col-xl-4 col-xxl-3 mb-4">
                  <Select
                    name={item.name}
                    label={item.label}
                    onChange={(e) => handlerChange(e)}
                    optionValue={item.optionValue}
                    required={true}
                    classstyle="form-control"
                    // defaultValue={selectedData.markupType}
                  />
                </div>
              );
            })}

            <div className="col-12 col-md-6 col-xl-4 col-xxl-3 mb-4">
              <Button
                title="Submit"
                onClick={(e) => bookingFormData(e)}
                color="btn btn-submit btnver-2"
              />
              {/* <input
                type="button"
                className="btn btn-submit btnver-2"
                value="Submit"
                onClick={(e) => bookingFormData(e)}
              /> */}
              {/* <button
                className="btn btn-submit btnver-2"
                onClick={(e) => bookingFormData(e)}
              >
                SUBMIT
              </button> */}
              <div className="invalid-feedback">
                Please provide a valid zip.
              </div>
            </div>
            {/* <div className="col-12">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="invalidCheck"
                required
              />
              <label className="form-check-label" for="invalidCheck">
                Agree to terms and conditions
              </label>
              <div className="invalid-feedback">
                You must agree before submitting.
              </div>
            </div>
          </div> */}
            {/* <div className="col-md-2">
            <button
              className="btn btn-dark"
              type="submit"
              onClick={(e) => bookingFormData(e)}
            >
              Submit form
            </button>
          </div> */}
          </form>
        </div>
      </div>

      {/*       
       <Collapsible
        contentInnerClassName="collapsible-custom"
        className=""
        trigger={filter}
      > */}

      {/* </Collapsible> */}
    </div>
  );
};

export default FilterGoogle;
