import React, { useState } from "react";
import Button from "../../../../CustomComponent/Button";
import Inputs from "../../../../CustomComponent/Inputs";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { travel_url } from "../../../../Host";
import { commonheader } from "../../../../utils/header";
import swal from "sweetalert";
import { useEffect } from "react";

const AddUpdateSiteId = ({
  setModalOpen,
  fetchData,
  sitedata,
  setIsEdit,
  isedit,
}) => {
  const [inputdata, setInputData] = useState({
    siteId: null,
    name: "",
    url: "",
  });
  const { siteId, url, name } = inputdata;
  const onChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputdata, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.request({
        method: isedit ? "put" : "post",
        url: `${travel_url}/${isedit ? "updateSite" : "addSite"}`,
        data: inputdata,
        headers: commonheader(),
      });
      swal("", data.msg, data.data ? "success" : "error");
      fetchData();
      setInputData({
        siteId: null,
        name: "",
        url: "",
      });
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      swal("Uncaught Error", "Please try again after some time", "error");
    }
  };
  useEffect(() => {
    if (isedit) {
      const newRes = sitedata.find((ele) => ele._id === isedit);
      setInputData(newRes);
    } else {
      // setInputData({
      //   siteId: null,
      //   name: "",
      //   url: "",
      // });
    }
  }, [isedit]);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="number"
              name="siteId"
              placeholder={"Enter the Site ID"}
              label={"Site ID"}
              onChange={(e) => onChange(e)}
              required={true}
              defaultValue={siteId}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="name"
              placeholder={"Enter the website name"}
              label={"Website Name"}
              onChange={(e) => onChange(e)}
              required={true}
              defaultValue={name}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="url"
              placeholder={"Enter the website url"}
              label={"URL"}
              onChange={(e) => onChange(e)}
              required={true}
              defaultValue={url}
              classstyle="form-control"
            />
          </Col>
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2  btn-md "
              // onClick={dublicateHandleSubmit}
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddUpdateSiteId;
