import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../CustomComponent/Button";
import Inputs from "../../../CustomComponent/Inputs";
import useSaveRange from "../../../hooks/ApiTrackingHook/useSaveRange";

const AddRangeModal = ({ closeModal }) => {
  // hooks
  const [saveRange, loading, setSaveRange] = useSaveRange();

  // State
  const [addRangeValue, setAddRangeValue] = useState({
    fromRange: "",
    toRange: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const body = {
      range: `${addRangeValue.fromRange}-${addRangeValue.toRange}`,
    };
    setSaveRange(body);
  };

  const changeFunction = (e) => {
    const { name, value } = e.target;

    setAddRangeValue({ ...addRangeValue, [name]: value });
  };

  const inputList = (addRangeValue) => [
    {
      id: 1,
      name: "fromRange",
      type: "text",
      pattern: "[0-9]+",
      placeholder: "From Range",
      label: "From Range",
      value: addRangeValue.fromRange,
      required: true,
      disabled: false,
      defaultValue: "",
    },
    {
      id: 2,
      name: "toRange",
      type: "text",
      pattern: "[0-9]+",
      value: addRangeValue.toRange,
      placeholder: "To Range",
      label: "To Range",
      required: true,
      disabled: false,
      defaultValue: "",
    },
  ];

  useEffect(() => {
    if (Object.keys(saveRange).length) {
      if (saveRange.httpStatus === "OK") {
        closeModal(false);
      }
    }
    // Object.keys(saveRange).length > 0
    //   ? saveRange.httpStatus === "OK"
    //     ? alert("close")
    //     : ""
    //   : "";
  }, [saveRange]);

  return (
    <form onSubmit={onSubmit}>
      <div className="text-center text-danger">
        {loading ? "Loading..." : saveRange.msg}
      </div>

      <Row>
        {/* <Col xs={12} md={6} xl={4} className="mb-4">
          <input
            name="fromRange"
            pattern="[0-9]+"
            type="text"
            placeholder="From Range"
            label="From Range"
            value={addRangeValue.fromRange}
            required={true}
            onChange={changeFunction}
            disabled={false}
            defaultValue=""
            minlength="1"
            maxlength="3"
            className="form-control"
            // className="input-classicsite"
          />
          <input
            name="toRange"
            pattern="[0-9]+"
            type="text"
            placeholder="To Range"
            label="To Range"
            value={addRangeValue.toRange}
            required={true}
            onChange={changeFunction}
            disabled={false}
            defaultValue=""
            minlength="1"
            maxlength="3"
            className="form-control"
          />
        </Col> */}
        {inputList(addRangeValue).map((item, i) => {
          return (
            <Col xs={12} md={6} xl={4} className="mb-4" key={i}>
              <input
                name={item.name}
                pattern={item.pattern}
                placeholder={item.placeholder}
                type={item.type}
                label={item.label}
                // value={item.value}
                onChange={changeFunction}
                required={item.required}
                defaultValue={item.defaultValue}
                disabled={item.disabled}
                className="form-control"
              />
            </Col>
          );
        })}

        <div className="col-12 text-right border-top mt-1 py-4">
          <Button
            type="submit"
            title="Submit"
            color="btn btn-submit btnver-2  btn-md "
            //   onClick={AddHandleSubmit}
          />
        </div>
      </Row>
    </form>
  );
};

export default AddRangeModal;
