import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../../../CustomComponent/Button";
import Inputs from "../../../CustomComponent/Inputs";
import Select from "../../../CustomComponent/Select";
import axios from "axios";
import { travel_url } from "../../../Host";
import swal from "sweetalert";
import { UpdateMeta } from "../../../Apis/Meta/UpdateMeta";
import { addUpdateAirline_Url } from "../../../Apis/Urls";
import { commonheader } from "../../../utils/header";
import { useLocation } from "react-router-dom";

const AddPrefredAirline = ({
  closeModal,
  setUpdatedMetaState,
  isedit,
  setIsEdit,
  setTicketingStatus,
  getSuppliersData,
}) => {
  const { pathname } = useLocation();
  const [inputdata, setInputData] = useState({
    supplier: "",
    airline: "",
    enable: true,
    hold: true,
  });
  const { enable, supplier, airline, hold } = inputdata;
  const onChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputdata, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // UpdateMeta({
    //   loading: setTicketingStatus,
    //   state: setUpdatedMetaState,
    //   url: addUpdateAirline_Url,
    //   method: "POST",
    //   body: {
    //     airline: airline,
    //     supplier: supplier,
    //     enable: enable,
    //     ...(isedit && {_id: isedit._id})
    //   },
    // });
    // isedit && setIsEdit("");
    // closeModal(false);

    try {
      const { data } = await axios.post(
        `${travel_url}/addBookingPreference`,
        {
          airline: airline,
          supplier: supplier,
          enable: enable,
          hold: hold,
          ...(isedit && { _id: isedit._id }),
        },
        {
          headers: commonheader(),
        }
      );
      setUpdatedMetaState(data);
      swal(
        "",
        isedit ? "Successfully Updated" : "Added Successfully",
        "success"
      );
      isedit && setIsEdit("");
      closeModal(false);
    } catch (error) {
      console.log(error);
      swal("Some Error", "Please try again after some time");
    }
  };
  useEffect(() => {
    isedit
      ? setInputData(isedit)
      : setInputData({ supplier: "", airline: "", enable: true });
  }, []);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="supplier"
              label="Supplier"
              firstName="Select"
              optionValue={getSuppliersData}
              onChange={(e) => onChange(e)}
              value={supplier}
              classstyle="form-control"
              required={true}
              disabled={pathname === "/flight-auto-booking" ? true : false}
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="airline"
              placeholder={"Enter the Airlines"}
              label={"Airlines"}
              onChange={(e) => onChange(e)}
              required={true}
              defaultValue={airline}
              disabled={pathname === "/flight-auto-booking" ? true : false}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              type="text"
              name="enable"
              placeholder={"Enter the Airlines"}
              label={"Status"}
              onChange={(e) => onChange(e)}
              required={true}
              optionValue={[
                { name: "Active", value: true, id: 1 },
                { name: "Inactive", value: false, id: 2 },
              ]}
              defaultValue={isedit ? isedit.enable : true}
              disabled={false}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              type="text"
              name="hold"
              label={"Hold"}
              onChange={(e) => onChange(e)}
              required={true}
              optionValue={[
                { name: "Active", value: true, id: 1 },
                { name: "Inactive", value: false, id: 2 },
              ]}
              defaultValue={isedit ? isedit.hold : true}
              disabled={false}
              classstyle="form-control mt-4"
            />
          </Col>
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2  btn-md "
              // onClick={dublicateHandleSubmit}
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddPrefredAirline;
