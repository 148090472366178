import React from "react";
import ApitrackingDev from "../../Atom/ApitrackingDev/ApitrackingDev";
import ApiTrackingSideBar from "../Apitracking/ApiTrackingSideBar";

const ApiTrackingDeveloper = ({activetab}) => {
  return (
    <>
      <div>
        {/* _____________________________API TRACKING DEVELOPER _____________________________  */}

        <ApitrackingDev activetab={activetab} />

        {/* _____________________________API TRACKING DEVELOPER _____________________________  */}
      </div>
    </>
  );
};

export default ApiTrackingDeveloper;
