import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const HotelMgtTable = ({ mgtData, editClick }) => {
  return (
    <div className="metaBox position-relative d-block border-0 smtb  mt-4">
      <div className="table-responsive lightthm-wrap scroll-auto">
        <table className="table table-lightthm text-left">
          <thead className="bg-light">
            <tr>
              <th scope="col" width={30}>
                Sr No.
              </th>
              <th scope="col">Site ID</th>
              <th scope="col">Meta Source</th>
              <th scope="col">Product</th>
              <th scope="col">Coupon Type</th>
              <th scope="col">Coupon Code</th>
              <th scope="col">Discount Applicable</th>
              <th scope="col">Min.Transaction Amount</th>
              <th scope="col">Max Discount</th>
              <th scope="col">Discount Type</th>
              <th scope="col">Discount On</th>
              <th scope="col">Discount Value</th>
              <th scope="col">Discount Text</th>
              <th scope="col">Country</th>
              <th scope="col">Currency</th>
              <th scope="col">Usablity</th>
              <th scope="col">No.of Coupons</th>
              <th scope="col">Used Count</th>
              <th scope="col">Validity From</th>
              <th scope="col">Validity To</th>
              <th scope="col">Status</th>
              <th scope="col">First Time User</th>
              <th scope="col">Edit</th>
            </tr>
          </thead>
          <tbody>
            {mgtData.content.map((items, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{items.siteId}</td>
                <td>{items.source}</td>
                <td>{items.product}</td>
                <td>{items.couponType}</td>
                <td>{items.couponCode}</td>
                <td>{items.discountApplicable}</td>
                <td>{items.minAmount}</td>
                <td>{items.maxDiscount}</td>
                <td>{items.discountType === "P" ? "Percentage" : "Flat"}</td>
                <td>{items.discountOn}</td>
                <td>{items.discountAmt}</td>
                <td>{items.description}</td>
                <td>{items.country}</td>
                <td>{items.currency}</td>
                <td>{items.useAbility}</td>
                <td>{items.useLimit}</td>
                <td>{items.usedCount}</td>
                <td>
                  {" "}
                  {new Date(items.validFrom).toLocaleDateString()}
                  <br />
                  {new Date(items.validFrom).toLocaleTimeString()}
                </td>
                <td>
                  {new Date(items.validTo).toLocaleDateString()}
                  <br />
                  {new Date(items.validTo).toLocaleTimeString()}
                </td>
                <td
                  className={`${
                    items.status ? "text-success" : "text-secondary"
                  }`}
                >
                  <i
                    className={`fa-solid fa-circle`}
                    style={{ fontSize: "10px", marginRight: "4px" }}
                  ></i>
                  <span className="">
                    {items.status ? "Active" : "Inactive"}
                  </span>
                </td>
                <td>{items.newUser === true ? "YES" : "NO"}</td>

                <td>
                  <div className="btni-sp">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Edit</Tooltip>}
                    >
                      <button
                        className="btn btn-sm btn-soft-success"
                        onClick={() => editClick(items)}
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </button>
                    </OverlayTrigger>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HotelMgtTable;
