import React from "react";
import SideBar from "../Atom/SideBar/SideBar";
import UserBar from "../Molecule/User/UserBar";

const Gateway = () => {
  return (
    <div>
      {/* <UserBar Pagetitle={`Gateway`} /> */}

      {/* <SideBar /> */}
    </div>
  );
};

export default Gateway;
