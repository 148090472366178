import React, { useState } from "react";
import ApiTracking from "../Molecule/Apitracking/ApiTracking";
import ApiTrackingSideBar from "../Molecule/Apitracking/ApiTrackingSideBar";
import { Tab, Tabs } from "react-bootstrap";
import Screen from "../CustomComponent/Screen";

const Apitracking = () => {
  const [activetab, setActiveTab] = useState(1);
  return (
    <div>
      {/* <ApiTrackingSideBar Pagetitle={`API Tracking`}/> */}
      <Screen>
        <div className="w-100 d-flex align-items-center p-2 custom_tabs">
          {tabs.map((tab) => {
            return (
              <div
                onClick={() => setActiveTab(tab.id)}
                className={`tab ${activetab === tab.id && "active"}`}
              >
                {tab.name}
              </div>
            );
          })}
        </div>
      </Screen>
      {<ApiTracking activetab={activetab} />}
    </div>
  );
};
export default Apitracking;
const tabs = [
  { id: 1, name: "Auto Ticketing Handler" },
  { id: 14, name: "Hold Ticketing Handler" },
  { id: 2, name: "Supplier Handler" },
  { id: 3, name: "Supplier Config Hit Limit" },
  { id: 4, name: "Supplier Search history" },
  { id: 5, name: "Airline Fare Identifier" },
  { id: 6, name: "Airline Booking Preference" },
  { id: 7, name: "All Sources" },
  { id: 8, name: "Supplier (API)" },
  { id: 9, name: "Range" },
  { id: 10, name: "Site" },
  { id: 11, name: "Block Airport" },
  { id: 12, name: "Portal Config" },
  { id: 13, name: "IP Whitelist" },
  { id: 15, name: "Content Visibility" },
  { id: 16, name: "Payment Gateway" },
  { id: 17, name: "Meta Supplier Map" },
  { id: 18, name: "Gateway Response Map" },
  { id: 19, name: "Insurance Handler" },
  { id: 20, name: "Insurance Plan Benefits" },
  { id: 21, name: "Insurance" },
  { id: 22, name: "Icon Handler" },
  { id: 23, name: "Payment Service" },
];
