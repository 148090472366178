import React from "react";

const ViewInsurancePlanNPremium = ({ insurncePlanObj, premiumObj }) => {
  const { planCode, name, categoryCode, dayPlan, annualPlan, countryCode } =
    insurncePlanObj;
  return (
    <div>
      <div className="w-100">
        <div>
          <h5>Insurance Plan</h5>
          <div className="table-responsive border-top-0">
            <table className="table table-lightthm text-left">
              <thead className="bg-light">
                <tr>
                  <th width={20}>S.no.</th>
                  <th>Name</th>
                  <th>Plan Code</th>
                  <th>Category Code</th>
                  <th>Day Plan</th>
                  <th>Annual Plan</th>
                  <th>Country Code</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{name}</td>
                  <td>{planCode}</td>
                  <td>{categoryCode}</td>
                  <td>{dayPlan.toString()}</td>
                  <td>{annualPlan.toString()}</td>
                  <td>{countryCode}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {premiumObj !== null && Object.keys(premiumObj).length > 0 && (
          <div className="mt-4">
            <h5>Premium</h5>
            <div className="table-responsive border-top-0">
              <table className="table table-lightthm text-left">
                <thead className="bg-light">
                  <tr>
                    <th width={20}>S.no.</th>
                    <th>Plan Code</th>
                    <th>Age Limit</th>
                    <th>Day Limit</th>
                    <th>Premium</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>{premiumObj.planCode}</td>
                    <td>{premiumObj.ageLimit}</td>
                    <td>{premiumObj.dayLimit}</td>
                    <td>{premiumObj.premium}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewInsurancePlanNPremium;
