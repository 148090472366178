import React from "react";
import { Modal } from "react-bootstrap";

const Modals = ({
  HandleClose,
  show,
  title,
  ModalBody,
  backdrop,
  keyboard,
  mclass,
  size = "sm",
}) => {
  return (
    <div>
      <Modal
        // size={"sm"}
        className={"modalbooknow-classic " + mclass}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={HandleClose}
        backdrop={backdrop}
        keyboard={keyboard}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-Title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">{ModalBody}</Modal.Body>
        {/* <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
</Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Modals;
