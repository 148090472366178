import axios from "axios";
import { travel_url } from "../../Host";

export const VeryfyToken = ({ token, navigate }) => {
  axios
    .get(`${travel_url}/checkToken`, {
      headers: {
        "x-api-key": token,
      },
    })
    .then(
      (resp) => {
        if (resp.data.httpStatus == "OK") {
          console.log("OK");
        } else {
          navigate("/login");
        }
      },
      (err) => {
        navigate("/login");
      }
    );
};
