import React from "react";
import axios from "axios";
import { travel_url } from "../../Host";

export const DeleteMarkup = async (id) => {
  const options = {
    method: "DELETE",
    url: `${travel_url}/travomint/markup/delete`,
    params: { id: id },
    headers: { "Content-Type": "application/json" },
  };
  try {
    const delete_Markup = await axios.request(options);
    const delete_mar = await delete_Markup.data;

    return delete_mar;
  } catch (err) {
    return "Error";
  }
};
