import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { addHoldticketApi } from "../../../Services/Api/holdTicketHandleApi";
import Swal from "sweetalert2";

const AddHoldTicketModal = ({ allSource, allApi, setShowModal, fetchData }) => {
  const [fieldData, setFieldData] = useState({
    source: "",
    supplier: "",
    hold: false,
    enable: true,
    airlines: "",
    tripType: 0,
  });
  const { source, supplier, airlines, tripType } = fieldData;
  const handleChangeValue = (e) => {
    let { value, name } = e.target;
    if (name === "airlines") {
      value = value.trim().toUpperCase();
    }
    setFieldData({ ...fieldData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (source && supplier && airlines) {
      try {
        const { data } = await addHoldticketApi(fieldData);
        data.httpStatus === "OK" && fetchData();
        Swal.fire({
          icon: data.httpStatus === "OK" ? "success" : "error",
          text: data.msg,
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {}
      setShowModal(false);
    }
  };
  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title className="modal-Title">Add Hold Ticket</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <div className="row mb-3">
            <div className="col-12">
              <div className="input-classicsite">
                <label for="inputEmail3" className="form-label">
                  Source
                </label>
                <select
                  className="form-select"
                  name="source"
                  placeholder="Select Title"
                  value={source}
                  onChange={handleChangeValue}
                >
                  <option value="">Select Meta</option>
                  {allSource ? (
                    <>
                      {allSource.data.map((items, i) => (
                        <option value={items.name}>{items.name}</option>
                      ))}
                    </>
                  ) : (
                    <>
                      <option value="">Select Meta</option>
                    </>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="input-classicsite">
                <label for="inputEmail3" className="form-label">
                  Supplier
                </label>
                <select
                  className="form-select"
                  name="supplier"
                  placeholder="Select Title"
                  value={supplier}
                  onChange={handleChangeValue}
                >
                  <option value="">Select Supplier</option>
                  {allApi ? (
                    <>
                      {allApi.data.map((items, i) => (
                        <option value={items.name}>{items.name}</option>
                      ))}
                    </>
                  ) : (
                    <>
                      <option value="">Select Supplier</option>
                    </>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="input-classicsite">
                <label for="inputEmail3" className="form-label">
                  Trip Type
                </label>
                <select
                  className="form-select"
                  name="tripType"
                  placeholder="Select Title"
                  value={tripType}
                  onChange={handleChangeValue}
                >
                  <option value={0}>All</option>
                  <option value={1}>One Way</option>
                  <option value={2}>Round Trip</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="input-classicsite">
                <label for="" className="form-label">
                  Airlines
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="airlines"
                  onChange={handleChangeValue}
                  value={airlines}
                  required
                  placeholder="i.e 6E,UK,AI etc."
                />
              </div>
            </div>
          </div>
          <fieldset className="row mb-3 mt-4">
            <legend className="col-form-label col-12 pt-0 fw-bold">
              Status
            </legend>
            <div className="col-12">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="enable"
                  id="gridRadios1"
                  value={true}
                  onChange={handleChangeValue}
                  defaultChecked={true}
                />
                <label className="form-check-label" for="gridRadios1">
                  Active
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="enable"
                  id="gridRadios2"
                  value={false}
                  onChange={handleChangeValue}
                  defaultChecked={false}
                />
                <label className="form-check-label" for="gridRadios2">
                  Inactive
                </label>
              </div>
            </div>
          </fieldset>
          <fieldset className="row mb-3 mt-4">
            <legend className="col-form-label col-12 pt-0 fw-bold">Hold</legend>
            <div className="col-12">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="hold"
                  id="gridRadios3"
                  value={true}
                  onChange={handleChangeValue}
                />
                <label className="form-check-label" for="gridRadios3">
                  Active
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="hold"
                  id="gridRadio4"
                  value={false}
                  onChange={handleChangeValue}
                  defaultChecked={true}
                  required
                />
                <label className="form-check-label" for="gridRadios4">
                  Inactive
                </label>
              </div>
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
          <button
            type="submit"
            className="btn btn btn-submit btnver-2"
            onClick={handleSubmit}
            disabled={!source || !supplier || !airlines}
          >
            Submit
          </button>
        </Modal.Footer>{" "}
      </form>
    </div>
  );
};

export default AddHoldTicketModal;
