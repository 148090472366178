import React from "react";
import { Sidebar } from "semantic-ui-react";
import SideBar from "../../Atom/SideBar/SideBar";
import TopBar from "../../Atom/TopBar/TopBar";
import { Route, useLocation, Link } from "react-router-dom";

const DashboardBar = (props) => {
  const location = useLocation();
  return (
    <>
      <TopBar
        pagetitle={
          location.key === "default"
            ? "Page Not Found"
            : location.pathname
                .replaceAll("/", " ")
                .replaceAll("page", ", page")
                .replaceAll("-", "  ")
                .replaceAll("=", " : ")
        }
      />
      <SideBar />
    </>
  );
};

export default DashboardBar;
