import React, { useEffect, useState } from "react";
import Button from "../../../CustomComponent/Button";
import Select from "../../../CustomComponent/Select";
import Inputs from "../../../CustomComponent/Inputs";
import { Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { gofirst_url } from "../../../Host";
import swal from "sweetalert";
import { commonheader } from "../../../utils/header";
import Multiselect from "multiselect-react-dropdown";

const AddPromoModal = ({
  setShowModal,
  fetchData,
  isEdit,
  sources,
  suppliers,
}) => {
  const [inputdata, setInputData] = useState({
    promoCode: "",
    airline: "",
    active: true,
    source: [],
    supplier: "",
    applyOn: "SB",
    desc: "",
  });
  const { promoCode, airline, active, source, desc, supplier, applyOn } =
    inputdata;
  function onChange(e) {
    const { name, value } = e.target;
    setInputData({ ...inputdata, [name]: value });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios({
        method: isEdit ? "put" : "post",
        url: `${gofirst_url}/promo/${isEdit ? "update" : "add"}`,
        data: inputdata,
        headers: commonheader(),
      });
      setShowModal(false);
      swal(
        "",
        `${isEdit ? "Successfully Updated" : "Added Successfully"}`,
        "success"
      );
      fetchData();
      setInputData({
        promoCode: "",
        airline: "",
        active: true,
        source: [],
        supplier: "",
        applyOn: "SB",
        desc: "",
      });
    } catch (error) {
      console.log(error);
      swal("Some Error", "Please try again after some time");
    }
  };
  useEffect(() => {
    if (isEdit) {
      setInputData(isEdit);
    }
  }, [isEdit]);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6} xl={4} className="mb-3">
            <Inputs
              type="text"
              name="promoCode"
              placeholder="Enter the promo code"
              label="Promo Code"
              onChange={(e) => onChange(e)}
              required={true}
              disabled={false}
              defaultValue={promoCode}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="mb-3">
            <Inputs
              type="text"
              name="airline"
              placeholder="Enter the airline"
              label="Airline"
              onChange={(e) => onChange(e)}
              required={true}
              disabled={false}
              defaultValue={airline}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="mb-3">
            <Select
              name="active"
              label="Status"
              onChange={(e) => onChange(e)}
              optionValue={[
                { id: 1, name: "Active", value: true },
                { id: 2, name: "Inactive", value: false },
              ]}
              required={true}
              value={active}
              disabled={false}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="mb-3">
            <Form.Group className="input-classicsite">
              <label className="form-label">Select Source</label>
              <Multiselect
                displayValue="name"
                placeholder="Select Source"
                className="multiselect-cs"
                onRemove={(e) =>
                  setInputData({ ...inputdata, source: e.map((el) => el.name) })
                }
                onSelect={(e) =>
                  setInputData({ ...inputdata, source: e.map((el) => el.name) })
                }
                options={sources}
                selectedValues={
                  source &&
                  source.map((ele) => {
                    return { name: ele };
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6} xl={4} className="mb-3">
            <Select
              name="supplier"
              label="Select Supplier"
              firstName="Select Supplier"
              optionValue={suppliers}
              onChange={(e) => onChange(e)}
              classstyle="form-control"
              required={true}
              value={supplier}
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="mb-3">
            <Select
              name="applyOn"
              label="Apply On"
              onChange={(e) => onChange(e)}
              optionValue={[
                { id: 1, name: "Search+Booking", value: "SB" },
                { id: 2, name: "Only Booking", value: "B" },
              ]}
              required={true}
              value={applyOn}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="mb-3">
            <Form.Group className="input-classicsite">
              <label className="form-label">Description</label>
              <textarea
                className="p-2 rounded"
                name="desc"
                defaultValue={desc}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
          </Col>
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2 btn-md"
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddPromoModal;
