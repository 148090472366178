import axios from "axios";
import React from "react";
import { useState } from "react";
import { travel_url } from "../../Host";

const useUpdateCacheManagement = () => {
  const [apiData, setApiData] = useState({});
  const [loading, setLoading] = useState(false);
  const AddNewCacheManagement = async (
    body,
    setRefreshloading,
    refreshLoading,
    setShowAddModal
  ) => {
    setLoading(true);
    const options = {
      method: "PUT",
      url: `${travel_url}/cache/config/update`,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("token"),
      },
      data: JSON.stringify(body),
    };

    try {
      const apiCall = await axios.request(options);
      const data = await apiCall;

      if (data.data.httpStatus === "OK") {
        setShowAddModal(false);
        setRefreshloading(!refreshLoading);
      }
      setApiData(data.data);
    } catch (e) {
      setApiData({
        httpStatus: "not ok",
        msg: "This is expection",
      });
      console.log(e);
    }
    setLoading(false);
  };
  return [apiData, loading, AddNewCacheManagement];
};

export default useUpdateCacheManagement;
