import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { travel_url } from "../../Host";
import loadergif from "../../Image/load.gif";
import { Link } from "react-router-dom";
import moment from "moment";
import Loading from "../../CustomComponent/Loading";
import Table from "../../CustomComponent/Table";

const PaymentInfo = ({ filterObj }) => {
  const [Payment, setPayment] = useState([]);
  const [load, setLoad] = useState(false);

  function GetPayment() {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var raw = "";

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/payments`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPayment(result);
        setLoad(true);
      })
      .catch((error) => {});
  }

  // _______________________________________________________________FILTER START___________________________________________________
  const freeAllbatch = [];
  const [status, setstatus] = useState(true);

  const [listTotal, setlistTotal] = useState([]);

  for (let i = 0; i < Payment.totalPages; i++) {
    freeAllbatch.push(i);
  }

  const [pagination_st, setPaginationst] = useState(0);

  function setPaginationLeft(e) {
    // if (pagination_st == 0) {
    // } else {
    //     setPaginationst(pagination_st - 1)
    // }
  }

  function setPaginationRight(e) {
    // if (pagination_st == freeAllbatch[0].length - 1) {
    // } else {
    //     setPaginationst(pagination_st + 1)
    // }
  }

  const pageblgid = useParams();

  const filter = async () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "Post",
      headers: myHeaders,
      body: JSON.stringify(filterObj),
      redirect: "follow",
    };

    // var requestOptions = {
    //   method: "Post",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // setstatus(true);

    const booking_data = await fetch(
      `${travel_url}/paymentDetailFilter?page=${pagination_st + 1}&size=20`,
      requestOptions
    );

    const booking_data_formet = await booking_data.json();

    if (booking_data_formet) {
      // setlistTotal(booking_data_formet);
      // setstatus(false);

      setPayment(booking_data_formet);
      setLoad(true);
    } else {
      setstatus(false);
    }
  };

  // _______________________________________________________________FILTER END___________________________________________________

  useEffect(() => {
    filter();
    setstatus(true);
  }, [filterObj, pageblgid.id]);

  useEffect(() => {
    // if(pageblgid.id != undefined || pageblgid.id){
    //  setPaginationst(pageblgid.id-1)
    // }
    window.scrollTo(0, 0);
  }, [pageblgid]);

  return (
    <>
      <div>
        <div className="container-fluid px-3 mb-4 mt-3">
          <div className="metaBox position-relative d-block border-0 smtb">
            {load ? (
              <div className="table-responsive lightthm-wrap scroll-auto">
                <table className="table table-lightthm text-left">
                  <thead className="bg-light">
                    <tr>
                      <th>Sr No.</th>
                      <th>Date</th>
                      <th>Gateway Name</th>
                      <th>Booking ID</th>
                      <th>Order Id</th>
                      <th>Amount</th>
                      <th>Transaction Id</th>
                      <th>Status</th>
                      <th>Payment Recieved By</th>
                    </tr>
                  </thead>

                  <tbody>
                    {Payment.content != null && Payment.content.length > 0 ? (
                      Payment.content.map((items, i) => (
                        <Table
                          data={[
                            i + 1,

                            moment(items.transactionDate).format(
                              "ddd, MMM-DD-yyyy, hh:mm A"
                            ),
                            items.gatewayName,
                            items.bookingId,
                            items.orderId,
                            items.amount.toFixed(2, 0),
                            items.transactionId.length > 20
                              ? `${items.transactionId.slice(0, 20)}...`
                              : items.transactionId,
                            <div>
                              <span
                                className={
                                  items.transactionStatus === "Success"
                                    ? "badge bg-success"
                                    : "badge bg-danger"
                                }
                              >
                                {items.transactionStatus}
                              </span>
                            </div>,
                            items.paymentReceivedBy.name,
                          ]}
                        />
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9}>No Payment Detail Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <Loading title="loading..." />
            )}

            <div>
              <div className="blog_bxrowpagi_empty"></div>
              {freeAllbatch.length > 0 ? (
                <div className="col-12 pt-3 pagination-footer">
                  <nav className="text-center ">
                    <ul className="pagination justify-content-end blog_bxrowpagi flex-wrap mb-0">
                      <li
                        className={
                          "nv page-item itemnv-1" +
                          (pagination_st == 0 ? " disabled" : "") +
                          (freeAllbatch.length == 1 ? " d-none" : "")
                        }
                      >
                        <Link
                          className="page-link"
                          to={"/payment/page=1"}
                          onClick={() => {
                            setPaginationst(0);
                          }}
                          aria-label="Previous"
                        >
                          <span aria-hidden="true">&laquo;</span>
                        </Link>
                      </li>
                      <li
                        className={
                          "nv page-item itemnv-2" +
                          (pagination_st == 0 ? " disabled" : "") +
                          (freeAllbatch.length == 1 ? " d-none" : "")
                        }
                      >
                        <Link
                          className="page-link"
                          to={"/payment/page=" + pagination_st}
                          onClick={(e) => {
                            setPaginationLeft(e);
                          }}
                          aria-label="Previous"
                        >
                          <span aria-hidden="true">‹</span>
                        </Link>
                      </li>

                      {new Array(freeAllbatch.length - 1)
                        .fill()
                        .map((_, index) => (
                          <li
                            key={index}
                            className={
                              "page-item dots" +
                              (pagination_st == index ? " active" : "") +
                              (pagination_st == index ? " activedot" : "") +
                              (pagination_st - 1 == index ? " activedot" : "") +
                              (pagination_st + 1 == index ? " activedot" : "") +
                              (pagination_st - 2 == index ? " activedot" : "") +
                              (pagination_st + 2 == index ? " activedot" : "")
                            }
                          >
                            <Link
                              className="page-link"
                              to={"/payment/page=" + (index + 1)}
                              onClick={() => {
                                setPaginationst(index);
                              }}
                            >
                              {index + 1}
                            </Link>
                          </li>
                        ))}
                      <li
                        className={
                          "page-item dots" +
                          (pagination_st == freeAllbatch.length - 1
                            ? " active"
                            : "") +
                          (pagination_st == freeAllbatch.length - 1
                            ? " activedot"
                            : "") +
                          (pagination_st - 1 == freeAllbatch.length - 1
                            ? " activedot"
                            : "") +
                          (pagination_st + 1 == freeAllbatch.length - 1
                            ? " activedot"
                            : "") +
                          (pagination_st - 2 == freeAllbatch.length - 1
                            ? " activedot"
                            : "") +
                          (pagination_st + 2 == freeAllbatch.length - 1
                            ? " activedot"
                            : "")
                        }
                      >
                        <Link
                          className="page-link"
                          to={"/payment/page=" + freeAllbatch.length}
                          onClick={() => {
                            setPaginationst(freeAllbatch.length - 1);
                          }}
                        >
                          {freeAllbatch.length}
                        </Link>
                      </li>
                      <li className="w-100 d-block d-lg-none"></li>
                      <li
                        className={
                          "nv page-item itemnv-3" +
                          (pagination_st == freeAllbatch.length - 1
                            ? " disabled"
                            : "") +
                          (freeAllbatch.length == 1 ? " d-none" : "")
                        }
                      >
                        <Link
                          className="page-link"
                          to={"/payment/page=" + (pagination_st + 2)}
                          onClick={(e) => {
                            setPaginationRight(e);
                          }}
                          aria-label="Next"
                        >
                          <span aria-hidden="true">›</span>
                        </Link>
                      </li>
                      <li
                        className={
                          "nv page-item itemnv-4" +
                          (pagination_st == freeAllbatch.length - 1
                            ? " disabled"
                            : "") +
                          (freeAllbatch.length == 1 ? " d-none" : "")
                        }
                      >
                        <Link
                          className="page-link"
                          to={"/payment/page=" + freeAllbatch.length}
                          onClick={() => {
                            setPaginationst(freeAllbatch.length - 1);
                          }}
                          aria-label="Next"
                        >
                          <span aria-hidden="true">&raquo;</span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              ) : (
                "No items found"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentInfo;
