import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import UserBar from "../Molecule/User/UserBar";
import Userinfo from "../Molecule/User/UserInfo";

const User = () => {
  const userparam = useParams();
  useEffect(() => {}, [userparam]);
  return (
    <div>
      {/* <UserBar Pagetitle={`Users`}/> */}
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1 mb-3">
        <Userinfo />
      </div>
    </div>
  );
};

export default User;
