import React from "react";
import axios from "axios";
import { travel_url } from "../../Host";
import { useState } from "react";

const useSaveRange = () => {
  const [saveData, setSaverange] = useState({});
  const [saveDataLoading, setSaverangeLoading] = useState(false);

  const saveRangeApiCall = async (body) => {
    setSaverangeLoading(true);
    const options = {
      method: "POST",
      url: `${travel_url}/saveRange`,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("token"),
      },
      data: body,
    };

    try {
      const apiData = await axios.request(options);
      const SaveRangeApiData = await apiData.data;
      setSaverange(SaveRangeApiData);
    } catch (e) {
      setSaverange({
        msg: "Server Error",
        httpStatus: "not ok",
      });
    } finally {
      setSaverangeLoading(false);
    }
  };
  return [saveData, saveDataLoading, saveRangeApiCall];
};

export default useSaveRange;
