import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import PopupSuccess from "./Atom/Popup/Popup.js";
import side from "../src/Image/side1.jpg";
import logoico from "../src/Image/logo-ico-lg.png";
import { travel_url } from "./Host.js";
import appClass from "./App";
import InputGroup from "react-bootstrap/InputGroup";
import Loading from "./CustomComponent/Loading.js";

// require("dotenv").config();

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Sucess, setSucess] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);
  let navigate = useNavigate();

  const baseUrl = `${travel_url}`;
  const url = useParams();

  window.onpopstate = function(event) {
    if (event) {
      window.location.href = "https://booking.travomint.com";
      // Code to handle back button or prevent from navigation
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      validateToken(localStorage.getItem("token"));
    } else {
      navigate("/login");
    }
  }, []);

  function validateToken(token) {
    axios
      .get(baseUrl + "/checkToken", {
        headers: {
          "x-api-key": token,
        },
      })
      .then(
        (resp) => {
          if (resp.data.httpStatus == "OK") {
            navigate("/Dashboard");
            setSucess(true);
          } else {
            navigate("/login");
          }
        },
        (err) => {
          navigate("/login");
        }
      );
  }

  async function Login() {
    setLoginLoader(true);
    var auth = "Basic " + btoa(email + ":" + password);
    var config = {
      method: "post",
      url: baseUrl + "/jwtToken",
      headers: {
        Authorization: auth,
      },
    };

    axios(config)
      .then(function(response) {
        // alert(response.data.msg);
        localStorage.setItem("token", response.data.data["x-api-key"]);
        localStorage.setItem("name", response.data.data["name"]);
        localStorage.setItem("phone", response.data.data["phone"]);
        localStorage.setItem("role", response.data.data["roles"][0]["role"]);
        localStorage.setItem("u_d", response.data.data["id"]);
        setSucess(true);
        setLoginLoader(false);
      })
      .catch(function(error) {
        setSucess(false);
        setLoginLoader(false);
      });
  }

  return (
    <main className="main-login">
      {loginLoader && <Loading title="Loading..." />}
      {Sucess ? <PopupSuccess /> : ""}
      <Form className="login-form" autoComplete="off">
        <div className="panel_form">
          <div className="text-center">
            <div className="icon-oflogo">
              <img src={logoico} width="60"></img>
            </div>
          </div>
          <h4 className="login-heading">LOGIN FORM</h4>

          <InputGroup className="mb-3">
            <InputGroup.Text>
              <i className="fa fa-user" aria-hidden="true"></i>
            </InputGroup.Text>
            <Form.Control
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Enter email"
              className="input-login"
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text>
              <i className="fa fa-lock" aria-hidden="true"></i>
            </InputGroup.Text>

            <Form.Control
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
              className="input-login"
            />
          </InputGroup>
          <Button onClick={() => Login()} className="btn-submit">
            Submit
          </Button>
          <div className="forgot">
            <span>
              Not have account?{" "}
              <a href="#" className="pull-right">
                Forgot password?
              </a>
            </span>
          </div>
        </div>
      </Form>
    </main>
  );
}

export default Login;
