import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../CustomComponent/Button";
import Inputs from "../../../CustomComponent/Inputs";
import Select from "../../../CustomComponent/Select";
import useUpdateSupplierPreferredAirline from "../../../hooks/SupplierPrefferedAirline/useUpdateSupplierPreferredAirline";
import { EditSupplierPreferredAirlineInputBox } from "../../../SmallApi/SupplierPreferredAirlineApi.js/EditSupplierPreferredAirline";
import ResultNotFound from "../../MarkUpDeatils/ErrorScreen/ResultNotFound";

const EditSupplierPreferredAirline = ({
  data,
  setRefreshloading,
  refreshLoading,
  setShowAddModal,
}) => {
  // State
  const [updatePreferredAirline, setUpdatePreferredAirline] = useState(data);

  // Hooks
  const [updateData, loading, setUpdateData] =
    useUpdateSupplierPreferredAirline();

  const onChange = (e) => {
    const { name, value } = e.target;
    setUpdatePreferredAirline({
      ...updatePreferredAirline,
      [name]: value.toUpperCase(),
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const body = {
      _id: updatePreferredAirline._id,
      airline:
        typeof updatePreferredAirline.airline === "string"
          ? updatePreferredAirline.airline.split(",")
          : updatePreferredAirline.airline,
      supplier: updatePreferredAirline.supplier,
      enabled: updatePreferredAirline.enabled,
    };

    await setUpdateData(body);
  };

  if (updateData.msg === "Server Error") {
    return <ResultNotFound title="Server Error" />;
  }

  useEffect(() => {
    if (Object.keys(updateData).length > 0) {
      if (updateData.httpStatus === "OK") {
        setRefreshloading(!refreshLoading);
        setShowAddModal(false);
      }
    }
  }, [updateData]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="text-center text-danger">
          {loading ? "Loading..." : updateData.msg}
        </div>
        <Row>
          {EditSupplierPreferredAirlineInputBox(data).map((item, i) => {
            const type = item.type;
            return (
              <Fragment key={item.id}>
                {["text", "date"].includes(type) ? (
                  <>
                    <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                      <Inputs
                        type={item.type}
                        name={item.name}
                        placeholder={item.placeholder}
                        label={item.label}
                        onChange={(e) => onChange(e)}
                        required={item.required}
                        defaultValue={item.defaultValue}
                        disabled={item.disabled}
                        classstyle="form-control"
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                      <Select
                        name={item.name}
                        placeholder={item.placeholder}
                        label={item.label}
                        onChange={(e) => onChange(e)}
                        optionValue={item.optionValue}
                        required={item.required}
                        defaultValue={item.defaultValue}
                        disabled={item.disabled}
                        classstyle="form-control"
                      />
                    </Col>
                  </>
                )}
              </Fragment>
            );
          })}
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2  btn-md "
              // onClick={dublicateHandleSubmit}
            />
          </div>
        </Row>
      </form>
    </>
  );
};

export default EditSupplierPreferredAirline;

// EditSupplierPreferredAirline
