import React, { useEffect, useState } from "react";
import { TbDatabaseSearch } from "react-icons/tb";
import {
  deleteIPWhiteListApi,
  getIPWhiteListApi,
} from "../../../Services/Api/IPWhitelistApi";
import AddIPWhitelistModal from "./AddIPWhitelistModal";
import Modals from "../../../CustomComponent/Modals";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";

const IPWhiltelist = () => {
  const [load, setLoad] = useState(false);
  const [IPlistData, setIPListData] = useState([]);
  const [modalopen, setModalopen] = useState(false);
  const fetchData = async () => {
    setLoad(true);
    try {
      const { data } = await getIPWhiteListApi();
      setIPListData(data);
    } catch (error) {}
    setLoad(false);
  };
  const handleDelete = async (id) => {
    try {
      const { data } = await deleteIPWhiteListApi(id);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  if (load) {
    return (
      <div className="col-12 mb-4 text-center font-semibod">
        Please wait for getting data....
      </div>
    );
  }
  return (
    <div>
      <div className="col-12 mb-4">
        <div className="metaBox position-relative d-block border-0 smtb">
          <h6 className="title-lightthm p-3 m-0">IP Whitelist</h6>
          {IPlistData.length > 0 && (
            <div className="w-100">
              <div className="table-responsive lightthm-wrap">
                <table className="table table-lightthm text-left">
                  <thead className="bg-light">
                    <tr>
                      <th width={20}>S.No.</th>
                      <th>IP</th>
                      <th>Date</th>
                      {/* <th>Delete</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {IPlistData.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.ip}</td>
                          <td>{new Date(item.date).toLocaleDateString()}</td>
                          {/* <td>
                            <div className="btni-sp">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft-danger"
                                  onClick={() => handleDelete(item._id)}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </OverlayTrigger>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {IPlistData.length === 0 && (
            <div className=" text-center text-muted mt-4">
              <TbDatabaseSearch size={40} />
              <p className="text text-bold mt-2">No Data Available!</p>
            </div>
          )}
          <div className="text-right px-3 py-4">
            <div className="btni-sp">
              <button
                className="btn-submit btnver-2 success"
                onClick={() => setModalopen(true)}
              >
                Add <i className="fa-solid fa-plus icon-add"></i>
              </button>
            </div>
          </div>

          <Modals
            show={modalopen}
            HandleClose={() => setModalopen(false)}
            ModalBody={
              <AddIPWhitelistModal
                fetchData={fetchData}
                setModalopen={setModalopen}
              />
            }
            title={"Add"}
            mclass="bdsList"
          />
        </div>
      </div>
    </div>
  );
};

export default IPWhiltelist;
