export const FilterCachePriceFeedInput = [
  {
    id: 1,
    name: "origin",
    type: "text",
    placeholder: "Enter origin",
    label: "Origin",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
  {
    id: 2,
    name: "destination",
    type: "text",
    placeholder: "Enter Destination",
    label: "Destination",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
  {
    id: 3,
    name: "outboundDate",
    type: "date",
    placeholder: "Enter Outbound Date",
    label: "Outbound Date",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
  {
    id: 4,
    name: "returnDate",
    type: "date",
    placeholder: "Enter Return Date",
    label: "Return Date",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
  {
    id: 5,
    name: "currency",
    type: "text",
    placeholder: "Enter Currency",
    label: "Currency",
    required: "true",
    disabled: false,
    defaultValue: "",
  },
];

export const FilterCachePriceFeedSelect = [
  {
    name: "tripType",
    label: "TripType",
    required: "true",
    disabled: false,
    defaultValue: "",
    optionValue: [
      {
        id: 1,
        name: "Select Your Trip",
        value: "",
      },
      {
        id: 2,
        name: "One Way",
        value: "OW",
      },
      {
        id: 3,
        name: "Round Way",
        value: "RT",
      },
    ],
  },
];
