import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Inputs from "../../CustomComponent/Inputs";
import axios from "axios";
import { travel_url } from "../../Host";
import swal from "sweetalert";
import Table from "../../CustomComponent/Table";
import { commonheader } from "../../utils/header";
import Select from "../../CustomComponent/Select";
import { useLocation } from "react-router-dom";

const FareIdentifier = ({ getSuppliersData }) => {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isedit, setISEdit] = useState(false);
  const [fareData, setFareData] = useState([]);
  const [inputdata, setInputData] = useState({
    api: "",
    airline: "",
    fareType: "PUB",
    fareList: "",
  });
  const { api, airline, fareType, fareList } = inputdata;
  const onChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputdata, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios({
        method: isedit ? "put" : "post",
        url: `${travel_url}/fareIdentifier/${isedit ? "update" : "save"}`,
        data: inputdata,
        headers: commonheader(),
      });
      swal(
        "",
        data.data
          ? `${isedit ? "Updated Successfully" : "Added Successfully"}`
          : data.msg,
        data.data ? "success" : "warning"
      );
      data.data && fetchData(false);
      handleClose();
      //   closeModal(false);
    } catch (error) {
      console.log(error);
      swal("Some Error", "Please try again after some time");
    }
  };
  const fetchData = async (isFirst) => {
    isFirst && setLoading(true);
    try {
      const { data } = await axios.get(`${travel_url}/fareIdentifier/get`, {
        headers: commonheader(),
      });
      setFareData(data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData(true);
  }, []);
  const handleEdit = (id) => {
    const newRes = fareData.find((Ele) => Ele._id === id);
    const { time, ...rest } = newRes;
    setInputData(rest);
    setISEdit(true);
    setShow(true);
  };
  function handleClose() {
    setShow(false);
    setISEdit(false);
    setInputData({ api: "", airline: "", fareType: "PUB", fareList: "" });
  }
  if (loading) {
    return <div className="w-100 text-center">Wait for getting data...</div>;
  }
  return (
    <>
      <div className="col-12 mb-4  ">
        <div className="metaBox position-relative d-block border-0 smtb">
          <h6 className="title-lightthm p-3 m-0">Airline Fare Identifier</h6>
          <div className="w-100">
            <div className="table-responsive lightthm-wrap border-top-0">
              <table className="table table-lightthm text-left">
                <thead className="bg-light">
                  <tr>
                    <th width={20}>S.N.</th>
                    <th>Api</th>
                    <th>Airline</th>
                    <th>Fare Type</th>
                    <th>Farelist</th>
                    {pathname === "/api-tracking" && <th>Edit</th>}
                  </tr>
                </thead>
                <tbody>
                  {fareData.map((item, i) => {
                    return (
                      <tr key={item._id}>
                        <td>{i + 1}</td>
                        <td>{item.api}</td>
                        <td>{item.airline}</td>
                        <td>{item.fareType}</td>
                        <td>{item.fareList}</td>
                        {pathname === "/api-tracking" && (
                          <td>
                            <div class="btni-sp">
                              <button
                                className={`btn btn-sm btn-soft-success`}
                                onClick={() => handleEdit(item._id)}
                              >
                                <div>
                                  <i
                                    class="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {pathname === "/api-tracking" && (
              <div className="text-right px-3 py-4">
                <button
                  className="btn-submit btnver-2 success"
                  onClick={() => setShow(!show)}
                >
                  Add <i className="fa-solid fa-plus icon-add"></i>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal centered show={show} onHide={handleClose}>
        {" "}
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="modal-Title">
              {isedit ? "Update" : "Add"} Fare Identifier
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="">
              <Col xs={12} md={6} xl={4}>
                <Select
                  name="api"
                  label="API"
                  firstName="Select"
                  optionValue={getSuppliersData}
                  onChange={(e) => onChange(e)}
                  value={api}
                  classstyle="form-control"
                  required={true}
                />
              </Col>
              <Col xs={12} md={6} xl={4}>
                <Inputs
                  type="text"
                  name="airline"
                  placeholder={"Enter the Airlines"}
                  label={"Airlines"}
                  onChange={(e) => onChange(e)}
                  required={true}
                  defaultValue={airline}
                  disabled={false}
                  classstyle="form-control"
                />
              </Col>
              <Col xs={12} md={6} xl={4}>
                {/* <Inputs
                  type="text"
                  name="fareType"
                  placeholder={"Enter the fare type"}
                  label={"Fare Type"}
                  onChange={(e) => onChange(e)}
                  required={true}
                  defaultValue={fareType}
                  disabled={false}
                  classstyle="form-control"
                /> */}
                <Select
                  name="fareType"
                  label="Fare Type"
                  optionValue={[
                    { id: 1, name: "Public", value: "PUB" },
                    { id: 2, name: "Private", value: "PVT" },
                  ]}
                  onChange={(e) => onChange(e)}
                  value={fareType}
                  classstyle="form-control"
                  required={true}
                />
              </Col>
              <Col xs={12} md={6} xl={4} className="mt-2">
                <Inputs
                  type="text"
                  name="fareList"
                  placeholder={"Enter the fair list"}
                  label={"Farelist"}
                  onChange={(e) => onChange(e)}
                  required={true}
                  defaultValue={fareList}
                  disabled={false}
                  classstyle="form-control"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
            <button type="submit" className="btn btn btn-submit btnver-2">
              submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default FareIdentifier;
