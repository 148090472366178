import React from "react";
import { Col } from "react-bootstrap";
import Inputs from "../../../CustomComponent/Inputs";

const OutboundItinerary = (props) => {
  const { changeFunction, i, value, minDate } = props;
  return (
    <>
      <Col xs={12} md={6} xl={4} className="mb-4">
        <Inputs
          name="origin"
          placeholder="Origin"
          type="text"
          label="Origin"
          onChange={(e) => changeFunction(e, i)}
          required={true}
          defaultValue={value.origin}
          disabled={false}
          classstyle="form-control"
        />
      </Col>
      <Col xs={12} md={6} xl={4} className="mb-4">
        <Inputs
          name="destination"
          placeholder="Destination"
          type="text"
          label="Destination"
          onChange={(e) => changeFunction(e, i)}
          required={true}
          defaultValue={value.destination}
          disabled={false}
          classstyle="form-control"
        />
      </Col>
      <Col xs={12} md={6} xl={4} className="mb-4">
        <Inputs
          name="marketingAirline"
          placeholder="marketingAirline"
          type="text"
          label="marketingAirline"
          onChange={(e) => changeFunction(e, i)}
          required={true}
          defaultValue={value.marketingAirline}
          disabled={false}
          classstyle="form-control"
        />
      </Col>
      <Col xs={12} md={6} xl={4} className="mb-4">
        <Inputs
          name="marketingFlightNumber"
          placeholder="marketingFlightNumber"
          type="text"
          label="marketingFlightNumber"
          onChange={(e) => changeFunction(e, i)}
          required={true}
          defaultValue={value.marketingFlightNumber}
          disabled={false}
          classstyle="form-control"
        />
      </Col>
      <Col xs={12} md={6} xl={4} className="mb-4">
        <Inputs
          name="operationAirline"
          placeholder="operationAirline"
          type="text"
          label="operationAirline"
          onChange={(e) => changeFunction(e, i)}
          required={true}
          defaultValue={value.operationAirline}
          disabled={false}
          classstyle="form-control"
        />
      </Col>
      <Col xs={12} md={6} xl={4} className="mb-4">
        <Inputs
          name="operatingFlightNumber"
          placeholder="operatingFlightNumber"
          type="text"
          label="operatingFlightNumber"
          onChange={(e) => changeFunction(e, i)}
          required={true}
          defaultValue={value.operatingFlightNumber}
          disabled={false}
          classstyle="form-control"
        />
      </Col>
      <Col xs={12} md={6} xl={4} className="mb-4">
        <Inputs
          name="depDate"
          placeholder="depDate"
          type="date"
          label="depDate"
          min={minDate}
          onChange={(e) => changeFunction(e, i)}
          required={true}
          defaultValue={value.depDate}
          disabled={false}
          classstyle="form-control"
        />
      </Col>
      <Col xs={12} md={6} xl={4} className="mb-4">
        <Inputs
          name="depTime"
          placeholder="depTime"
          type="time"
          label="depTime"
          onChange={(e) => changeFunction(e, i)}
          required={true}
          defaultValue={value.depTime}
          disabled={false}
          classstyle="form-control"
        />
      </Col>
      <Col xs={12} md={6} xl={4} className="mb-4">
        <Inputs
          name="arriTime"
          placeholder="arriTime"
          type="time"
          label="arriTime"
          onChange={(e) => changeFunction(e, i)}
          required={true}
          defaultValue={value.arriTime}
          disabled={false}
          classstyle="form-control"
        />
      </Col>
    </>
  );
};

export default OutboundItinerary;
