import axios from "axios";
import { commonheader } from "../../../utils/header";
import { travel_url } from "../../../Host";
const metaMappingInstance = axios.create({
  baseURL: travel_url,
});
metaMappingInstance.interceptors.request.use((config) => {
  const userToken = localStorage.getItem("token");
  if (userToken) {
    config.headers = commonheader();
  }
  return config;
});
export const getMetaMapSupplierAPi = () =>
  metaMappingInstance.get(`metaMapping/findAll`);
export const addMetaMapSupplierAPi = (data) =>
  metaMappingInstance.post(`metaMapping/save`, data);
export const deleteMetaMapSupplierAPi = (id) =>
  metaMappingInstance.delete(`metaMapping/delete?token=${id}`);
