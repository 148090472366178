import React, { useEffect, useState } from "react";
import { travel_url } from "../../Host";
import loadergif from "../../Image/load.gif";
import flighttravel_1 from "../../Image/flightico-1.png";
import flighttravel_2 from "../../Image/flightico-2.png";
import flighttravel_3 from "../../Image/flightico-3.png";
import flighttravel_4 from "../../Image/flightico-4.png";
import flighttravel_5 from "../../Image/flightico-5.png";
import flighttravel_6 from "../../Image/flightico-6.png";
import flighttravel_7 from "../../Image/flightico-7.png";
import gheadicon from "../../Image/search.png";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import AllBooking from "../../AllBooking.json";
import ReactJson from "react-json-view";
import FlightDetailsTable from "../../Atom/GoogleModalTables/FlightDetailsTable";
import BookingDetailsTable from "../../Atom/GoogleModalTables/BookingDetailsTable";
import Lottie from "react-lottie";
import loader from "../../loader.json";
import { AiFillEdit } from "react-icons/ai";
import PassengerDetailsTable from "../../Atom/GoogleModalTables/PassengerDetailsTable";
import TransactionTable from "../../Atom/GoogleModalTables/TransactionTables/TransactionTable";
import TransInputTable from "../../Atom/GoogleModalTables/TransactionTables/TransInputTable";
import Adult_Fare from "../../Atom/GoogleModalTables/FaresTables/Adult_Fare";
import Child_Fare from "../../Atom/GoogleModalTables/FaresTables/Child_Fare";
import Infant_Fare from "../../Atom/GoogleModalTables/FaresTables/Infant_Fare";
import ReturnAdultFare from "../../Atom/GoogleModalTables/FaresTables/ReturnFare/ReturnAdultFare";
import ReturnMarkup from "../../Atom/GoogleModalTables/FaresTables/ReturnFare/ReturnMarkup";
import ReturnTax from "../../Atom/GoogleModalTables/FaresTables/ReturnFare/ReturnTax";
import PassengerDetailsInput from "../../Atom/GoogleModalTables/PassengerDetailsInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../../CustomComponent/Loading";
import Button from "../../CustomComponent/Button";
import Table from "../../CustomComponent/Table";
import { CabinType } from "../../utils/CabinType";
import { DateFormater } from "../../utils/DateFormater";
import PassangerDetailsNew from "../../Atom/GoogleModalTables/PassangerDetailsNew";
import ResultNotFound from "../MarkUpDeatils/ErrorScreen/ResultNotFound";

const Googleflight = ({ filterObj, setGetGoogleData }) => {
  const navigate = useNavigate();
  const [modify, setModify] = useState([]);
  const [modifyStatus, setModifyStatus] = useState(false);
  const [Payment, setPayment] = useState([]);
  const [load, setLoad] = useState(true);
  const [isloading, setIsloading] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [pnr1, setPnr1] = useState("");
  const [ticketNumber1, setTicketNumber1] = useState("");
  const [pnr2, setPnr2] = useState("");
  const [ticketNumber2, setTicketNumber2] = useState("");
  const [transID, setTransID] = useState("");
  const [transAmount, setTransAmount] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");
  const [gatewayName, setGatewayName] = useState("");
  const [payDetails, setNewPayemntDetails] = useState(null);
  const [changeSubmitDetails, setChangeSubmitDetails] = useState(false);
  const [ChangeInputState, setChangeInputState] = useState(0);
  const [but, setPayDet] = useState("Update");
  const [noOfTravellers, setNoOfTravellers] = useState(0);
  const [newObj, setNewObj] = useState("");
  const [remarkData, setRemarkData] = useState("");
  const [remarkStatus, setremarkStatus] = useState(false);
  const [addRemarkStatus, setAddRemarkStatus] = useState(false);
  const [remarkText, setRemarktext] = useState("");
  const [orderIDValue, setOrderIDValue] = useState("");
  const [buttonAble, setButtonAble] = useState(true);
  const [newPaymentDetails, setNewPaymentDetails] = useState({
    transactionId: "",
    orderId: "",
    transactionStatus: "",
    gatewayName: "",
    amount: "",
    transactionDate: "",
  });

  const [errorHandle, setErrorHandle] = useState(0);

  // Sachin State

  const [getPassengerInputData, setGetPassengerInputData] = useState([]);

  const [inputList, setInputList] = useState([
    {
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      email:
        modify != null &&
        modify.passengerDetails != null &&
        modify.passengerDetails.length
          ? modify.passengerDetails[0]["email"]
          : "email@test",
      phone:
        modify != null &&
        modify.passengerDetails != null &&
        modify.passengerDetails.length
          ? modify.passengerDetails[0]["phone"]
          : "phone Test",
      address1: null,
      address2: null,
      city: null,
      state: null,
      country: "India",
      countryCode: "IN",
      zipCode: null,
      gender: "",
      passengerType: "",
      passportNumber: "",
      passportExpiry: "",
      passportIssued: null,
      passportIssuedCountry: "",
      gstNumber: "",
      gstCompany: "",
      ticketDetails: null,
    },
  ]);

  const AdultInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  if (show2 === true) {
    if (inputList.length !== noOfTravellers) {
      for (let i = noOfTravellers; i > 1; i--) {
        inputList.push({
          title: "",
          firstName: "",
          middleName: "",
          lastName: "",
          dob: "",
          email: "",
          phone: "",
          address1: null,
          address2: null,
          city: null,
          state: null,
          country: "India",
          countryCode: "IN",
          zipCode: null,
          gender: "",
          passengerType: "",
          passportNumber: "",
          passportExpiry: "",
          passportIssued: null,
          passportIssuedCountry: "",
          gstNumber: "",
          gstCompany: "",
          ticketDetails: null,
        });
      }
    }
  }

  const handleClose2 = () => {
    setNewPaymentDetails({
      transactionId: "",
      orderId: "",
      transactionStatus: "",
      gatewayName: "",
      amount: "",
      transactionDate: "",
    });
    setShow(false);
    setShow2(false);
    setInputList([]);
    // window.location.reload(true);
  };
  const handleShow2 = () => setShow2(true);

  function modifyUser(e) {
    if (e.paymentDetails.gatewayResponse === null) {
    } else if (e.paymentDetails.gatewayResponse.length === 0) {
    } else {
      const res = JSON.parse(e.paymentDetails.gatewayResponse);
      setGatewayName(e.paymentDetails.gatewayName);
      setTransactionStatus(res.STATUS.split("_")[1]);
      setTransAmount(res.TXNAMOUNT);
      setTransID(res.TXNID);
    }

    setModify(e);
    setInputList(e.passengerDetails);
    setModifyStatus(true);
    handleShow2();
    remark(e.orderId);
  }
  const TransactionId = (e) => {
    setTransID(e.target.value);
  };
  const TransactionAmt = (e) => {
    setTransAmount(e.target.value);
  };
  const TransactionStatus = (e) => {
    setTransactionStatus(e.target.value);
  };
  const changeGatewayName = (e) => {
    setGatewayName(e.target.value);
  };

  function remark(e) {
    setOrderIDValue(e);
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getRemarks?orderId=${e}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setRemarkData(result);
        setremarkStatus(true);
      })
      .catch((error) => console.log("error", error));
  }

  function openRemarkAdd() {
    setAddRemarkStatus(true);
  }
  function closeRemarkAdd() {
    setAddRemarkStatus(false);
  }

  function setSubmitRemark() {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      remarks: remarkText,
      orderId: orderIDValue,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/addRemarks`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.msg == "remarks saved successfully") {
          remark(orderIDValue);
          setAddRemarkStatus(false);
        }
      })
      .catch((error) => console.log("error", error));
  }

  const setPayemntDetails = () => {
    setNewPaymentDetails({
      transactionId: transID,
      orderId: modify.orderId,
      transactionStatus: transactionStatus,
      gatewayName: gatewayName,
      amount: transAmount,
      transactionDate: new Date(),
    });

    if (but === "Save" || but === "Update") {
      setNewPayemntDetails({
        transactionId: transID,
        orderId: modify.orderId,
        transactionStatus: transactionStatus,
        gatewayName: gatewayName,
        amount: transAmount,
        transactionDate: new Date(),
      });
      setChangeSubmitDetails(true);
      setPayDet("Edit");
      setChangeInputState(1);
    } else {
      setChangeInputState(0);
      setPayDet("Save");
    }
  };

  const settingObj = () => {
    setShow(true);
    let newDetailsObject;
    if (modify.tripType === "1") {
      const origin = modify.supplierFlight.onWordFlight.outBound[0].fromAirport;
      const dest = modify.supplierFlight.onWordFlight.outBound[0].toAirport;

      newDetailsObject = [
        {
          origin: origin,
          destination: dest,
          departureDate: null,
          terminal: null,
          pnr: pnr1,
          ticketNumber: ticketNumber1,
        },
      ];
    }
    if (modify.tripType === "2") {
      const origin = modify.supplierFlight.onWordFlight.outBound[0].fromAirport;
      const dest = modify.supplierFlight.onWordFlight.outBound[0].toAirport;

      newDetailsObject = [
        {
          origin: origin,
          destination: dest,
          departureDate: null,
          terminal: null,
          pnr: pnr1,
          ticketNumber: ticketNumber1,
        },
        {
          origin: origin,
          destination: dest,
          departureDate: null,
          terminal: null,
          pnr: pnr2,
          ticketNumber: ticketNumber2,
        },
      ];
    }

    var UpdatingDetails =
      modify.passengerDetails[0].firstName != ""
        ? modify.passengerDetails.map((item, index) => ({
            ...item,
            ticketDetails: newDetailsObject,
          }))
        : inputList.map((item, index) => ({
            ...item,
            ticketDetails: newDetailsObject,
          }));

    var currentObj = {
      ...modify,
      paymentDetails:
        payDetails == undefined || payDetails == null || !payDetails
          ? modify.paymentDetails
          : payDetails,
      passengerDetails: UpdatingDetails,
      bookingStatus: "SUCCESS",
      paymentStatus: "SUCCESS",
    };
    setNewObj(currentObj);
    UpdateTicketDetails(currentObj);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  /* -------------------------------------------------------------Api Call------------------------------------------------------------ */
  function GetPayment() {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/getAllBookingMaster`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.httpStatus === "OK") {
          setPayment(result);
          setLoad(false);
        }
      })
      .catch((error) => {});
  }

  const UpdateTicketDetails = async () => {
    const paymentDetailsNew =
      payDetails == undefined || payDetails == null || !payDetails
        ? modify.paymentDetails
        : payDetails;
    const { paymentDetails, bookingStatus, paymentStatus, ...rest } = modify;
    const newObject = {
      ...rest,
      paymentDetails: paymentDetailsNew,
      bookingStatus: "SUCCESS",
      paymentStatus: paymentDetailsNew.transactionStatus,
    };

    // console.log("newObject", newObject, paymentDetailsNew);
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");
    const body = JSON.stringify(newObject);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(`${travel_url}/updateBookingMaster`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.httpStatus === "OK") {
          // console.log("result", result);
          window.location.reload(true);
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => {});
  };

  const sendEmail = (orderId) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/sendEmail?orderId=${orderId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert(result.msg);
      })
      .then((error) => {});
  };

  /* -------------------------------------------------------------Api Call------------------------------------------------------------ */

  // _______________________________________________________________FILTER START___________________________________________________
  const freeAllbatch = [];
  const [status, setstatus] = useState(true);

  const [listTotal, setlistTotal] = useState([]);

  for (let i = 0; i < Payment.totalPages; i++) {
    freeAllbatch.push(i);
  }

  const [pagination_st, setPaginationst] = useState(0);

  function setPaginationLeft(e) {
    // if (pagination_st == 0) {
    // } else {
    //     setPaginationst(pagination_st - 1)
    // }
  }

  function setPaginationRight(e) {
    // if (pagination_st == freeAllbatch[0].length - 1) {
    // } else {
    //     setPaginationst(pagination_st + 1)
    // }
  }

  const pageblgid = useParams();

  const filter = async () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "Post",
      headers: myHeaders,
      body: JSON.stringify(filterObj),
      redirect: "follow",
    };

    try {
      const booking_data = await fetch(
        `${travel_url}/googleBookingFilter?page=${pagination_st + 1}&size=20`,
        requestOptions
      );
      const booking_data_formet = await booking_data.json();

      if (booking_data_formet) {
        setPayment(booking_data_formet);
        setGetGoogleData(booking_data_formet);
        setLoad(false);
      } else {
        setstatus(false);
      }
    } catch (e) {
      setErrorHandle(500);
      setstatus(false);
    }
  };

  // function ButtonOn(){
  //   if(pnr1 != ""){
  //     setButtonAble(false)
  //   }else{

  //   }
  // }

  function CheckButtonAble() {
    const name = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    if (
      inputList[0].firstName.match(name) &&
      inputList[0].lastName.match(name) &&
      inputList[0].title != "" &&
      inputList[0].dob != "" &&
      (inputList[0].gender == 1 || inputList[0].gender == 2) &&
      (inputList[0].passengerType == 1 ||
        inputList[0].passengerType == 2 ||
        inputList[0].passengerType == 3)
    ) {
      setButtonAble(false);
    }
  }

  // _______________________________________________________________FILTER END___________________________________________________

  useEffect(() => {
    filter();
    setLoad(true);
    window.scrollTo(0, 0);
  }, [filterObj, pageblgid.id]);

  const FunctionMerge = (modify) => {
    const paymenmtDeatils =
      payDetails == undefined || payDetails == null || !payDetails
        ? modify.paymentDetails
        : payDetails;
    if (
      (paymenmtDeatils.transactionStatus == "SUCCESS" ||
        paymenmtDeatils.transactionStatus == "success") &&
      modify.passengerDetails.length > 0
    ) {
      var status = false;
      var status2 = false;

      const passengerDetailsArray = modify.passengerDetails;

      for (var index = 0; index < passengerDetailsArray.length; index++) {
        if (passengerDetailsArray[index].passengerType == 1) {
          if (
            passengerDetailsArray[index].title != null &&
            passengerDetailsArray[index].title != "" &&
            passengerDetailsArray[index].firstName != null &&
            passengerDetailsArray[index].firstName != "" &&
            passengerDetailsArray[index].lastName != null &&
            passengerDetailsArray[index].lastName != "" &&
            passengerDetailsArray[index].dob != null &&
            passengerDetailsArray[index].dob != "" &&
            passengerDetailsArray[index].gender != null &&
            passengerDetailsArray[index].gender != "" &&
            passengerDetailsArray[index].passengerType != null &&
            passengerDetailsArray[index].passengerType != ""
          ) {
            status2 = true;
          } else {
            status2 = false;
            break;
          }
        } else {
          if (
            passengerDetailsArray[index].firstName != null &&
            passengerDetailsArray[index].firstName != "" &&
            passengerDetailsArray[index].lastName != null &&
            passengerDetailsArray[index].lastName != "" &&
            passengerDetailsArray[index].dob != null &&
            passengerDetailsArray[index].dob != "" &&
            passengerDetailsArray[index].gender != null &&
            passengerDetailsArray[index].gender != "" &&
            passengerDetailsArray[index].passengerType != null &&
            passengerDetailsArray[index].passengerType != ""
          ) {
            status2 = true;
          } else {
            status2 = false;
            break;
          }
        }
      }

      if (status2) {
        // for (var index = 0; index < modify.passengerDetails.length; index++) {
        //   var pp = index + 1;
        //   if (eval("pnr" + pp) != "" && eval("ticketNumber" + pp) != "") {
        //     status = true;
        //   } else {
        //     status = false;
        //     break;
        //   }
        // }
        for (let index = 0; index < passengerDetailsArray.length; index++) {
          const element = passengerDetailsArray[index];
          if (element.ticketDetails == null) {
            status = false;
          } else {
            for (let i = 0; i < element.ticketDetails.length; i++) {
              const abc = element.ticketDetails[i];
              if (
                abc.pnr == null ||
                abc.pnr == "" ||
                abc.ticketNumber == null ||
                abc.ticketNumber == ""
              ) {
                status = false;
              } else {
                status = true;
              }
            }
          }
        }
      } else {
        alert("Please check your fileds");
      }

      if (status) {
        // settingObj(modify);
        UpdateTicketDetails();
        // alert("Complete");
        // setShow(false);
      } else {
        alert(
          "Please provide return flight ticket detail. Pnr and Ticket No. in respected field with comma(,) seprated."
        );
      }

      // if (modify.passengerDetails.length == 2) {
      //   if (
      //     pnr1 != "" &&
      //     ticketNumber1 != "" &&
      //     pnr2 != "" &&
      //     ticketNumber2 != ""
      //   ) {
      //     settingObj(modify);
      //     alert("ARE YOU ss");
      //     setShow(false);
      //   } else {
      //     alert("Please Input Required Field");
      //   }
      // }
    }
    // setShow(false);
  };

  const StateFunMerge = () => (setChangeInputState(1), setPayemntDetails());

  const data = load
    ? ""
    : Payment.content.filter(
        (items) => items.supplierFlight.onWordFlight !== null
      );

  const totalPassenger =
    modify.length != 0 &&
    modify.supplierFlight.adults + modify.supplierFlight.child;

  if (errorHandle === 500) {
    return (
      <>
        <ResultNotFound title="Server Error" />
      </>
    );
  }

  return (
    <>
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1 mt-3">
        <div className="container-fluid px-3">
          {/* _________________________________GOOGLE BODY _______________________________________  */}
          <div className="metaBox position-relative">
            {load ? (
              <Loading title="loading..." />
            ) : (
              <div className="table-responsive lightthm-wrap  scroll-auto">
                <table className="table table-lightthm text-center">
                  <thead className="bg-light">
                    <tr>
                      <th>Sr.no</th>
                      <th>Order Id</th>
                      <th>Trip Type</th>
                      <th>Origin</th>
                      <th>Destination</th>
                      <th>Cabin Class</th>
                      <th>Airline</th>
                      <th>PNR</th>
                      <th>Total pax</th>
                      <th>Booking Status</th>
                      {/* <th>Success</th> */}
                      <th>Payment Status</th>
                      <th>Supplier</th>
                      <th>Source</th>

                      <th>Currency</th>

                      <th>Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((items, i) => {
                      const index = i + 1;
                      const orderId = (
                        <button
                          className="btn btn-sm btn-soft btn-soft-green"
                          onClick={(e) => {
                            modifyUser(items);
                          }}
                        >
                          {items.orderId}
                        </button>
                      );
                      const tripType =
                        items.tripType === "1" ? "Oneway" : "Twoway";
                      const origin =
                        items.supplierFlight.onWordFlight.outBound[0]
                          .fromAirport;
                      const destination =
                        items.supplierFlight.onWordFlight.outBound[
                          items.supplierFlight.onWordFlight.outBound.length - 1
                        ].toAirport;
                      const cabin = CabinType({
                        item: Number(
                          items.supplierFlight.onWordFlight.cabinClass
                        ),
                      });
                      const airline = items.supplierFlight.onWordFlight.airline;

                      const pnr =
                        items.passengerDetails === null ? (
                          "X"
                        ) : (
                          <>
                            {items.passengerDetails.map((items, i) =>
                              items.ticketDetails === null
                                ? "X"
                                : items.ticketDetails.map(
                                    (item, index) =>
                                      index === i && item.pnr + "   "
                                  )
                            )}
                          </>
                        );
                      const totalPax =
                        items.supplierFlight.adults +
                        items.supplierFlight.child +
                        items.supplierFlight.infant;
                      const bookingStatus = (
                        <span
                          className={
                            items.bookingStatus === "SUCCESS"
                              ? "badge bg-success"
                              : "badge bg-warningpending"
                          }
                        >
                          {items.bookingStatus}
                        </span>
                      );

                      const paymentStatus = (
                        <span
                          className={
                            items.paymentStatus === "PENDING"
                              ? "badge bg-warningpending"
                              : "badge bg-success"
                          }
                        >
                          {items.paymentStatus}
                        </span>
                      );

                      const gdsType = items.supplierFlight.gdsType;
                      const source = items.supplierFlight.source;
                      const currencyCode =
                        items.supplierFlight.onWordFlight.currencyCode;
                      const date = DateFormater({ date: items.creationDate });
                      return (
                        <Table
                          data={[
                            index,
                            orderId,
                            tripType,
                            origin,
                            destination,
                            cabin,
                            airline,
                            pnr,
                            totalPax,
                            bookingStatus,
                            paymentStatus,
                            gdsType,
                            source,
                            currencyCode,
                            date,
                          ]}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {/* _________________________________GOOGLE BODY ________________________________________  */}
        </div>
      </div>

      {/* _________________________________pagination_________________________________________ */}
      <div className="row BookingLayout">
        <div>
          <div className="blog_bxrowpagi_empty"></div>
          {freeAllbatch.length > 0 ? (
            <div className="col-12 pt-3 pagination-footer">
              <nav className="text-center ">
                <ul className="pagination justify-content-center blog_bxrowpagi flex-wrap mb-0">
                  <li
                    className={
                      "nv page-item itemnv-1" +
                      (pagination_st == 0 ? " disabled" : "") +
                      (freeAllbatch.length == 1 ? " d-none" : "")
                    }
                  >
                    <Link
                      className="page-link"
                      to={"/google-flight-booking/page=1"}
                      onClick={() => {
                        setPaginationst(0);
                      }}
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </Link>
                  </li>
                  <li
                    className={
                      "nv page-item itemnv-2" +
                      (pagination_st == 0 ? " disabled" : "") +
                      (freeAllbatch.length == 1 ? " d-none" : "")
                    }
                  >
                    <Link
                      className="page-link"
                      to={"/google-flight-booking/page=" + pagination_st}
                      onClick={(e) => {
                        setPaginationLeft(e);
                      }}
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">‹</span>
                    </Link>
                  </li>

                  {new Array(freeAllbatch.length - 1).fill().map((_, index) => (
                    <li
                      key={index}
                      className={
                        "page-item dots" +
                        (pagination_st == index ? " active" : "") +
                        (pagination_st == index ? " activedot" : "") +
                        (pagination_st - 1 == index ? " activedot" : "") +
                        (pagination_st + 1 == index ? " activedot" : "") +
                        (pagination_st - 2 == index ? " activedot" : "") +
                        (pagination_st + 2 == index ? " activedot" : "")
                      }
                    >
                      <Link
                        className="page-link"
                        to={"/google-flight-booking/page=" + (index + 1)}
                        onClick={() => {
                          setPaginationst(index);
                        }}
                      >
                        {index + 1}
                      </Link>
                    </li>
                  ))}
                  <li
                    className={
                      "page-item dots" +
                      (pagination_st == freeAllbatch.length - 1
                        ? " active"
                        : "") +
                      (pagination_st == freeAllbatch.length - 1
                        ? " activedot"
                        : "") +
                      (pagination_st - 1 == freeAllbatch.length - 1
                        ? " activedot"
                        : "") +
                      (pagination_st + 1 == freeAllbatch.length - 1
                        ? " activedot"
                        : "") +
                      (pagination_st - 2 == freeAllbatch.length - 1
                        ? " activedot"
                        : "") +
                      (pagination_st + 2 == freeAllbatch.length - 1
                        ? " activedot"
                        : "")
                    }
                  >
                    <Link
                      className="page-link"
                      to={"/google-flight-booking/page=" + freeAllbatch.length}
                      onClick={() => {
                        setPaginationst(freeAllbatch.length - 1);
                      }}
                    >
                      {freeAllbatch.length}
                    </Link>
                  </li>
                  <li className="w-100 d-block d-lg-none"></li>
                  <li
                    className={
                      "nv page-item itemnv-3" +
                      (pagination_st == freeAllbatch.length - 1
                        ? " disabled"
                        : "") +
                      (freeAllbatch.length == 1 ? " d-none" : "")
                    }
                  >
                    <Link
                      className="page-link"
                      to={"/google-flight-booking/page=" + (pagination_st + 2)}
                      onClick={(e) => {
                        setPaginationRight(e);
                      }}
                      aria-label="Next"
                    >
                      <span aria-hidden="true">›</span>
                    </Link>
                  </li>
                  <li
                    className={
                      "nv page-item itemnv-4" +
                      (pagination_st == freeAllbatch.length - 1
                        ? " disabled"
                        : "") +
                      (freeAllbatch.length == 1 ? " d-none" : "")
                    }
                  >
                    <Link
                      className="page-link"
                      to={"/google-flight-booking/page=" + freeAllbatch.length}
                      onClick={() => {
                        setPaginationst(freeAllbatch.length - 1);
                      }}
                      aria-label="Next"
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* _________________________________pagination_________________________________________ */}

      {/* ____________________________________MODAL___________________________  */}
      <Modal
        size="xl"
        className="modalbooknow-classic"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show2}
        onHide={handleClose2}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src={gheadicon} className="gheadicon" />
            oogle Fare Update
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="w-100">
            <div className=" tablemargin">
              {modifyStatus ? (
                <>
                  {/* -------------UNIQUE--------------------------- */}

                  {/* _____________________________________ START BOOKING DETAIL___________________________________________  */}
                  <div className="bookingDetail-Google">
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <img
                        style={{ width: 30, height: 30, marginRight: 10 }}
                        src={flighttravel_7}
                      />
                      <h3>&nbsp; Booking Details</h3>
                    </div>
                    <hr className="hr-line" />
                    <BookingDetailsTable
                      modify={modify}
                      payDetails={payDetails}
                      transactionStatus={transactionStatus}
                    />
                  </div>
                  {/* _____________________________________ END BOOKING DETAIL___________________________________________  */}

                  {/* ----------------------------------------------1st Case-------------------------------------- */}
                  <div className="w-100 text-right pt-3">
                    {(modify.paymentStatus === "SUCCESS" ||
                      modify.paymentStatus === "success") &&
                      modify.bookingStatus === "SUCCESS" && (
                        <div className="FooterButton">
                          <div className="text-right">
                            <button
                              type="button"
                              onClick={() => sendEmail(orderIDValue)}
                              className="btn btn-submit btnver-2 success  btn-md"
                            >
                              Send Email{" "}
                              <i
                                class="fa fa-paper-plane-o ms-1"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      )}
                  </div>

                  {/*----------------------------------------------1st Case-------------------------------------- */}

                  {/* _____________________________________ START Flight Details_____________________________________ */}
                  <div className="rowad-fl clearfix">
                    <hr className="hr-line my-4 gfbline"></hr>
                  </div>
                  <div className="flightDetail-Google gfaremodal mt-4">
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                      }}
                    >
                      <img
                        style={{ width: 30, height: 30, marginRight: 10 }}
                        src={flighttravel_6}
                      />
                      <h3>&nbsp; Flight Details</h3>
                    </div>
                    <hr className="hr-line" />
                    <FlightDetailsTable modify={modify} />
                  </div>
                  {/* _____________________________________ END Flight Details_____________________________________ */}

                  {/* _____________________________________ START Passanger Details_____________________________________ */}
                  <div className="rowad-fl clearfix">
                    <hr className="hr-line my-4 gfbline"></hr>
                  </div>
                  <div className="flightDetail-Google">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={flighttravel_5}
                        style={{ width: 40, height: 40, marginRight: 10 }}
                      />
                      <h3>Passenger Details</h3>
                    </div>

                    <hr className="hr-line mt-2 mb-3" />
                    <PassangerDetailsNew
                      data={modify}
                      show={show}
                      inputList={inputList}
                      setGetPassengerInputData={setGetPassengerInputData}
                    />
                  </div>
                  {/* _____________________________________ END Passanger Details_____________________________________ */}

                  {modify.bookingStatus !== "SUCCESS" &&
                    modify.paymentStatus === "SUCCESS" && (
                      <div className="FooterButton">
                        <div className="buttonClass">
                          {show ? (
                            <>
                              <button
                                type="button"
                                onClick={() => FunctionMerge(modify)}
                                className="btn btn-dark"
                              >
                                SAVE PNR
                              </button>
                            </>
                          ) : (
                            <>
                              {/* <button type="button" className="btn btn-dark">
                                Retrive PNR
                              </button> */}
                              <button
                                type="button"
                                onClick={() =>
                                  modify.paymentDetails !== null
                                    ? modify.paymentDetails
                                        .transactionStatus !== "success"
                                      ? setShow(true)
                                      : setShow(true)
                                    : setShow(true)
                                }
                                className="btn btn-dark ms-3"
                              >
                                Update PNR
                              </button>
                            </>
                          )}
                        </div>

                        <div className="buttonClass">
                          <button
                            type="button"
                            onClick={() => setShow(false)}
                            className="btn btn-dark"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}

                  {payDetails !== null
                    ? payDetails.transactionStatus == "SUCCESS" && (
                        <div className="FooterButton">
                          <div className="buttonClass">
                            {show ? (
                              <>
                                <button
                                  type="button"
                                  onClick={() => FunctionMerge(modify)}
                                  className="btn btn-dark"
                                  // disabled={buttonAble}
                                >
                                  SAVE PNR
                                </button>
                              </>
                            ) : (
                              <button
                                type="button"
                                onClick={() => setShow(true)}
                                className="btn btn-dark"
                              >
                                Add PNR
                              </button>
                            )}
                          </div>

                          <div className="buttonClass">
                            <button
                              type="button"
                              onClick={() => setShow(false)}
                              className="btn btn-dark"
                            >
                              Cancel
                            </button>
                          </div>
                          {/* <div className="buttonClass">
                              <button
                                type="button"
                                onClick={() => sendEmail(updatedObj.orderId)}
                                className="btn btn-dark"
                              >
                                Send Email
                              </button>
                            </div> */}
                        </div>
                      )
                    : ""}

                  {/* _____________________________________ REMARK SECTION  _____________________________________  */}
                  <div className="rowad-fl clearfix">
                    <hr className="hr-line my-4 gfbline"></hr>
                  </div>
                  <div className="flightDetail-Google">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={flighttravel_4}
                        style={{ width: 40, height: 40, marginRight: 10 }}
                      />
                      <h3>Remark Section </h3>
                    </div>

                    <hr className="hr-line" />
                    <div className="table-responsive lightthm-wrap scroll-auto w-100">
                      <table className="table table-lightthm text-left">
                        <thead className="head-remark">
                          <tr>
                            <th width="77">Sr No.</th>
                            <th>Remark</th>
                            <th>User</th>
                            <th>Date</th>
                          </tr>
                        </thead>

                        <tbody>
                          {remarkStatus ? (
                            <>
                              {remarkData.data != null &&
                              remarkData.data.length != 0 ? (
                                <>
                                  {remarkData.data.map((items, i) => (
                                    <>
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>{items.remarks}</td>
                                        <td>{items.user}</td>
                                        <td>
                                          {moment(items.modifiedDate).format(
                                            "ddd, MMM-DD-yyyy, hh:mm:ss A"
                                          )}{" "}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </>
                              ) : (
                                <tr>
                                  <td
                                    align="center"
                                    colspan="4"
                                    className="text-danger"
                                  >
                                    <i
                                      className="fas fa-exclamation-triangle me-2"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    No Remark Found
                                  </td>
                                </tr>
                              )}
                            </>
                          ) : (
                            <tr>
                              <td
                                align="center"
                                colspan="4"
                                className="text-danger"
                              >
                                <i
                                  className="fas fa-exclamation-triangle me-2"
                                  aria-hidden="true"
                                ></i>{" "}
                                No Remarks
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="remark-Google mt-4 clearfix">
                    {addRemarkStatus ? (
                      <div className="row">
                        <div className="col-12">
                          <div className="input-classicsite mt-0">
                            <div className="d-flex">
                              <input
                                type="text"
                                placeholder="Enter Remark "
                                onChange={(e) => setRemarktext(e.target.value)}
                                className="form-control font-16 "
                              />

                              {addRemarkStatus ? (
                                <Button
                                  title="Cancel"
                                  color="btn btn-submit btnver-2 danger mx-2  "
                                  onClick={() => closeRemarkAdd()}
                                />
                              ) : (
                                ""
                              )}

                              <button
                                className="btn btn-submit btnver-2 success   "
                                onClick={() => setSubmitRemark()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Button
                        title={
                          <>
                            <i className="fa-solid fa-plus icon-add"></i> Add
                            Remark
                          </>
                        }
                        color="btn-submit btnver-2 success "
                        other="addRemark"
                        onClick={() => openRemarkAdd()}
                      />
                    )}
                  </div>

                  {/* _____________________________________ REMARK SECTION  _____________________________________  */}
                  <div className="rowad-fl clearfix">
                    <hr className="hr-line my-4 gfbline"></hr>
                  </div>
                  {/*_______________________________________________ START TRANSACTION DETAIL ______________________________________________  */}
                  <div className="flightDetail-Google">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <img
                          src={flighttravel_3}
                          style={{ width: 30, height: 30, marginRight: 10 }}
                        />{" "}
                        <h3>Transactions</h3>
                      </div>
                    </div>

                    <hr className="hr-line" />
                    {/*----------------------------------------------2nd Case-------------------------------------  */}

                    {modify.bookingStatus !== "SUCCESS" &&
                    modify.paymentStatus !== "SUCCESS" ? (
                      <>
                        {modify.paymentDetails !== null ? (
                          modify.paymentDetails.transactionStatus !==
                          "success" ? (
                            <TransInputTable
                              TransactionId={TransactionId}
                              changeGatewayName={changeGatewayName}
                              TransactionAmt={TransactionAmt}
                              TransactionStatus={TransactionStatus}
                              StateFunMerge={StateFunMerge}
                              transID={transID}
                              gatewayName={gatewayName}
                              transAmount={transAmount}
                              transactionStatus={transactionStatus}
                              but={but}
                              modify={modify}
                              ChangeInputState={ChangeInputState}
                              setChangeInputState={setChangeInputState}
                            />
                          ) : (
                            <TransactionTable
                              modify={modify}
                              ChangeInputState={ChangeInputState}
                              setChangeInputState={setChangeInputState}
                            />
                          )
                        ) : (
                          <TransInputTable
                            TransactionId={TransactionId}
                            changeGatewayName={changeGatewayName}
                            TransactionAmt={TransactionAmt}
                            TransactionStatus={TransactionStatus}
                            StateFunMerge={StateFunMerge}
                            transID={transID}
                            gatewayName={gatewayName}
                            transAmount={transAmount}
                            transactionStatus={transactionStatus}
                            but={but}
                            modify={modify}
                            ChangeInputState={ChangeInputState}
                            setChangeInputState={setChangeInputState}
                          />
                        )}
                      </>
                    ) : (
                      <TransactionTable
                        modify={modify}
                        ChangeInputState={ChangeInputState}
                        setChangeInputState={setChangeInputState}
                      />
                    )}
                  </div>

                  {/*_______________________________________________ END TRANSACTION DETAIL ______________________________________________  */}

                  <div className="rowad-fl clearfix">
                    <hr className="hr-line my-4 gfbline"></hr>
                  </div>
                  {/*----------------------------------------------2nd Case-------------------------------------  */}
                  {modify.supplierFlight.returnFlight !== null ? (
                    <>
                      {/* ___________________________________TOTAL TABLE ___________________________________  */}
                      <div className="flightDetail-Google">
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <img
                            src="https://img.icons8.com/3d-fluency/344/money.png"
                            style={{ width: 30, height: 30, marginRight: 10 }}
                          />{" "}
                          <h3>Total Table</h3>
                        </div>
                        <hr className="hr-line" />
                        <div className="row">
                          {/*----------------------------------------Total Fare  Return------------------------------------  */}
                          <div className="col-lg-4 col-12 mb-2">
                            <div class="table-responsive lightthm-wrap scroll-auto w-100">
                              <table className="table table-lightthm text-left tbthm-2">
                                <tbody>
                                  <tr>
                                    <th scope="row">Total adult Fare</th>
                                    <td>
                                      {modify.supplierFlight.returnFlight.fare
                                        .adultFare +
                                        modify.supplierFlight.onWordFlight.fare
                                          .adultFare}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total child Fare</th>
                                    <td>
                                      {" "}
                                      {modify.supplierFlight.returnFlight.fare
                                        .childFare +
                                        modify.supplierFlight.onWordFlight.fare
                                          .childFare}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total infant Fare</th>
                                    <td>
                                      {" "}
                                      {modify.supplierFlight.returnFlight.fare
                                        .infantFare +
                                        modify.supplierFlight.onWordFlight.fare
                                          .infantFare}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Additional Fee (Diff)</th>
                                    <td>
                                      {" "}
                                      {modify.supplierFlight.returnFlight.fare
                                        .AdditionalTxnFeeOfrd +
                                        modify.supplierFlight.onWordFlight.fare
                                          .AdditionalTxnFeeOfrd}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total tax</th>
                                    <td>
                                      {" "}
                                      {modify.supplierFlight.returnFlight.fare
                                        .totalTax +
                                        modify.supplierFlight.onWordFlight.fare
                                          .totalTax}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total Markup</th>
                                    <td>
                                      {" "}
                                      {modify.supplierFlight.returnFlight.fare
                                        .totalMarkup +
                                        modify.supplierFlight.onWordFlight.fare
                                          .totalMarkup}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/*----------------------------------------Total Fare  Return------------------------------------  */}
                          {/*----------------------------------------Total Markup  Return------------------------------------  */}

                          <div className="col-lg-4 col-12 mb-2">
                            <div class="table-responsive lightthm-wrap scroll-auto w-100">
                              <table className="table table-lightthm text-left tbthm-2">
                                <tbody>
                                  <tr>
                                    <th scope="row">Total adult markup</th>
                                    <td>
                                      {modify.supplierFlight.returnFlight.fare
                                        .adultMarkup +
                                        modify.supplierFlight.onWordFlight.fare
                                          .adultMarkup}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total child markup</th>
                                    <td>
                                      {" "}
                                      {modify.supplierFlight.returnFlight.fare
                                        .childMarkup +
                                        modify.supplierFlight.onWordFlight.fare
                                          .childMarkup}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total infant markup</th>
                                    <td>
                                      {" "}
                                      {modify.supplierFlight.returnFlight.fare
                                        .infantMarkup +
                                        modify.supplierFlight.onWordFlight.fare
                                          .infantMarkup}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row"> Total GST Fare</th>
                                    <td>
                                      {" "}
                                      {modify.supplierFlight.returnFlight.fare
                                        .gstPrice +
                                        modify.supplierFlight.onWordFlight.fare
                                          .gstPrice}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total Base fare</th>
                                    <td>
                                      {" "}
                                      {modify.supplierFlight.returnFlight.fare
                                        .baseFare +
                                        modify.supplierFlight.onWordFlight.fare
                                          .baseFare}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Grand Total</th>
                                    <td>
                                      {" "}
                                      {modify.supplierFlight.returnFlight.fare
                                        .grandTotal +
                                        modify.supplierFlight.onWordFlight.fare
                                          .grandTotal}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/*----------------------------------------Total Markup  Return------------------------------------  */}
                          {/*----------------------------------------Total Tax  Return------------------------------------  */}

                          <div className="col-lg-4 col-12 mb-2">
                            <div class="table-responsive lightthm-wrap scroll-auto w-100">
                              <table className="table table-lightthm text-left tbthm-2">
                                <tbody>
                                  <tr>
                                    <th scope="row">Total adult tax</th>
                                    <td>
                                      {modify.supplierFlight.returnFlight.fare
                                        .adultTax +
                                        modify.supplierFlight.onWordFlight.fare
                                          .adultTax}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total child tax</th>
                                    <td>
                                      {" "}
                                      {modify.supplierFlight.returnFlight.fare
                                        .childTax +
                                        modify.supplierFlight.onWordFlight.fare
                                          .childTax}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total infant tax</th>
                                    <td>
                                      {" "}
                                      {modify.supplierFlight.returnFlight.fare
                                        .infantTax +
                                        modify.supplierFlight.onWordFlight.fare
                                          .infantTax}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th scope="row">Convenience Fees</th>
                                    <td>
                                      {" "}
                                      {/* {modify.supplierFlight.returnFlight.fare
                                    .convenienceFees +
                                    modify.supplierFlight.onWordFlight.fare
                                      .convenienceFees} */}
                                      {(modify.supplierFlight.returnFlight.fare
                                        .convenienceFees +
                                        modify.supplierFlight.onWordFlight.fare
                                          .convenienceFees) *
                                        totalPassenger}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th scope="row">Markup Id</th>
                                    <td>
                                      {" "}
                                      {
                                        modify.supplierFlight.returnFlight.fare
                                          .markupID
                                      }{" "}
                                      ,
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .markupID
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      <div className="extra_space">Extra</div>
                                    </th>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/*----------------------------------------Total Tax  Return------------------------------------  */}
                        </div>
                      </div>
                      {/* ___________________________________TOTAL TABLE ___________________________________  */}
                    </>
                  ) : (
                    <>
                      {/* ___________________________________TOTAL FARE ___________________________________  */}
                      <div className="flightDetail-Google">
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <img
                            src={flighttravel_2}
                            style={{ width: 30, height: 30, marginRight: 10 }}
                          />{" "}
                          <h3>Total Fare </h3>
                        </div>
                        <hr className="hr-line" />
                        <div className="row">
                          {/* --------------------------------Total Fare Oneway------------------------- */}
                          <div className="col-lg-4 col-12 mb-2">
                            <div className="table-responsive lightthm-wrap scroll-auto w-100">
                              <table className="table table-lightthm text-left tbthm-2">
                                <thead>
                                  <tr>
                                    <th scope="row">Total adult Fare</th>
                                    <td className="text-right">
                                      {modify.currency != null &&
                                      modify.currency == "INR" ? (
                                        <i className="fa-sharp fa-solid fa-indian-rupee-sign"></i>
                                      ) : (
                                        ""
                                      )}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .adultFare
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total child Fare</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .childFare
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total infant Fare</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .infantFare
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Additional Fee (Diff)</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .AdditionalTxnFeeOfrd
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total tax</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .totalTax
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total Markup</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .totalMarkup
                                      }
                                    </td>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                          </div>
                          {/* --------------------------------Total Fare Oneway------------------------- */}

                          {/* --------------------------------Total Markup Oneway------------------------- */}
                          <div className="col-lg-4 col-12 mb-2">
                            <div className="table-responsive lightthm-wrap scroll-auto w-100">
                              <table className="table table-lightthm text-left tbthm-2">
                                <thead>
                                  <tr>
                                    <th scope="row">Total adult markup</th>
                                    <td className="text-right">
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .adultMarkup
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total child markup</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .childMarkup
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total infant markup</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .infantMarkup
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">GST Fare</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .gstPrice
                                      }{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total Base fare</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .baseFare
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Grand Total</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .grandTotal
                                      }
                                    </td>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                          </div>

                          {/* --------------------------------Total Markup Oneway------------------------- */}

                          {/* --------------------------------Total Tax Oneway------------------------- */}
                          <div className="col-lg-4 col-12 mb-2">
                            <div className="table-responsive lightthm-wrap scroll-auto w-100">
                              <table className="table table-lightthm text-left tbthm-2">
                                <thead>
                                  <tr>
                                    <th scope="row">Total adult tax</th>
                                    <td className="text-right">
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .adultTax
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total child tax</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .childTax
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Total infant tax</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .infantTax
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">ConvenienceFees</th>
                                    <td className="text-right">
                                      {" "}
                                      {modify.supplierFlight.onWordFlight.fare
                                        .convenienceFees * totalPassenger}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Markup Id</th>
                                    <td className="text-right">
                                      {" "}
                                      {
                                        modify.supplierFlight.onWordFlight.fare
                                          .markupID
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      <div className="extra_space">Extra</div>
                                    </th>
                                    <td className="text-right"> </td>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                          </div>
                          {/* --------------------------------Total Tax Oneway------------------------- */}
                        </div>
                      </div>

                      {/* ___________________________________TOTAL FARE ___________________________________  */}
                    </>
                  )}

                  {/*______________________________________________________ FARE DATAIL ______________________________________________________   */}
                  <div className="rowad-fl clearfix">
                    <hr className="hr-line my-4 gfbline"></hr>
                  </div>
                  <div className="flightDetail-Google">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={flighttravel_1}
                        style={{ width: 30, marginRight: 6 }}
                      />

                      <h3>
                        Fare Details{" "}
                        <span className="badge ms-2 bdgsite">Onword Fare</span>
                      </h3>
                    </div>
                    <hr className="hr-line" />

                    {/* ________________________________ ONWORD FARE ________________________________  */}
                    <div className="row">
                      <div className="col-lg-4 col-12 mb-2">
                        <Adult_Fare modify={modify} />
                      </div>
                      <div className="col-lg-4 col-12 mb-2">
                        <Child_Fare modify={modify} />
                      </div>
                      <div className="col-lg-4 col-12 mb-2">
                        <Infant_Fare modify={modify} />
                      </div>
                    </div>
                    {/* ________________________________ ONWORD FARE ________________________________  */}

                    {/* ________________________________ RETURN FARE ________________________________  */}

                    {modify.supplierFlight.returnFlight === null ? (
                      ""
                    ) : (
                      <>
                        <span className="badge bdgsite mb-2 mt-5">
                          Return fare
                        </span>
                        <div className="row">
                          <div className="col-lg-4 col-12 mb-2">
                            <ReturnAdultFare modify={modify} />
                          </div>
                          <div className="col-lg-4 col-12 mb-2">
                            <ReturnMarkup modify={modify} />
                          </div>
                          <div className="col-lg-4 col-12 mb-2">
                            <ReturnTax modify={modify} />
                          </div>
                        </div>
                      </>
                    )}
                    {/* ________________________________ RETURN FARE ________________________________  */}
                  </div>

                  {/*______________________________________________________ FARE DATAIL ______________________________________________________   */}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ____________________________________MODAL___________________________  */}
    </>
  );
};

export default Googleflight;
