import React from "react";

const AddIconModal = ({ iconList, onClickIcon, icon }) => {
  return (
    <div>
      <div className="w-100 d-flex align-content-center gap-3 pb-3">
        {iconList.map((item, i) => (
          <div
            className={`iconCard cursor-pointer ${
              icon === item.svg && "active"
            }`}
            key={i}
            onClick={() => onClickIcon(item.svg)}
          >
            <img
              src={`data:image/svg+xml;base64,${btoa(item.svg)}`}
              width={30}
              height={30}
              alt="icon"
            />
            <p>{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddIconModal;
