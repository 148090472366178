import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Inputs from "../../../CustomComponent/Inputs";
import Button from "../../../CustomComponent/Button";
import { allProducts } from "../PaymentGateway/Modal/AddPaymentGateway";
import Select from "../../../CustomComponent/Select";
import Swal from "sweetalert2";
import { addGateWaySaveResApi } from "../../../Services/Api/gateSaveResponeMapping";

const AddSaveGatewayResModal = ({
  allSource,
  fetchData,
  setShowModal,
  isEdit,
}) => {
  const { time, ...newEditData } = isEdit || {};
  const [load, setLoad] = useState(false);
  const [fieldData, setFieldData] = useState(
    isEdit
      ? newEditData
      : {
          baseUrl: "",
          api: "",
          source: "",
          siteId: null,
          currency: "",
          product: "ALL",
        }
  );
  const { baseUrl, api, source, siteId, currency, product } = fieldData;
  console.log("sdjkksdksdk", fieldData);
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "currency") {
      value = value.toUpperCase();
    }
    setFieldData({ ...fieldData, [name]: value.trimStart() });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      const { data } = await addGateWaySaveResApi(fieldData);
      if (data.httpStatus === "OK") {
        fetchData();
        setShowModal(false);
      }
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
    setLoad(false);
  };
  return (
    <div>
      <div className="">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="source"
                label="Source"
                onChange={handleChange}
                optionValue={allSource.data}
                firstName={"Select"}
                required={true}
                classstyle="form-control"
                value={source}
                disabled={isEdit ? true : false}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="text"
                name="baseUrl"
                placeholder="Enter the base URL"
                label="Base URL"
                onChange={handleChange}
                required={true}
                classstyle="form-control"
                value={baseUrl}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="text"
                name="api"
                placeholder="Enter the API endpoint"
                label="API"
                onChange={handleChange}
                required={true}
                classstyle="form-control"
                value={api}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="number"
                name="siteId"
                placeholder="Enter the Site ID"
                label="Site ID"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={siteId}
                disabled={isEdit ? true : false}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="product"
                placeholder=""
                label="Product"
                onChange={handleChange}
                optionValue={allProducts}
                required={true}
                classstyle="form-control mt-4"
                value={product}
                disabled={isEdit ? true : false}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="text"
                name="currency"
                placeholder="Enter the currency code"
                label="Currency"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={currency}
                disabled={isEdit ? true : false}
              />
            </Col>
            <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
              <Button
                type="submit"
                title={load ? "Please Wait..." : "Submit"}
                disabled={load}
                color="btn btn-submit btnver-2  btn-md "
              />
            </div>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default AddSaveGatewayResModal;
