import axios from "axios";
import { commonheader } from "../../../../utils/header";
import { travel_url } from "../../../../Host";
const hotelCuponMgtInstance = axios.create({
  baseURL: travel_url,
});
hotelCuponMgtInstance.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      config.headers = commonheader();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const addHotelCouponApi = (data) =>
  hotelCuponMgtInstance.post(`/coupon/hotel/add`, data);
export const getHotelCouponApiData = (data) =>
  hotelCuponMgtInstance.post(`/coupon/hotel/filter`, data);
export const updateHotelCouponApi = (data) =>
  hotelCuponMgtInstance.post(`/coupon/hotel/update`, data);
