export const UpdateCouponMgtData = (soruceData, data, advanceField) => [
  {
    id: 1,
    name: "couponCode",
    pattern: "",
    type: "text",
    placeholder: "",
    label: "Coupon Code",
    required: true,
    disabled: false,
    defaultValue: data.couponCode,
  },
  {
    id: 2,
    name: "couponType",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Coupon Type",
    required: true,
    disabled: false,
    defaultValue: data.couponType,
    optionValue: [
      { id: 0, name: "Select", value: "" },
      { id: 1, name: "Promotional", value: "PROMOTIONAL" },
      { id: 2, name: "Other", value: "OTHER" },
    ],
  },
  {
    id: 3,
    name: "description",
    pattern: "",
    type: "textarea",
    placeholder: "",
    label: "Description  Text",
    required: true,
    disabled: false,
    defaultValue: data.description,
  },
  {
    id: 4,
    name: "siteId",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Site Name",
    required: true,
    disabled: false,
    defaultValue: data.siteId,
    optionValue: [{ id: 0, name: "Travomint", value: 6 }],
  },
  {
    id: 5,
    name: "source",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Meta Source",
    required: true,
    disabled: false,
    defaultValue: data.source,
    optionValue: [{ id: 0, name: "All", value: "ALL" }, ...soruceData],
  },
  {
    id: 6,
    name: "product",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Products",
    required: true,
    disabled: false,
    defaultValue: data.product,
    optionValue: [
      { id: 0, name: "All", value: "ALL" },
      { id: 1, name: "Flight", value: "FLIGHT" },
      { id: 2, name: "Hotel", value: "HOTEL" },
    ],
  },

  {
    id: 7,
    name: "discountApplicable",
    pattern: "",
    type: "Select",
    placeholder: "",
    label: "Discount Applicable",
    required: true,
    disabled: false,
    defaultValue: data.discountApplicable,
    optionValue: [
      { id: 0, name: "All", value: "ALL" },
      { id: 1, name: "Per Booking", value: "BOOKING" },
      { id: 2, name: "Per Ticket", value: "TICKET" },
      { id: 3, name: "Per Passenger", value: "PASSENGER" },
    ],
  },
  {
    id: 8,
    name: "minAmount",
    pattern: "",
    type: "text",
    placeholder: "",
    label: "Min. Transaction Amount",
    required: true,
    disabled: false,
    defaultValue: data.minAmount,
  },
  {
    id: 9,
    name: "discountType",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Discount Type",
    required: true,
    disabled: false,
    defaultValue: data.discountType,
    optionValue: [
      { id: 0, name: "Select", value: "" },
      { id: 1, name: "FLAT", value: "F" },
      { id: 2, name: "Percentage", value: "P" },
    ],
  },
  {
    id: 10,
    name: "discountOn",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Discount On",
    required: true,
    disabled: false,
    defaultValue: data.discountOn,
    optionValue: [
      { id: 0, name: "Select", value: "" },
      { id: 1, name: "Total Fare", value: "totalFare" },
      { id: 2, name: "Base Fare", value: "baseFare" },
    ],
  },
  {
    id: 11,
    name: "discountAmt",
    pattern: "",
    type: "number",
    placeholder: "Enter the discount value",
    label: "Discount Value",
    required: true,
    disabled: false,
    defaultValue: data.discountAmt,
  },
  {
    id: 12,
    name: "maxDiscount",
    pattern: "",
    type: "number",
    placeholder: "",
    label: "Maximum Discount",
    required: true,
    disabled: false,
    defaultValue: data.maxDiscount,
  },
  {
    id: 13,
    name: "originCountry",
    pattern: "",
    type: "text",
    placeholder: "E.g.IN,US,UK etc.",
    label: "Origin Country",
    required: true,
    disabled: false,
    defaultValue: data.originCountry,
  },
  {
    id: 14,
    name: "destinationCountry",
    pattern: "",
    type: "text",
    placeholder: "E.g.IN,US,UK etc.",
    label: "Destination Country",
    required: true,
    disabled: false,
    defaultValue: data.destinationCountry,
  },
  {
    id: 15,
    name: "currency",
    pattern: "",
    type: "text",
    placeholder: "Enter the currency code",
    label: "Currency",
    required: true,
    disabled: false,
    defaultValue: data.currency,
  },
  {
    id: 16,
    name: "useAbility",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Usability",
    required: true,
    disabled: true,
    defaultValue: data.newUser ? "onetime" : "mulitipletime",
    optionValue: [
      { id: 0, name: "Select", value: "" },
      { id: 1, name: "One Time", value: "onetime" },
      { id: 2, name: "Mulitiple Time", value: "mulitipletime" },
    ],
  },
  {
    id: 17,
    name: "useLimit",
    pattern: "",
    type: "number",
    placeholder: "Enter the number of coupons",
    label: "No. of Coupons",
    required: true,
    disabled: data.newUser ? true : false,
    defaultValue: data.useLimit,
  },
  {
    id: 18,
    name: "validFrom",
    pattern: "[a-zA-Z]+",
    type: "date",
    placeholder: "",
    label: "Validity From",
    required: true,
    disabled: false,
    defaultValue: data.validFrom,
  },
  {
    id: 19,
    name: "validTo",
    pattern: "[a-zA-Z]+",
    type: "date",
    placeholder: "",
    label: "Validity To",
    required: true,
    disabled: false,
    defaultValue: data.validTo,
  },
  {
    id: 20,
    name: "status",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Status",
    required: true,
    disabled: false,
    defaultValue: data.status,
    optionValue: [
      { id: 0, name: "Active", value: true },
      { id: 1, name: "Inactive", value: false },
    ],
  },
  {
    id: "head",
    name: "adHead",
    pattern: "",
    type: "heading",
    placeholder: "",
    label: "Advanced Coupon Section",
    required: advanceField ? true : false,
    disabled: true,
    defaultValue: "",
  },
  {
    id: 21,
    name: "originAirport",
    pattern: "",
    type: "text",
    placeholder: "E.g.DEL,BOM,PAT etc.",
    label: "Origin Airport",
    required: advanceField ? true : false,
    disabled: false,
    defaultValue: data.originAirport,
  },
  {
    id: 22,
    name: "destinationAirport",
    pattern: "",
    type: "text",
    placeholder: "E.g.DEL,BOM,PAT etc.",
    label: "Destination Airport",
    required: advanceField ? true : false,
    disabled: false,
    defaultValue: data.destinationAirport,
  },
  {
    id: 23,
    name: "airlines",
    pattern: "",
    type: "text",
    placeholder: "E.g.AI,UK,6E etc",
    label: "Airlines",
    required: advanceField ? true : false,
    disabled: false,
    defaultValue: data.airlines,
  },
  {
    id: 24,
    name: "stops",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Stops",
    required: advanceField ? true : false,
    disabled: false,
    defaultValue: data.stops,
    optionValue: [
      { id: 0, name: "All", value: 0 },
      { id: 1, name: "Direct Flight", value: 1 },
      { id: 2, name: "One Stop", value: 2 },
      { id: 3, name: "Two Stop", value: 3 },
      { id: 4, name: "Three Stop", value: 4 },
      { id: 5, name: "Four Stop", value: 5 },
      { id: 6, name: "Five Stop", value: 6 },
    ],
  },
  {
    id: 25,
    name: "cabin",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Cabin Type",
    required: advanceField ? true : false,
    disabled: false,
    defaultValue: data.cabin,
    optionValue: [
      { id: 0, name: "All", value: 0 },
      { id: 1, name: "Economy", value: 1 },
      { id: 2, name: "Premium Economy", value: 2 },
      { id: 3, name: "Business", value: 3 },
      { id: 4, name: "First", value: 4 },
    ],
  },
  {
    id: 26,
    name: "platform",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Platform",
    required: advanceField ? true : false,
    disabled: false,
    defaultValue: data.platform,
    optionValue: [
      { id: 0, name: "Both", value: "ALL" },
      { id: 1, name: "Website", value: "WEBSITE" },
      { id: 2, name: "Application", value: "APPLICATION" },
    ],
  },
  {
    id: 27,
    name: "paymentMode",
    pattern: "",
    type: "text",
    placeholder: "E.g.UPI,Credit,Debit etc.",
    label: "Payment Mode",
    required: advanceField ? true : false,
    disabled: false,
    defaultValue: data.paymentMode,
  },
  {
    id: 28,
    name: "capDays",
    pattern: "",
    type: "number",
    placeholder: "Enter the cap days",
    label: "Cap Days",
    required: advanceField ? true : false,
    disabled: false,
    defaultValue: data.capDays,
  },
  {
    id: 29,
    name: "travelFrom",
    pattern: "",
    type: "date",
    placeholder: "",
    label: "Travel From",
    required: false,
    disabled: false,
    defaultValue: data.travelFrom,
  },
  {
    id: 30,
    name: "travelTo",
    pattern: "",
    type: "date",
    placeholder: "",
    label: "Travel To",
    required: false,
    disabled: false,
    defaultValue: data.travelTo,
  },
  {
    id: 31,
    name: "dayOfWeek",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Specific Days",
    required: advanceField ? true : false,
    disabled: false,
    defaultValue: data.dayOfWeek,
    optionValue: [
      { id: 0, name: "All", value: 0 },
      { id: 1, name: "Monday", value: 1 },
      { id: 2, name: "Tuesday", value: 2 },
      { id: 3, name: "Wednesday", value: 3 },
      { id: 4, name: "Thursday", value: 4 },
      { id: 5, name: "Friday", value: 5 },
      { id: 6, name: "Saturday", value: 6 },
      { id: 7, name: "Sunday", value: 7 },
    ],
  },
  {
    id: 32,
    name: "dateOfMonth",
    pattern: "",
    type: "select",
    placeholder: "",
    label: "Date Of Month",
    required: advanceField ? true : false,
    disabled: false,
    defaultValue: data.dateOfMonth,
    optionValue: Array(32)
      .fill()
      .map((item, i) => {
        return { id: i, name: i, value: i };
      }),
  },
  {
    id: 33,
    name: "userIds",
    pattern: "",
    type: "text",
    placeholder: "E.g.2,10,6 etc.",
    label: "User ID",
    required: false,
    disabled: false,
    defaultValue: data.userIds ? data.userIds : null,
  },
];
