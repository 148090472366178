import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { decryptDataWithMode } from "../../../utils/decryptData";

const RetrieveTable = ({ getRetrieveData }) => {
  const [view, setView] = useState(false);
  const [retrieveData, setRetriveData] = useState(getRetrieveData);
  const {
    firstName,
    lastName,
    cardType,
    street,
    city,
    state,
    country,
    zip,
    ccn,
    cvv,
    ccexp,
    orderId,
    currency,
    amount,
  } = retrieveData;
  const decyptMethodCall = () => {
    setView(true);
    const scrKey = "ZEhKaGRtOVFTRkJEVWsweU1ESXpNalJ3WVhsdFpXNTA=";
    const decryCnn = decryptDataWithMode(ccn, scrKey, false);
    const decryExp = decryptDataWithMode(ccexp, scrKey, false);
    const decryCvv = decryptDataWithMode(cvv, scrKey, false);
    setRetriveData({
      ...retrieveData,
      ccn: decryCnn,
      ccexp: decryExp,
      cvv: decryCvv,
    });
    console.log("DSdsssssss", decryCnn, decryCvv, decryExp);
  };
  const backToEncrypt = () => {
    setView(false);
    setRetriveData(getRetrieveData);
  };
  return (
    <div className="mt-5">
      {" "}
      <Table responsive className="rwd-table">
        <tbody>
          <tr>
            <th>S.No.</th>
            <th>Order ID</th>
            <th>Card Type</th>
            <th>Card Number</th>
            <th>Expiry Date</th>
            <th>CVV</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>Country</th>
            <th>Zipcode</th>
            <th>Amount</th>
            <th>Currency</th>
            <th>View All</th>
          </tr>
          <tr>
            <td>1</td>
            <td>{orderId}</td>
            <td>{cardType}</td>
            <td>{view ? ccn : "XXXXXXXX"}</td>
            <td>{view ? ccexp : "XXXX"}</td>
            <td>{view ? cvv : "XXXX"}</td>
            <td>{firstName}</td>
            <td>{lastName}</td>
            <td>{street}</td>
            <td>{city}</td>
            <td>{state}</td>
            <td>{country}</td>
            <td>{zip}</td>
            <td>{amount}</td>
            <td>{currency}</td>
            <td className="">
              <i
                className={`fa-solid ${
                  view ? "fa-eye-slash" : "fa-eye"
                }  cursor-pointer`}
                onClick={view ? backToEncrypt : decyptMethodCall}
              ></i>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default RetrieveTable;
