import React, { useEffect, useState } from "react";
import Loading from "../../../CustomComponent/Loading";
import loader from "../../../Image/93134-not-found.json";
import Lottie from "react-lottie";
import Table from "../../../CustomComponent/Table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import Modals from "../../../CustomComponent/Modals";
import {
  deleteInsuranceHandleApi,
  getInsuranceDataApi,
} from "../../../Services/Api/InsuranceHandleApi";
import AddInsuranceHandler from "../InsurnaceHandlerMarkup/AddInsuranceHandler";
import AddInsuranceModal from "./Modal/AddInsuranceModal";
import {
  deleteInsuranceApi,
  getInsurnaceAllDataApi,
} from "../../../Services/Api/insurance";
import ViewInsurancePlanNPremium from "./Tables/ViewInsurancePlanNPremium";
import ShowPlanBenefit from "./ShowPlanBefefit";
const Insurance = ({ allSource, allSupplier }) => {
  const [planPremiumObj, setplanPremiumObj] = useState({
    getPlanObj: {},
    getPremiumObj: {},
    isClick: false,
    existBenefitId: "",
  });
  const { getPlanObj, getPremiumObj, isClick, existBenefitId } = planPremiumObj;
  const [isEdit, setIsEdit] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [getInsuranceData, setGetInsuranceData] = useState([]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await getInsurnaceAllDataApi();
      setGetInsuranceData(data.data ? data.data : []);
    } catch (error) {}
    setLoading(false);
  };
  // ----------------------------------------------Delete API CALL------------------------------------------

  const handleDeleteApi = async (id) => {
    try {
      const { data } = await deleteInsuranceApi(id);
      data.httpStatus === "OK" && fetchData();
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="w-100">
      <div className="container-fluid px-3">
        <div className="metaBox position-relative d-block border-0 smtb">
          {loading && <Loading title="loading..." />}
          {getInsuranceData.length <= 0 ? (
            <>
              <Lottie options={defaultOptions} height={400} width={400} />
              <h6> Click on add button to add Meta Mapping Supplier</h6>
            </>
          ) : (
            <>
              <div className="w-100">
                <div className="table-responsive lightthm-wrap  border-top-0">
                  <table className="table table-lightthm text-left">
                    <thead className="bg-light">
                      <tr>
                        <th width={20}>S.no.</th>
                        <th>Source</th>
                        <th>Site ID</th>
                        <th>Currency</th>
                        <th>Insurance Provider</th>
                        <th>Insurance Name</th>
                        <th>Travel Category</th>
                        <th>Insurance Plan</th>
                        <th>Plan Benefits</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getInsuranceData.map((item, i) => (
                        <Table
                          i={i}
                          data={[
                            i + 1,
                            item.source,
                            item.siteId,
                            item.currency,
                            item.insuranceProvider,
                            item.insuranceName,
                            item.travelCategory,
                            <div className="ins_tab_btn">
                              <p className="">{item.insurancePlan.name}</p>
                              <span
                                onClick={() => {
                                  setplanPremiumObj({
                                    ...planPremiumObj,
                                    getPlanObj: item.insurancePlan,
                                    getPremiumObj: item.premium,
                                    isClick: true,
                                  });
                                  setShowModal(true);
                                }}
                              >
                                Click for insurnace plan and premium
                              </span>
                            </div>,
                            <div className="btni-sp">
                              <button
                                className="btn btn-sm btn-soft-success"
                                onClick={() => {
                                  setShowModal(true);
                                  setplanPremiumObj({
                                    ...planPremiumObj,
                                    isClick: false,
                                    existBenefitId: item.insurancePlan.planCode,
                                  });
                                }}
                              >
                                <i className="fa-regular fa-eye"></i>
                              </button>
                            </div>,
                            <div className="btni-sp">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Edit</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft-success"
                                  onClick={() => {
                                    setIsEdit(item);
                                    setShowModal(true);
                                    setplanPremiumObj({
                                      ...planPremiumObj,
                                      isClick: false,
                                      existBenefitId: "",
                                    });
                                  }}
                                >
                                  <i className="fa-solid fa-pencil"></i>
                                </button>
                              </OverlayTrigger>
                            </div>,
                            <div className="btni-sp">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft-danger"
                                  onClick={() => handleDeleteApi(item._id)}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </OverlayTrigger>
                            </div>,
                          ]}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          <div className="text-right px-3 py-4">
            <button
              className="btn-submit btnver-2 success"
              onClick={() => {
                setShowModal(true);
                setplanPremiumObj({
                  ...planPremiumObj,
                  isClick: false,
                  existBenefitId: "",
                });
                setIsEdit("");
              }}
            >
              Add <i className="fa-solid fa-plus icon-add"></i>
            </button>
          </div>
        </div>
      </div>
      <Modals
        show={showModal}
        backdrop={false}
        HandleClose={() => setShowModal(false)}
        ModalBody={
          isClick ? (
            <ViewInsurancePlanNPremium
              insurncePlanObj={getPlanObj}
              premiumObj={getPremiumObj}
            />
          ) : existBenefitId ? (
            <ShowPlanBenefit planCode={existBenefitId} />
          ) : (
            <AddInsuranceModal
              allSource={allSource}
              fetchData={fetchData}
              setShowModal={setShowModal}
              isEdit={isEdit}
            />
          )
        }
        title={
          isClick || existBenefitId
            ? ""
            : isEdit
            ? "Update"
            : "Add" + " Insurance"
        }
      />
    </div>
  );
};

export default Insurance;
