import axios from "axios";
import { travel_url } from "../../Host";

const AddMarkup = async (data) => {
  try {
    const options = {
      method: "POST",
      url: `${travel_url}/travomint/addMarkup`,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("token"),
      },
      data: data,
    };

    const addMarkup = await axios.request(options);
    const markupData = await addMarkup.data;
    return markupData;
  } catch (err) {
    return "error";
  }
};

export default AddMarkup;
