import React from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import { FareRuleFilterApi } from "../../SmallApi/TravoFAreRuleSmallApi/FareRuleFilterApi";
import { Fragment } from "react";
import Inputs from "../../CustomComponent/Inputs";
import DateInput from "../../CustomComponent/DateInput";
import Select from "../../CustomComponent/Select";
import Button from "../../CustomComponent/Button";

const FareRuleFilter = ({
  isfilter,
  supplierList,
  metaList,
  filterdata,
  setFilterData,
  fetchData,
}) => {
  function onChange(e) {
    const { name, value } = e.target;
    setFilterData({ ...filterdata, [name]: value });
  }
  // console.log("first",filterdata)
  return (
    <div>
      <Collapse in={isfilter}>
        <div id="example-collapse-text">
          <Row className="align-items-end">
            {FareRuleFilterApi(supplierList, metaList).map((item, i) => {
              const type = item.type;
              return (
                <Fragment key={item.id}>
                  {["text", "number"].includes(type) ? (
                    <>
                      <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                        <Inputs
                          type={item.type}
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => onChange(e)}
                          required={item.required}
                          defaultValue={item.defaultValue}
                          disabled={item.disabled}
                          classstyle="form-control"
                        />
                      </Col>
                    </>
                  ) : type === "date" ? (
                    <>
                      <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                        <DateInput
                          type={item.type}
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => onChange(e)}
                          required={item.required}
                          defaultValue={item.defaultValue}
                          disabled={item.disabled}
                          classstyle="form-control"
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                        <Select
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => onChange(e)}
                          optionValue={item.optionValue}
                          required={item.required}
                          defaultValue={item.defaultValue}
                          disabled={item.disabled}
                          classstyle="form-control"
                        />
                      </Col>
                    </>
                  )}
                </Fragment>
              );
            })}
            <Col xs={12} md={6} xl={12} className="mb-4">
              <Button
                title="Submit"
                color="btn-submit btnver-2"
                onClick={fetchData}
                style={{ window: "100px" }}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export default FareRuleFilter;
