import React, { useEffect, useState } from "react";
import Loading from "../../../CustomComponent/Loading";
import loader from "../../../Image/93134-not-found.json";
import Lottie from "react-lottie";
import Table from "../../../CustomComponent/Table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import Modals from "../../../CustomComponent/Modals";
import AddSaveGatewayResModal from "./AddSaveGatewayResModal";
import {
  deleteGatewaySaveResApi,
  getGatewaySaveResApi,
} from "../../../Services/Api/gateSaveResponeMapping";
import Select from "../../../CustomComponent/Select";
import Inputs from "../../../CustomComponent/Inputs";
import { allProducts } from "../PaymentGateway/Modal/AddPaymentGateway";
const GateSaveResponseMap = ({ allSource, allSupplier }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [isEdit, setIsEdit] = useState();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [allResult, setAllResult] = useState([]);
  const [saveGateResData, setSaveGateResData] = useState([]);
  const [filterData, setfilterData] = useState({
    source: "",
    product: "",
    siteId: "",
    currency: "",
  });
  const { source, product, siteId, currency } = filterData;

  const fetchData = async () => {
    try {
      const { data } = await getGatewaySaveResApi();
      if (data.data) {
        setSaveGateResData(data.data);
        setAllResult(data.data);
      } else {
        setSaveGateResData([]);
        setAllResult([]);
      }
    } catch (error) {}
    setLoading(false);
    setfilterData({
      source: "",
      product: "",
      siteId: "",
      currency: "",
    });
  };
  // ----------------------------------------------Delete API CALL------------------------------------------

  const handleDeleteApi = async (id) => {
    try {
      const { data } = await deleteGatewaySaveResApi(id);
      data.httpStatus === "OK" && fetchData();
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
  };
  // ---------------------------------Handle Filter-----------------
  const handleFilter = (e) => {
    let { name, value } = e.target;
    setfilterData({ ...filterData, [name]: value });
  };
  useEffect(() => {
    fetchData();
  }, []);
  // ------------------------Filter Logic-----------------
  useEffect(() => {
    let newRes = [...allResult];
    if (source) {
      newRes = newRes.filter((it) => it.source === source);
    }
    if (siteId) {
      newRes = newRes.filter((it) => it.siteId === +siteId);
    }
    if (product) {
      newRes = newRes.filter((it) => it.product === product);
    }
    if (currency) {
      newRes = newRes.filter((it) => it.currency === currency);
    }
    setSaveGateResData(newRes);
  }, [filterData]);
  return (
    <div className="w-100">
      <div className="container-fluid px-3">
        <div className="metaBox position-relative d-block border-0 smtb">
          {loading && <Loading title="loading..." />}
          {saveGateResData.length <= 0 && allResult.length === 0 ? (
            <>
              <Lottie options={defaultOptions} height={400} width={400} />
              <h6> Click on add button to add save gateway mapping</h6>
            </>
          ) : (
            <>
              <div className="w-100">
                <div className="table-responsive lightthm-wrap  border-top-0">
                  <table className="table table-lightthm text-left">
                    <thead className="bg-light">
                      <tr>
                        <th width={20}>S.no.</th>
                        <th>
                          <Select
                            label=""
                            firstName="Select Source"
                            name="source"
                            value={source}
                            optionValue={allSource.data}
                            onChange={handleFilter}
                            classstyle="form-control"
                            style={{ width: "150px" }}
                          />
                        </th>
                        <th>
                          {" "}
                          <Inputs
                            name="siteId"
                            value={siteId}
                            placeholder={"Site ID"}
                            onChange={handleFilter}
                            classstyle="form-control"
                            style={{
                              width: "80px",
                              height: "100%",
                              padding: "7px 4px",
                            }}
                          />
                        </th>
                        <th>
                          <Inputs
                            name="currency"
                            value={currency}
                            placeholder={"Currency"}
                            onChange={handleFilter}
                            classstyle="form-control"
                            style={{
                              width: "100px",
                              height: "100%",
                              padding: "7px 4px",
                            }}
                          />
                        </th>
                        <th>
                          <Select
                            name="product"
                            value={product}
                            firstName="Select Product"
                            optionValue={allProducts}
                            onChange={handleFilter}
                            classstyle="form-control"
                            style={{ width: "150px" }}
                          />
                        </th>
                        <th>Base URL</th>
                        <th>API</th>
                        <th>Time</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {saveGateResData.map((item, i) => (
                        <Table
                          i={i}
                          data={[
                            i + 1,
                            item.source,
                            item.siteId,
                            item.currency,
                            item.product,
                            item.baseUrl,
                            item.api,
                            new Date(item.time).toLocaleString(),
                            <div className="btni-sp">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Edit</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft-success"
                                  onClick={() => {
                                    setIsEdit(item);
                                    setShowModal(true);
                                  }}
                                >
                                  <i className="fa-solid fa-pencil"></i>
                                </button>
                              </OverlayTrigger>
                            </div>,
                            <div className="btni-sp">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft-danger"
                                  onClick={() => handleDeleteApi(item._id)}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </OverlayTrigger>
                            </div>,
                          ]}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          <div className="text-right px-3 py-4">
            <button
              className="btn-submit btnver-2 success"
              onClick={() => {
                setShowModal(true);
                setIsEdit("");
              }}
            >
              Add <i className="fa-solid fa-plus icon-add"></i>
            </button>
          </div>
        </div>
      </div>
      <Modals
        show={showModal}
        HandleClose={() => setShowModal(false)}
        ModalBody={
          <AddSaveGatewayResModal
            allSource={allSource}
            setShowModal={setShowModal}
            fetchData={fetchData}
            isEdit={isEdit}
          />
        }
        title={isEdit ? "Update" : "Add" + " Save Gateway Mapping"}
      />
    </div>
  );
};

export default GateSaveResponseMap;
