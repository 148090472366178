import React from "react";

const SvgIcons = ({ width, height, path1, path2 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      className="bi bi-exclamation-triangle text-danger mb-2"
      viewBox="0 0 16 16"
    >
      <path d={path1} />
      <path d={path2} />
    </svg>
  );
};

export default SvgIcons;
