import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Inputs from "../../../CustomComponent/Inputs";
import Button from "../../../CustomComponent/Button";
import { addIconApi } from "../../../Services/Api/IconHandlerApi";
import Swal from "sweetalert2";

const AddSvgIconHandle = ({ fetchData, setShowModal }) => {
  const [loading, setLoading] = useState(false);
  const [iconName, setIconName] = useState("");
  const [svg, setSvg] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await addIconApi({
        name: iconName,
        svg: svg,
      });
      if (data.httpStatus === "OK") {
        fetchData();
        setShowModal(false);
      }
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
    setLoading(false);
  };
  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="iconName"
              placeholder="Enter the Icon name"
              label="Icon Name"
              onChange={(e) => setIconName(e.target.value)}
              required={true}
              classstyle="form-control"
              value={iconName}
            />
          </Col>
          <Col xs={12} md={6} xl={8}>
            <Inputs
              type="text"
              name="svg"
              placeholder="Enter the SVG code"
              label="SVG Code"
              onChange={(e) => setSvg(e.target.value)}
              required={true}
              classstyle="form-control"
              value={svg}
            />
          </Col>

          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title={loading ? "Please Wait..." : "Submit"}
              disabled={loading}
              color="btn btn-submit btnver-2  btn-md "
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default AddSvgIconHandle;
