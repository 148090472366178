import React from "react";

const ReturnAdultFare = ({ modify }) => {
  return (
    <div className="table-responsive lightthm-wrap scroll-auto w-100">
    <table className="table table-lightthm text-left tbthm-2">
        <thead>
        <tr>
          <th scope="row">Adult Fare</th>
          <td  className="text-right">{modify.supplierFlight.returnFlight.fare.adultFare}</td>
        </tr>
        <tr>
          <th scope="row">Child Fare</th>
          <td  className="text-right"> {modify.supplierFlight.returnFlight.fare.childFare}</td>
        </tr>
        <tr>
          <th scope="row">Infant Fare</th>
          <td  className="text-right"> {modify.supplierFlight.returnFlight.fare.infantFare}</td>
        </tr>

        <tr>
          <th scope="row">Additional Fee (Diff)</th>
          <td  className="text-right">
            {" "}
            {modify.supplierFlight.returnFlight.fare.AdditionalTxnFeeOfrd}
          </td>
        </tr>
        <tr>
          <th scope="row">Total tax</th>
          <td  className="text-right"> {modify.supplierFlight.returnFlight.fare.totalTax}</td>
        </tr>
        <tr>
          <th scope="row">Markup</th>
          <td  className="text-right"> {modify.supplierFlight.returnFlight.fare.totalMarkup}</td>
        </tr>
      </thead>
    </table>
    </div>
  );
};

export default ReturnAdultFare;
