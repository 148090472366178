import React from "react";
import ReactJson from "react-json-view";

const TransInputTable = ({
  TransactionId,
  changeGatewayName,
  TransactionAmt,
  TransactionStatus,
  StateFunMerge,
  transID,
  gatewayName,
  transAmount,
  transactionStatus,
  but,
  modify,
  setChangeInputState,
  ChangeInputState,
}) => {
  return (
    <div className="table-responsive lightthm-wrap scroll-auto w-100">
      <table className="table table-lightthm text-left tbthm-2">
        <thead className="bg-light">
          <tr>
            <th className="gtway-w">Gateway Name</th>
            <td>
              <div className="input-classicsite  my-2">
                <select
                  className="form-select"
                  placeholder="Gatway Name"
                  value={gatewayName}
                  onChange={(e) => changeGatewayName(e)}
                  disabled={ChangeInputState === 1 ? true : false}
                >
                  <option>Select gateway name</option>
                  <option value="PayU">PayU</option>
                  <option value="Paytm">Paytm</option>
                  <option value="CC-Avenue">CC-Avenue</option>
                  <option value="Razorpay">Razorpay</option>
                </select>
              </div>
            </td>
          </tr>

          <tr>
            <th className="gtway-w">Transaction Id</th>
            <td>
              <div className="input-classicsite  my-2">
                <input
                  className={
                    transID.length === 0 ? " form-control" : " form-control"
                  }
                  placeholder="Enter the transaction id"
                  value={transID}
                  onChange={(e) => TransactionId(e)}
                  disabled={ChangeInputState === 1 ? true : false}
                />
              </div>
            </td>
          </tr>

          <tr>
            <th className="gtway-w">Enter amount</th>
            <td>
              <div className="input-classicsite  my-2">
                <input
                  className={
                    transID.length === 0 ? "form-control" : "form-control"
                  }
                  value={transAmount}
                  onChange={(e) => TransactionAmt(e)}
                  placeholder="Enter the booking amount"
                  disabled={ChangeInputState === 1 ? true : false}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th className="gtway-w"> Select status</th>
            <td>
              <div className="input-classicsite  my-2">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={transactionStatus}
                  defaultValue="Transaction"
                  onChange={(e) => TransactionStatus(e)}
                  disabled={ChangeInputState === 1 ? true : false}
                >
                  <option>Select status</option>
                  <option value="SUCCESS">SUCCESS</option>
                  <option value="PENDING">PENDING</option>
                  <option value="FAILURE">FAILURE</option>
                </select>
              </div>
            </td>
          </tr>

          <tr>
            <th className="gtway-w"></th>

            <td className="gtway-w">
              <button
                className="btn btn-submit btnver-2 my-2 mw-120"
                type="submit"
                onClick={() =>
                  transID && transAmount && transactionStatus && gatewayName
                    ? StateFunMerge()
                    : alert("please fill required fields")
                }
              >
                {" "}
                {but}{" "}
              </button>
            </td>
          </tr>

          <tr>
            <th className="align-top" colspan="2">
              {modify.paymentDetails.gatewayResponse === null ? (
                ""
              ) : (
                <>
                  {modify.paymentDetails.gatewayResponse.length === 0 ? (
                    <p className="mt-3 text-danger">No gateway response !</p>
                  ) : (
                    <>
                      <h3 className="fw-bold text-capitalize mt-4 mb-4 itinhead">
                        Gateway response
                      </h3>
                      <div className="reactjson-wc my-3">
                        <ReactJson
                          theme="twilight"
                          name="paymentGatewayResponse"
                          collapsed={true}
                          src={JSON.parse(
                            modify.paymentDetails.gatewayResponse
                          )}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default TransInputTable;
