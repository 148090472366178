import React, { useState, useEffect } from "react";
import Modals from "../../../../CustomComponent/Modals";
import axios from "axios";
import AddUpdateSiteId from "./AddUpdateSiteId";
import { travel_url } from "../../../../Host";
import { commonheader } from "../../../../utils/header";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SiteTracking = () => {
  const [modalopen, setModalOpen] = useState(false);
  const [sitedata, setSiteData] = useState([]);
  const [isedit, setIsEdit] = useState("");
  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${travel_url}/getAllSite`, {
        headers: commonheader(),
      });
      if (data.data.data) {
        setSiteData(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div className="col-12 mb-4 ">
        <div className="metaBox position-relative d-block border-0 smtb">
          <h6 className="title-lightthm p-3 m-0">Site</h6>
          <div className="w-100">
            <div className="table-responsive lightthm-wrap">
              <table className="table table-lightthm text-left">
                <thead className="bg-light">
                  <tr>
                    <th width={20}>S.N.</th>
                    <th>Site ID</th>
                    <th>Website Name</th>
                    <th>Website URL</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {sitedata.map((item, i) => {
                    return (
                      <tr key={item._id}>
                        <td>{i + 1}</td>
                        <td>{item.siteId}</td>
                        <td>{item.name}</td>
                        <td>{item.url}</td>
                        <td>
                          <div className="btni-sp">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Edit</Tooltip>}
                            >
                              <button
                                className="btn btn-sm btn-soft-success"
                                onClick={() => {
                                  setIsEdit(item._id);
                                  setModalOpen(true);
                                }}
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="text-right px-3 py-4">
            <div className="btni-sp">
              <button
                className="btn-submit btnver-2 success"
                onClick={() => {
                  setModalOpen(true);
                  setIsEdit("");
                }}
              >
                Add <i className="fa-solid fa-plus icon-add"></i>
              </button>
            </div>
          </div>

          <Modals
            show={modalopen}
            HandleClose={() => setModalOpen(false)}
            ModalBody={
              <AddUpdateSiteId
                setModalOpen={setModalOpen}
                fetchData={fetchData}
                setIsEdit={setIsEdit}
                isedit={isedit}
                sitedata={sitedata}
              />
            }
            title={(isedit ? "Update" : "Add") + " Site"}
          />
        </div>
      </div>
    </div>
  );
};

export default SiteTracking;
