import React, { useState, useEffect } from "react";
import Modals from "../../../../CustomComponent/Modals";
import axios from "axios";
import { travel_url } from "../../../../Host";
import { commonheader } from "../../../../utils/header";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddUpdateBlockAir from "./AddUpdateBlockAir";
import swal from "sweetalert";

const BlockAirport = ({ getSourceData, getSuppliersData }) => {
  const [modalopen, setModalOpen] = useState(false);
  const [airportdata, setAirPortData] = useState([]);
  const [isedit, setIsEdit] = useState("");
  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${travel_url}/block/airport/getAll`, {
        headers: commonheader(),
      });
      setAirPortData(data.data);
      //   setSiteData(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getDeleteData = async (id) => {
    try {
      const { data } = await axios.delete(
        `${travel_url}/block/airport/delete/${id}`,
        {
          headers: commonheader(),
        }
      );
      fetchData();
      swal("", "Successfully Deleted", "success");
    } catch (err) {
      console.log(err);
      swal("Uncaught Error", "Please try again after some time", "error");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div className="col-12 mb-4 ">
        <div className="metaBox position-relative d-block border-0 smtb">
          <h6 className="title-lightthm p-3 m-0">Block Airport</h6>
          <div className="w-100">
            <div className="table-responsive lightthm-wrap">
              <table className="table table-lightthm text-left">
                <thead className="bg-light">
                  <tr>
                    <th width={20}>S.N.</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Source</th>
                    <th>API</th>
                    <th>Site ID</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {airportdata.map((item, i) => {
                    return (
                      <tr key={item._id}>
                        <td>{i + 1}</td>
                        <td>{item.org}</td>
                        <td>{item.dst}</td>
                        <td>{item.source}</td>
                        <td>{item.api}</td>
                        <td>{item.siteId}</td>
                        <td>
                          <div className="btni-sp">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Edit</Tooltip>}
                            >
                              <button
                                className="btn btn-sm btn-soft-success"
                                onClick={() => {
                                  setIsEdit(item._id);
                                  setModalOpen(true);
                                }}
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </button>
                            </OverlayTrigger>
                          </div>
                        </td>
                        <td>
                          <div className="btni-sp">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Delete</Tooltip>}
                            >
                              <button
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => getDeleteData(item._id)}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="text-right px-3 py-4">
            <div className="btni-sp">
              <button
                className="btn-submit btnver-2 success"
                onClick={() => {
                  setModalOpen(true);
                  setIsEdit("");
                }}
              >
                Add <i className="fa-solid fa-plus icon-add"></i>
              </button>
            </div>
          </div>

          <Modals
            show={modalopen}
            HandleClose={() => setModalOpen(false)}
            ModalBody={
              <AddUpdateBlockAir
                getSourceData={getSourceData}
                isedit={isedit}
                setModalOpen={setModalOpen}
                fetchData={fetchData}
                getSuppliersData={getSuppliersData}
                airportdata={airportdata}
              />
            }
            title={(isedit ? "Update" : "Add") + " Block Airport"}
          />
        </div>
      </div>
    </div>
  );
};

export default BlockAirport;
