export const AddSupplierPreferredAirlineInputBox = (supplierList, metaList) => [
  {
    id: 4,
    name: "source",
    type: "select",
    placeholder: "Enter Status",
    label: "Source",
    required: true,
    disabled: false,
    optionValue: [{ id: 0, name: "Select", value: "" }, ...metaList],
    defaultValue: "",
  },
  {
    id: 1,
    name: "supplier",
    type: "select",
    placeholder: "Enter Supplier",
    label: "Supplier",
    required: true,
    disabled: false,
    optionValue: supplierList,
    defaultValue: "",
  },
  {
    id: 2,
    name: "airline",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter Destination",
    label: "Airline",
    required: true,
    disabled: false,
    defaultValue: "",
  },
  {
    id: 3,
    name: "enabled",
    type: "select",
    placeholder: "Enter Status",
    label: "Status",
    required: true,
    disabled: false,
    optionValue: [
      { id: 0, name: "Select", value: "" },
      { id: 1, name: "True", value: true },
      { id: 2, name: "False", value: false },
    ],
    defaultValue: "",
  },
];
