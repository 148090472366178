import React, { Fragment, useState } from "react";
import { AddTravoFareRuleApi } from "../../../SmallApi/TravoFAreRuleSmallApi/AddTravoFareRuleApi";
import Select from "../../../CustomComponent/Select";
import Inputs from "../../../CustomComponent/Inputs";
import Button from "../../../CustomComponent/Button";
import { Col, Row } from "react-bootstrap";
import AddMarkup from "../../../Apis/trav_fare_rule/AddMarkup";
import DateInput from "../../../CustomComponent/DateInput";

const TravoFareRuleAddModals = ({
  supplierList,
  metaList,
  setRefresh,
  refresh,
  setShowAddModal,
}) => {
  const [addTravofareRule, setAddTravofareRule] = useState({
    api: "all",
    adult: 0,
    origin: "",
    amount: 0,
    source: metaList,
    child: 0,
    infant: 0,
    airline: "all",
    classOfService: "0",
    classOfServiceReturn: "0",
    cappingDays: 0,
    cappingAmount: 0.0,
    siteId: 0,
    enable: true,
    fareType: "",
    ruleName: "",
    markupType: "",
    amountType: "",
    destination: "",
    fromCountry: "",
    stops: 0,
    toCountry: "",
    cabinType: 0,
    convenienceFee: 0,
    additionalDisAmount: 0,
    additionalDisAmountType: "",
    fromDate: "",
    toDate: "",
    fromAmount: 0,
    toAmount: 0,
    trip: 0,
    opAkAirline: "all",
  });
  const [loading, setLoading] = useState(false);
  const [getAddData, setGetAddData] = useState({});

  const onChange = (e) => {
    setAddTravofareRule({
      ...addTravofareRule,
      [e.target.name]: e.target.value,
    });
  };
  console.log("erererer", addTravofareRule);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const addMrkpData = await AddMarkup(addTravofareRule);

      if (addMrkpData === "error") {
        setGetAddData(addMrkpData);
        setLoading(false);
      } else if (addMrkpData.httpStatus === "OK") {
        setGetAddData(addMrkpData);
        setShowAddModal(false);
        setRefresh(!refresh);
        setLoading(false);
      } else {
        setGetAddData(addMrkpData);
        setLoading(false);
      }
    } catch (err) {}
  };

  if (getAddData === "error") {
    return "Technical Error";
  }

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="text-center text-danger">
          {loading ? "Loading..." : getAddData.msg}
        </div>
        <Row>
          {AddTravoFareRuleApi(supplierList, metaList, addTravofareRule).map(
            (item, i) => {
              const type = item.type;
              return (
                <Fragment key={item.id}>
                  {["text", "number"].includes(type) ? (
                    <>
                      {
                        <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                          {
                            <Inputs
                              type={item.type}
                              name={item.name}
                              placeholder={item.placeholder}
                              label={item.label}
                              onChange={(e) => onChange(e)}
                              required={item.required}
                              defaultValue={item.defaultValue}
                              disabled={item.disabled}
                              classstyle="form-control"
                            />
                          }
                        </Col>
                      }
                    </>
                  ) : type === "date" ? (
                    <>
                      <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                        <DateInput
                          type={item.type}
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => onChange(e)}
                          required={item.required}
                          defaultValue={item.defaultValue}
                          disabled={item.disabled}
                          classstyle="form-control"
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                        <Select
                          name={item.name}
                          placeholder={item.placeholder}
                          label={item.label}
                          onChange={(e) => onChange(e)}
                          optionValue={item.optionValue}
                          required={item.required}
                          defaultValue={item.defaultValue}
                          disabled={item.disabled}
                          classstyle="form-control"
                        />
                      </Col>
                    </>
                  )}
                </Fragment>
              );
            }
          )}
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2  btn-md "
              // onClick={dublicateHandleSubmit}
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default TravoFareRuleAddModals;
