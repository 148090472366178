import axios from "axios";
import { travel_url } from "../../Host";

const UpdateMarkup = async (data) => {
  const removeHistoryData = { ...data, markupHistory: null };

  try {
    const options = {
      method: "PUT",
      url: `${travel_url}/travomint/fare/update`,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("token"),
      },
      data: removeHistoryData,
    };

    const addMarkup = await axios.request(options);
    const markupData = await addMarkup.data;
    return markupData;
  } catch (err) {
    return "error";
  }
};

export default UpdateMarkup;
