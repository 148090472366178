import axios from "axios";
import { mca_url } from "../../../Host";
import { commonheader, header } from "../../../utils/header";
const contentVisibleInstance = axios.create({
  baseURL: mca_url,
});
contentVisibleInstance.interceptors.request.use((config) => {
  const userToken = localStorage.getItem("token");
  if (userToken) {
    config.headers = commonheader();
  }
  return config;
});
// const extraHeader = {
//   "X-Extra-Key": "your_extra_value",
// };
export const getContentVisApiData = () =>
  contentVisibleInstance.get("/fetchSupplierContentHandler");
export const addContentVisApi = (data) =>
  contentVisibleInstance.post("/saveSupplierContentHandler", data);
export const updateContentVisApi = (data) =>
  contentVisibleInstance.put("/updateSupplierContentHandler", data);
export const deleteContentVisApi = (id) => {
  return axios.delete(`${mca_url}/deleteSupplierContentHandler`, {
    headers: {
      "key-id": id,
      ...commonheader(),
    },
  });
};
