import React from "react";
import Form from "react-bootstrap/Form";

const DateInput = ({
  label,
  type,
  placeholder,
  classstyle,
  pattern,
  ...props
}) => {
  const date = new Date().getDate();
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  const minDate = `${year}-${
    String(month).length <= 1 ? `0${month + 1}` : month + 1
  }-${date}`;

  return (
    <Form.Group className="input-classicsite">
      {label && <Form.Label>{label}</Form.Label>}
      <input
        type={type}
        className={classstyle}
        placeholder={placeholder}
        min={minDate}
        {...props}
      />
    </Form.Group>
  );
};

export default DateInput;
