export const UpdateMeta = async ({ loading, state, url, body, method }) => {
  loading(true);
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", localStorage.getItem("token"));
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(body);

  var requestOptions = {
    method: method,
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const dataapi = await fetch(url, requestOptions);

  const updatedata = await dataapi.json();
  if (updatedata) {
    state(updatedata);
    if (updatedata) {
      alert(updatedata.msg);
    }
  } else {
    alert(updatedata.msg);
  }
  loading(false);
};
