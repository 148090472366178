import React from "react";
import axios from "axios";
import { travel_url } from "../../Host";

export const EnableAndDisable = async (id) => {
  const options = {
    method: "PUT",
    url: `${travel_url}/travomint/enableOrDisable`,
    params: { id: id },
    headers: { "Content-Type": "application/json" },
  };
  try {
    const eandd = await axios.request(options);
    const changeEandD = await eandd.data;

    return changeEandD;
  } catch (err) {
    return "Error";
  }
};
