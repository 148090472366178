import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Inputs from "../../../../CustomComponent/Inputs";
import Button from "../../../../CustomComponent/Button";
import Select from "../../../../CustomComponent/Select";
import Swal from "sweetalert2";
import { addGateWaySaveResApi } from "../../../../Services/Api/gateSaveResponeMapping";
import {
  addInsuranceApi,
  getInsurancePlanApi,
} from "../../../../Services/Api/InsuranceHandleApi";
import { insuranceProviderList } from "../../InsurnaceHandlerMarkup/StaticInsuranceOpt";
import { addInsuranceBenefitApi } from "../../../../Services/Api/InsuranceBenefitsApi";
import Modals from "../../../../CustomComponent/Modals";
import AddIconModal from "./AddIconModal";

const AddInsuranceBenefit = ({
  allSource,
  fetchData,
  setShowModal,
  isEdit,
  iconList,
}) => {
  const [showIcon, setShowIcon] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [planLoading, setPlanLoading] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState({});
  const { time, ...newEditData } = isEdit || {};
  const [load, setLoad] = useState(false);
  const [fieldData, setFieldData] = useState(
    isEdit
      ? newEditData
      : {
          insuranceProvider: "",
          insuranceName: "",
          travelCategory: "",
          planCountry: "",
          planCode: "",
          coverage,
          benefitName: "",
        }
  );
  const {
    insuranceProvider,
    insuranceName,
    travelCategory,
    planCountry,
    planCode,
    coverage,
    icon,
    benefitName,
  } = fieldData;
  const handleInsuranceProvider = () => {
    const res = insuranceProviderList.find(
      (ele) => ele.name === insuranceProvider
    );
    setSelectedProvider(res);
  };
  const getInsurancePlan = async () => {
    setPlanLoading(true);
    const getPlanCtryId = selectedProvider.travelCountryList.find(
      (it) => it.name === planCountry
    ).valueCode;
    const getTravelCateId = selectedProvider.travelCategoryList.find(
      (it) => it.name === travelCategory
    ).valueCode;
    const getInsPlanNameId = selectedProvider.insuranceNameList.find(
      (it) => it.name === insuranceName
    ).valueCode;
    try {
      const { data } = await getInsurancePlanApi(
        getPlanCtryId,
        getTravelCateId,
        getInsPlanNameId
      );
      const newRes = data.data
        ? data.data.map((ele) => {
            return { name: ele.name, value: ele.planCode };
          })
        : [];
      setPlanList(newRes);
    } catch (error) {}
    setPlanLoading(false);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "currency") {
      value = value.toUpperCase();
    }
    setFieldData({ ...fieldData, [name]: value.trimStart() });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      planCode: planCode,
      coverage: coverage,
      icon: icon,
      type: benefitName,
    };
    setLoad(true);
    try {
      const { data } = await addInsuranceBenefitApi(payload);
      if (data.httpStatus === "OK") {
        fetchData();
        setShowModal(false);
      }
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
    setLoad(false);
  };
  useEffect(() => {
    insuranceProvider && handleInsuranceProvider();
    isEdit && Object.keys(getInsurancePlan).length > 0 && getInsurancePlan();
  }, [insuranceProvider, isEdit]);

  return (
    <div>
      <div className="">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="text"
                name="benefitName"
                placeholder="Enter the insurance benefit name"
                label="Benefit Name"
                onChange={handleChange}
                required={true}
                classstyle="form-control"
                value={benefitName}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="insuranceProvider"
                label="Insurance Provider"
                onChange={handleChange}
                optionValue={insuranceProviderList}
                firstName={"Select"}
                required={true}
                classstyle="form-control"
                value={insuranceProvider}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="insuranceName"
                optionValue={selectedProvider.insuranceNameList || []}
                firstName={"Select"}
                value={insuranceName}
                label="Insurance Name"
                onChange={handleChange}
                required={true}
                classstyle="form-control"
                disabled={!insuranceProvider}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="travelCategory"
                optionValue={selectedProvider.travelCategoryList || []}
                firstName={"Select"}
                label="Travel Category"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={travelCategory}
                disabled={!insuranceProvider}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="planCountry"
                optionValue={selectedProvider.travelCountryList || []}
                firstName={"Select"}
                label="Travel Country"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={planCountry}
                disabled={!insuranceProvider}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="planCode"
                optionValue={planList}
                firstName={planLoading ? "Loading..." : "Select"}
                label="Insurance Plan Code"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={planCode}
                onClick={() =>
                  insuranceName &&
                  planCountry &&
                  travelCategory &&
                  getInsurancePlan()
                }
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="number"
                name="coverage"
                placeholder="Enter the coverage"
                label="Coverage"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={coverage}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <div className="input-classicsite">
                <label className="form-label">Icon</label>
                <div
                  className="form-control mt-4 d-flex align-items-center justify-content-between cursor-pointer"
                  onClick={() => setShowIcon(true)}
                >
                  <div>
                    {icon ? (
                      <img
                        src={`data:image/svg+xml;base64,${btoa(icon)}`}
                        width={30}
                        height={30}
                        alt="icon"
                      />
                    ) : (
                      "Add Icon"
                    )}
                  </div>
                  <i className="fa-solid fa-plus"></i>
                </div>
              </div>
            </Col>
            <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
              <Button
                type="submit"
                title={load ? "Please Wait..." : "Submit"}
                disabled={load}
                color="btn btn-submit btnver-2  btn-md "
              />
            </div>
          </Row>
        </form>
      </div>
      <Modals
        show={showIcon}
        HandleClose={() => setShowIcon(false)}
        ModalBody={
          <AddIconModal
            iconList={iconList}
            onClickIcon={(svg) => {
              setFieldData({ ...fieldData, icon: svg });
              setShowIcon(false);
            }}
            icon={icon}
          />
        }
        title={"Add Icon"}
      />
    </div>
  );
};

export default AddInsuranceBenefit;
