import { getApiHitStatus } from "./Urls";

export const getApihitStats = ({ setHitStatus }) => {
  const options = { method: "GET" };

  fetch(getApiHitStatus, options)
    .then((response) => response.json())
    .then((response) => {
      if (response.httpStatus === "OK") {
        setHitStatus(response.data);
      }
    })
    .catch((err) => console.error(err));
};
