import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import FilterGoogle from "../Molecule/Google/FilterGoogle";
import Googleflight from "../Molecule/Google/Googleflight";
import UserBar from "../Molecule/User/UserBar";

const Googlebooking = () => {
  const [filterObj, setFilterObj] = useState({});
  const [getGoogleData, setGetGoogleData] = useState({});

  return (
    <div>
      {/* <UserBar Pagetitle={`Google Flight Booking`}/> */}
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1 mt-cachepricefeed">
        <FilterGoogle
          setFilterObj={setFilterObj}
          getGoogleData={getGoogleData}
        />

        <div className="leftspc-less">
          <Googleflight
            filterObj={filterObj}
            setGetGoogleData={setGetGoogleData}
          />
        </div>
      </div>
    </div>
  );
};

export default Googlebooking;
