import React from "react";
import moment from "moment";
import ReactJson from "react-json-view";
const TransactionTable = ({ modify, ChangeInputState }) => {
  return (
    <div className="table-responsive lightthm-wrap scroll-auto w-100">
      <table className="table table-lightthm text-left tbthm-2">
        <thead>
          <tr>
            <th className="gtway-w">Transaction Id</th>
            <td>
              {modify.paymentDetails.transactionId === null
                ? "---"
                : modify.paymentDetails.transactionId}
            </td>
          </tr>
          <tr>
            <th className="gtway-w">gatewayName</th>
            <td> {modify.paymentDetails.gatewayName}</td>
          </tr>
          <tr>
            <th className="gtway-w">orderId</th>
            <td> {modify.paymentDetails.orderId}</td>
          </tr>
          <tr>
            <th className="gtway-w">TransactionDate</th>
            <td>
              {" "}
              {modify.paymentDetails.transactionDate === null ? (
                "No date available"
              ) : (
                <>
                  {moment(
                    modify.paymentDetails.transactionDate.split("T")[0]
                  ).format("MMM Do YY")}
                </>
              )}
            </td>
          </tr>
          <tr>
            <th className="gtway-w">amount</th>
            <td> {modify.paymentDetails.amount}</td>
          </tr>
          <tr>
            <th className="gtway-w">TrasactionStatus</th>
            <td
              className={
                modify.paymentDetails.transactionStatus !== "SUCCESS"
                  ? "PayTextStatPending"
                  : "PayTextStatSuccess"
              }
            >
              {modify.paymentDetails.transactionStatus}
            </td>
          </tr>

          <tr>
            <th className="align-top" colspan="2">
              {modify.paymentDetails.gatewayResponse === null ? (
                ""
              ) : (
                <>
                  {modify.paymentDetails.gatewayResponse.length === 0 ? (
                    <p className="mt-3 text-danger">No gateway response !</p>
                  ) : (
                    <>
                      <h3 className="fw-bold text-capitalize mt-4 mb-4 itinhead">Gateway response</h3>
                      <div className="reactjson-wc my-3">
                        <ReactJson
                          theme="twilight"
                          name="paymentGatewayResponse"
                          collapsed={true}
                          src={JSON.parse(modify.paymentDetails.gatewayResponse)}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default TransactionTable;
