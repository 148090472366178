export const EditCacheManagementApi = (data, rangeList) => [
  {
    id: 1,
    name: "origin",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter origin",
    label: "Origin",
    required: true,
    disabled: false,
    defaultValue: data.origin,
  },
  {
    id: 2,
    name: "destination",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter Destination",
    label: "Destination",
    required: true,
    disabled: false,
    defaultValue: data.destination,
  },
  {
    id: 4,
    name: "range",
    type: "select",
    placeholder: "Enter Range",
    label: "Range",
    required: true,
    disabled: false,
    optionValue: rangeList,
    defaultValue: data.range,
  },
  {
    id: 5,
    name: "session",
    pattern: "[0-9]+",
    type: "text",
    placeholder: "Enter Session",
    label: "Session  ( In Minute )",
    required: true,
    disabled: false,
    defaultValue: data.session,
  },
  {
    id: 6,
    name: "enabled",
    type: "select",
    placeholder: "Enter Status",
    label: "Status",
    required: true,
    disabled: false,
    optionValue: [
      { id: 0, name: "Select", value: "" },
      { id: 1, name: "True", value: true },
      { id: 2, name: "False", value: false },
    ],
    defaultValue: data.enabled,
  },
];
