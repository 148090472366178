import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2';
import { updateHoldticketApi } from '../../../Services/Api/holdTicketHandleApi';

const UpdateHoldTicket = ({editData,setShowEditMdl,fetchData}) => {
    const [fieldData, setFieldData] = useState(editData);
      const { hold, enable,airlines,tripType } = fieldData?fieldData:editData;
    const handleChangeValue=(e)=>{
        let {value,name}=e.target;
        if(name==="airlines"){
            value=value.trim().toUpperCase()
        }
        setFieldData({...fieldData,[name]:value})
    }
    const handleUpdate = async()=>{
        const {time,...rest}=fieldData;
        try {
          const {data}=await updateHoldticketApi(rest);
          data.httpStatus === "OK" && fetchData()
          Swal.fire({
            icon: data.httpStatus === "OK" ? "success" : "error",
            text: data.msg,
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (error) {
        }
        setShowEditMdl(false)
      }
  return (
    <div><Modal.Header closeButton>
    <Modal.Title className="modal-Title">Update Hold Ticket</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <form>
    <div className="row mb-3">
        <div className="col-12">
          <div className="input-classicsite">
            <label for="inputEmail3" className="form-label">
              Trip Type
            </label>
            <select
              className="form-select"
              name="tripType"
              placeholder="Select Title"
              value={tripType}
              onChange={handleChangeValue}
            >
            <option value={0}>All</option>
              <option value={1}>One Way</option>
              <option value={2}>Round Trip</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <div className="input-classicsite">
            <label for="" className="form-label">
              Airlines
            </label>
            <input
              className="form-control"
              type="text"
              name="airlines"
              onChange={handleChangeValue}
              value={airlines}
              required
              placeholder='i.e 6E,UK,AI etc.'
            />
          </div>
        </div>
      </div>
      <fieldset className="row mb-3 mt-4">
        <legend className="col-form-label col-12 pt-0 fw-bold">
          Status
        </legend>
        <div className="col-12">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="enable"
              id="gridRadios1"
              value={true}
              onChange={handleChangeValue}
              defaultChecked={enable?true:false}
            />
            <label className="form-check-label" for="gridRadios1">
              Active
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="enable"
              id="gridRadios2"
              value={false}
              onChange={handleChangeValue}
              defaultChecked={enable?false:true}
            />
            <label className="form-check-label" for="gridRadios2">
              Inactive
            </label>
          </div>
        </div>
      </fieldset>
      <fieldset className="row mb-3 mt-4">
        <legend className="col-form-label col-12 pt-0 fw-bold">
          Hold
        </legend>
        <div className="col-12">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="hold"
              id="gridRadios3"
              value={true}
              onChange={handleChangeValue}
              defaultChecked={hold?true:false}
            />
            <label className="form-check-label" for="gridRadios3">
              Active
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="hold"
              id="gridRadio4"
              value={false}
              onChange={handleChangeValue}
              defaultChecked={hold?false:true}
            />
            <label className="form-check-label" for="gridRadios4">
              Inactive
            </label>
          </div>
        </div>
      </fieldset>
    </form>
  </Modal.Body>
  <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
    <button
      type="submit"
      className="btn btn btn-submit btnver-2"
 onClick={handleUpdate}
    >
      Submit
    </button>
  </Modal.Footer></div>
  )
}

export default UpdateHoldTicket