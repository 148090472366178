import React, { useState } from "react";
import Screen from "../../CustomComponent/Screen";
import {} from "./Retrieve.css";
import RetrieveTable from "../../Molecule/RetrieveComp/Tables/RetrieveTable";
import axios from "axios";
import { travel_url } from "../../Host";
import { decryptDataWithMode } from "../../utils/decryptData";
const Retrieve = () => {
  const [getRetrieveData, setgetRetrieveData] = useState("");
  const [loading, setLoad] = useState(false);
  const [searchInp, setSearchInp] = useState("");
  const searchRetriveData = async () => {
    setLoad(true);
    const scrKey = "ZEhKaGRtOVFTRkJEVWsweU1ESXpNalJ3WVhsdFpXNTA=";
    // try {
    //   const { data } = await axios.get(`${travel_url}/retrieveDetail`, {
    //     headers: {
    //       token: searchInp,
    //       "x-api-key": localStorage.getItem("token"),
    //     },
    //   });
    //   const decryData = data.data ? decryptDataWithMode(data.data, scrKey) : {};
    //   setgetRetrieveData(decryData);
    // } catch (error) {}

    try {
      const { data } = await axios.get(
        `https://pay.travomint.com/intpay/fetch?tokenKey=${searchInp}`
      );
      const decryData = data.data ? decryptDataWithMode(data.data, scrKey) : {};
      setgetRetrieveData(decryData);
    } catch (error) {}

    setLoad(false);
  };
  return (
    <div>
      <Screen>
        <div class="searchBar">
          <input
            id="searchQueryInput"
            type="text"
            name="searchQueryInput"
            placeholder="Search..."
            value={searchInp}
            onChange={(e) => setSearchInp(e.target.value.trim())}
          />
          <button
            id="searchQuerySubmit"
            onClick={searchRetriveData}
            type="submit"
            name="searchQuerySubmit"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
        {Object.keys(getRetrieveData).length > 0 && (
          <RetrieveTable getRetrieveData={getRetrieveData} />
        )}
      </Screen>
    </div>
  );
};

export default Retrieve;
