import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { travel_url } from "../../Host";
import loadergif from "../../Image/load.gif";
import notfound from "../../Image/notfound.gif";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import Inputs from "../../CustomComponent/Inputs";
import Button from "../../CustomComponent/Button";
import Loading from "../../CustomComponent/Loading";

const AutoUsers = () => {
  const [user, setUser] = useState([]);
  const [code, setCode] = useState("");
  const [load, setLoad] = useState(false);
  const [changeFare, setChangeFare] = useState(false);
  const [pnr, setpnr] = useState([]);
  const [BookData, setBookData] = useState([]);
  const [Bookstatus, setBookstatus] = useState(false);
  const [loader, setloader] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [status, SetStatus] = useState(false);
  const [payment, setPayment] = useState({});
  const [PayStatus, setPayStatus] = useState(false);

  async function autoUserFilter(e) {
    setCode(e.target.value);
  }

  async function GetUser() {
    setloader(true);
    setLoad(false);
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/checkAndBook?orderId=` + code, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let response = JSON.parse(result);

        setUser(response);

        setUpdatedStatus(
          response.data != null && response.data.length > 0
            ? response.data[0].bookingResponse.paymentgateway != null &&
              response.data[0].bookingResponse.paymentgateway.length > 0
              ? response.data[0].bookingResponse.paymentgateway[0].status
              : ""
            : ""
        );
        setBookButton(
          response.data != null && response.data.length > 0
            ? response.data[0].bookingResponse.paymentgateway != null &&
              response.data[0].bookingResponse.paymentgateway.length > 0
              ? response.data[0].bookingResponse.paymentgateway[0].status
              : ""
            : ""
        );
        setLoad(true);
        setloader(false);
        if (response.data != null) {
          getPaymentValue();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getPaymentValue() {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${travel_url}/verifyPayments?orderId=${code}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPayment(result);
        setPayStatus(true);

        var status = result.data.map.status;

        if (
          result.data.map.status == "success" ||
          result.data.map.status == "Success" ||
          result.data.map.status == "SUCCESS"
        ) {
          setUpdatedGateway(result.data.map.payment_source);
          setUpdatedTransaction(result.data.map.mihpayid);
          setUpdatedAmount(result.data.map.amt);
          setUpdatedStatus(status);
          setBookButton(result.data.map.status);
        }
      })
      .catch((error) => console.log("error", error));
  }

  function Clear() {
    setupdateRate(false);
  }

  function BookConfirm(items) {
    setpnr(items);
    handleShow();
  }

  // DATE AND TIME FORMAT
  const DetailConvertMinsToTime = ({ data }) => {
    let hours = Math.floor(data / 60);
    let minutes = data % 60;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}hrs: ${minutes}min`;
  };

  // DATE AND TIME FORMAT
  const ConvertMinsToTime = ({ data }) => {
    let realData = 0;
    for (let i = 0; i < data.length; i++) {
      realData += data[i];
    }

    let hours = Math.floor(realData / 60);
    let minutes = realData % 60;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}hrs: ${minutes}min`;
  };

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  function bookConfirm() {
    handleClose();
    SetStatus(true);

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(pnr);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/reviewAndBook`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setBookData(result);
        setBookstatus(true);

        handleShow3(true);
        SetStatus(false);
      })
      .catch((error) => {});
  }

  function Done() {
    window.location.reload();
  }

  const [updateRate, setupdateRate] = useState(false);
  const [UpdatedGateway, setUpdatedGateway] = useState("");
  const [UpdatedTransaction, setUpdatedTransaction] = useState("");
  const [UpdatedAmount, setUpdatedAmount] = useState("");
  const [UpdatedStatus, setUpdatedStatus] = useState("");
  const [BookButton, setBookButton] = useState("");

  function updateRateFunction(e) {
    setupdateRate(true);
    setsaveStatus(true);
    setUpdatedGateway(e.resType);
    setUpdatedTransaction(
      e.trackingId != null && e.trackingId.length > 0
        ? e.trackingId.split("-", 1)[0]
        : "0"
    );
    setUpdatedAmount(e.amount);
    setUpdatedStatus(e.status);
  }

  const [saveStatus, setsaveStatus] = useState(true);
  function updateRateFunction2() {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("access-control-allow-origin", "*");

    var raw = JSON.stringify({
      transactionId: UpdatedTransaction,
      bookingId: "",
      orderId: code,
      transactionStatus: UpdatedStatus,
      gatewayName: UpdatedGateway,
      amount: UpdatedAmount,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${travel_url}/savePayment`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setUpdatedGateway(result.data.gatewayName);
        setUpdatedTransaction(result.data.transactionId);
        setUpdatedAmount(result.data.amount);
        setUpdatedStatus(result.data.transactionStatus);
        setBookButton(result.data.transactionStatus);
        setupdateRate(false);
        setsaveStatus(false);
      })
      .catch((error) => {});
  }

  const onSubmit = (event) => {
    event.preventDefault();
    updateRateFunction2();
  };

  return (
    <>
      <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
        {status && <Loading title="Loading..." />}
        {/* --------------------------------------search----------------------------------- */}
        <div className="container-fluid px-3">
          <div className="row justify-content-center my-3 py-3">
            <div className="col-lg-10 col-xxl-8">
              <div className="input-group align-items-end w-100 autouser-inputgroup">
                <div className="flex-grow-1">
                  <Inputs
                    placeholder="Search Flight Auto User"
                    type="text"
                    className="form-control"
                    label="Search Flight"
                    onChange={(e) => autoUserFilter(e)}
                  />
                </div>
                <div className="ms-2">
                  <Button
                    other="btn-submit btnver-2"
                    title={
                      <>
                        Search{" "}
                        <i className="fa-solid search-icon fa-magnifying-glass ms-1 text-white"></i>
                      </>
                    }
                    color="btn-dark"
                    onClick={() => GetUser()}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="search-autouser">
                    {" "}
                    <i className="fa-solid search-icon fa-magnifying-glass"></i>
                    <input
                      type={`text`}
                      className="autoUser"
                      placeholder="Search Flight Auto User"
                      onChange={(e) => autoUserFilter(e)}
                    />
                    <input
                      type={`button`}
                      value={`search`}
                      className="btn-search"
                      onClick={() => GetUser()}
                    />
                  </div> */}
        </div>

        {/* -----------------------------------------------------loader ---------------------------------------------------------- */}
        {loader ? (
          <div className="loader-div">
            {/* <img src={loadergif} className="loader" /> */}
            <div className="loader"></div>
          </div>
        ) : (
          <div></div>
        )}

        {/* _____________________________search END_____________________________*/}

        {load ? (
          <div>
            {user.httpStatus == "OK" ? (
              <div>
                <div className="">
                  {user.data && user.data.length > 0 ? (
                    <div>
                      {/* FLIGHT DETAIL AND  PASANGER  */}
                      <div className="autouser-div">
                        <div>
                          <div className="row flightDetail">
                            {/* _____________________________FLIGHT DETAIL_____________________________ */}
                            {user.data[0].domestic ? (
                              <div>
                                {/* domestic */}
                                {user.data[0].tripType == 1 ? (
                                  <div>
                                    <div className="col-12 flightDetailTitle">
                                      {/* fare Update */}
                                      {changeFare ? (
                                        <h4>{user.data[0].msg}</h4>
                                      ) : (
                                        ""
                                      )}
                                      {/* fare Update */}

                                      {/* DAte */}
                                      <span>
                                        {moment(
                                          user.data[0].onWordFlight.outBound[0]
                                            .depDate
                                        ).format("dddd, DD-MMMM-yyyy")}{" "}
                                        /{" "}
                                      </span>
                                      {/* DAte */}

                                      {/* Time */}
                                      <span>
                                        <b>
                                          <span>
                                            {moment(
                                              user.data[0].onWordFlight
                                                .outBound[0].depDate
                                            ).format("LT")}
                                          </span>
                                        </b>
                                      </span>
                                      {/* Time */}

                                      <button className="TripType">
                                        {" "}
                                        ONE WAY{" "}
                                      </button>
                                    </div>
                                    <div className="row flightDetailData">
                                      {/* NAME AND PIc */}
                                      <div className="col-3">
                                        <div className="row">
                                          <div className="col-3">
                                            <img
                                              src={`https://www.travomint.com/resources/images/airline-logo/${user.data[0].onWordFlight.airline}.png`}
                                              className=""
                                              width={`100%`}
                                            />
                                          </div>
                                          <div className="col-9">
                                            <p>
                                              {
                                                user.data[0].onWordFlight
                                                  .outBound[0].airlineName
                                              }
                                            </p>
                                            <p>
                                              {
                                                user.data[0].onWordFlight
                                                  .outBound[0].airline
                                              }
                                              -
                                              {
                                                user.data[0].onWordFlight
                                                  .outBound[0].flightNo
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* NAME AND PIc */}

                                      {/* DEparture and FLIGHT  */}
                                      <div className="col-2">
                                        <p>
                                          <b>
                                            <span>
                                              {" "}
                                              {moment(
                                                user.data[0].onWordFlight
                                                  .outBound[0].depDate
                                              ).format("LT")}
                                            </span>
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          {
                                            user.data[0].onWordFlight
                                              .outBound[0].fromAirport
                                          }
                                        </p>
                                      </div>
                                      {/* DEparture and FLIGHT  */}

                                      {/* DEparture and FLIGHT  */}
                                      <div className="col-2">
                                        <p>
                                          <b>
                                            <span>
                                              {moment(
                                                user.data[0].onWordFlight
                                                  .outBound[
                                                  user.data[0].onWordFlight
                                                    .outBound.length - 1
                                                ].reachDate
                                              ).format("LT")}{" "}
                                            </span>
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          {
                                            user.data[0].onWordFlight.outBound[
                                              user.data[0].onWordFlight.outBound
                                                .length - 1
                                            ].toAirport
                                          }
                                        </p>
                                      </div>
                                      {/* DEparture and FLIGHT  */}

                                      <div className="col-2">
                                        <p>
                                          <ConvertMinsToTime
                                            data={[
                                              ...user.data[0].onWordFlight.outBound.map(
                                                (item, i) =>
                                                  item.eft + item.layOverTime
                                              ),
                                            ]}
                                          />

                                          {/* } */}
                                        </p>
                                        <p>
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 1
                                            ? "Economy"
                                            : ""}
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 2
                                            ? "Premium Economy"
                                            : ""}
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 3
                                            ? "Business"
                                            : ""}
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 4
                                            ? "First"
                                            : ""}
                                        </p>
                                      </div>

                                      <div className="col-2">
                                        <button
                                          className=" btn-auto "
                                          onClick={handleShow1}
                                        >
                                          Flight Detail
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    {/* ONWARD */}
                                    <div className="col-12 flightDetailTitle">
                                      {/* fare Update */}
                                      {changeFare ? (
                                        <h4>{user.data[0].msg}</h4>
                                      ) : (
                                        ""
                                      )}
                                      {/* fare Update */}

                                      {/* DAte */}
                                      <span>
                                        {user.data[0].onWordFlight.outBound[0].depDate.slice(
                                          0,
                                          10
                                        )}{" "}
                                        /{" "}
                                      </span>
                                      {/* DAte */}

                                      {/* Time */}
                                      <span>
                                        <b>
                                          <span>
                                            {
                                              user.data[0].onWordFlight.outBound[0].depDate.split(
                                                "T",
                                                2
                                              )[1]
                                            }
                                          </span>
                                        </b>
                                      </span>
                                      {/* Time */}
                                      <button className="TripType">
                                        {" "}
                                        ROUND TRIP{" "}
                                      </button>
                                    </div>

                                    <div className="row flightDetailData">
                                      {/* NAME AND PIc */}
                                      <div className="col-3">
                                        <div className="row">
                                          <div className="col-3">
                                            <img
                                              src={`https://www.travomint.com/resources/images/airline-logo/${user.data[0].onWordFlight.airline}.png`}
                                              className=""
                                              width={`100%`}
                                            />
                                          </div>
                                          <div className="col-9">
                                            <p>
                                              {
                                                user.data[0].onWordFlight
                                                  .outBound[0].airlineName
                                              }
                                            </p>
                                            <p>
                                              {
                                                user.data[0].onWordFlight
                                                  .outBound[0].airline
                                              }
                                              -
                                              {
                                                user.data[0].onWordFlight
                                                  .outBound[0].flightNo
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* NAME AND PIc */}

                                      {/* DEparture and FLIGHT  */}
                                      <div className="col-2">
                                        <p>
                                          <b>
                                            <span>
                                              {
                                                user.data[0].onWordFlight.outBound[0].depDate.split(
                                                  "T",
                                                  2
                                                )[1]
                                              }
                                            </span>
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          {
                                            user.data[0].onWordFlight
                                              .outBound[0].fromAirport
                                          }
                                        </p>
                                      </div>
                                      {/* DEparture and FLIGHT  */}

                                      {/* DEparture and FLIGHT  */}
                                      <div className="col-2">
                                        <p>
                                          <b>
                                            <span>
                                              {
                                                user.data[0].onWordFlight.outBound[
                                                  user.data[0].onWordFlight
                                                    .outBound.length - 1
                                                ].reachDate.split("T", 2)[1]
                                              }
                                            </span>
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          {
                                            user.data[0].onWordFlight.outBound[
                                              user.data[0].onWordFlight.outBound
                                                .length - 1
                                            ].toAirport
                                          }
                                        </p>
                                      </div>
                                      {/* DEparture and FLIGHT  */}

                                      <div className="col-2">
                                        <p>
                                          <ConvertMinsToTime
                                            data={[
                                              ...user.data[0].onWordFlight.outBound.map(
                                                (item, i) =>
                                                  item.eft + item.layOverTime
                                              ),
                                            ]}
                                          />
                                        </p>
                                        <p>
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 1
                                            ? "Economy"
                                            : ""}
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 2
                                            ? "Premium Economy"
                                            : ""}
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 3
                                            ? "Business"
                                            : ""}
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 4
                                            ? "First"
                                            : ""}
                                        </p>
                                      </div>

                                      <div className="col-2">
                                        <button
                                          className="btn-auto "
                                          onClick={handleShow1}
                                        >
                                          Flight Detail
                                        </button>
                                      </div>
                                    </div>
                                    {/* ONWARD */}

                                    {/* RETURN */}
                                    <div className="col-12 flightDetailTitle">
                                      {/* DAte */}
                                      <span>
                                        {user.data[0].returnFlight.inBound[0].depDate.slice(
                                          0,
                                          10
                                        )}{" "}
                                        /{" "}
                                      </span>
                                      {/* DAte */}

                                      {/* Time */}
                                      <span>
                                        <b>
                                          <span>
                                            {
                                              user.data[0].returnFlight.inBound[0].depDate.split(
                                                "T",
                                                2
                                              )[1]
                                            }
                                          </span>
                                        </b>
                                      </span>
                                      {/* Time */}
                                    </div>

                                    <div className="row flightDetailData">
                                      {/* NAME AND PIc */}
                                      <div className="col-3">
                                        <div className="row">
                                          <div className="col-3">
                                            <img
                                              src={`https://www.travomint.com/resources/images/airline-logo/${user.data[0].returnFlight.airline}.png`}
                                              className=""
                                              width={`100%`}
                                            />
                                          </div>
                                          <div className="col-9">
                                            <p>
                                              {
                                                user.data[0].returnFlight
                                                  .inBound[0].airlineName
                                              }
                                            </p>
                                            <p>
                                              {
                                                user.data[0].returnFlight
                                                  .inBound[0].airline
                                              }
                                              -
                                              {
                                                user.data[0].returnFlight
                                                  .inBound[0].flightNo
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* NAME AND PIc */}

                                      {/* DEparture and FLIGHT  */}
                                      <div className="col-2">
                                        <p>
                                          <b>
                                            <span>
                                              {
                                                user.data[0].returnFlight.inBound[0].depDate.split(
                                                  "T",
                                                  2
                                                )[1]
                                              }
                                            </span>
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          {
                                            user.data[0].returnFlight.inBound[0]
                                              .fromAirport
                                          }
                                        </p>
                                      </div>
                                      {/* DEparture and FLIGHT  */}

                                      {/* DEparture and FLIGHT  */}
                                      <div className="col-2">
                                        <p>
                                          <b>
                                            <span>
                                              {
                                                user.data[0].returnFlight.inBound[
                                                  user.data[0].returnFlight
                                                    .inBound.length - 1
                                                ].reachDate.split("T", 2)[1]
                                              }
                                            </span>
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          {
                                            user.data[0].returnFlight.inBound[
                                              user.data[0].returnFlight.inBound
                                                .length - 1
                                            ].toAirport
                                          }
                                        </p>
                                      </div>
                                      {/* DEparture and FLIGHT  */}

                                      <div className="col-2">
                                        <p>
                                          <ConvertMinsToTime
                                            data={[
                                              ...user.data[0].returnFlight.inBound.map(
                                                (item, i) =>
                                                  item.eft + item.layOverTime
                                              ),
                                            ]}
                                          />
                                          {/* } */}
                                        </p>
                                        <p>
                                          {user.data[0].returnFlight.inBound[0]
                                            .cabinClass == 1
                                            ? "Economy"
                                            : ""}
                                          {user.data[0].returnFlight.inBound[0]
                                            .cabinClass == 2
                                            ? "Premium Economy"
                                            : ""}
                                          {user.data[0].returnFlight.inBound[0]
                                            .cabinClass == 3
                                            ? "Business"
                                            : ""}
                                          {user.data[0].returnFlight.inBound[0]
                                            .cabinClass == 4
                                            ? "First"
                                            : ""}
                                        </p>
                                      </div>

                                      <div className="col-2">
                                        <button
                                          className="btn-auto "
                                          onClick={handleShow2}
                                        >
                                          Flight Detail
                                        </button>
                                      </div>
                                    </div>

                                    {/* RETURN */}
                                  </div>
                                )}
                                {/* domestic */}
                              </div>
                            ) : (
                              <div>
                                {/* INTERNATIONAL */}
                                {user.data[0].tripType == 1 ? (
                                  <div>
                                    {/* ONE way */}
                                    <div className="col-12 flightDetailTitle">
                                      {/* fare Update */}
                                      {changeFare ? (
                                        <h4>{user.data[0].msg}</h4>
                                      ) : (
                                        ""
                                      )}
                                      {/* fare Update */}

                                      {/* DAte */}
                                      <span>
                                        {user.data[0].onWordFlight.outBound[0].depDate.slice(
                                          0,
                                          10
                                        )}{" "}
                                        /{" "}
                                      </span>
                                      {/* DAte */}

                                      {/* Time */}
                                      <span>
                                        <b>
                                          <span>
                                            {
                                              user.data[0].onWordFlight.outBound[0].depDate.split(
                                                "T",
                                                2
                                              )[1]
                                            }
                                          </span>
                                        </b>
                                      </span>
                                      {/* Time */}
                                      <button className="TripType">
                                        {" "}
                                        ONE WAY{" "}
                                      </button>
                                    </div>

                                    <div className="row flightDetailData">
                                      {/* NAME AND PIc */}
                                      <div className="col-3">
                                        <div className="row">
                                          <div className="col-3">
                                            <img
                                              src={`https://www.travomint.com/resources/images/airline-logo/${user.data[0].onWordFlight.airline}.png`}
                                              className=""
                                              width={`100%`}
                                            />
                                          </div>
                                          <div className="col-9">
                                            <p>
                                              {
                                                user.data[0].onWordFlight
                                                  .outBound[0].airlineName
                                              }
                                            </p>
                                            <p>
                                              {
                                                user.data[0].onWordFlight
                                                  .outBound[0].airline
                                              }
                                              -
                                              {
                                                user.data[0].onWordFlight
                                                  .outBound[0].flightNo
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* NAME AND PIc */}

                                      {/* DEparture and FLIGHT  */}
                                      <div className="col-2">
                                        <p>
                                          <b>
                                            <span>
                                              {
                                                user.data[0].onWordFlight.outBound[0].depDate.split(
                                                  "T",
                                                  2
                                                )[1]
                                              }
                                            </span>
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          {
                                            user.data[0].onWordFlight
                                              .outBound[0].fromAirport
                                          }
                                        </p>
                                      </div>
                                      {/* DEparture and FLIGHT  */}

                                      {/* DEparture and FLIGHT  */}
                                      <div className="col-2">
                                        <p>
                                          <b>
                                            <span>
                                              {
                                                user.data[0].onWordFlight.outBound[
                                                  user.data[0].onWordFlight
                                                    .outBound.length - 1
                                                ].reachDate.split("T", 2)[1]
                                              }
                                            </span>
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          {
                                            user.data[0].onWordFlight.outBound[
                                              user.data[0].onWordFlight.outBound
                                                .length - 1
                                            ].toAirport
                                          }
                                        </p>
                                      </div>
                                      {/* DEparture and FLIGHT  */}

                                      <div className="col-2">
                                        <p>
                                          <ConvertMinsToTime
                                            data={[
                                              ...user.data[0].onWordFlight.outBound.map(
                                                (item, i) =>
                                                  item.eft + item.layOverTime
                                              ),
                                            ]}
                                          />
                                        </p>
                                        <p>
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 1
                                            ? "Economy"
                                            : ""}
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 2
                                            ? "Premium Economy"
                                            : ""}
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 3
                                            ? "Business"
                                            : ""}
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 4
                                            ? "First"
                                            : ""}
                                        </p>
                                      </div>

                                      <div className="col-2">
                                        <button
                                          className=" btn-auto "
                                          onClick={handleShow1}
                                        >
                                          Flight Detail
                                        </button>
                                      </div>
                                    </div>
                                    {/* ONE way */}
                                  </div>
                                ) : (
                                  <div>
                                    {/* ONWARD */}
                                    <div className="col-12 flightDetailTitle">
                                      {/* fare Update */}
                                      {changeFare ? (
                                        <h4>{user.data[0].msg}</h4>
                                      ) : (
                                        ""
                                      )}
                                      {/* fare Update */}

                                      {/* DAte */}
                                      <span>
                                        {user.data[0].onWordFlight.outBound[0].depDate.slice(
                                          0,
                                          10
                                        )}{" "}
                                        / &nbsp;{" "}
                                      </span>
                                      {/* DAte */}

                                      {/* Time */}
                                      <span>
                                        <b>
                                          <span>
                                            {
                                              user.data[0].onWordFlight.outBound[0].depDate.split(
                                                "T",
                                                2
                                              )[1]
                                            }
                                          </span>
                                        </b>
                                      </span>
                                      {/* Time */}
                                      <button className="TripType">
                                        {" "}
                                        ROUND TRIP{" "}
                                      </button>
                                    </div>

                                    <div className="row flightDetailData">
                                      {/* NAME AND PIc */}
                                      <div className="col-3">
                                        <div className="row">
                                          <div className="col-3">
                                            <img
                                              src={`https://www.travomint.com/resources/images/airline-logo/${user.data[0].onWordFlight.airline}.png`}
                                              className=""
                                              width={`100%`}
                                            />
                                          </div>
                                          <div className="col-9">
                                            <p>
                                              {
                                                user.data[0].onWordFlight
                                                  .outBound[0].airlineName
                                              }
                                            </p>
                                            <p>
                                              {
                                                user.data[0].onWordFlight
                                                  .outBound[0].airline
                                              }
                                              -
                                              {
                                                user.data[0].onWordFlight
                                                  .outBound[0].flightNo
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* NAME AND PIc */}

                                      {/* DEparture and FLIGHT  */}
                                      <div className="col-2">
                                        <p>
                                          <b>
                                            <span>
                                              {
                                                user.data[0].onWordFlight.outBound[0].depDate.split(
                                                  "T",
                                                  2
                                                )[1]
                                              }
                                            </span>
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          {
                                            user.data[0].onWordFlight
                                              .outBound[0].fromAirport
                                          }
                                        </p>
                                      </div>
                                      {/* DEparture and FLIGHT  */}

                                      {/* DEparture and FLIGHT  */}
                                      <div className="col-2">
                                        <p>
                                          <b>
                                            <span>
                                              {
                                                user.data[0].onWordFlight.outBound[
                                                  user.data[0].onWordFlight
                                                    .outBound.length - 1
                                                ].reachDate.split("T", 2)[1]
                                              }
                                            </span>
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          {
                                            user.data[0].onWordFlight.outBound[
                                              user.data[0].onWordFlight.outBound
                                                .length - 1
                                            ].toAirport
                                          }
                                        </p>
                                      </div>
                                      {/* DEparture and FLIGHT  */}

                                      <div className="col-2">
                                        <p>
                                          <ConvertMinsToTime
                                            data={[
                                              ...user.data[0].onWordFlight.outBound.map(
                                                (item, i) =>
                                                  item.eft + item.layOverTime
                                              ),
                                            ]}
                                          />
                                        </p>
                                        <p>
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 1
                                            ? "Economy"
                                            : ""}
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 2
                                            ? "Premium Economy"
                                            : ""}
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 3
                                            ? "Business"
                                            : ""}
                                          {user.data[0].onWordFlight.outBound[0]
                                            .cabinClass == 4
                                            ? "First"
                                            : ""}
                                        </p>
                                      </div>

                                      <div className="col-2">
                                        <button
                                          className="btn-auto "
                                          onClick={handleShow1}
                                        >
                                          Flight Detail
                                        </button>
                                      </div>
                                    </div>
                                    {/* ONWARD */}

                                    {/* RETURN */}
                                    <div className="col-12 flightDetailTitle">
                                      {/* DAte */}
                                      <span>
                                        {user.data[0].onWordFlight.inBound[0].depDate.slice(
                                          0,
                                          10
                                        )}{" "}
                                        /{" "}
                                      </span>
                                      {/* DAte */}

                                      {/* Time */}
                                      <span>
                                        <b>
                                          <span>
                                            {
                                              user.data[0].onWordFlight.inBound[0].depDate.split(
                                                "T",
                                                2
                                              )[1]
                                            }
                                          </span>
                                        </b>
                                      </span>
                                      {/* Time */}
                                      <button className="TripType">
                                        {" "}
                                        ROUND TRIP{" "}
                                      </button>
                                    </div>

                                    <div className="row flightDetailData">
                                      {/* NAME AND PIc */}
                                      <div className="col-3">
                                        <div className="row">
                                          <div className="col-3">
                                            <img
                                              src={`https://www.travomint.com/resources/images/airline-logo/${user.data[0].onWordFlight.airline}.png`}
                                              className=""
                                              width={`100%`}
                                            />
                                          </div>
                                          <div className="col-9">
                                            <p>
                                              {
                                                user.data[0].onWordFlight
                                                  .inBound[0].airlineName
                                              }
                                            </p>
                                            <p>
                                              {
                                                user.data[0].onWordFlight
                                                  .inBound[0].airline
                                              }
                                              -
                                              {
                                                user.data[0].onWordFlight
                                                  .inBound[0].flightNo
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* NAME AND PIc */}

                                      {/* DEparture and FLIGHT  */}
                                      <div className="col-2">
                                        <p>
                                          <b>
                                            <span>
                                              {
                                                user.data[0].onWordFlight.inBound[0].depDate.split(
                                                  "T",
                                                  2
                                                )[1]
                                              }
                                            </span>
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          {
                                            user.data[0].onWordFlight.inBound[0]
                                              .fromAirport
                                          }
                                        </p>
                                        Number
                                      </div>
                                      {/* DEparture and FLIGHT  */}

                                      {/* DEparture and FLIGHT  */}
                                      <div className="col-2">
                                        <p>
                                          <b>
                                            <span>
                                              {
                                                user.data[0].onWordFlight.inBound[
                                                  user.data[0].onWordFlight
                                                    .inBound.length - 1
                                                ].reachDate.split("T", 2)[1]
                                              }
                                            </span>
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          {
                                            user.data[0].onWordFlight.inBound[
                                              user.data[0].onWordFlight.inBound
                                                .length - 1
                                            ].toAirport
                                          }
                                        </p>
                                      </div>
                                      {/* DEparture and FLIGHT  */}

                                      {/* GAP */}
                                      <div className="col-2">
                                        <p>
                                          <ConvertMinsToTime
                                            data={[
                                              ...user.data[0].onWordFlight.inBound.map(
                                                (item, i) =>
                                                  item.eft + item.layOverTime
                                              ),
                                            ]}
                                          />

                                          {/* } */}
                                        </p>
                                        <p>
                                          {user.data[0].onWordFlight.inBound[0]
                                            .cabinClass == 1
                                            ? "Economy"
                                            : ""}
                                          {user.data[0].onWordFlight.inBound[0]
                                            .cabinClass == 2
                                            ? "Premium Economy"
                                            : ""}
                                          {user.data[0].onWordFlight.inBound[0]
                                            .cabinClass == 3
                                            ? "Business"
                                            : ""}
                                          {user.data[0].onWordFlight.inBound[0]
                                            .cabinClass == 4
                                            ? "First"
                                            : ""}
                                        </p>
                                      </div>
                                      {/* GAP */}

                                      <div className="col-2">
                                        <button
                                          className="btn-auto"
                                          onClick={handleShow2}
                                        >
                                          Flight Detail
                                        </button>
                                      </div>
                                    </div>

                                    {/* RETURN */}
                                  </div>
                                )}
                                {/* INTERNATIONAL */}
                              </div>
                            )}
                            {/* _____________________________FLIGHT DETAIL_____________________________ */}

                            <hr className="" />

                            {/*_____________________________Passanger Detail_____________________________   */}
                            <div className="row flightDetailData">
                              <div className="row">
                                <div className="col-2 head">Sr</div>
                                <div className="col-3 head">Name</div>
                                <div className="col-3 head">PNR Status</div>
                                <div className="col-2 head">PNR Number</div>
                                <div className="col-2 head">
                                  Meal and Baggage
                                </div>
                              </div>
                              {user.data[0].bookingResponse.PassengerDetails.map(
                                (items, i) => (
                                  <div className="row">
                                    <div className="col-2 ">{i + 1}</div>
                                    <div className="col-3 ">
                                      {items.paxFirstName} {items.paxMiddleName}{" "}
                                      {items.paxLastName}
                                    </div>
                                    <div className="col-3 ">
                                      {
                                        user.data[0].onWordFlight.outBound[0]
                                          .fromAirport
                                      }
                                      -
                                      {
                                        user.data[0].onWordFlight.outBound[0]
                                          .toAirport
                                      }
                                      :{" "}
                                      {user.data[0].bookingResponse
                                        .bookingDetail.bookingStatus ==
                                      "pending" ? (
                                        <div>{`Pending`}</div>
                                      ) : (
                                        <span>
                                          {
                                            user.data[0].bookingResponse
                                              .bookingDetail.bookingStatus
                                          }
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-2 ">
                                      {user.data[0].bookingResponse
                                        .bookingDetail.pnrConfirmation != null
                                        ? user.data[0].bookingResponse
                                            .bookingDetail.pnrConfirmation
                                        : ""}
                                    </div>
                                    <div className="col-2 ">NA</div>
                                  </div>
                                )
                              )}
                            </div>
                            {/*_____________________________Passanger Detail_____________________________   */}

                            {/*_____________________________PAyment DEATIL_____________________________ */}
                            <div className="row flightDgetailData1">
                              <div className="flightDetailTitle payment-detail">
                                Payment Detail
                              </div>
                              <div className="flightDetailData">
                                <div className="row">
                                  <div className="col-2 head">Payee Name</div>

                                  <div className="col-2 head">
                                    Payment Gateway
                                  </div>
                                  <div className="col-2 head">
                                    Transaction ID
                                  </div>
                                  <div className="col-2 head">Amount</div>
                                  <div className="col-2 head">Status</div>
                                  <div className="col-2 head"></div>
                                </div>

                                {updateRate ? (
                                  <div className="row">
                                    <form
                                      className="updatedForm"
                                      onSubmit={onSubmit}
                                    >
                                      <div className="col-2">
                                        {PayStatus
                                          ? payment.data != null &&
                                            payment.data.map != null
                                            ? payment.data.map.firstname
                                            : "No data Found "
                                          : "Please Wait"}
                                      </div>
                                      <div className="col-2">
                                        <select
                                          id="framework"
                                          className="addUserData"
                                          required
                                          onChange={(e) =>
                                            setUpdatedGateway(e.target.value)
                                          }
                                        >
                                          <option value={UpdatedGateway}>
                                            Select Gateway
                                          </option>
                                          <option value="PayTM">PayTM</option>
                                          <option value="PayU">PayU</option>
                                          <option value="ccAvenue">
                                            CCAvenue
                                          </option>
                                          <option value="RazorPay">
                                            RazorPay
                                          </option>
                                        </select>
                                      </div>
                                      <div className="col-2">
                                        <input
                                          type={`text`}
                                          placeholder={UpdatedTransaction}
                                          required
                                          className="transaction"
                                          onChange={(e) =>
                                            setUpdatedTransaction(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="col-2">
                                        <input
                                          type={`text`}
                                          placeholder={UpdatedAmount}
                                          required
                                          className="amount"
                                          onChange={(e) =>
                                            setUpdatedAmount(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="col-2">
                                        <div className="row">
                                          <div className="col-10">
                                            <select
                                              id="framework"
                                              className="addUserData"
                                              required
                                              onChange={(e) =>
                                                setUpdatedStatus(e.target.value)
                                              }
                                            >
                                              <option>Select Status</option>
                                              <option value="Success">
                                                Success
                                              </option>
                                              <option value="Failure">
                                                Failure
                                              </option>
                                            </select>
                                          </div>
                                          <div className="col-2">
                                            <button
                                              className="btn btn-danger mt-2"
                                              onClick={() => Clear()}
                                            >
                                              X
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-2">
                                        <input
                                          type="submit"
                                          className="btn-auto"
                                          value={`Save`}
                                        />
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  <div>
                                    {saveStatus ? (
                                      <div className="row">
                                        <div className="col-2">
                                          {PayStatus
                                            ? payment.data != null &&
                                              payment.data.map != null
                                              ? payment.data.map.firstname
                                              : "No Data Found"
                                            : "Please Wait"}
                                        </div>
                                        <div className="col-2">
                                          {PayStatus
                                            ? payment.data != null &&
                                              payment.data.map != null
                                              ? payment.data.map.payment_source
                                              : "No Data Found"
                                            : "Please Wait"}
                                        </div>
                                        <div className="col-2">
                                          {PayStatus
                                            ? payment.data != null &&
                                              payment.data.map != null
                                              ? payment.data.map.mihpayid
                                              : "No Data Found"
                                            : "Please Wait"}
                                        </div>
                                        <div className="col-2">
                                          {PayStatus
                                            ? payment.data != null &&
                                              payment.data.map != null
                                              ? payment.data.map.amt
                                              : "No Data Found"
                                            : "Please Wait"}
                                        </div>
                                        <div className="col-2">
                                          {PayStatus
                                            ? payment.data != null &&
                                              payment.data.map != null
                                              ? payment.data.map.status
                                              : "No Data Found"
                                            : "Please Wait"}
                                        </div>
                                        <div className="col-2">
                                          {(PayStatus
                                            ? payment.data != null &&
                                              payment.data.map != null
                                              ? payment.data.map.status !=
                                                  "success" ||
                                                payment.data.map.status !=
                                                  "Success" ||
                                                payment.data.map.status !=
                                                  "pending"
                                              : ""
                                            : "") &&
                                          (user.data[0].bookingResponse
                                            .bookingDetail.bookingStatus ==
                                            "BKGFAIL" ||
                                            user.data[0].bookingResponse
                                              .bookingDetail.bookingStatus ==
                                              "PENDING") ? (
                                            <button
                                              className="btn-auto"
                                              onClick={(e) =>
                                                updateRateFunction(
                                                  user.data[0].bookingResponse
                                                    .paymentgateway != null &&
                                                    user.data[0].bookingResponse
                                                      .paymentgateway.length > 0
                                                    ? user.data[0]
                                                        .bookingResponse
                                                        .paymentgateway[0]
                                                    : ""
                                                )
                                              }
                                            >
                                              Update
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="row">
                                        <div className="col-2">
                                          {PayStatus
                                            ? payment.data != null &&
                                              payment.data.map != null
                                              ? payment.data.map.firstname
                                              : ""
                                            : "Please Wait"}
                                        </div>
                                        <div className="col-2">
                                          {UpdatedGateway}
                                        </div>
                                        <div className="col-2">
                                          {UpdatedTransaction}
                                        </div>
                                        <div className="col-2">
                                          {UpdatedAmount}
                                        </div>
                                        <div className="col-2">
                                          {UpdatedStatus}
                                        </div>
                                        <div className="col-2">
                                          {UpdatedStatus == "Success" ||
                                          UpdatedStatus == "success" ||
                                          UpdatedStatus == "SUCCESS" ? (
                                            ""
                                          ) : (
                                            <button
                                              className="btn-auto"
                                              onClick={(e) =>
                                                updateRateFunction(
                                                  user.data[0].bookingResponse
                                                    .paymentgateway != null &&
                                                    user.data[0].bookingResponse
                                                      .paymentgateway.length > 0
                                                    ? user.data[0]
                                                        .bookingResponse
                                                        .paymentgateway[0]
                                                    : ""
                                                )
                                              }
                                            >
                                              Update
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {/* {updateRate ?  : <div></div>} */}
                              </div>
                            </div>
                            {/* _____________________________PAyment DEATIL END_____________________________ */}
                          </div>
                        </div>
                      </div>

                      {/* _____________________________FARE Summary _____________________________*/}
                      <div className=" fare-summary-Box autouser-div">
                        <div className="row flightDetail">
                          <div className="col-12 flightDetailTitle">
                            Fare Summary
                          </div>
                          <div className="titleFare">
                            <div className="row fareTitleINFO2">
                              <div className="col-2 head">Supplier</div>
                              <div className="col-2 head">Booking Amount</div>
                              <div className="col-2 head">Backend Amount</div>
                              <div className="col-2 head">Profit</div>
                              <div className="col-2 head">Detail</div>
                              <div className="col-2 head"></div>
                            </div>
                          </div>

                          {user.data.map((items, i) => (
                            <div>
                              {items.domestic ? (
                                <div>
                                  {items.tripType === 1 ? (
                                    <div>
                                      <div className="row flightDetailData">
                                        <div className="fareDetailINFO">
                                          <Accordion>
                                            <div className="row">
                                              <div className="col-2">
                                                {items.gdsType}
                                              </div>
                                              <div className="col-2">
                                                {
                                                  items.bookingResponse
                                                    .FareDetail[0].grandTotal
                                                }
                                              </div>
                                              <div className="col-2">
                                                {items.onWordFlight.fare.grandTotal.toFixed(
                                                  2
                                                )}{" "}
                                              </div>
                                              <div className="col-2">
                                                {(
                                                  items.bookingResponse
                                                    .FareDetail[0].grandTotal -
                                                  items.onWordFlight.fare
                                                    .grandTotal +
                                                  (items.onWordFlight.fare
                                                    .fareDetail != null
                                                    ? items.onWordFlight.fare
                                                        .fareDetail
                                                        .netCommission
                                                    : 0)
                                                ).toFixed(2)}
                                              </div>
                                              <div className="col-2">
                                                {" "}
                                                {items.onWordFlight.fare
                                                  .fareDetail != null ? (
                                                  <Accordion.Item eventKey="0">
                                                    {" "}
                                                    <Accordion.Header>
                                                      More Detail
                                                    </Accordion.Header>{" "}
                                                  </Accordion.Item>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div className="col-2">
                                                {(user.data[0].bookingResponse
                                                  .bookingDetail
                                                  .bookingStatus == "BKGFAIL" ||
                                                  user.data[0].bookingResponse
                                                    .bookingDetail
                                                    .bookingStatus ==
                                                    "PENDING") &&
                                                (BookButton == "Success" ||
                                                  (PayStatus
                                                    ? payment.data != null &&
                                                      payment.data.map != null
                                                      ? payment.data.map
                                                          .status == "success"
                                                      : ""
                                                    : " Please Wait")) ? (
                                                  <>
                                                    <button
                                                      className="btn-Book float-right"
                                                      onClick={() =>
                                                        BookConfirm(items)
                                                      }
                                                    >
                                                      Book Now {i + 1}
                                                    </button>
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                            </div>

                                            <Accordion.Item eventKey="0">
                                              <Accordion.Body>
                                                <div className="row fareTitleINFO">
                                                  <div className="col-2 head">
                                                    Base Amount
                                                  </div>
                                                  <div className="col-2 head">
                                                    Fare Identifier
                                                  </div>
                                                  <div className="col-2 head">
                                                    Net Commission
                                                  </div>
                                                  <div className="col-2 head">
                                                    Net Fare
                                                  </div>
                                                  <div className="col-2 head">
                                                    Total Fare
                                                  </div>
                                                  <div className="col-2 head">
                                                    Total Tax
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-2 ">
                                                    {items.onWordFlight.fare
                                                      .fareDetail != null
                                                      ? items.onWordFlight.fare
                                                          .fareDetail.baseAmount
                                                      : 0}
                                                  </div>
                                                  <div className="col-2 ">
                                                    {items.onWordFlight.fare
                                                      .fareDetail != null
                                                      ? items.onWordFlight.fare
                                                          .fareDetail
                                                          .fareIdentifier
                                                      : "No"}
                                                  </div>
                                                  <div className="col-2 ">
                                                    {items.onWordFlight.fare
                                                      .fareDetail != null
                                                      ? items.onWordFlight.fare
                                                          .fareDetail
                                                          .netCommission
                                                      : 0}
                                                  </div>
                                                  <div className="col-2 ">
                                                    {items.onWordFlight.fare
                                                      .fareDetail != null
                                                      ? items.onWordFlight.fare
                                                          .fareDetail.netFare
                                                      : 0}
                                                  </div>
                                                  <div className="col-2 ">
                                                    {items.onWordFlight.fare
                                                      .fareDetail != null
                                                      ? items.onWordFlight.fare
                                                          .fareDetail.totalFare
                                                      : 0}
                                                  </div>
                                                  <div className="col-2 ">
                                                    {items.onWordFlight.fare
                                                      .fareDetail != null
                                                      ? items.onWordFlight.fare
                                                          .fareDetail.totalTax
                                                      : 0}
                                                  </div>
                                                </div>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          </Accordion>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="row flightDetailData">
                                        <Accordion>
                                          <div className="row">
                                            <div className="col-2">
                                              {items.gdsType}
                                            </div>
                                            <div className="col-2">
                                              {
                                                items.bookingResponse
                                                  .FareDetail[0].grandTotal
                                              }
                                            </div>
                                            <div className="col-2">
                                              {(
                                                items.onWordFlight.fare
                                                  .grandTotal +
                                                items.returnFlight.fare
                                                  .grandTotal
                                              ).toFixed(2)}{" "}
                                            </div>
                                            <div className="col-2">
                                              {(
                                                items.bookingResponse
                                                  .FareDetail[0].grandTotal -
                                                (items.onWordFlight.fare
                                                  .grandTotal +
                                                  items.returnFlight.fare
                                                    .grandTotal) +
                                                (items.onWordFlight.fare
                                                  .fareDetail != null
                                                  ? items.onWordFlight.fare
                                                      .fareDetail.netCommission
                                                  : 0 +
                                                      items.onWordFlight.fare
                                                        .fareDetail !=
                                                    null
                                                  ? items.returnFlight.fare
                                                      .fareDetail.netCommission
                                                  : 0)
                                              ).toFixed(2)}
                                            </div>

                                            <div className="col-2">
                                              {items.onWordFlight.fare
                                                .fareDetail != null ? (
                                                <Accordion.Item eventKey="1">
                                                  {" "}
                                                  <Accordion.Header>
                                                    More Detail
                                                  </Accordion.Header>{" "}
                                                </Accordion.Item>
                                              ) : (
                                                "No Detail Found"
                                              )}{" "}
                                            </div>
                                            <div className="col-2">
                                              {PayStatus ? (
                                                payment.data != null &&
                                                payment.data.map != null ? (
                                                  BookButton == "Success" ||
                                                  payment.data.map.status ==
                                                    "success" ? (
                                                    <>
                                                      <button
                                                        className="btn-Book float-right"
                                                        onClick={() =>
                                                          BookConfirm(items)
                                                        }
                                                      >
                                                        Book Now2
                                                      </button>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                "Please Wait"
                                              )}
                                            </div>
                                          </div>

                                          <Accordion.Item eventKey="1">
                                            <Accordion.Body>
                                              <div className="row fareTitleINFO">
                                                <div className="col-2 head">
                                                  Base Amount
                                                </div>
                                                <div className="col-2 head">
                                                  Fare Identifier
                                                </div>
                                                <div className="col-2 head">
                                                  Net Commission
                                                </div>
                                                <div className="col-2 head">
                                                  Net Fare
                                                </div>
                                                <div className="col-2 head">
                                                  Total Fare
                                                </div>
                                                <div className="col-2 head">
                                                  Total Tax
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-2 ">
                                                  {items.onWordFlight.fare
                                                    .fareDetail.baseAmount +
                                                    items.returnFlight.fare
                                                      .fareDetail.baseAmount}
                                                </div>
                                                <div className="col-2 ">
                                                  {items.onWordFlight.fare
                                                    .fareDetail.fareIdentifier +
                                                    "/" +
                                                    items.returnFlight.fare
                                                      .fareDetail
                                                      .fareIdentifier}
                                                </div>
                                                <div className="col-2 ">
                                                  {items.onWordFlight.fare
                                                    .fareDetail.netCommission +
                                                    items.returnFlight.fare
                                                      .fareDetail.netCommission}
                                                </div>
                                                <div className="col-2 ">
                                                  {items.onWordFlight.fare
                                                    .fareDetail.netFare +
                                                    items.returnFlight.fare
                                                      .fareDetail.totalFare}
                                                </div>
                                                <div className="col-2 ">
                                                  {items.onWordFlight.fare
                                                    .fareDetail.totalFare +
                                                    items.returnFlight.fare
                                                      .fareDetail.totalFare}
                                                </div>
                                                <div className="col-2 ">
                                                  {items.onWordFlight.fare
                                                    .fareDetail.totalTax +
                                                    items.returnFlight.fare
                                                      .fareDetail.totalTax}
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {items.tripType === 1 ? (
                                    <div>
                                      <div className="row flightDetailData">
                                        <Accordion>
                                          <div className="row">
                                            <div className="col-2">
                                              {items.gdsType}
                                            </div>
                                            <div className="col-2">
                                              {
                                                items.bookingResponse
                                                  .FareDetail[0].grandTotal
                                              }
                                            </div>
                                            <div className="col-2">
                                              {items.onWordFlight.fare.grandTotal.toFixed(
                                                2
                                              )}{" "}
                                            </div>
                                            <div className="col-2">
                                              {(
                                                items.bookingResponse
                                                  .FareDetail[0].grandTotal -
                                                items.onWordFlight.fare
                                                  .grandTotal +
                                                (items.onWordFlight.fare
                                                  .fareDetail != null
                                                  ? items.onWordFlight.fare
                                                      .fareDetail.netCommission
                                                  : 0)
                                              ).toFixed(2)}
                                            </div>
                                            <div className="col-2">
                                              {" "}
                                              {items.onWordFlight.fare
                                                .fareDetail != null ? (
                                                <Accordion.Item eventKey="2">
                                                  {" "}
                                                  <Accordion.Header>
                                                    More Detail
                                                  </Accordion.Header>{" "}
                                                </Accordion.Item>
                                              ) : (
                                                "No Detail Found"
                                              )}
                                            </div>
                                            <div className="col-2">
                                              {PayStatus ? (
                                                payment.data != null &&
                                                payment.data.map != null ? (
                                                  BookButton == "Success" ||
                                                  payment.data.map.status ==
                                                    "success" ? (
                                                    <>
                                                      <button
                                                        className="btn-Book float-right"
                                                        onClick={() =>
                                                          BookConfirm(items)
                                                        }
                                                      >
                                                        Book Now3
                                                      </button>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                "Please Wait"
                                              )}
                                            </div>
                                          </div>

                                          <Accordion.Item eventKey="3">
                                            <Accordion.Body>
                                              <div className="row fareTitleINFO">
                                                <div className="col-2 head">
                                                  Base Amount
                                                </div>
                                                <div className="col-2 head">
                                                  Fare Identifier
                                                </div>
                                                <div className="col-2 head">
                                                  Net Commission
                                                </div>
                                                <div className="col-2 head">
                                                  Net Fare
                                                </div>
                                                <div className="col-2 head">
                                                  Total Fare
                                                </div>
                                                <div className="col-2 head">
                                                  Total Tax
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-2 ">
                                                  {
                                                    items.onWordFlight.fare
                                                      .fareDetail.baseAmount
                                                  }
                                                </div>
                                                <div className="col-2 ">
                                                  {
                                                    items.onWordFlight.fare
                                                      .fareDetail.fareIdentifier
                                                  }
                                                </div>
                                                <div className="col-2 ">
                                                  {
                                                    items.onWordFlight.fare
                                                      .fareDetail.netCommission
                                                  }
                                                </div>
                                                <div className="col-2 ">
                                                  {
                                                    items.onWordFlight.fare
                                                      .fareDetail.netFare
                                                  }
                                                </div>
                                                <div className="col-2 ">
                                                  {
                                                    items.onWordFlight.fare
                                                      .fareDetail.totalFare
                                                  }
                                                </div>
                                                <div className="col-2 ">
                                                  {
                                                    items.onWordFlight.fare
                                                      .fareDetail.totalTax
                                                  }
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="row flightDetailData">
                                        <Accordion>
                                          <div className="row">
                                            <div className="col-2">
                                              {items.gdsType}
                                            </div>
                                            <div className="col-2">
                                              {
                                                items.bookingResponse
                                                  .FareDetail[0].grandTotal
                                              }
                                            </div>
                                            <div className="col-2">
                                              {items.onWordFlight.fare.grandTotal.toFixed(
                                                2
                                              )}{" "}
                                            </div>
                                            <div className="col-2">
                                              {(
                                                items.bookingResponse
                                                  .FareDetail[0].grandTotal -
                                                items.onWordFlight.fare
                                                  .grandTotal +
                                                (items.onWordFlight.fare
                                                  .fareDetail != null
                                                  ? items.onWordFlight.fare
                                                      .fareDetail.netCommission
                                                  : 0)
                                              ).toFixed(2)}
                                            </div>
                                            <div className="col-2">
                                              {" "}
                                              {items.onWordFlight.fare
                                                .fareDetail != null ? (
                                                <Accordion.Item eventKey="4">
                                                  {" "}
                                                  <Accordion.Header>
                                                    More Detail
                                                  </Accordion.Header>{" "}
                                                </Accordion.Item>
                                              ) : (
                                                "No Detail Found"
                                              )}{" "}
                                            </div>
                                            <div className="col-2">
                                              {PayStatus ? (
                                                payment.data != null &&
                                                payment.data.map != null ? (
                                                  BookButton == "Success" ||
                                                  payment.data.map.status ==
                                                    "success" ? (
                                                    <>
                                                      <button
                                                        className="btn-Book float-right"
                                                        onClick={() =>
                                                          BookConfirm(items)
                                                        }
                                                      >
                                                        Book Now4
                                                      </button>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                "Please Wait"
                                              )}
                                            </div>
                                          </div>

                                          <Accordion.Item eventKey="4">
                                            <Accordion.Body>
                                              <div className="row fareTitleINFO">
                                                <div className="col-2 head">
                                                  Base Amount
                                                </div>
                                                <div className="col-2 head">
                                                  Fare Identifier
                                                </div>
                                                <div className="col-2 head">
                                                  Net Commission
                                                </div>
                                                <div className="col-2 head">
                                                  Net Fare
                                                </div>
                                                <div className="col-2 head">
                                                  Total Fare
                                                </div>
                                                <div className="col-2 head">
                                                  Total Tax
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-2 ">
                                                  {
                                                    items.onWordFlight.fare
                                                      .fareDetail.baseAmount
                                                  }
                                                </div>
                                                <div className="col-2 ">
                                                  {
                                                    items.onWordFlight.fare
                                                      .fareDetail.fareIdentifier
                                                  }
                                                </div>
                                                <div className="col-2 ">
                                                  {
                                                    items.onWordFlight.fare
                                                      .fareDetail.netCommission
                                                  }
                                                </div>
                                                <div className="col-2 ">
                                                  {
                                                    items.onWordFlight.fare
                                                      .fareDetail.netFare
                                                  }
                                                </div>
                                                <div className="col-2 ">
                                                  {
                                                    items.onWordFlight.fare
                                                      .fareDetail.totalFare
                                                  }
                                                </div>
                                                <div className="col-2 ">
                                                  {
                                                    items.onWordFlight.fare
                                                      .fareDetail.totalTax
                                                  }
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* _____________________________FARE Summary END_____________________________*/}
                    </div>
                  ) : (
                    <div>
                      <p className="text-white">No Result Found</p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                {user.msg == "Pnr has been generated already" ? (
                  <div className="Pre-Search">
                    {user.data != null ? (
                      <div>
                        <table>
                          <thead className="bg-light">
                            <tr>
                              <th>Booking Date</th>
                              <th>Origin</th>
                              <th>Destination</th>
                              <th>PNR</th>
                              <th>Booking Recieved Ammount</th>
                              <th>Booking Done Ammount</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>{user.data.bookingDate}</td>
                              <td>{user.data.origin}</td>
                              <td>{user.data.destination} </td>
                              <td>{user.data.pnr} </td>
                              <td>{user.data.receivedBooking.amount} </td>
                              <td>{user.data.doneBooking.amount} </td>
                            </tr>
                          </tbody>
                        </table>
                        <button
                          className="btn btn-success Okay"
                          onClick={() => Done()}
                        >
                          Okay
                        </button>
                      </div>
                    ) : (
                      <table></table>
                    )}
                  </div>
                ) : (
                  <div>
                    <h4>{user.msr}</h4>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}

        {status == true ? (
          <div className="loader-div">
            {/* <img src={loadergif} className="loadergif" /> */}
            <div className="loader"></div>
          </div>
        ) : (
          <div></div>
        )}

        <Modal
          show={show}
          onHide={handleClose}
          className="modalbooknow-classic "
          keyboard={false}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title classame="texNt-white">Confirmation:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {user.data[0].bookingResponse? "Your PNR no. is This":"NO"} */}
            <p>Do you Want to Procced for booking. </p>
            <button className="btn btn-danger" onClick={() => bookConfirm()}>
              {" "}
              YES
            </button>{" "}
            &nbsp;{" "}
            <button className="btn btn-success" onClick={handleClose}>
              NO
            </button>
          </Modal.Body>
        </Modal>

        {Bookstatus ? (
          <div>
            <Modal
              show={show3}
              onHide={handleClose3}
              size="xl"
              className="modalbooknow-classic"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title classame="texNt-white">Confirmation:</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {BookData != null ? (
                  <div>
                    {BookData.httpStatus == "OK" ? (
                      <div>
                        <table>
                          <tr>
                            <th>Booking Date</th>
                            <th>Origin</th>
                            <th>Destination</th>
                            <th>PNR</th>
                            <th>Booking Recieved Ammount</th>
                            <th>Booking Done Ammount</th>
                          </tr>

                          <tr>
                            <td>
                              {BookData != null
                                ? BookData.data.bookingDate
                                : ""}
                            </td>
                            <td>
                              {BookData != null ? BookData.data.origin : ""}
                            </td>
                            <td>
                              {BookData != null
                                ? BookData.data.destination
                                : ""}{" "}
                            </td>
                            <td>
                              {BookData != null ? BookData.data.pnr : ""}{" "}
                            </td>
                            <td>
                              {BookData != null
                                ? BookData.data.receivedBooking.amount
                                : ""}{" "}
                            </td>
                            <td>
                              {BookData != null
                                ? BookData.data.doneBooking.amount
                                : ""}{" "}
                            </td>
                          </tr>
                        </table>
                      </div>
                    ) : (
                      <h4>{BookData.msg}</h4>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </Modal.Body>
              <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
                <button variant=" btn btn-success" onClick={() => Done()}>
                  Okay
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : (
          <div></div>
        )}

        {load ? (
          <div>
            {user.data != null && user.data.length > 0 ? (
              <div className="flightdetailModal">
                <Modal
                  show={show1}
                  onHide={handleClose1}
                  size="xl"
                  className="modalbooknow-classic"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Onward Flight Detail </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <div>
                        {user.data[0].domestic ? (
                          <div>
                            {user.data[0].onWordFlight.outBound.map(
                              (items, i) => (
                                <div className="row">
                                  <div className="col-3">
                                    <p>{items.airlineName}</p>
                                    <p>
                                      {items.airline}
                                      {items.flightID}
                                    </p>
                                  </div>
                                  <div className="col-3">
                                    <p>
                                      {moment(items.depDate).format(
                                        "dddd, DD-MMMM-yyyy"
                                      )}{" "}
                                    </p>
                                    <p>{items.fromAirport}</p>
                                  </div>
                                  <div className="col-3">
                                    <p>
                                      {moment(items.reachDate).format(
                                        "dddd, DD-MMMM-yyyy"
                                      )}{" "}
                                    </p>
                                    <p>{items.toAirport}</p>
                                  </div>
                                  <div className="col-3">
                                    <DetailConvertMinsToTime
                                      data={items.eft + items.layOverTime}
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <div>
                            {user.data[0].onWordFlight.outBound.map(
                              (items, i) => (
                                <div className="row">
                                  <div className="col-3">
                                    <p>{items.airlineName}</p>
                                    <p>
                                      {items.airline}
                                      {items.flightID}
                                    </p>
                                  </div>
                                  <div className="col-3">
                                    <p>
                                      {moment(items.depDate).format(
                                        "dddd, DD-MMMM-yyyy"
                                      )}{" "}
                                    </p>
                                    <p>{items.fromAirport}</p>
                                  </div>
                                  <div className="col-3">
                                    <p>
                                      {moment(items.reachDate).format(
                                        "dddd, DD-MMMM-yyyy"
                                      )}{" "}
                                    </p>
                                    <p>{items.toAirport}</p>
                                  </div>
                                  <div className="col-3">
                                    <DetailConvertMinsToTime
                                      data={items.eft + items.layOverTime}
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={show2}
                  size="xl"
                  className="modalbooknow-classic"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={handleClose2}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Return Flight Detail</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      {user.data[0].domestic ? (
                        <div>
                          <div>
                            {user.data[0].tripType == 2 ? (
                              <div>
                                {user.data[0].returnFlight.inBound.map(
                                  (items, i) => (
                                    <div className="row">
                                      <div className="col-3">
                                        <p>{items.airlineName}</p>
                                        <p>
                                          {items.airline}
                                          {items.flightID}
                                        </p>
                                      </div>
                                      <div className="col-3">
                                        <p>{items.depDate}</p>
                                        <p>{items.fromAirport}</p>
                                      </div>
                                      <div className="col-3">
                                        <p>{items.reachDate}</p>
                                        <p>{items.toAirport}</p>
                                      </div>
                                      <div className="col-3">
                                        <DetailConvertMinsToTime
                                          data={items.eft + items.layOverTime}
                                        />
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            {user.data[0].tripType == 2 ? (
                              <div>
                                {user.data[0].onWordFlight.inBound.map(
                                  (items, i) => (
                                    <div className="row">
                                      <div className="col-3">
                                        <p>{items.airlineName}</p>
                                        <p>
                                          {items.airline}
                                          {items.flightID}
                                        </p>
                                      </div>
                                      <div className="col-3">
                                        <p>{items.depDate}</p>
                                        <p>{items.fromAirport}</p>
                                      </div>
                                      <div className="col-3">
                                        <p>{items.reachDate}</p>
                                        <p>{items.toAirport}</p>
                                      </div>
                                      <div className="col-3">
                                        <DetailConvertMinsToTime
                                          data={items.eft + items.layOverTime}
                                        />
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            ) : (
              <div className="bg-white my-4 pb-3">
                <div className="container notfound">
                  <img src={notfound} className="nofound" />
                </div>
                <h5 className="text-center mt-4 mb-4"> {user.msg}</h5>{" "}
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default AutoUsers;
