import React from "react";
import { useState } from "react";
import Modals from "../../CustomComponent/Modals";
import Table from "../../CustomComponent/Table";
import AddRangeModal from "./components/AddRangeModal";

const RangeList = ({ data }) => {
  const [addRangeshowModal, setAddRangeshowModal] = useState(false);

  const closeModal = () => {
    setAddRangeshowModal(false);
  };
  const addRange = () => {
    setAddRangeshowModal(true);
  };
  return (
    <div>
      <div className="col-12 mb-4 ">
        <div className="metaBox position-relative d-block border-0 smtb">
          <h6 className="title-lightthm p-3 m-0">Range</h6>
          <div className="w-100">
            <div className="table-responsive lightthm-wrap">
              <table className=" table table-lightthm text-left">
                <thead className="bg-light">
                  <tr>
                    <th width={20}>S no.</th>
                    <th>Range</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <>
                        <Table data={[i + 1, item.name]} />
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="text-right px-3 py-4">
            <div className="btni-sp">
              <button
                className="btn-submit btnver-2 success"
                onClick={() => addRange()}
              >
                Add Range <i className="fa-solid fa-plus icon-add"></i>
              </button>
            </div>
          </div>

          <Modals
            show={addRangeshowModal}
            HandleClose={closeModal}
            ModalBody={<AddRangeModal closeModal={setAddRangeshowModal} />}
            title="Add Range"
          />
        </div>
      </div>
    </div>
  );
};

export default RangeList;
