import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { travel_url } from "../../Host";
import { Link } from "react-router-dom";
import loadergif from "../../Image/load.gif";
import Modal from "react-bootstrap/Modal";
import JSONPretty from "react-json-prettify";
import moment from "moment";
import Loading from "../../CustomComponent/Loading";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// import Booking_List_Api from "../../CustomApi/Booking_List_Api.json";

const SupplierLists = ({ filterObj }) => {
  //----------------------- modal ---------------------------
  const [show, setShow] = useState(false);
  const [res, setRes] = useState({});
  const [error, setError] = useState("None");

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setRes(e);
    setShow(true);
  };

  //----------------------- modal ---------------------------

  // const [list, setlist] = useState([])
  const [status, setstatus] = useState(true);

  const [listTotal, setlistTotal] = useState([]);
  // const [statusTotal, setstatusTotal] = useState(false)

  const [check, setcheck] = useState("");

  const pageblgid = useParams();

  async function GetList() {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${travel_url}/findSupplierResponseByPagination?page=${pagination_st +
        1}&size=15`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);

        setlistTotal(data);
        setstatus(false);
      })
      .catch((error) => {
        console.log(error);
        setError(
          "There is some technical Error, try to contact with our technical team"
        );
        setstatus(false);
      });
  }

  const filter = async () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    var bodyRaw = {
      from: filterObj.from == "" ? 0 : filterObj.from,
      to: filterObj.to == "" ? 0 : filterObj.to,
      bookingId: filterObj.bookingId == "" ? 0 : filterObj.bookingId,
      orderId: filterObj.orderId == "" ? 0 : filterObj.orderId,
      gds: filterObj.gds == "" ? 0 : filterObj.gds,
    };
    var raw = JSON.stringify(bodyRaw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${travel_url}/supplierResponseFilter?page=${pagination_st + 1}&size=15`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setlistTotal(result);
        setstatus(false);
      })
      .catch((error) => {});

    setstatus(true);
  };

  function filterData() {}
  function autoUserFilter(e) {
    setcheck(e.target.value);
  }

  const freeAllbatch = [];
  // if (statusTotal == true) {
  //     const chunkSize = 15
  //     const arr = list.data
  //     const groups = arr
  //         .map((e, i) => {
  //             return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null
  //         })
  //         .filter((e) => {
  //             return e
  //         })
  //     freeAllbatch.push(groups)
  // }

  for (let i = 0; i < listTotal.totalPages; i++) {
    freeAllbatch.push(i);
  }

  const [pagination_st, setPaginationst] = useState(0);
  // const [parinum, setParinum] = useState([])

  function setPaginationLeft(e) {
    // if (pagination_st == 0) {
    // } else {
    //     setPaginationst(pagination_st - 1)
    // }
  }

  function setPaginationRight(e) {
    // if (pagination_st == freeAllbatch[0].length - 1) {
    // } else {
    //     setPaginationst(pagination_st + 1)
    // }
  }

  useEffect(() => {
    GetList();

    setstatus(true);
  }, [pageblgid.id]);

  useEffect(() => {
    // if(pageblgid.id != undefined || pageblgid.id){
    //  setPaginationst(pageblgid.id-1)
    // }
    window.scrollTo(0, 0);
  }, [pageblgid]);

  useEffect(() => {
    filter();
  }, [filterObj]);

  return (
    <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0">
      {status !== true ? (
        <>
          {error == "None" ? (
            <>
              <div className="container-fluid px-3 mb-4">
                <div className="metaBox position-relative d-block border-0 smtb">
                  <div className="row ">
                    <div className="col-12  ">
                      <div className="table-responsive lightthm-wrap scroll-auto">
                        <table className="table table-lightthm text-left">
                          <thead className="bg-light">
                            <tr>
                              <th>Sr.No.</th>
                              <th>Booking Date</th>
                              {/* <th>From</th>
                    <th>To</th> */}
                              {/* <th>Trip Type</th>
                    <th>PNR</th> */}
                              <th>Booking ID</th>
                              <th>Order ID</th>
                              {/* <th>
                      Recieved <br /> Booking
                    </th>
                    <th>
                      Confirmed <br /> Booking
                    </th> */}
                              {/* <th>
                      Booking <br /> Recieved <br /> Supplier
                    </th> */}
                              {/* <th>
                      Booking <br /> Done <br /> Supplier
                    </th> */}
                              {/* <th>Agent Name</th> */}
                              <th>gds</th>
                              <th>Response</th>
                            </tr>
                          </thead>

                          <tbody>
                            {listTotal.content != undefined &&
                            listTotal.content.length > 0 ? (
                              listTotal.content.map((item, i) => (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>
                                    {moment(item.date).format(
                                      "ddd, MMM-DD-yyyy, hh:mm A"
                                    )}{" "}
                                  </td>
                                  {/* <td>
                          {" "}
                          <i className="fa-solid fa-plane-departure"></i>{" "}
                          {item.origin}
                        </td>
                        <td>
                          {" "}
                          <i className="fa-solid fa-plane-arrival"></i>{" "}
                          {item.destination}
                        </td> */}
                                  {/* <td>{item.tripType == 1 ? "One Way" : "Two Way"}</td> */}
                                  {/* <td>{item.pnr}</td> */}
                                  <td>{item.bookingId}</td>
                                  <td className="bg-primary text-white text-center fw-bold">
                                    {item.orderId != null
                                      ? item.orderId
                                      : "---"}
                                  </td>
                                  {/* <td> ₹ {item.receivedBooking.amount}</td>
                        <td> ₹ {item.doneBooking.amount}</td> */}
                                  {/* <td>{item.receivedBooking.gds}</td>
                        <td>{item.doneBooking.gds}</td>
                        <td>
                          {item != null && item.bookingBy != null
                            ? item.bookingBy.name
                            : "---"}
                        </td> */}
                                  <td>
                                    {item != null && item.gds != null
                                      ? item.gds
                                      : "---"}
                                  </td>
                                  <td>
                                    <div className="btni-sp">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>View Response</Tooltip>
                                        }
                                      >
                                        <button
                                          className="btn btn-sm btn-soft btn-soft-green"
                                          onClick={() =>
                                            handleShow(item.response)
                                          }
                                        >
                                          Response{" "}
                                          <i
                                            className="fa fa-reply ms-1"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={14} className="text-center">
                                  No items found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* ===============================PAGINATION ==============================  */}
                  <div className="row ">
                    <div>
                      <div className="blog_bxrowpagi_empty"></div>
                      {freeAllbatch.length > 0 ? (
                        <div className="col-12 pt-3 pagination-footer">
                          <nav className="text-center ">
                            <ul className="pagination justify-content-end blog_bxrowpagi flex-wrap mb-0">
                              <li
                                className={
                                  "nv page-item itemnv-1" +
                                  (pagination_st == 0 ? " disabled" : "") +
                                  (freeAllbatch.length == 1 ? " d-none" : "")
                                }
                              >
                                <Link
                                  className="page-link"
                                  to={"/supplier-response/page=1"}
                                  onClick={() => {
                                    setPaginationst(0);
                                  }}
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true">&laquo;</span>
                                </Link>
                              </li>
                              <li
                                className={
                                  "nv page-item itemnv-2" +
                                  (pagination_st == 0 ? " disabled" : "") +
                                  (freeAllbatch.length == 1 ? " d-none" : "")
                                }
                              >
                                <Link
                                  className="page-link"
                                  to={
                                    "/supplier-response/page=" + pagination_st
                                  }
                                  onClick={(e) => {
                                    setPaginationLeft(e);
                                  }}
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true">‹</span>
                                </Link>
                              </li>

                              {new Array(freeAllbatch.length - 1)
                                .fill()
                                .map((_, index) => (
                                  <li
                                    key={index}
                                    className={
                                      "page-item dots" +
                                      (pagination_st == index
                                        ? " active"
                                        : "") +
                                      (pagination_st == index
                                        ? " activedot"
                                        : "") +
                                      (pagination_st - 1 == index
                                        ? " activedot"
                                        : "") +
                                      (pagination_st + 1 == index
                                        ? " activedot"
                                        : "") +
                                      (pagination_st - 2 == index
                                        ? " activedot"
                                        : "") +
                                      (pagination_st + 2 == index
                                        ? " activedot"
                                        : "")
                                    }
                                  >
                                    <Link
                                      className="page-link"
                                      to={
                                        "/supplier-response/page=" + (index + 1)
                                      }
                                      onClick={() => {
                                        setPaginationst(index);
                                      }}
                                    >
                                      {index + 1}
                                    </Link>
                                  </li>
                                ))}
                              <li
                                className={
                                  "page-item dots" +
                                  (pagination_st == freeAllbatch.length - 1
                                    ? " active"
                                    : "") +
                                  (pagination_st == freeAllbatch.length - 1
                                    ? " activedot"
                                    : "") +
                                  (pagination_st - 1 == freeAllbatch.length - 1
                                    ? " activedot"
                                    : "") +
                                  (pagination_st + 1 == freeAllbatch.length - 1
                                    ? " activedot"
                                    : "") +
                                  (pagination_st - 2 == freeAllbatch.length - 1
                                    ? " activedot"
                                    : "") +
                                  (pagination_st + 2 == freeAllbatch.length - 1
                                    ? " activedot"
                                    : "")
                                }
                              >
                                <Link
                                  className="page-link"
                                  to={
                                    "/supplier-response/page=" +
                                    freeAllbatch.length
                                  }
                                  onClick={() => {
                                    setPaginationst(freeAllbatch.length - 1);
                                  }}
                                >
                                  {freeAllbatch.length}
                                </Link>
                              </li>
                              <li className="w-100 d-block d-lg-none"></li>
                              <li
                                className={
                                  "nv page-item itemnv-3" +
                                  (pagination_st == freeAllbatch.length - 1
                                    ? " disabled"
                                    : "") +
                                  (freeAllbatch.length == 1 ? " d-none" : "")
                                }
                              >
                                <Link
                                  className="page-link"
                                  to={
                                    "/supplier-response/page=" +
                                    (pagination_st + 2)
                                  }
                                  onClick={(e) => {
                                    setPaginationRight(e);
                                  }}
                                  aria-label="Next"
                                >
                                  <span aria-hidden="true">›</span>
                                </Link>
                              </li>
                              <li
                                className={
                                  "nv page-item itemnv-4" +
                                  (pagination_st == freeAllbatch.length - 1
                                    ? " disabled"
                                    : "") +
                                  (freeAllbatch.length == 1 ? " d-none" : "")
                                }
                              >
                                <Link
                                  className="page-link"
                                  to={
                                    "/supplier-response/page=" +
                                    freeAllbatch.length
                                  }
                                  onClick={() => {
                                    setPaginationst(freeAllbatch.length - 1);
                                  }}
                                  aria-label="Next"
                                >
                                  <span aria-hidden="true">&raquo;</span>
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                className="modalbooknow-classic"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className="reqpre">
                  <p className="black">
                    {/* {res} */}
                    {/* {JSON.stringify(res)} */}
                    {/* if (typeof (response) != 'object'){
response = JSON.parse(response);
} */}

                    {typeof res == "object" ? (
                      <JSONPretty json={res} />
                    ) : res.includes("400 : ") ? (
                      <JSONPretty
                        json={JSON.parse(
                          res
                            .replace("400 : ", "")
                            .replace('"{', "{")
                            .replace('}"', "}")
                        )}
                      />
                    ) : res.includes('{"') && res.includes('"}') ? (
                      <JSONPretty json={JSON.parse(res)} />
                    ) : (
                      <JSONPretty json={JSON.parse(res)} />
                    )}
                  </p>
                </Modal.Body>
                <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
                  <button
                    className="btn btn btn-submit btnver-2"
                    onClick={handleClose}
                  >
                    Done
                  </button>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <div className="container">
              <h4> {error} </h4>
            </div>
          )}
        </>
      ) : (
        <Loading title="loading..." />
      )}
    </div>
  );
};

export default SupplierLists;
