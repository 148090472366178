import axios from "axios";
import { travel_url } from "../../../Host";
import { commonheader } from "../../../utils/header";
const gatewayInstance = axios.create({
  baseURL: travel_url,
});
gatewayInstance.interceptors.request.use((config) => {
  const userToken = localStorage.getItem("token");
  if (userToken) {
    config.headers = commonheader();
  }
  return config;
});
export const getGatewaySaveResApi = () =>
  gatewayInstance.get(`/getAllSaveGatewayMapping`);
export const addGateWaySaveResApi = (payload) =>
  gatewayInstance.post(`/saveSaveGatewayMapping`, payload);
export const deleteGatewaySaveResApi = (id) =>
  gatewayInstance.delete(`/deleteSaveGatewayMapping?token=${id}`);
