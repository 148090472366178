import React, { useState } from "react";
import Inputs from "../../CustomComponent/Inputs";
import Select from "../../CustomComponent/Select";
import Gender from "../../SmallApi/Gender.json";
import PassengerType from "../../SmallApi/PassengerType.json";
import Table from "../../CustomComponent/Table";
import Button from "../../CustomComponent/Button";
import Modals from "../../CustomComponent/Modals";
import PassportModalBody from "./PassengerDetailModal/PassportModalBody";
import moment from "moment";
import GstModalBody from "./PassengerDetailModal/GstModalBody";

const PassangerDetailsNew = (props) => {
  const { data, show, inputList } = props;
  const pessengerDetails =
    data.passengerDetails == null
      ? [
          {
            title: "",
            firstName: "",
            middleName: "",
            lastName: "",
            dob: "",
            email: "",
            phone: "",
            address1: null,
            address2: null,
            city: null,
            state: null,
            country: "India",
            countryCode: "IN",
            zipCode: null,
            gender: "",
            passengerType: "1",
            passportNumber: "",
            passportExpiry: "",
            passportIssued: null,
            passportIssuedCountry: "",
            gstNumber: "",
            gstCompany: "",
            ticketDetails: null,
          },
        ]
      : data.passengerDetails;
  const [inputData, setInputData] = useState(pessengerDetails);
  const [pnrTest, setPnrTest] = useState("");
  const [ticketTest, seTicketTest] = useState("");
  const [findIndex, setFindIndex] = useState("");
  const [passportDataVisible, setPassportDataVisible] = useState(false);
  const [selectModal, setSelectModal] = useState("");

  // const setTT = (pnr, name, indexk) => {
  //   if (inputData == null)
  //     if (indexk === findIndex) {
  //       inputData[indexk].ticketDetails = [];
  //     }

  //   for (let index = 0; index < inputData.length; index++) {
  //     var pnrs = pnr.split(",");
  //     const origin = data.supplierFlight.onWordFlight.outBound[0].fromAirport;
  //     const dest = data.supplierFlight.onWordFlight.outBound[0].toAirport;

  //     var size = 0;
  //     if (pnrs[pnrs.length - 1].length > 0) {
  //       // console.log("size : ", pnrs.length);
  //       size = pnrs.length;
  //     }
  //     var tickets = [];

  //     for (let i = 0; i < size; i++) {
  //       var ticket = {};
  //       {
  //         //   ticket.id = i + 1;
  //         //   ticket.pnr = pnrs[i];
  //         ticket.departureDate = null;
  //         ticket.destination = dest;
  //         ticket.origin = origin;
  //         ticket.pnr = pnrs[i];
  //         ticket.terminal = null;
  //         ticket.ticketNumber = "123";
  //       }
  //       if (index === indexk) {
  //         tickets.push(ticket);
  //       }
  //     }
  //     if (tickets.length > 0) {
  //       inputData[index].ticketDetails = tickets;
  //     }
  //   }
  // };

  const myfun = (e, i) => {
    setFindIndex(i);
    setPnrTest(e.target.value);
    setTicketObj(e.target.value, e.target.name, i);
  };

  const setTicketObj = (str, name, i) => {
    var pp = [];
    var pnrs = str.split(",");
    var tripType = data.supplierFlight.tripType;
    const origin = data.supplierFlight.onWordFlight.outBound[0].fromAirport;
    const dest = data.supplierFlight.onWordFlight.outBound[0].toAirport;

    if (i === findIndex) {
      if (inputData[i].ticketDetails != null) {
        pp = inputData[i].ticketDetails;
      }
    }

    if (tripType == 1) {
      if (pp.length > 0) {
        if (pp[0] != null) {
          if (name == "ticketNumber") {
            pp[0].ticketNumber = str.split(",")[0];
          }
          if (name == "pnr") {
            pp[0].pnr = str.split(",")[0];
          }
        }
      } else {
        var ticket = {};
        if (name == "ticketNumber") {
          ticket.ticketNumber = str.split(",")[0];
          ticket.origin = origin;
          ticket.destination = dest;
          ticket.terminal = null;
          ticket.departureDate = null;
        }
        if (name == "pnr") {
          ticket.pnr = str.split(",")[0];
          ticket.origin = origin;
          ticket.destination = dest;
          ticket.terminal = null;
          ticket.departureDate = null;
        }
        pp.push(ticket);
      }
    } else {
      if (pp.length > 0) {
        if (pp[0] != null) {
          if (name == "ticketNumber") {
            pp[0].ticketNumber = str.split(",")[0];
            pp[1].ticketNumber = str.split(",")[1];
          }
          if (name == "pnr") {
            pp[0].pnr = str.split(",")[0];
            pp[1].pnr = str.split(",")[1];
          }
        }
      } else {
        var ticket1 = {};
        var ticket2 = {};

        if (name == "ticketNumber") {
          ticket1.ticketNumber = str.split(",")[0];
          ticket2.ticketNumber = str.split(",")[1];
        }
        if (name == "pnr") {
          ticket1.pnr = str.split(",")[0];
          ticket2.pnr = str.split(",")[1];
        }
        pp.push(ticket1);
        pp.push(ticket2);
      }
    }
    inputData[i].ticketDetails = pp;
  };

  const closeModal = () => {
    setPassportDataVisible(false);
  };

  const changeHandler = (e, index) => {
    // const { name, value } = e.target;
    // setInputData({ ...inputData, [name]: value });
    const { name, value } = e.target;
    const list = [...inputData];
    list[index][name] = value;
    setInputData(list);
  };

  return (
    <>
      <div className="table-responsive lightthm-wrap scroll-auto w-100">
        <table className="table table-lightthm text-left">
          <thead className="bg-light">
            <tr>
              <th width={100}>Title</th>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>LastName</th>
              <th>DOB</th>
              <th>Gender</th>
              <th>Passenger Type</th>
              <th>PnrData</th>
              <th>Ticket Number</th>
            </tr>
          </thead>
          <tbody>
            {pessengerDetails.map((item, i) => {
              const getPnr =
                item.ticketDetails == null
                  ? []
                  : item.ticketDetails.map((pnrDetails) => pnrDetails.pnr);
              const getTicketNumber =
                item.ticketDetails == null
                  ? []
                  : item.ticketDetails.map(
                      (pnrDetails) => pnrDetails.ticketNumber
                    );
              const pnrData = getPnr && getPnr.toString();
              const ticketNumberData =
                getTicketNumber && getTicketNumber.toString();

              const dob = (
                <Inputs
                  placeholder="DOB"
                  type="date"
                  name="dob"
                  label=""
                  defaultValue={item.dob}
                  disabled={show ? false : true}
                  onChange={(e) => changeHandler(e, i)}
                  classstyle={
                    !show ? "form-control-plaintext spaceeq" : "form-control"
                  }
                />
              );

              return (
                <Table
                  data={[
                    <Inputs
                      placeholder="Mr"
                      type="text"
                      label=""
                      name="title"
                      defaultValue={item.title}
                      disabled={show ? false : true}
                      classstyle={
                        !show
                          ? "form-control-plaintext spaceeq"
                          : "form-control"
                      }
                      onChange={(e) => changeHandler(e, i)}
                    />,
                    <Inputs
                      placeholder="First Name"
                      type="text"
                      label=""
                      name="firstName"
                      defaultValue={item.firstName}
                      disabled={show ? false : true}
                      classstyle={
                        !show
                          ? "form-control-plaintext spaceeq"
                          : "form-control"
                      }
                      onChange={(e) => changeHandler(e, i)}
                    />,
                    <Inputs
                      placeholder="Middle Name"
                      type="text"
                      label=""
                      name="middleName"
                      defaultValue={item.middleName}
                      disabled={show ? false : true}
                      classstyle={
                        !show
                          ? "form-control-plaintext spaceeq"
                          : "form-control"
                      }
                      onChange={(e) => changeHandler(e, i)}
                    />,
                    <Inputs
                      placeholder="Last Name"
                      type="text"
                      label=""
                      name="lastName"
                      defaultValue={item.lastName}
                      disabled={show ? false : true}
                      classstyle={
                        !show
                          ? "form-control-plaintext spaceeq"
                          : "form-control"
                      }
                      onChange={(e) => changeHandler(e, i)}
                    />,
                    dob,
                    <Select
                      name="gender"
                      optionValue={Gender}
                      classstyle={
                        !show
                          ? "form-control-plaintext spaceeq"
                          : "form-control"
                      }
                      defaultValue={item.gender}
                      disabled={show ? false : true}
                      onChange={(e) => changeHandler(e, i)}
                    />,
                    <Select
                      name="passengerType"
                      optionValue={PassengerType}
                      classstyle={
                        !show
                          ? "form-control-plaintext spaceeq"
                          : "form-control"
                      }
                      defaultValue={item.passengerType}
                      disabled={show ? false : true}
                      onChange={(e) => changeHandler(e, i)}
                    />,
                    // <Inputs
                    //   name="passengerType"
                    //   placeholder="Type"
                    //   type="text"
                    //   label=""
                    //   onChange={(e) => changeHandler(e, i)}
                    //   defaultValue={
                    //     item.passengerType == 1
                    //       ? "Adult"
                    //       : item.passengerType == 2
                    //       ? "Child"
                    //       : "Infant"
                    //   }
                    //   disabled={show ? false : true}
                    //   classstyle={
                    //     !show
                    //       ? "form-control-plaintext spaceeq"
                    //       : "form-control"
                    //   }
                    // />,

                    <Inputs
                      name="pnr"
                      placeholder="PNR"
                      type="text"
                      label=""
                      defaultValue={pnrData}
                      // disabled={!show && pnrData && true}
                      onChange={(e) => myfun(e, i)}
                      classstyle={
                        !show
                          ? "form-control-plaintext spaceeq"
                          : "form-control"
                      }
                      disabled={show ? false : true}
                    />,
                    <Inputs
                      name="ticketNumber"
                      placeholder="Ticket No."
                      type="text"
                      label=""
                      defaultValue={ticketNumberData}
                      onChange={(e) => myfun(e, i)}
                      classstyle={
                        !show
                          ? "form-control-plaintext spaceeq"
                          : "form-control"
                      }
                      //   disabled={!show && ticketNumberData}
                      disabled={show ? false : true}
                    />,
                  ]}
                />
              );
            })}
          </tbody>
        </table>

        <div className="my-3 text-right">
          {data.domestic == false && (
            <Button
              title="Passport"
              color="btn btn-submit btnver-2 success ms-3"
              onClick={() => {
                setSelectModal("Passport");
                setPassportDataVisible(true);
              }}
            />
          )}

          {pessengerDetails[0].gstNumber && (
            <Button
              title="GST"
              color="btn btn-submit btnver-2 success ms-3"
              onClick={() => {
                setSelectModal("GST");
                setPassportDataVisible(true);
              }}
            />
          )}
        </div>

        <Modals
          show={passportDataVisible}
          HandleClose={closeModal}
          ModalBody={
            selectModal !== "GST" ? (
              <PassportModalBody pessengerPassportData={pessengerDetails} />
            ) : (
              <GstModalBody pessengerPassportData={pessengerDetails} />
            )
          }
          title={selectModal !== "GST" ? "Passport Detail" : "GST Detail"}
        />
      </div>

      {/* <Modals
        show={passportDataVisible}
        HandleClose={closeModal}
        ModalBody={
          <PassportModalBody pessengerPassportData={pessengerDetails} />
        }
        title="Passport Detail"
      /> */}
    </>
  );
};

export default PassangerDetailsNew;
