export const EditSupplierPreferredAirlineInputBox = (data) => [
  {
    id: 1,
    name: "supplier",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter Supplier",
    label: "Supplier",
    required: true,
    disabled: true,
    defaultValue: data.supplier,
  },
  {
    id: 2,
    name: "airline",
    pattern: "[a-zA-Z]+",
    type: "text",
    placeholder: "Enter Destination",
    label: "Airline",
    required: true,
    disabled: false,
    defaultValue: data.airline.toString(),
  },
  {
    id: 6,
    name: "enabled",
    type: "select",
    placeholder: "Enter Status",
    label: "Status",
    required: true,
    disabled: false,
    optionValue: [
      { id: 0, name: "Select", value: "" },
      { id: 1, name: "True", value: true },
      { id: 2, name: "False", value: false },
    ],
    defaultValue: data.enabled,
  },
];
