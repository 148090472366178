import React from "react";
import Inputs from "../../../CustomComponent/Inputs";

const InBoundFlightData = (props) => {
  const {
    index,
    data,
    mainData,
    Currentindex,
    SelectedIndex,
    Inputshow,
    onChange,
  } = props;
  const OutDate = (
    <Inputs
      placeholder="Dep Date"
      type="date"
      label=""
      name="depDate"
      defaultValue={data.depDate}
      disabled={Currentindex == SelectedIndex && Inputshow ? false : true}
      classstyle={
        Currentindex !== SelectedIndex
          ? "form-control-plaintext spaceeq"
          : "form-control"
      }
      onChange={onChange}
    />
  );
  const OutTime = (
    <Inputs
      placeholder="depTime"
      type="time"
      label=""
      name="depTime"
      defaultValue={data.depTime}
      disabled={Currentindex == SelectedIndex && Inputshow ? false : true}
      classstyle={
        Currentindex !== SelectedIndex
          ? "form-control-plaintext spaceeq"
          : "form-control"
      }
      onChange={onChange}
    />
  );
  const ArriTime = (
    <Inputs
      placeholder="ArriTime"
      type="time"
      label=""
      name="arriTime"
      defaultValue={data.arriTime}
      disabled={Currentindex == SelectedIndex && Inputshow ? false : true}
      classstyle={
        Currentindex !== SelectedIndex
          ? "form-control-plaintext spaceeq"
          : "form-control"
      }
      onChange={onChange}
    />
  );
  return (
    <>
      <tr>
        {index == 0 ? (
          <td className="brout-itn" rowSpan={mainData.returnItinerary.length}>Return Itnerey</td>
        ) : (
          ""
        )}

        <td>{data.origin}</td>
        <td>{data.destination}</td>
        <td>{OutDate}</td>
        <td>{data.marketingAirline}</td>
        <td>{data.marketingFlightNumber}</td>
        <td>{data.operationAirline ? data.operationAirline : "--"}</td>
        <td>
          {data.operatingFlightNumber ? data.operatingFlightNumber : "--"}
        </td>
        <td>{OutTime}</td>
        <td>{ArriTime}</td>
      </tr> 
    </>
  );
};

export default InBoundFlightData;
