import React from "react";

const Loading = ({ title }) => {
  return (
    <div className="overlay-ofloader d-flex align-items-center justify-content-center">
      <div className="text-center">
        <div className="loaderdataload"></div>
        {title}
      </div>
    </div>
  );
};

export default Loading;
