import axios from "axios";
import { DownloadFile } from "../../utils/DownloadFile";
import { DownloadGoogleReport_URL } from "../Urls";
export const DownloadReport = ({ data }) => {
  const options = {
    method: "POST",
    url: DownloadGoogleReport_URL,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": localStorage.getItem("token"),
    },
    data: data,
  };

  axios
    .request(options)
    .then(function (response) {
      DownloadFile(response.data.data.file, "google_report", ".csv");
    })
    .catch(function (error) {
      console.error(error);
    });
};
