import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../CustomComponent/Button";
import Inputs from "../../CustomComponent/Inputs";
import Select from "../../CustomComponent/Select";
import {
  FilterCachePriceFeedInput,
  FilterCachePriceFeedSelect,
} from "../../SmallApi/CachePriceFeedSmallApi/FilterCachePriceFeedInput";

const PriceFeedTableFilter = ({ changeFunction, onClick }) => {
  return (
    <Row className="align-items-end">
      {FilterCachePriceFeedInput.map((item, i) => {
        return (
          <Col xs={12} md={6} xl={3} className="mb-4">
            <Inputs
              name={item.name}
              placeholder={item.placeholder}
              type={item.type}
              label={item.label}
              onChange={changeFunction}
              required={item.required}
              defaultValue={item.defaultValue}
              disabled={item.disabled}
              classstyle="form-control"
            />
          </Col>
        );
      })}
      {FilterCachePriceFeedSelect.map((item, i) => {
        return (
          <Col xs={12} md={6} xl={3} className="mb-4">
            <Select
              name={item.name}
              placeholder={item.placeholder}
              label={item.label}
              onChange={changeFunction}
              optionValue={item.optionValue}
              required={item.required}
              defaultValue={item.defaultValue}
              disabled={item.disabled}
              classstyle="form-control"
            />
          </Col>
        );
      })}
           <Col xs={12} md={6} xl={3} className="mb-4">
      <Button
        title="Submit"
        color="btn-submit btnver-2"
        onClick={onClick}
      />
       </Col>
    </Row>
  );
};

export default PriceFeedTableFilter;
