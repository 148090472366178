export const AddMarkUpApi = (
  allSource,
  MarkupType,
  AmountType,
  data,
  isEdit = false
) => {
  return [
    {
      id: 1,
      name: "airline",
      type: "text",
      placeholder: "Enter airline code i.e,AI,UK",
      label: "Airline",
      required: true,
      disabled: false,
      defaultValue: data.airline,
    },
    {
      id: 2,
      name: "originCountry",
      type: "text",
      placeholder: "Enter the from country code",
      label: "From Country",
      required: true,
      disabled: false,
      defaultValue: data.originCountry,
    },
    {
      id: 3,
      name: "destinationCountry",
      type: "text",
      placeholder: "Enter the to country code",
      label: "To Country",
      required: true,
      disabled: false,
      defaultValue: data.destinationCountry,
    },
    {
      id: 4,
      name: "source",
      type: "select",
      label: "Source",
      required: true,
      disabled: isEdit ? true : false,
      defaultValue: data.source,
      optionValue: (allSource && allSource.data) || [],
    },
    {
      id: 5,
      name: "amount",
      type: "text",
      placeholder: "Enter amount",
      label: "Amount",
      required: true,
      disabled: false,
      defaultValue: data.amount,
    },
    {
      id: 7,
      name: "currency",
      type: "text",
      placeholder: "Enter currency",
      label: "Currency",
      required: true,
      disabled: isEdit ? true : false,
      defaultValue: data.currency,
    },
    {
      id: 10,
      name: "siteId",
      type: "number",
      placeholder: "Enter Site ID",
      label: "Site ID",
      required: true,
      disabled: isEdit ? true : false,
      defaultValue: data.siteId,
    },
    {
      id: 8,
      name: "convenienceFee",
      type: "text",
      placeholder: "Convenience Fee",
      label: "Convenience Fee",
      required: true,
      disabled: false,
      defaultValue: data.convenienceFee,
    },
    {
      id: 9,
      name: "markupType",
      type: "select",
      label: "MarkupType",
      required: true,
      disabled: false,
      defaultValue: data.markupType,
      optionValue: MarkupType || [],
    },
    {
      id: 10,
      name: "amountType",
      type: "select",
      label: "Amount Type",
      required: true,
      disabled: false,
      defaultValue: data.amountType,
      optionValue: AmountType || [],
    },
  ];
};
