import React, { useEffect, useState } from "react";
import Loading from "../../../CustomComponent/Loading";
import loader from "../../../Image/93134-not-found.json";
import axios from "axios";
import { pay_int_url } from "../../../Host";
import Lottie from "react-lottie";
import { decryptDataWithMode } from "../../../utils/decryptData";
import Table from "../../../CustomComponent/Table";
import { Modal, OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import JSONPretty from "react-json-prettify";
import AuthReversalModal from "./AuthReversalModal";
import Modals from "../../../CustomComponent/Modals";
import Inputs from "../../../CustomComponent/Inputs";
const PaymentService = () => {
  const [filterData, setFilterData] = useState({
    orderId: "",
    gId: "",
    status: "",
    gateway: "",
    product: "",
  });
  const { orderId, gateway, status, gId, product } = filterData;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [allData, setAllData] = useState([]);
  const [show, setShow] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [jsonRes, setJsonRes] = useState("");
  const [paymentData, setPaymentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    const scrKey = "ZEhKaGRtOVFRMGxFVTFNeU1ESXpNalJ3WVhsdFpXNTA=";
    try {
      const { data } = await axios.get(`${pay_int_url}/payglocal/payments`);
      const decryptData = data.data
        ? decryptDataWithMode(data.data, scrKey)
        : [];
      setPaymentData(decryptData);
      setAllData(decryptData);
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };
  const filterChange = (e) => {
    let { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    let newRes = [...allData];
    if (gateway.length > 2) {
      newRes = newRes.filter((ele) =>
        ele.gateway.toLowerCase().includes(gateway)
      );
    }
    if (orderId.length > 2) {
      newRes = newRes.filter((ele) => ele.orderId.includes(orderId));
    }
    if (gId.length > 2) {
      newRes = newRes.filter((ele) => ele.gid.includes(gId));
    }
    if (status.length > 2) {
      newRes = newRes.filter((ele) =>
        ele.status.toLowerCase().includes(status)
      );
    }
    if (product.length > 2) {
      newRes = newRes.filter((ele) =>
        ele.product.toLowerCase().includes(product)
      );
    }
    setPaymentData(newRes);
  }, [filterData]);

  return (
    <div className="w-100">
      <div className="container-fluid px-3">
        <div className="metaBox position-relative d-block border-0 smtb">
          {loading && <Loading title="loading..." />}
          {allData.length === 0 ? (
            <>
              <Lottie options={defaultOptions} height={400} width={400} />
              <h6>No Data</h6>
            </>
          ) : (
            <>
              <div className="w-100">
                <div className="text-right px-3 py-4">
                  <button
                    className="btn-submit btnver-2 success"
                    onClick={() => setShowAuth(true)}
                  >
                    Auth Reversal
                  </button>
                </div>
                <div className="table-responsive lightthm-wrap  border-top-0">
                  <table className="table table-lightthm text-left">
                    <thead className="bg-light">
                      <tr>
                        <th width={20}>S.no.</th>
                        <th>
                          <Inputs
                            name="gateway"
                            value={gateway}
                            placeholder={"Gateway Nme"}
                            onChange={filterChange}
                            classstyle="form-control"
                            style={{
                              width: "150px",
                              height: "100%",
                              padding: "7px 4px",
                            }}
                          />
                        </th>
                        <th>
                          <Inputs
                            name="orderId"
                            value={orderId}
                            placeholder={"Order ID"}
                            onChange={filterChange}
                            classstyle="form-control"
                            style={{
                              width: "150px",
                              height: "100%",
                              padding: "7px 4px",
                            }}
                          />
                        </th>
                        <th>
                          <Inputs
                            name="gId"
                            value={gId}
                            placeholder={"GID"}
                            onChange={filterChange}
                            classstyle="form-control"
                            style={{
                              width: "150px",
                              height: "100%",
                              padding: "7px 4px",
                            }}
                          />
                        </th>
                        <th>
                          <Inputs
                            name="product"
                            value={product}
                            placeholder={"Product"}
                            onChange={filterChange}
                            classstyle="form-control"
                            style={{
                              width: "150px",
                              height: "100%",
                              padding: "7px 4px",
                            }}
                          />
                        </th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Request</th>
                        <th>Response</th>
                        <th>
                          <Inputs
                            name="status"
                            value={status}
                            placeholder={"Status"}
                            onChange={filterChange}
                            classstyle="form-control"
                            style={{
                              width: "150px",
                              height: "100%",
                              padding: "7px 4px",
                            }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentData.length > 0 &&
                        paymentData.map((item, i) => (
                          <Table
                            i={i}
                            data={[
                              i + 1,
                              item.gateway,
                              item.orderId,
                              item.gid || "NA",
                              item.product || "NA",
                              new Date(item.date).toDateString(),
                              new Date(item.time).toLocaleTimeString(),
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>View Request</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft btn-soft-green"
                                  onClick={() => {
                                    setShow(true + "-Req");
                                    setJsonRes(item.request);
                                  }}
                                >
                                  Request{" "}
                                  <i
                                    className="fa fa-reply ms-1"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </OverlayTrigger>,
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>View Response</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft btn-soft-green"
                                  onClick={() => {
                                    setShow(true + "-Res");
                                    setJsonRes(item.response);
                                  }}
                                >
                                  Response{" "}
                                  <i
                                    className="fa fa-reply ms-1"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </OverlayTrigger>,
                              item.status,
                            ]}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Modals
        show={showAuth}
        backdrop={false}
        HandleClose={() => setShowAuth(false)}
        ModalBody={
          <AuthReversalModal fetchData={fetchData} setShowAuth={setShowAuth} />
        }
        title={"Auth Reversal"}
      />
      <Modal
        show={!show ? show : show.split("-")[0]}
        onHide={() => setShow(false)}
        size="xl"
        className="modalbooknow-classic"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {show && show.split("-")[1] === "Req" ? "Request" : "Response"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="reqpre">
          {jsonRes.includes("{") ? (
            <p className="black">
              {typeof jsonRes == "object" ? (
                <JSONPretty json={jsonRes} />
              ) : jsonRes.includes("400 : ") ? (
                <JSONPretty
                  json={JSON.parse(
                    jsonRes
                      .replace("400 : ", "")
                      .replace('"{', "{")
                      .replace('}"', "}")
                  )}
                />
              ) : jsonRes.includes('{"') && jsonRes.includes('"}') ? (
                <JSONPretty json={JSON.parse(jsonRes)} />
              ) : (
                <JSONPretty json={JSON.parse(jsonRes)} />
              )}
            </p>
          ) : (
            <p>{jsonRes}</p>
          )}
        </Modal.Body>
        <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
          <button
            className="btn btn btn-submit btnver-2"
            onClick={() => setShow(false)}
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentService;
