import React, { useEffect, useState } from "react";
import Loading from "../../../CustomComponent/Loading";
import Lottie from "react-lottie";
import loader from "../../../Image/93134-not-found.json";
import Modals from "../../../CustomComponent/Modals";
import AddContentVisibleData from "./Modal/AddContentVisibleData";
import {
  deleteContentVisApi,
  getContentVisApiData,
} from "../../../Services/Api/coontentVisibleMgtApi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../../CustomComponent/Table";
import Swal from "sweetalert2";
import UpdateContentVisData from "./Modal/UpdateContentVisData";
const ContentVisibleMgt = ({ allSource, allApi }) => {
  const [contentData, setContentdata] = useState([]);
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const fetchData = async () => {
    try {
      const { data } = await getContentVisApiData();
      data.data ? setContentdata(data.data) : setContentdata([]);
    } catch (error) {}
    setLoading(false);
  };
  // ----------------------------------------------Delete API CALL------------------------------------------

  const handleDeleteApi = async (id) => {
    try {
      const { data } = await deleteContentVisApi(id);
      data.httpStatus === "OK" && fetchData();
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-100">
      <div className="container-fluid px-3">
        <div className="metaBox position-relative d-block border-0 smtb">
          {loading && <Loading title="loading..." />}
          {contentData.length <= 0 ? (
            <>
              <Lottie options={defaultOptions} height={400} width={400} />
              <h6> Click on add button to add hold data</h6>
            </>
          ) : (
            <>
              <div className="w-100">
                <div className="table-responsive lightthm-wrap  border-top-0">
                  <table className="table table-lightthm text-left">
                    <thead className="bg-light">
                      <tr>
                        <th width={20}>S.no.</th>
                        <th>Source</th>
                        <th>Supplier</th>
                        <th>Origin</th>
                        <th>Destination</th>
                        <th>No.Of Solution</th>
                        <th>Status</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contentData.map((item, i) => (
                        <Table
                          i={i}
                          data={[
                            i + 1,
                            item.source,
                            item.supplier,
                            item.origin,
                            item.destination,
                            item.noOfSolution,
                            <div
                              className={`${
                                item.enabled ? "text-success" : "text-danger"
                              }`}
                            >
                              <i
                                className={`fa-solid fa-circle`}
                                style={{
                                  fontSize: "10px",
                                  marginRight: "4px",
                                }}
                              ></i>
                              <span className="">
                                {item.enabled ? "Active" : "Inactive"}
                              </span>
                            </div>,
                            <div className="btni-sp">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Edit</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft-success"
                                  onClick={() => {
                                    setEditData(item);
                                    setShowEditModal(true);
                                  }}
                                >
                                  <i className="fa-solid fa-pencil"></i>
                                </button>
                              </OverlayTrigger>
                            </div>,
                            <div className="btni-sp">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm btn-soft-danger"
                                  onClick={() => handleDeleteApi(item._id)}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </OverlayTrigger>
                            </div>,
                          ]}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          <div className="text-right px-3 py-4">
            <button
              className="btn-submit btnver-2 success"
              onClick={() => setShowModal(true)}
            >
              Add <i className="fa-solid fa-plus icon-add"></i>
            </button>
          </div>
        </div>
      </div>
      <Modals
        show={showModal}
        HandleClose={() => setShowModal(false)}
        ModalBody={
          <AddContentVisibleData
            allApi={allApi}
            allSource={allSource}
            fetchData={fetchData}
            setShowModal={setShowModal}
          />
        }
        title={"Add"}
      />
      <Modals
        show={showEditModal}
        HandleClose={() => setShowEditModal(false)}
        ModalBody={
          <UpdateContentVisData
            allApi={allApi}
            allSource={allSource}
            fetchData={fetchData}
            setShowEditModal={setShowEditModal}
            editData={editData}
          />
        }
        title={"Update"}
      />
    </div>
  );
};

export default ContentVisibleMgt;
