import axios from "axios";
import { AirTicketDetails_URL } from "../Urls";
export const GetBookingListData = ({ state, loading, pnr }) => {
  const options = {
    method: "POST",
    url: AirTicketDetails_URL,
    headers: { "Content-Type": "application/json" },
    data: { pnrNo: pnr },
  };

  axios
    .request(options)
    .then(function (response) {
      state(response.data);
      loading(false);
    })
    .catch(function (error) {
      console.error(error);
    });
};
