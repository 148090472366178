import React, { useEffect, useState } from "react";
import Loading from "../../../CustomComponent/Loading";
import loader from "../../../Image/93134-not-found.json";
import Lottie from "react-lottie";
import Swal from "sweetalert2";
import Modals from "../../../CustomComponent/Modals";
import "./IconStyle.css";
import AddSvgIconHandle from "./AddSvgIconHandle";
import {
  deleteIconApi,
  getAllSvgIconApi,
} from "../../../Services/Api/IconHandlerApi";
const IconHandler = () => {
  const [isEdit, setIsEdit] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [getIconData, setGetIconData] = useState([]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const fetchData = async () => {
    const response = await getAllSvgIconApi();
    setLoading(false);
    setGetIconData(response.data ? response.data : []);
  };
  // ----------------------------------------------Delete API CALL------------------------------------------

  const handleDeleteApi = async (id) => {
    try {
      const { data } = await deleteIconApi(id);
      data.httpStatus === "OK" && fetchData();
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="w-100">
      <div className="container-fluid px-3">
        <div className="metaBox position-relative d-block border-0 smtb">
          {loading && <Loading title="loading..." />}
          {getIconData.length <= 0 ? (
            <>
              <Lottie options={defaultOptions} height={400} width={400} />
              <h6> Click on add button to add SVG Icon</h6>
            </>
          ) : (
            <>
              <div className="w-100 showIcon">
                {getIconData.map((item, i) => (
                  <div className="iconCard" key={i}>
                    <img
                      src={`data:image/svg+xml;base64,${btoa(item.svg)}`}
                      width={30}
                      height={30}
                      alt="icon"
                    />
                    <p>{item.name}</p>
                    <i
                      className="fa-solid fa-trash-can"
                      onClick={() => {
                        if (window.confirm("Are you sure to delete Icon?")) {
                          handleDeleteApi(item._id);
                        }
                      }}
                    ></i>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="text-right px-3 py-4">
            <button
              className="btn-submit btnver-2 success"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Add <i className="fa-solid fa-plus icon-add"></i>
            </button>
          </div>
        </div>
      </div>
      <Modals
        show={showModal}
        backdrop={false}
        HandleClose={() => setShowModal(false)}
        ModalBody={
          <AddSvgIconHandle setShowModal={setShowModal} fetchData={fetchData} />
        }
        title={isEdit ? "Update" : "Add" + "Icon Handler"}
      />
    </div>
  );
};

export default IconHandler;
