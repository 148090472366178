import React from "react";

const ReturnTax = ({ modify }) => {
  const totalPessenger =
    modify.supplierFlight.adults +
    modify.supplierFlight.child ;
  return (
    <div className="table-responsive lightthm-wrap scroll-auto w-100">
    <table className="table table-lightthm text-left tbthm-2">
      <thead>
        <tr>
          <th scope="row">Adult tax</th>
          <td  className="text-right">{modify.supplierFlight.returnFlight.fare.adultTax}</td>
        </tr>
        <tr>
          <th scope="row">Child tax</th>
          <td  className="text-right"> {modify.supplierFlight.returnFlight.fare.childTax}</td>
        </tr>
        <tr>
          <th scope="row">Infant tax</th>
          <td  className="text-right"> {modify.supplierFlight.returnFlight.fare.infantTax}</td>
        </tr>
        <tr>
          <th scope="row">Convenience Fees</th>
          <td  className="text-right">
            {" "}
            {modify.supplierFlight.returnFlight.fare.convenienceFees *
              totalPessenger}
          </td>
        </tr>
        <tr>
          <th scope="row">Markup Id</th>
          <td  className="text-right"> {modify.supplierFlight.returnFlight.fare.markupID}</td>
        </tr>
        <tr>
          <th scope="row" className="extra_space">
            Extra
          </th>
          <td  className="text-right"></td>
        </tr>
      </thead>
    </table>
    </div>
  );
};

export default ReturnTax;
