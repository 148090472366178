import React from 'react'
import AutoUsers from '../Molecule/AutoUser/AutoUser'
import AutoUserSideBar from '../Molecule/AutoUser/AutoUserSideBar'

const AutoUser = () => {
  return (
    <div>
      {/* <AutoUserSideBar  Pagetitle={`Auto-Booking`}/> */}
      <AutoUsers />
    </div>
  )
}
export default AutoUser
