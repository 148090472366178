import React, { useEffect, useState } from "react";
import { getInsuranceBenefitApi } from "../../../Services/Api/insurance";
import ViewAllExistingBenfit from "./Tables/ViewAllExistingBenfit";
const ShowPlanBenefit = ({ planCode }) => {
  const [loading, setLoading] = useState(true);
  const [benefitList, setBenefitList] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await getInsuranceBenefitApi(planCode);
        setBenefitList(data.data ? data.data : []);
      } catch (error) {}
      setLoading(false);
    })();
  }, []);
  if (loading) {
    return <div className="text-center">Please Wait...</div>;
  }
  return (
    <div>
      {benefitList.length > 0 ? (
        <ViewAllExistingBenfit listData={benefitList} />
      ) : (
        <div className="text-center">No Available Benefit</div>
      )}
    </div>
  );
};

export default ShowPlanBenefit;
