import { commonheader } from "../../utils/header";
import { ImportTravFareRule_Url } from "../Urls";

export const ImportTravFareRule = async (fileInput, fileName,source) => {
  var formdata = new FormData();
  formdata.append("file", fileInput.files[0], fileName);
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
    headers:{"x-api-key": localStorage.getItem("token")}
  };
  try {
    const fetchData = await fetch(`${ImportTravFareRule_Url}/?source=${source}`, requestOptions);
    const dataa = await fetchData.json();
    return dataa;
  } catch (error) {
    return error;
  }
  // .then((response) => response.json())
  // .then((result) => {
  //   return result;
  // })
  // .catch((error) => {
  //   return error;
  // });
};
