import React from "react";

const Button = ({ title, onClick, color, other, ...props }) => {
  return (
    <>
      <button
        className={`${`btn ${color} btn-md ${other ? other : ""}`}`}
        onClick={onClick}
        {...props}
      >
        {title}
      </button>
    </>
  );
};

export default Button;
