// __________________________________________START LIVE __________________________________________
export const travel_url = "https://vbs.travomint.com/GOOGLE";
export const traveloes_url = "https://routes.traveloes.com";
export const gofirst_url = "https://cache.travomint.com/gofirst";
export const mca_url = "https://mca.travomint.com/API";
export const pay_int_url = "https://pay.travomint.com/intpay";
// __________________________________________ END LIVE __________________________________________

// __________________________________________ START DEVELOPMENT __________________________________________
// export const travel_url = "http://10.20.1.86:8128";
// export const traveloes_url = "http://10.20.1.100:1001";
// export const gofirst_url = "http://10.20.1.71:8888";
// export const mca_url = "http://10.20.1.86:9092";
// export const pay_int_url = "http://10.20.1.86:8080";
// __________________________________________ ENDDEVELOPMENT __________________________________________

// export default travel_url;

// ---------------------staging-------------------------------------
// export const travel_url =
//   "https://staging8.travomint.com/vbs-travomint-com/GOOGLE";
// // export const traveloes_url = "https://routes.traveloes.com";
// export const gofirst_url =
//   "https://staging17.travomint.com/supplier-travomint/gofirst";
// export const mca_url = "https://staging17.travomint.com/mca-travomint/API";
