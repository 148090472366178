import axios from "axios";
import { travel_url } from "../../../Host";
import { commonheader } from "../../../utils/header";

const insuranceInstance = axios.create({
  baseURL: travel_url,
});
insuranceInstance.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      config.headers = commonheader();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const getInsuranceDataApi = () =>
  insuranceInstance.get(`/Insurance/getAllHandler`);
export const deleteInsuranceHandleApi = (id) =>
  insuranceInstance.delete(`/Insurance/deleteHandler?token=${id}`);
export const addInsuranceApi = (data) =>
  insuranceInstance.post(`/Insurance/saveHandler`, data);
export const UpdateInsuranceHandleApi = (data) =>
  insuranceInstance.put(`/Insurance/updateHandler`, data);
export const getInsurancePlanApi = (country, category, iCode) =>
  insuranceInstance.get(
    `/Insurance/getPlansName?country=${country}&category=${category}&iCode=${iCode}`
  );
