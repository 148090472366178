import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Inputs from "../../../CustomComponent/Inputs";
import Button from "../../../CustomComponent/Button";
import { allProducts } from "../PaymentGateway/Modal/AddPaymentGateway";
import Select from "../../../CustomComponent/Select";
import Swal from "sweetalert2";
import { addGateWaySaveResApi } from "../../../Services/Api/gateSaveResponeMapping";
import {
  addInsuranceApi,
  getInsurancePlanApi,
  UpdateInsuranceHandleApi,
} from "../../../Services/Api/InsuranceHandleApi";
import { insuranceProviderList } from "./StaticInsuranceOpt";
import AmountType from "../../../SmallApi/AmountType.json";

const AddInsuranceHandler = ({
  allSource,
  fetchData,
  setShowModal,
  isEdit,
}) => {
  const [planList, setPlanList] = useState([]);

  const [planLoading, setPlanLoading] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState({});
  const { time, ...newEditData } = isEdit || {};
  const [load, setLoad] = useState(false);
  const [fieldData, setFieldData] = useState(
    isEdit
      ? newEditData
      : {
          source: "",
          currency: "",
          siteId: "",
          origin: "",
          destination: "",
          insuranceProvider: "",
          insuranceName: "",
          travelCategory: "",
          planCountry: "",
          insurancePlan: "",
          markup: "",
          priceFrom: "",
          priceTo: "",
          status: true,
          amountType: "",
        }
  );
  const {
    source,
    currency,
    siteId,
    origin,
    destination,
    insuranceProvider,
    insuranceName,
    travelCategory,
    planCountry,
    insurancePlan,
    markup,
    priceFrom,
    priceTo,
    status,
    amountType,
  } = fieldData;
  const handleInsuranceProvider = () => {
    const res = insuranceProviderList.find(
      (ele) => ele.name === insuranceProvider
    );
    setSelectedProvider(res);
  };
  const getInsurancePlan = async () => {
    setPlanLoading(true);
    const getPlanCtryId = selectedProvider.travelCountryList.find(
      (it) => it.name === planCountry
    ).valueCode;
    const getTravelCateId = selectedProvider.travelCategoryList.find(
      (it) => it.name === travelCategory
    ).valueCode;
    const getInsPlanNameId = selectedProvider.insuranceNameList.find(
      (it) => it.name === insuranceName
    ).valueCode;
    try {
      const { data } = await getInsurancePlanApi(
        getPlanCtryId,
        getTravelCateId,
        getInsPlanNameId
      );
      setPlanList(data.data ? data.data : []);
    } catch (error) {}
    setPlanLoading(false);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "currency") {
      value = value.toUpperCase();
    }
    setFieldData({ ...fieldData, [name]: value.trimStart() });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      const { data } = await (isEdit
        ? UpdateInsuranceHandleApi(fieldData)
        : addInsuranceApi({ ...fieldData }));
      if (data.httpStatus === "OK") {
        fetchData();
        setShowModal(false);
      }
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {}
    setLoad(false);
  };
  useEffect(() => {
    insuranceProvider && handleInsuranceProvider();
    isEdit && Object.keys(getInsurancePlan).length > 0 && getInsurancePlan();
  }, [insuranceProvider, isEdit]);

  return (
    <div>
      <div className="">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="source"
                label="Source"
                onChange={handleChange}
                optionValue={allSource.data}
                firstName={"Select"}
                required={true}
                classstyle="form-control"
                value={source}
                disabled={isEdit ? true : false}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="number"
                name="siteId"
                placeholder="Enter the Site ID"
                label="Site ID"
                onChange={handleChange}
                required={true}
                classstyle="form-control"
                value={siteId}
                disabled={isEdit ? true : false}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="text"
                name="currency"
                placeholder="Enter the currency code"
                label="Currency"
                onChange={handleChange}
                required={true}
                classstyle="form-control"
                value={currency}
                disabled={isEdit ? true : false}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="text"
                name="origin"
                placeholder="Enter the from country"
                label="From Country"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={origin}
                disabled={isEdit ? true : false}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="text"
                name="destination"
                placeholder="Enter the to country"
                label="To Country"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={destination}
                disabled={isEdit ? true : false}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="insuranceProvider"
                label="Insurance Provider"
                onChange={handleChange}
                optionValue={insuranceProviderList}
                firstName={"Select"}
                required={true}
                classstyle="form-control mt-4"
                value={insuranceProvider}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="insuranceName"
                optionValue={selectedProvider.insuranceNameList || []}
                firstName={"Select"}
                value={insuranceName}
                label="Insurance Name"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                disabled={!insuranceProvider}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="travelCategory"
                optionValue={selectedProvider.travelCategoryList || []}
                firstName={"Select"}
                label="Travel Category"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={travelCategory}
                disabled={!insuranceProvider}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="planCountry"
                optionValue={selectedProvider.travelCountryList || []}
                firstName={"Select"}
                label="Travel Country"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={planCountry}
                disabled={!insuranceProvider}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="insurancePlan"
                optionValue={planList}
                firstName={
                  planLoading
                    ? "Loading..."
                    : isEdit && planList.length === 0
                    ? insurancePlan
                    : "Select"
                }
                label="Insurance Plan"
                onChange={handleChange}
                required={!insurancePlan && true}
                classstyle="form-control mt-4"
                value={insurancePlan}
                onClick={() =>
                  insuranceName &&
                  planCountry &&
                  travelCategory &&
                  getInsurancePlan()
                }
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="number"
                name="markup"
                placeholder="Enter the markup"
                label="Markup"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={markup}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="number"
                name="priceFrom"
                placeholder="Enter the price from"
                label="Price From"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={priceFrom}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Inputs
                type="number"
                name="priceTo"
                placeholder="Enter the price To"
                label="Price To"
                onChange={handleChange}
                required={true}
                classstyle="form-control mt-4"
                value={priceTo}
              />
            </Col>{" "}
            <Col xs={12} md={6} xl={4}>
              <Select
                name="amountType"
                placeholder=""
                label="Amount Type"
                onChange={handleChange}
                optionValue={AmountType}
                required={true}
                classstyle="form-control mt-4"
                value={amountType}
              />
            </Col>
            <Col xs={12} md={6} xl={4}>
              <Select
                name="status"
                placeholder=""
                label="Status"
                onChange={handleChange}
                optionValue={[
                  { id: 1, name: "Active", value: true },
                  { id: 2, name: "Inactive", value: false },
                ]}
                required={true}
                classstyle="form-control mt-4"
                value={status}
              />
            </Col>
            <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
              <Button
                type="submit"
                title={load ? "Please Wait..." : "Submit"}
                disabled={load}
                color="btn btn-submit btnver-2  btn-md "
              />
            </div>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default AddInsuranceHandler;
