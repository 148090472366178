import React from "react";
import { Modal } from "react-bootstrap";
const HitApiModal = ({
  handlecloseApihit,
  apiHitShow,
  allSource,
  ApiName,
  allApi,
  setSourceApihit,
  setSupplierApihit,
  suppliers,
  sources,
  supplierApihit,
  sourceApihit,
  setHitLimitValue,
  hitLimitValue,
  onClick,
}) => {
  return (
    <Modal size="xl" className="modalbooknow-classic metadialog" aria-labelledby="contained-modal-title-vcenter" centered show={apiHitShow} onHide={handlecloseApihit}>
      <Modal.Header closeButton>
        <Modal.Title className="modal-Title">Add Meta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
        <div className="filter-css p-0 shadow-0">
              <div className="input-classicsite  mb-4">
                <label className="form-label">
                  Source
                </label>
                <div className="input-group"> 
                  <select
                    className="form-select"
                    value={sourceApihit}
                    aria-label="Default select example"
                    onChange={(e) => setSourceApihit(e.target.value)}
                  >
                    <option selected >select source</option>
                    {sources == null ||
                      sources === undefined ||
                      sources.data === undefined ||
                      sources.data == null ? (
                      <h3>No Record Found</h3>
                    ) : (
                      <>
                        {sources.data.map((item) => (
                          <option value={item.name}>{item.name}</option>
                        ))}
                      </>
                    )}
                  </select>
                </div>
              </div>

              <div className="input-classicsite  mb-4">
                <label className="form-label">
                  Supplier
                </label>
                <div className="input-group"> 
                  <select
                    className="form-select"
                    value={supplierApihit}
                    aria-label="Default select example"
                    onChange={(e) => setSupplierApihit(e.target.value)}
                  >
                    <option selected >Select API</option>
                    <option value="CLARITY">CLARITY</option>
                    {/* 
                    {suppliers == null ||
                    suppliers === undefined ||
                    suppliers.data === undefined ||
                    suppliers.data == null ? (
                      <h3>No Record Found</h3>
                    ) : (
                      <>
                        {suppliers.data.map((item) => (
                          <option value={item.name}>{item.name}</option>
                        ))}
                      </>
                    )} */}
                  </select>
                </div>
              </div>

              <div className="input-classicsite  mb-4">
              <label className="form-label">
              Hit Limit
                </label>
              <div className="input-group"> 
                <input type="number" className="form-control" value={hitLimitValue} onChange={(e) => setHitLimitValue(e.target.value)}
                />
              </div>
              </div>


            </div>
        </form>
      </Modal.Body>

      {supplierApihit && sourceApihit && hitLimitValue && (
        <Modal.Footer className="col-12 text-right border-top mt-1 py-4">
          <button type="submit" className="btn btn btn-submit btnver-2" onClick={onClick}>
            submit
          </button>
        </Modal.Footer>
      )}

 
    </Modal>
  );
};

export default HitApiModal;
