import React from "react";
import pageNot from "../../src/Image/pageNot.gif";
const PageNotFound = () => {
  return (
    <div className="w-100 page_Not_found metaDashboard pr-0-adms">
      <div className=" w-100 h-100 d-flex justify-content-center align-items-center">
        <div className="">
          <img src={pageNot} alt="PAGE_NOT_FOUND" width={400} className="" />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
