import React, { useState } from "react";
import DashboardBar from "../Molecule/Dashboard/DashboardSideBar";
import DashboardData from "../Molecule/Dashboard/Dashboard";
import ApiTrackingDeveloper from "../Molecule/ApiTrackingDeveloper/ApiTrackingDevloper";
import Screen from "../CustomComponent/Screen";

const Dashboard = () => {
  const [activetab,setActiveTab]=useState(1)
  return (
    <div>
      <Screen>
        <div className="w-100 d-flex align-items-center p-2 custom_tabs">
          {tabs.map(tab=>{
            return <div onClick={()=>setActiveTab(tab.id)} className={`tab ${activetab===tab.id && "active"}`}>{tab.name}</div>
          })}
        </div>
      </Screen>
      {/* <DashboardBar Pagetitle={`Flight Auto Booking`}/> */}
      {activetab===1 && <DashboardData/>} 
      <ApiTrackingDeveloper activetab={activetab}/>
    </div>
  );
};

export default Dashboard;
const tabs = [ 
  { id: 1, name: "Auto Ticketing Handler" },
  { id: 2, name: "Supplier Handler" },
  { id: 3, name: "Supplier Config Hit Limit" },
  { id: 4, name: "Supplier Search history" },
  // { id: 5, name: "Supplier Preferred Airline" },
  { id: 6, name: "Airline Fare Identifier" },
  {id:7,name:"Airline Booking Preference"}
];