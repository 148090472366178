import React from "react";

const Screen = ({ children }) => {
  return (
    <div className="w-100 metaDashboard pr-0-adms pb-0 pt-1 pb-0 pt-1">
      <div className="container-fluid px-3 mb-4">{children}</div>
    </div>
  );
};

export default Screen;
