import React, { useState } from "react";
import Button from "../../../../CustomComponent/Button";
import Select from "../../../../CustomComponent/Select";
import { Col, Row } from "react-bootstrap";
import Inputs from "../../../../CustomComponent/Inputs";
import { updateContentVisApi } from "../../../../Services/Api/coontentVisibleMgtApi";
import Swal from "sweetalert2";

const UpdateContentVisData = ({
  allSource,
  allApi,
  editData,
  setShowEditModal,
  fetchData,
}) => {
  const [load, setLoad] = useState(false);
  const [fieldData, setFieldData] = useState(editData);
  const {
    source,
    destination,
    supplier,
    enabled,
    noOfSolution,
    origin,
  } = fieldData ? fieldData : editData;
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFieldData({ ...fieldData, [name]: value.trim() });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { time, ...rest } = fieldData;
    setLoad(true);
    try {
      const { data } = await updateContentVisApi(rest);
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 2000,
      });
      if (data.httpStatus === "OK") {
        fetchData();
        setShowEditModal(false);
      }
    } catch (error) {}
    setLoad(false);
  };
  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="origin"
              placeholder="i.e, AI,6E,UK etc."
              label="Origin"
              onChange={handleChange}
              required={true}
              defaultValue={origin}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="text"
              name="destination"
              placeholder="i.e, AI,6E,UK etc."
              label="Destination"
              onChange={handleChange}
              required={true}
              defaultValue={destination}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Inputs
              type="number"
              name="noOfSolution"
              placeholder="Enter the No.of solutions"
              label="No.Of Solution"
              onChange={handleChange}
              required={true}
              defaultValue={noOfSolution}
              classstyle="form-control"
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="source"
              placeholder=""
              label="Source"
              onChange={handleChange}
              optionValue={allSource.data}
              firstName={"Select"}
              defaultValue={source}
              required={true}
              classstyle="form-control mt-4"
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="supplier"
              placeholder="Supplier"
              label="Supplier"
              onChange={handleChange}
              optionValue={allApi.data}
              firstName={"Select"}
              required={true}
              defaultValue={supplier}
              classstyle="form-control mt-4"
            />
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Select
              name="enabled"
              placeholder=""
              label="Status"
              defaultValue={enabled}
              onChange={handleChange}
              optionValue={[
                { id: 1, name: "Active", value: true },
                { id: 2, name: "Inactive", value: false },
              ]}
              required={true}
              classstyle="form-control mt-4"
            />
          </Col>
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title={load ? "Please Wait..." : "Submit"}
              disabled={load}
              color="btn btn-submit btnver-2  btn-md "
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default UpdateContentVisData;
