import React, { useEffect, useState } from "react";
import Modals from "../CustomComponent/Modals";
import Lottie from "react-lottie";
import Screen from "../CustomComponent/Screen";
import Loading from "../CustomComponent/Loading";
import loader from "../Image/93134-not-found.json";
import { GetAllSources } from "../Apis/FlightSource/GetAllSources";
import { useNavigate } from "react-router";
import { MarkUpList } from "../Apis/Markup/MarkUpList";
import Table from "../CustomComponent/Table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddMarkUpModalBody from "../Molecule/MarkUpDeatils/AddMarkUpModalBody";
import UpdateModalBody from "../Molecule/MarkUpDeatils/UpdateModalBody";
const MarkUpDetails = () => {
  const [isEditData, setIsEditData] = useState("");
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [showModal, setShowModal] = useState(false);
  const [markupData, setMarkupData] = useState({});
  const [allSource, setAllSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    MarkUpList({
      state: setMarkupData,
      loading: setLoading,
      navigate,
    });
  };
  useEffect(() => {
    fetchData();
    GetAllSources({
      state: setAllSource,
      state2: () => {},
      error: () => {},
    });
  }, []);

  return (
    <Screen>
      <div className="w-100">
        <div className="container-fluid px-3">
          <div className="metaBox position-relative d-block border-0 smtb">
            {loading && <Loading title="loading..." />}
            {!markupData.data ? (
              <>
                <Lottie options={defaultOptions} height={400} width={400} />
                <h6> Click on add button to add Markup</h6>
              </>
            ) : (
              <>
                <div className="w-100">
                  <div className="table-responsive lightthm-wrap  border-top-0">
                    <table className="table table-lightthm text-left">
                      <thead className="bg-light">
                        <tr>
                          <th width={20}>S.no.</th>
                          <th scope="col" width={30}>
                            Markup ID
                          </th>
                          <th scope="col">Airline</th>
                          <th scope="col">From Country</th>
                          <th scope="col">To Country</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Amount Type</th>
                          <th scope="col">MarkupType</th>
                          <th scope="col">Source</th>
                          <th scope="col">Currency</th>
                          <th scope="col">Site ID</th>
                          <th scope="col">Convenience Fee</th>
                          <th scope="col">Update</th>
                          {/* <th scope="col">Expired</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {markupData.data.map((item, i) => (
                          <Table
                            i={i}
                            data={[
                              i + 1,
                              item.markupId,
                              item.airline,
                              item.originCountry,
                              item.destinationCountry,
                              item.amount,
                              item.amountType,
                              item.markupType,
                              item.source,
                              item.currency,
                              item.siteId,
                              item.convenienceFee,
                              <div className="btni-sp">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Edit</Tooltip>}
                                >
                                  <button
                                    className="btn btn-sm btn-soft-success"
                                    onClick={() => {
                                      setIsEditData(item);
                                      setShowModal(true);
                                    }}
                                  >
                                    <i className="fa-solid fa-pencil"></i>
                                  </button>
                                </OverlayTrigger>
                              </div>,
                            ]}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            <div className="text-right px-3 py-4">
              <button
                className="btn-submit btnver-2 success"
                onClick={() => {
                  setShowModal(true);
                  setIsEditData("");
                }}
              >
                Add <i className="fa-solid fa-plus icon-add"></i>
              </button>
            </div>
          </div>
        </div>
        <Modals
          show={showModal}
          HandleClose={() => setShowModal(false)}
          ModalBody={
            isEditData ? (
              <UpdateModalBody
                fetchData={fetchData}
                allSource={allSource}
                setShowModal={setShowModal}
                editData={isEditData}
              />
            ) : (
              <AddMarkUpModalBody
                fetchData={fetchData}
                allSource={allSource}
                setShowModal={setShowModal}
              />
            )
          }
          title={(isEditData ? "Update" : "Add") + " Markup Details"}
        />
      </div>
    </Screen>
  );
};

export default MarkUpDetails;
