import React from "react";
import Screen from "../CustomComponent/Screen";
import Button from "../CustomComponent/Button";
import { useState, useEffect } from "react";
import Modals from "../CustomComponent/Modals";
import AddPromoModal from "../Molecule/ManagePromo/Modal/AddPromoModal";
import { gofirst_url, travel_url } from "../Host";
import axios from "axios";
import Loading from "../CustomComponent/Loading";
import { commonheader } from "../utils/header";

const ManagePromo = () => {
  const [showAddModal, setShowModal] = useState(false);
  const [promodata, setPromoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sources, setSource] = useState([]);
  const [suppliers, setSupplier] = useState([]);
  const [isEdit, setISEdit] = useState("");
  const getSourceApi = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${travel_url}/getSource`, {
        headers: commonheader(),
      });
      setSource(data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const GetSupplierAPI = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${travel_url}/getAPI`, {
        headers: commonheader(),
      });
      setSupplier(data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${gofirst_url}/promo/get`, {
        headers: commonheader(),
      });
      data.data && setPromoData(data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
    getSourceApi();
    GetSupplierAPI();
  }, []);
  // console.log("first",sources,suppliers)
  const handleEdit = (data) => {
    setISEdit(data);
    setShowModal(true);
  };
  return (
    <Screen>
      {loading && <Loading title="loading..." />}
      <div className=" text-right pb-3  d-flex">
        <Button
          title={
            <>
              <i className="fa-solid fa-plus icon-add"></i> Add
            </>
          }
          color="btn-submit btnver-2 success ms-auto"
          onClick={() => {
            setShowModal(true);
            setISEdit("");
          }}
        />
      </div>

      {promodata.length > 0 && (
        <div className="table-responsive lightthm-wrap scroll-auto">
          <table className="table table-lightthm text-left">
            <thead className="bg-light">
              <tr>
                <th scope="col" width={30}>
                  S.No.
                </th>
                <th scope="col">Promo Code</th>
                <th scope="col">Airline</th>
                <th scope="col">Status</th>
                <th scope="col">Source</th>
                <th scope="col">Supplier</th>
                <th scope="col">Apply On</th>
                <th scope="col">Description</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
              {promodata.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.promoCode}</td>
                    <td>{item.airline}</td>
                    <td
                      className={`${
                        item.active ? "text-success" : "text-secondary"
                      }`}
                    >
                      <i
                        className={`fa-solid fa-circle`}
                        style={{ fontSize: "10px", marginRight: "4px" }}
                      ></i>
                      <span className="">
                        {item.active ? "Active" : "Inactive"}
                      </span>
                    </td>
                    <td>{item.source ? item.source.toString() : ""}</td>
                    <td>{item.supplier}</td>
                    <td>{item.applyOn}</td>
                    <td>{item.desc}</td>
                    <td>
                      <div class="btni-sp">
                        <button
                          onClick={() => handleEdit(item)}
                          className={`btn btn-sm btn-soft-success`}
                        >
                          <div>
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                          </div>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <Modals
        show={showAddModal}
        HandleClose={() => {
          setShowModal(false);
          setISEdit("");
        }}
        ModalBody={
          <AddPromoModal
            setShowModal={setShowModal}
            fetchData={fetchData}
            isEdit={isEdit}
            sources={sources}
            suppliers={suppliers}
          />
        }
        title={isEdit ? "Update" : "Add"}
      />
    </Screen>
  );
};

export default ManagePromo;
