import axios from "axios";
import { travel_url } from "../../Host";
export const DeleteCachePriceFeed = ({ data, loading, navigate }) => {
  loading(true);
  const options = {
    method: "DELETE",
    url: `${travel_url}/feed/delete`,
    params: { id: data },
    headers: {
      "x-api-key": localStorage.getItem("token"),
    },
  };
  axios
    .request(options)
    .then(function (response) {
      alert(response.data.msg);
      if (response.data.httpStatus == "OK") {
        loading(false);
      }
      loading(false);
    })
    .catch(function (error) {
      console.error(error);
      if (error.response.status == 401) {
        navigate("/login");
        localStorage.clear();
      }
      console.error(error);
    });
};
