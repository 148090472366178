import React from "react";
import axios from "axios";
import { useState } from "react";
import { travel_url } from "../../Host";
import swal from "sweetalert";

const useAddSupplierPreferredAirline = () => {
  const [
    saveSupplierPreferredAirlineData,
    setSaveSupplierPreferredAirlineData,
  ] = useState({});
  const [
    saveSupplierPreferredAirlineDataLoading,
    setSaveSupplierPreferredAirlineDataLoading,
  ] = useState(false);
  const saveSupplierPreferredAirline = async (body) => {
    setSaveSupplierPreferredAirlineDataLoading(true);
    const options = {
      method: "POST",
      url: `${travel_url}/savePreferredAirline`,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("token"),
      },
      data: body,
    };

    try {
    } catch (e) {}
    try {
      const apiData = await axios.request(options);
      const SaveData = await apiData.data;

      if (SaveData.httpStatus === "OK") {
        setSaveSupplierPreferredAirlineData(SaveData);
        swal("Add Successfully");
      } else {
        setSaveSupplierPreferredAirlineData(SaveData);
        swal("Some Error", "Please try again after some time");
      }
    } catch (e) {
      setSaveSupplierPreferredAirlineData({
        msg: "Server Error From Save Preferred Airline",
        httpStatus: "not ok",
      });
    } finally {
      setSaveSupplierPreferredAirlineDataLoading(false);
    }
  };
  return [
    saveSupplierPreferredAirlineData,
    saveSupplierPreferredAirlineDataLoading,
    saveSupplierPreferredAirline,
  ];
};

export default useAddSupplierPreferredAirline;
