import React from "react";

const Child_Fare = ({ modify }) => {
  return (
    <div className="table-responsive lightthm-wrap scroll-auto w-100">
      <table className="table table-lightthm text-left tbthm-2">
        <thead>
          <tr>
            <th scope="row">Adult markup</th>
            <td className="text-right">{modify.supplierFlight.onWordFlight.fare.adultMarkup}</td>
          </tr>
          <tr>
            <th scope="row">Child markup</th>
            <td className="text-right"> {modify.supplierFlight.onWordFlight.fare.childMarkup}</td>
          </tr>
          <tr>
            <th scope="row">Infant markup</th>
            <td className="text-right"> {modify.supplierFlight.onWordFlight.fare.infantMarkup}</td>
          </tr>
          <tr>
            <th scope="row">GST Fare</th>
            <td className="text-right">{modify.supplierFlight.onWordFlight.fare.gstPrice} </td>
          </tr>
          <tr>
            <th scope="row">Total Base fare</th>
            <td className="text-right"> {modify.supplierFlight.onWordFlight.fare.baseFare}</td>
          </tr>
          <tr>
            <th scope="row">Grand Total</th>
            <td className="text-right"> {modify.supplierFlight.onWordFlight.fare.grandTotal}</td>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default Child_Fare;
