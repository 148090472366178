import React from "react";
import { useState } from "react";
import axios from "axios";
import { travel_url } from "../../Host";

const useDeleteCacheManagement = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const setDelete = async (id) => {
    setLoading(true);
    const options = {
      method: "DELETE",
      url: `${travel_url}/cache/config/delete`,
      params: { id: id },
      headers: {
        "x-api-key": localStorage.getItem("token"),
      },
    };

    try {
      const apiCall = await axios.request(options);
      const data = await apiCall;
      setData(data.data);
      // .then(function (response) {
      //   console.log(response.data);
      // })
      // .catch(function (error) {
      //   console.error(error);
      // });
    } catch (e) {
      setData({
        httpStatus: "not ok",
        msg: "Server Error",
      });
      console.log(e);
    }
    setLoading(false);
  };
  return [data, loading, setDelete];
};

export default useDeleteCacheManagement;
