import React from "react";
import { useState } from "react";
import Button from "../../CustomComponent/Button";
import Loading from "../../CustomComponent/Loading";
import Modals from "../../CustomComponent/Modals";
import Screen from "../../CustomComponent/Screen";
import useGetSupplierPrefferedAirline from "../../hooks/SupplierPrefferedAirline/useGetSupplierPrefferedAirline";
import ResultNotFound from "../../Molecule/MarkUpDeatils/ErrorScreen/ResultNotFound";
import { SupplierPrefferedAirlineTable } from "../../Molecule/SupplierPreferredAirline";
import AddSupplierPreferredAirline from "../../Molecule/SupplierPreferredAirline/Modal/AddSupplierPreferredAirline";
import EditSupplierPreferredAirline from "../../Molecule/SupplierPreferredAirline/Modal/EditSupplierPreferredAirline";

import useGetSupplier from "../../hooks/Supplier/useGetSupplier";
import { useFetch } from "../../hooks/useFetch";
import { header } from "../../utils/header";
import { getSourceUrl } from "../../hooks/url";
import { travel_url } from "../../Host";
import swal from "sweetalert";
const SupPreAirline = () => {
  // State
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModalData, setShowEditModaldata] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const headerList = header();
  var myHeaders = headerList;
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  // Hooks
  const [data, supplierloading] =
    useGetSupplierPrefferedAirline(refreshLoading);
  const getSupplierData = useGetSupplier();
  const getMetaSource = useFetch(getSourceUrl, requestOptions);

  const closeAddModal = () => {
    setShowAddModal(false);
    setShowEditModal(false);
  };

  const onEdit = (item) => {
    setShowEditModaldata(item);
    setShowEditModal(true);
  };
  const onDelete = (item) => {
    setDeleteLoading(true);
    const options = {
      method: "DELETE",
      headers: {
        "x-api-key": localStorage.getItem("token"),
      },
    };

    fetch(`${travel_url}/deletePreAir?id=${item._id}`, options)
      .then((response) => response.json())
      .then((response) => {
        if (response.httpStatus === "OK") {
          setRefreshLoading(!refreshLoading);
        } else {
          swal(response.msg);
        }
      })
      .catch((err) => swal("Server Error"))
      .finally(() => setDeleteLoading(false));
  };

  if (
    deleteLoading ||
    supplierloading ||
    getMetaSource.loading ||
    getSupplierData.getSupplierLoading
  ) {
    return <Loading title="Loading..." />;
  }

  if (data.msg === "Server Error From AllPreferredAirline") {
    return <ResultNotFound title="Server Error From AllPreferredAirline" />;
  }

  if (getMetaSource.error) {
    return <ResultNotFound title="Server Error From AllPreferredAirline" />;
  }

  if (getSupplierData.getSupplier.msg === "Server Error From Supplier") {
    return <ResultNotFound title="Technical Error From Supplier" />;
  }
  if (getMetaSource.response.httpStatus !== "OK") {
    return <ResultNotFound title="Meta Not Found..." />;
  }

  return (
    <Screen>
      <div className=" text-right pb-3  d-flex">
        <Button
          title={
            <>
              <i className="fa-solid fa-plus icon-add"></i> Add
            </>
          }
          color="btn-submit btnver-2 success ms-auto"
          onClick={() => setShowAddModal(true)}
        />
      </div>
      {data.httpStatus === "OK" ? (
        <SupplierPrefferedAirlineTable
          getData={data}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ) : (
        <ResultNotFound />
      )}

      <Modals
        show={showAddModal}
        HandleClose={closeAddModal}
        ModalBody={
          <AddSupplierPreferredAirline
            setRefreshloading={setRefreshLoading}
            refreshLoading={refreshLoading}
            setShowAddModal={setShowAddModal}
            supplierList={getSupplierData.getSupplier.data}
            metaList={getMetaSource.response.data}
          />
        }
        title="Add"
      />
      <Modals
        show={showEditModal}
        HandleClose={closeAddModal}
        ModalBody={
          <EditSupplierPreferredAirline
            data={showEditModalData}
            setRefreshloading={setRefreshLoading}
            refreshLoading={refreshLoading}
            setShowAddModal={setShowEditModal}
          />
        }
        title="Update"
      />
    </Screen>
  );
};

export default SupPreAirline;
