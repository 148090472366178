import React, { useEffect, useState } from "react";
import Loading from "../../../CustomComponent/Loading";
import Lottie from "react-lottie";
import loader from "../../../Image/93134-not-found.json";
import { deleteHoldticketApi, getHoldticketApi } from "../../../Services/Api/holdTicketHandleApi";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import AddHoldTicketModal from "./AddHoldTicketModal";
import Table from "../../../CustomComponent/Table";
import Swal from "sweetalert2";
import UpdateHoldTicket from "./UpdateHoldTicket";
const HoldTicketHandler = ({ allSource, allApi }) => {
  const [loading, setLoading] = useState(true);
  const [holdData, setHolddata] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditMdl, setShowEditMdl] = useState(false);
const [editData, setEditData] = useState({})
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const fetchData = async () => {
    try {
      const { data } = await getHoldticketApi();
      data.data ? setHolddata(data.data) : setHolddata([]) ;
    } catch (error) {}
    setLoading(false);
  };
  // ----------------------------------------------Delete API CALL------------------------------------------

  const handleDeleteApi = async (id) => {
    try {
      const { data } = await deleteHoldticketApi(id);
      data.httpStatus === "OK" && fetchData();
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-100">
      <div className="container-fluid px-3">
        <div className="metaBox position-relative d-block border-0 smtb">
          {loading && <Loading title="loading..." />}
          {holdData.length <= 0 ? (
            <>
              <Lottie options={defaultOptions} height={400} width={400} />
              <h6> Click on add button to add hold data</h6>
            </>
          ) : (
            <>
              <div className="w-100">
                <div className="table-responsive lightthm-wrap  border-top-0">
                  <table className="table table-lightthm text-left">
                    <thead className="bg-light">
                      <tr>
                        <th width={20}>S.no.</th>
                        <th>Source</th>
                        <th>Supplier</th>
                        <th>Airlines</th>
                        <th>Trip Type</th>
                        <th>Status</th>
                        <th>Hold</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {holdData.map((item, i) => {
                        return (
                          <>
                            <Table
                              i={i}
                              data={[
                                i + 1,
                                item.source,
                                item.supplier,
                                item.airlines,
                                item.tripType==="1"?"One Way":item.tripType==="2"?"Round Trip":"All",
                                <div
                                  className={`${
                                    item.enable ? "text-success" : "text-danger"
                                  }`}
                                >
                                  <i
                                    className={`fa-solid fa-circle`}
                                    style={{
                                      fontSize: "10px",
                                      marginRight: "4px",
                                    }}
                                  ></i>
                                  <span className="">
                                    {item.enable ? "Active" : "Inactive"}
                                  </span>
                                </div>,
                                <div
                                  className={`${
                                    item.hold ? "text-success" : "text-danger"
                                  }`}
                                >
                                  <i
                                    className={`fa-solid fa-circle`}
                                    style={{
                                      fontSize: "10px",
                                      marginRight: "4px",
                                    }}
                                  ></i>
                                  <span className="">
                                    {item.hold ? "Active" : "Inactive"}
                                  </span>
                                </div>,
                                ,
                        <div className="btni-sp">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                          >
                            <button
                              className="btn btn-sm btn-soft-success"
                              onClick={() => {setEditData(item); setShowEditMdl(true);}}
                            >
                              <i className="fa-solid fa-pencil"></i>
                            </button>
                          </OverlayTrigger>
                        </div>,
                                <div className="btni-sp">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Delete</Tooltip>}
                                  >
                                    <button
                                      className="btn btn-sm btn-soft-danger"
                                      onClick={() => handleDeleteApi(item._id)}
                                    >
                                      <i className="fa-solid fa-trash"></i>
                                    </button>
                                  </OverlayTrigger>
                                </div>,
                              ]}
                            />
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          <div className="text-right px-3 py-4">
            <button
              className="btn-submit btnver-2 success"
              onClick={() => setShowModal(true)}
            >
              Add <i className="fa-solid fa-plus icon-add"></i>
            </button>
          </div>
          <Modal
            size="xl"
            className="modalbooknow-classic metadialog"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <AddHoldTicketModal
              setShowModal={setShowModal}
              allSource={allSource}
              allApi={allApi}
              fetchData={fetchData}
            />
          </Modal>
          {/* ----------------------------Update Modal---------------------------------------- */}
          <Modal
            size="xl"
            className="modalbooknow-classic metadialog"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showEditMdl}
            onHide={() => setShowEditMdl(false)}
          >
            <UpdateHoldTicket
              setShowModal={setShowEditMdl}
              editData={editData}
              setShowEditMdl={setShowEditMdl}
              fetchData={fetchData}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default HoldTicketHandler;
