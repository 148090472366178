import axios from "axios";
import { travel_url } from "../../../Host";
import { commonheader } from "../../../utils/header";

const insuranceInstance = axios.create({
  baseURL: travel_url,
});
insuranceInstance.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      config.headers = commonheader();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const addInsuranceApi = (data) =>
  insuranceInstance.post(`/addInsurance`, data);
export const updateInsuranceApi = (data) =>
  insuranceInstance.put(`/updateInsurance`, data);
export const getInsurnaceAllDataApi = () =>
  insuranceInstance.get(`/getAllInsurance`);
export const deleteInsuranceApi = (id) =>
  insuranceInstance.delete(`/deleteInsurance?token=${id}`);
export const getInsurancePremiumApi = (Code) =>
  insuranceInstance.get(`/Insurance/getPlansPremium?code=${Code}`);
export const getInsuranceBenefitApi = (Code) =>
  insuranceInstance.get(`/Insurance/getPlansBenefit?code=${Code}`);
