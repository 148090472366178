import React from "react";
import { AddTravoFareRuleApi } from "../../../SmallApi/TravoFAreRuleSmallApi/AddTravoFareRuleApi";
import Button from "../../../CustomComponent/Button";
import { Col, Row } from "react-bootstrap";
import Select from "../../../CustomComponent/Select";
import Inputs from "../../../CustomComponent/Inputs";
import { Fragment } from "react";
import { UpdateTravoFareRuleApi } from "../../../SmallApi/TravoFAreRuleSmallApi/UpdateTravoFareRuleApi";
import { useState } from "react";
import UpdateMarkup from "../../../Apis/trav_fare_rule/UpdateMarkUp";
import DateInput from "../../../CustomComponent/DateInput";
import AddMarkup from "../../../Apis/trav_fare_rule/AddMarkup";

const TravoFareRuleUpdateModals = ({
  data,
  supplierList,
  metaList,
  setRefresh,
  refresh,
  setShowUpdateModal,
  isduplicate,
}) => {
  const [travFareRueUpdate, setTravFareRueUpdate] = useState(data);
  const [loading, setLoading] = useState(false);
  const [getUpdateData, setGetUpdateData] = useState({});

  const onChange = (e) => {
    setTravFareRueUpdate({
      ...travFareRueUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const updateMarkpData = await UpdateMarkup(travFareRueUpdate);
      if (updateMarkpData === "error") {
        setGetUpdateData(updateMarkpData);
        setLoading(false);
      } else if (updateMarkpData.httpStatus === "OK") {
        setGetUpdateData(updateMarkpData);
        setShowUpdateModal(false);
        setRefresh(!refresh);
        setLoading(false);
      } else {
        setGetUpdateData(updateMarkpData);
        setLoading(false);
      }
    } catch (err) {}
  };
  // ----------------Duplicate add Data----------------
  const handleDuplicate = async (e) => {
    e.preventDefault();
    let { _id, ...rest } = travFareRueUpdate;
    try {
      setLoading(true);
      const addMrkpData = await AddMarkup(rest);
      if (addMrkpData.data && addMrkpData.httpStatus === "OK") {
        setRefresh(!refresh);
        setLoading(false);
        setShowUpdateModal(false);
      } else {
        alert(addMrkpData.msg);
        setLoading(false);
      }
    } catch (err) {}
  };

  return (
    <div>
      <form onSubmit={isduplicate ? handleDuplicate : onSubmit}>
        <div className="text-center text-danger">
          {loading ? "Loading..." : getUpdateData.msg}
        </div>
        <Row>
          {UpdateTravoFareRuleApi(
            data,
            supplierList,
            metaList,
            isduplicate
          ).map((item, i) => {
            const type = item.type;
            return (
              <Fragment key={item.id}>
                {["text", "number"].includes(type) ? (
                  <>
                    <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                      <Inputs
                        type={item.type}
                        name={item.name}
                        placeholder={item.placeholder}
                        label={item.label}
                        onChange={(e) => onChange(e)}
                        required={item.required}
                        defaultValue={item.defaultValue}
                        disabled={(item.name==="classOfServiceReturn" && !["0","2"].includes(travFareRueUpdate.trip))?true:item.disabled}
                        classstyle="form-control"
                      />
                    </Col>
                  </>
                ) : type === "date" ? (
                  <>
                    <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                      <DateInput
                        type={item.type}
                        name={item.name}
                        placeholder={item.placeholder}
                        label={item.label}
                        onChange={(e) => onChange(e)}
                        required={item.required}
                        defaultValue={item.defaultValue}
                        disabled={item.disabled}
                        classstyle="form-control"
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={12} md={6} xl={4} className="mb-3" key={i}>
                      <Select
                        name={item.name}
                        placeholder={item.placeholder}
                        label={item.label}
                        onChange={(e) => onChange(e)}
                        optionValue={item.optionValue}
                        required={item.required}
                        defaultValue={item.defaultValue}
                        disabled={item.disabled}
                        classstyle="form-control"
                      />
                    </Col>
                  </>
                )}
              </Fragment>
            );
          })}
          <div className="col-12 text-center border-top mt-4 pt-3 pb-3">
            <Button
              type="submit"
              title="Submit"
              color="btn btn-submit btnver-2  btn-md "
              // onClick={dublicateHandleSubmit}
            />
          </div>
        </Row>
      </form>
    </div>
  );
};

export default TravoFareRuleUpdateModals;
