import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { GetTravoFareRule } from "../../Apis/GetTravoFareRule";
import Loading from "../../CustomComponent/Loading";
import TravoFareRuleTable from "../../Molecule/Travofarerule/TravoFareRuleTable";
import ResultNotFound from "../../Molecule/MarkUpDeatils/ErrorScreen/ResultNotFound";
import Screen from "../../CustomComponent/Screen";
import Button from "../../CustomComponent/Button";
import Modals from "../../CustomComponent/Modals";
import TravoFareRuleAddModals from "../../Molecule/Travofarerule/Modals/TravoFareRuleAddModals";
import useGetSupplier from "../../hooks/Supplier/useGetSupplier";
import { getMetaUrl, getSourceUrl } from "../../hooks/url";
import { useFetch } from "../../hooks/useFetch";
import { commonheader, header } from "../../utils/header";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "../../CustomComponent/Select";
import NumberSelect from "../../CustomComponent/NumberSelect";
import TravoFareRuleUpdateModals from "../../Molecule/Travofarerule/Modals/TravoFareRuleUpdateModals";
import { EnableAndDisable } from "../../Apis/trav_fare_rule/EnableAndDisable";
import { DeleteMarkup } from "../../Apis/trav_fare_rule/DeleteMarkup";
import { DownloadTravFareRule } from "../../Apis/trav_fare_rule/DownloadTravFareRule";
import { ImportTravFareRule } from "../../Apis/trav_fare_rule/ImportTravFareRule";
import FareRuleFilter from "../../Molecule/FareRule/FareRuleFilter";
import { ProgressBar } from "react-bootstrap";
import promo from "../../Image/promo.png";
import QuickFareRuleEdit from "../../Molecule/Travofarerule/Modals/EditFareRuleTable";
import EditFareRuleTable from "../../Molecule/Travofarerule/Modals/EditFareRuleTable";
import axios from "axios";
import { gofirst_url, travel_url } from "../../Host";
import Swal from "sweetalert2";

const FareRule = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const headerList = header();
  var myHeaders = headerList;
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  // Hooks
  const getSupplierData = useGetSupplier();
  const getMetaSource = useFetch(
    getMetaUrl + `?id=${localStorage.getItem("u_d")}`,
    requestOptions
  );
  const [progrssbar, setProgrssBar] = useState("");
  const [getTravFareRuleData, setGetTravFareRuleData] = useState({});
  const [
    getTravFareRuleDataByIdForUpdate,
    setGetTravFareRuleDataByIdForUpdate,
  ] = useState({});
  const [
    getFareRuleDataByDuplicateId,
    setGetFareRuleDataByDuplicateId,
  ] = useState({});
  const [getTravFareRuleError, setGetTravFareRuleError] = useState();
  const [getTravFareRuleLoader, setGetTravFareRuleLoader] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showeditModal, setShowEditModal] = useState(false);
  const [alleditdata, setAllEditdata] = useState([]);
  const [selectedmeta, setSelectMeta] = useState("ALL");
  const [promos, setPromos] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [defaultResult, setDefaultResult] = useState("20");
  const [importfileValue, setImportfileValue] = useState("");
  const [importfileMsg, setImportfileMsg] = useState("");
  const [isfilter, setIsFilter] = useState(false);
  const [isMasterOn, setIsMasterOn] = useState(false);
  const [filterdata, setFilterData] = useState({
    api: "",
    adult: null,
    origin: "",
    amount: null,
    source: selectedmeta,
    child: null,
    infant: null,
    airline: "",
    classOfService: "0",
    classOfServiceReturn: "0",
    cappingDays: 0,
    cappingAmount: 0.0,
    siteId: null,
    enable: "",
    fareType: "",
    ruleName: "",
    markupType: "",
    amountType: "",
    destination: "",
    fromCountry: "",
    stops: null,
    toCountry: "",
    cabinType: null,
    convenienceFee: null,
    additionalDisAmount: null,
    additionalDisAmountType: "",
    fromDate: "",
    toDate: "",
    fromAmount: 0,
    toAmount: 0,
    trip: null,
    deleted: false,
    opAkAirline: "",
  });
  const fetchData = async () => {
    try {
      const getFareRule = await GetTravoFareRule(
        location.pathname.split("=")[1],
        defaultResult,
        filterdata
      );
      if (getFareRule === "error") {
        setGetTravFareRuleError(getFareRule);
        setGetTravFareRuleLoader(false);
      } else {
        Number(location.pathname.split("=")[1]) >
          Number(getFareRule.totalPages) &&
          navigate(`/trav-fare-rule/page=${getFareRule.totalPages}`);
        setGetTravFareRuleData(getFareRule);
        setGetTravFareRuleLoader(false);
        setIsFilter(false);
      }
    } catch (err) {
      setGetTravFareRuleError(err);
      setGetTravFareRuleLoader(false);
    }
  };
  //  ----------------PromoCode_API_Calling------------------
  const fetchPromoApi = async () => {
    try {
      const {
        data,
      } = await axios.get(
        `${gofirst_url}/promo/getBySource?source=${selectedmeta}`,
        { headers: commonheader() }
      );
      setPromos(data.data ? data.data : []);
    } catch (err) {
      console.log(err);
    }
  };
  // ------------GetMAster_Enable_Data________________________
  const fetchMasterEnable = async () => {
    try {
      const {
        data,
      } = await axios.get(
        `${travel_url}/travomint/fare/get/ena-dis?source=${selectedmeta}`,
        { headers: commonheader() }
      );
      if (data) {
        setIsMasterOn(data.status);
      } else {
        setIsMasterOn(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData(false);
    selectedmeta && fetchPromoApi();
    selectedmeta && fetchMasterEnable();
  }, [
    refresh,
    defaultResult,
    location,
    selectedmeta,
    filterdata.enable,
    filterdata.deleted,
  ]);
  // ________________
  const applyIsMasterEnable = async (e) => {
    Swal.fire({
      title: `Do you want to ${
        isMasterOn ? "disable" : "enable"
      } ${selectedmeta} personal rules?`,
      showDenyButton: true,
      confirmButtonText: "YES",
      denyButtonText: `NO`,
      width: "800px",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const {
            data,
          } = await axios.post(
            `${travel_url}/travomint/fare/enable-disable?source=${selectedmeta}&status=${!isMasterOn}`,
            "",
            { headers: commonheader() }
          );
        } catch (err) {
          console.log(err);
        }
      } else {
        fetchMasterEnable();
      }
    });
  };

  const updateFareRule = (item) => {
    setGetTravFareRuleDataByIdForUpdate(item);
    setShowUpdateModal(true);
  };
  const editFareRuleTable = (e, item) => {
    if (e.target.checked) {
      setAllEditdata([...alleditdata, item]);
    } else {
      setAllEditdata(alleditdata.filter((ele) => ele._id !== item._id));
    }
  };
  const enableAnddisable = async (item) => {
    try {
      const EanandDis = await EnableAndDisable(item._id);

      if (EanandDis === "error") {
        setGetTravFareRuleError(EanandDis);
        setGetTravFareRuleLoader(false);
      } else {
        setGetTravFareRuleLoader(false);
        setRefresh(!refresh);
      }
    } catch (err) {
      setGetTravFareRuleError("error");
      setGetTravFareRuleLoader(false);
    }
  };
  const deletebyID = async (item) => {
    try {
      const EanandDis = await DeleteMarkup(item._id);
      if (EanandDis === "error") {
        setGetTravFareRuleError(EanandDis);
        setGetTravFareRuleLoader(false);
      } else {
        setGetTravFareRuleLoader(false);
        setRefresh(!refresh);
      }
    } catch (err) {
      setGetTravFareRuleError("error");
      setGetTravFareRuleLoader(false);
    }
  };
  const onDuplicate = async (item) => {
    setGetFareRuleDataByDuplicateId(item);
    setShowDuplicateModal(true);
  };
  const importExcelData = async (e) => {
    e.preventDefault();
    setProgrssBar(50);
    const data = await ImportTravFareRule(
      importfileValue.target,
      importfileValue.target.value,
      selectedmeta
    );
    setProgrssBar(100);
    if (data.httpStatus === "OK") {
      setShowImportModal(false);
      setRefresh(!refresh);
      setProgrssBar("");
    } else {
      setImportfileMsg(data.msg);
      setProgrssBar("");
    }
  };
  // ----------------------------Restore API call----------------------------------
  const onRestoreClick = async (id) => {
    try {
      const { data } = await axios.put(
        `${travel_url}/travomint/restoreRule?id=${id}`,
        "",
        {
          headers: commonheader(),
        }
      );
      data.httpStatus === "OK" && setRefresh(!refresh);
      Swal.fire({
        icon: data.httpStatus === "OK" ? "success" : "error",
        text: data.msg,
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (error) {}
  };
  // ___________________Master_Play/Pause_________________________
  if (
    getTravFareRuleLoader ||
    getMetaSource.loading ||
    getSupplierData.getSupplierLoading
  ) {
    return <Loading title="Loading..." />;
  }
  if (getTravFareRuleError) {
    return <ResultNotFound title="Technical Error From Supplier" />;
  }
  return (
    <>
      <Screen>
        <div className="pb-3 d-flex justify-content-between">
          <div>
            <Button
              title={
                <>
                  <i className="fa-solid fa-filter me-2"></i> Filter
                </>
              }
              color="btn-submit btnver-2 success ms-3"
              disabled={!selectedmeta && true}
              onClick={() => setIsFilter(!isfilter)}
            />
          </div>
          <div className="d-flex  align-items-center">
            <div className="master_check form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="live"
                id="all_rule"
                checked={isMasterOn}
                value={isMasterOn}
                onChange={(e) => {
                  setIsMasterOn(!isMasterOn);
                  applyIsMasterEnable(e);
                }}
                disabled={!selectedmeta && true}
              />
            </div>
            <Button
              title={
                <>
                  <i className="fa-solid fa-download icon-add me-2"></i> Import
                </>
              }
              color="btn-submit btnver-2 success ms-3"
              onClick={() => setShowImportModal(true)}
              aria-controls="example-collapse-text"
              aria-expanded={isfilter}
            />
            <Button
              title={
                <>
                  <i className="fa-solid fa-upload icon-add me-2"></i> EXPORT
                </>
              }
              color="btn-submit btnver-2 success ms-3"
              onClick={() => DownloadTravFareRule(selectedmeta)}
            />
            <Button
              title={
                <>
                  <i className="fa-solid fa-plus icon-add me-2"></i> Add
                </>
              }
              color="btn-submit btnver-2 success ms-3"
              onClick={() => setShowAddModal(true)}
            />
          </div>
        </div>
        <FareRuleFilter
          isfilter={isfilter}
          supplierList={getSupplierData.getSupplier.data}
          metaList={selectedmeta}
          filterdata={filterdata}
          setFilterData={setFilterData}
          fetchData={fetchData}
        />
        <div className="d-flex justify-content-between my-3 align-items-center">
          <div className="d-flex align-items-center gap-4">
            <NumberSelect
              firstName="Default All Source"
              name="pageResult"
              placeholder="No of result in page"
              label="Source"
              onChange={(e) => {
                setSelectMeta(e.target.value ? e.target.value : "ALL");
                setFilterData({
                  ...filterdata,
                  source: e.target.value ? e.target.value : "ALL",
                });
              }}
              optionValue={getMetaSource.response.data.map((item, i) => {
                return { id: i + 1, name: item, value: item };
              })}
              required={true}
              defaultValue={selectedmeta}
              disabled={false}
              classstyle="form-control"
            />
            <NumberSelect
              name="pageResult"
              placeholder="No of result in page"
              label="No. Of Result"
              onChange={(e) => setDefaultResult(e.target.value)}
              optionValue={[
                {
                  id: 1,
                  name: "5",
                  value: "5",
                },
                {
                  id: 2,
                  name: "10",
                  value: "10",
                },
                {
                  id: 3,
                  name: "20",
                  value: "20",
                },
                {
                  id: 4,
                  name: "30",
                  value: "30",
                },
                {
                  id: 5,
                  name: "40",
                  value: "40",
                },
                {
                  id: 6,
                  name: "50",
                  value: "50",
                },
              ]}
              required={true}
              defaultValue="20"
              disabled={false}
              classstyle="form-control"
            />
            <div className="d-flex">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="enable"
                  id="gridRadios"
                  value="true"
                  // checked
                  onChange={(e) =>
                    setFilterData({
                      ...filterdata,
                      enable: true,
                      deleted: false,
                    })
                  }
                />
                <label className="form-check-label" for="gridRadios">
                  Active
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="enable"
                  id="gridRadiosk2"
                  value="false"
                  // checked
                  onChange={(e) =>
                    setFilterData({
                      ...filterdata,
                      enable: false,
                      deleted: false,
                    })
                  }
                />
                <label className="form-check-label" for="gridRadiosk2">
                  Inactive
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="enable"
                  id="gridRadiosk3"
                  value="false"
                  onChange={(e) =>
                    setFilterData({ ...filterdata, deleted: true, enable: "" })
                  }
                />
                <label className="form-check-label" for="gridRadiosk3">
                  View Deleted
                </label>
              </div>
            </div>

            {alleditdata.length > 0 && (
              <Button
                title={
                  <>
                    <i className="fa-solid fa-pencil"></i> Edit
                  </>
                }
                color="btn-submit btnver-2 success"
                onClick={() => setShowEditModal(true)}
              />
            )}
          </div>

          {selectedmeta && (
            <div className="text-left">
              <ul className="mb-3 pagination d-inline-flex justify-content-end blog_bxrowpagi flex-wrap mb-0 text-center">
                {1 !== Number(location.pathname.split("=")[1]) && (
                  <>
                    <li className="nv page-item itemnv-1">
                      <a
                        className="page-link"
                        onClick={() => navigate(`/trav-fare-rule/page=1`)}
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>

                    <li className="nv page-item itemnv-2">
                      <a
                        className="page-link"
                        onClick={() =>
                          navigate(
                            `/trav-fare-rule/page=${Number(
                              location.pathname.split("=")[1]
                            ) - 1}`
                          )
                        }
                      >
                        <span aria-hidden="true">&#8592;</span>
                      </a>
                    </li>
                  </>
                )}
                {Array(getTravFareRuleData.totalPages)
                  .fill(null)
                  .map((item, i) => {
                    return (
                      <li
                        key={i}
                        className={`page-item ${
                          Number(location.pathname.split("=")[1]) ===
                          Number(i + 1)
                            ? "active"
                            : ""
                        }`}
                      >
                        <a
                          onClick={() => {
                            navigate(`/trav-fare-rule/page=${i + 1}`);
                          }}
                          className={`page-link`}
                        >
                          {i + 1}
                        </a>
                      </li>
                    );
                  })}
                {getTravFareRuleData.totalPages !==
                  Number(location.pathname.split("=")[1]) && (
                  <>
                    <li className="nv page-item itemnv-3">
                      <a
                        className="page-link"
                        onClick={() =>
                          navigate(
                            `/trav-fare-rule/page=${Number(
                              location.pathname.split("=")[1]
                            ) + 1}`
                          )
                        }
                      >
                        <span aria-hidden="true"> &#8594;</span>
                      </a>
                    </li>

                    <li className="nv page-item itemnv-4">
                      <a
                        className="page-link"
                        onClick={() =>
                          navigate(
                            `/trav-fare-rule/page=${getTravFareRuleData.totalPages}`
                          )
                        }
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          )}
        </div>
        {/* ----------Promo_Code------------------------------------------------------ */}
        {promos.length > 0 && (
          <div className="mb-4">
            <h6 className="text-left">Prome Code</h6>
            <div className="d-flex align-content-center gap-2">
              {promos.map((pro, _i) => {
                return (
                  <div
                    className="avail_promo d-flex align-content-center gap-1"
                    key={_i}
                  >
                    <img src={promo} width={20} height={20} alt="" srcset="" />
                    <span>{pro}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {getTravFareRuleData.content.length > 0 ? (
          <>
            <TravoFareRuleTable
              getData={getTravFareRuleData}
              onEdit={updateFareRule}
              onEnable={enableAnddisable}
              onDelete={deletebyID}
              onEditTable={editFareRuleTable}
              onDuplicate={onDuplicate}
              alleditdata={alleditdata}
              filterdata={filterdata}
              onRestoreClick={onRestoreClick}
            />
          </>
        ) : (
          <ResultNotFound />
        )}

        <Modals
          show={showImportModal}
          HandleClose={() => {
            setImportfileMsg("");
            setShowImportModal(false);
          }}
          mclass="uploadby"
          ModalBody={
            <form onSubmit={(e) => importExcelData(e)}>
              <div class="pt-2 pb-5 mb-2">
                <label for="formFile" class="form-label fw-bold">
                  Select File here
                </label>
                <div class="">
                  <input
                    class="form-control"
                    type="file"
                    required
                    onChange={(e) => {
                      setImportfileValue(e);
                    }}
                    id="formFile"
                  />
                </div>
                {progrssbar && (
                  <ProgressBar animated now={progrssbar} className="mt-2" />
                )}
                {importfileMsg && (
                  <div class="alert alert-danger mt-3 py-2 text-sm">
                    {importfileMsg}
                  </div>
                )}
              </div>

              <div className="pb-3 text-right">
                <Button
                  title="Submit"
                  disabled={progrssbar ? true : false}
                  color="btn-submit btnver-2 success ms-auto"
                />
              </div>
            </form>
          }
          title="Import Data"
        />

        <Modals
          show={showAddModal}
          HandleClose={() => setShowAddModal(false)}
          ModalBody={
            <TravoFareRuleAddModals
              supplierList={getSupplierData.getSupplier.data}
              metaList={selectedmeta}
              setRefresh={setRefresh}
              refresh={refresh}
              setShowAddModal={setShowAddModal}
            />
          }
          title="Add"
        />
        <Modals
          show={showUpdateModal}
          HandleClose={() => setShowUpdateModal(false)}
          ModalBody={
            <TravoFareRuleUpdateModals
              data={getTravFareRuleDataByIdForUpdate}
              supplierList={getSupplierData.getSupplier.data}
              metaList={selectedmeta}
              setRefresh={setRefresh}
              refresh={refresh}
              setShowUpdateModal={setShowUpdateModal}
              isduplicate=""
            />
          }
          title="Update"
        />
        <Modals
          show={showDuplicateModal}
          HandleClose={() => setShowDuplicateModal(false)}
          ModalBody={
            <TravoFareRuleUpdateModals
              data={getFareRuleDataByDuplicateId}
              supplierList={getSupplierData.getSupplier.data}
              metaList={
                selectedmeta === "ALL"
                  ? selectedmeta
                  : getMetaSource.response.data
              }
              setRefresh={setRefresh}
              refresh={refresh}
              setShowUpdateModal={setShowDuplicateModal}
              isduplicate={showDuplicateModal}
            />
          }
          title="Duplicate"
        />
        <Modals
          show={showeditModal}
          HandleClose={() => setShowEditModal(false)}
          ModalBody={
            <EditFareRuleTable
              alleditdata={alleditdata}
              setAllEditdata={setAllEditdata}
              setShowEditModal={setShowEditModal}
            />
          }
          title="Edit"
        />
      </Screen>
    </>
  );
};

export default FareRule;
