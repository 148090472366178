export const insuranceProviderList = [
  {
    id: 1,
    name: "BLUE RIBBON",
    insuranceNameList: [
      { id: 1, name: "BLUE RIBBON", valueCode: "BLUE RIBBON" },
    ],
    travelCategoryList: [{ id: "", name: "Not Available", valueCode: "" }],
    travelCountryList: [{ id: 1, name: "Not Available", valueCode: "" }],
  },
  {
    id: 2,
    name: "ASEGO",
    insuranceNameList: [
      { id: 1, name: "ICICI", valueCode: "IC" },
      { id: 2, name: "Aditya Birla Group", valueCode: "AB" },
    ],
    travelCategoryList: [
      {
        id: 1,
        valueCode: "de5ee71c-098f-4cc0-b486-e69391cc9fa8",
        name: "Domestic Travel",
      },
      {
        id: 2,
        valueCode: "5f940bd2-c152-454a-83f0-ea54ad8788d6",
        name: "Overseas A2A",
      },
      {
        id: 3,
        valueCode: "6b123144-2e3a-490e-baeb-b59f09327b7c",
        name: "Overseas Travel",
      },
    ],
    travelCountryList: [
      { id: 1, name: "Excluding USA and CANADA", valueCode: "2" },
      { id: 2, name: "Including USA and CANADA (Worldwide)", valueCode: "1" },
      { id: 3, name: "India", valueCode: "4" },
    ],
  },
];
